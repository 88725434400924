import styled from 'styled-components';

import { SearchIcon } from '../../Icons';

export const SearchInputWrapper = styled.div`
  width: 100%;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 35px;
  position: relative;
  height: 68px;
  padding: 15px 14px;
  color: #212f52;
  display: flex;
  align-items: center;
  &:hover {
    background: #212f5214;
  }
`;

export const Search = styled(SearchIcon)``;

export const SearchInputBar = styled.input`
  padding-left: 30px;
  border: 0;
  outline: 0;
  width: 100%;
`;

export const SelectCityBox = styled.div`
  width: 100%;
  #react-select-3-placeholder {
    color: #212f52;
  }
`;
