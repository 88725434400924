import React from 'react';
import { Col } from 'react-bootstrap';
import {
  HeroBG,
  HeroBGOverlay,
  HeroTitle,
  HeroText,
  HeroPlaceDetail,
  Herocontainer,
  HeroContant,
} from './Style';
import HeroBGImage from '../../Assets/Images/HeroBGImage.jpg';
import LocationIcon from '../../Assets/Images/Icons/LocationIcon.svg';
import SearchBar from '../SearchBar';

const Hero: React.FC = () => (
  <HeroBG src={HeroBGImage}>
    <HeroBGOverlay>
      <Herocontainer fluid>
        <HeroContant>
          <Col xl={12} lg={12} md={12} sm={12}>
            <HeroTitle>A better way to stay</HeroTitle>
            <Col lg={8} md={8} sm={12}>
              <HeroText>
                Inspiring, award-wining design meets modern, mobil-first
                service. Welcome to the future of hospitality.
              </HeroText>
            </Col>
            <HeroPlaceDetail src={LocationIcon}>
              <p>Philadelphia</p>
              <p>2 bedroom stay</p>
            </HeroPlaceDetail>
          </Col>
          <SearchBar />
        </HeroContant>
      </Herocontainer>
    </HeroBGOverlay>
  </HeroBG>
);

export default Hero;
