import React from 'react';
import { Details, DotTag, Heading } from './styled';

const DirectlyFromYou: React.FC = () => (
  <>
    <Heading>Information We Collect Directly from You.</Heading>
    <Details>
      <p>
        <DotTag className="px-1">&bull;</DotTag>
        <b>Registration and check-in.</b> When you register with us through the
        Services or become a Gilbert Hotels member through the Services
        (“Member”) to book an accommodation at a Gilbert Hotels unit
        (“Accommodation”), we will ask you for certain Personal Information
        including your name, phone number, email address, home postal address,
        address of submitted property, password, date of birth, and booking
        information. We will also ask for your email address and password when
        you register as a Gilbert Hotels service partner via our Apps. You may
        be asked to provide a picture and ID for identity verification purposes
        at registration or check-in.
      </p>
      <p className="pt-2">
        <DotTag className="px-1">&bull;</DotTag>
        <b>Third Party Services.</b> We may make available to you at your
        election the services of third-party service providers, such as luggage
        storage, parking reservations, or meal/grocery delivery. When you access
        these providers via the Services you may be asked to provide certain
        Personal Information, such as your name, phone number, address of
        submitted property, drivers license number, and/or license plate number,
        which will be used by these service providers in the provision of their
        services.
      </p>
      <p className="pt-2">
        <DotTag className="px-1">&bull;</DotTag>
        <b> Payment Information.</b> If you make a purchase through the
        Services, your payment related information, such as credit card number
        or billing information, is collected by our third party payment
        processor on our behalf.
      </p>
      <p className="pt-2">
        <DotTag className="px-1">&bull;</DotTag>
        <b>
          Administration and personalization of your Gilbert Hotels Account
          profile.
        </b>
        We may collect any Personal Information you provide to us as part of the
        administration and personalization of your Gilbert Hotels account
        (“Gilbert Hotels Account”) profile (e.g., gender, zip code and
        individual preferences or demographic information). You may voluntarily
        provide us with information about yourself so that we can personalize
        your stays with us (e.g., the temperature you prefer, whether or not you
        have children, whether or not you need a crib, etc.).
      </p>
      <p className="pt-2">
        <DotTag className="px-1">&bull;</DotTag>
        <b> Communications. </b>If you contact us directly (e.g., via email,
        SMS, phone call, in-app messaging), we will collect your name, contact
        details, the content, date and time of your message and any attachments
        thereto, and other information you may directly provide to us. You may
        choose to submit your email address and phone number on our Site to
        receive our updates. You may also provide us with your reviews of our
        Services or complete surveys regarding our Services.
      </p>
      <p className="pt-2">
        <DotTag className="px-1">&bull;</DotTag>
        <b> Recruitment.</b> If you apply for a job via the “Careers” page on
        the Site, we will collect your name, contact details, email, phone
        number and information in your resume or curriculum vitae. We will also
        ask you to provide a link to your full LinkedIn profile. You may
        voluntarily share with us also your Twitter, GitHub or portfolio URLs or
        links to other websites, your current workplace, and any other
        information you want to share with us.
      </p>
      <p className="pt-2">
        <DotTag className="px-1">&bull;</DotTag>
        <b>COVID-19.</b> The wellbeing of our guests and team members is our top
        priority. During the ongoing COVID-19 pandemic, if you are a guest in
        one of our Accommodations, we may request certain Personal Information
        from you related to possible risk of COVID-19 exposure or infection.
        This information may include (but may not be limited to) whether you
        have been in recent contact with someone known to have or who is
        experiencing symptoms of COVID-19; whether you have recently traveled
        internationally; if you are currently quarantining due to a possible
        COVID-19 exposure and/or are awaiting a COVID-19 test result; and
        whether you are currently experiencing COVID-19 symptoms (consistent
        with Centers for Disease Control and Prevention (CDC) guidance). Learn
        more about how we’re responding to COVID-19 at
        gilberthotels.com/covid-response.
      </p>
    </Details>
    <Heading>Information We Collect from Your Interactions with Us.</Heading>
    <Details>
      <p>
        <DotTag className="px-1">&bull;</DotTag>
        <b> Customer service.</b> If you reach out to us with inquiries or
        requests, we will keep a record of how your inquiry or request was
        handled. If you contact us via phone, we may record your call for
        quality assurance, training, and compliance purposes.
      </p>
    </Details>
  </>
);

export default DirectlyFromYou;
