import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail } from './styled';

const SecondPTag: React.FC = () => (
  <Row>
    <Detail>
      <p>
        <b>
          2. If you are utilizing Services in connection with Properties located
          in the U.S. or any country other than Mexico and Canada, you are
          contracting with Gilbert Hotels Hospitality USA Inc.
        </b>{' '}
        If you are utilizing Services in connection with Properties located in
        Canada you are contracting with Hospitalité Gilbert Hotels Canada Inc.
        If you are utilizing Services in connection with multifamily apartment
        Properties located in Mexico, you are contracting with Gilbert Hotels
        Stay México, S. de R.L. de C.V. If you are utilizing Services in
        connection with hotel Properties located in Mexico, you are contracting
        with Gilbert Hotels Hotels México, S. de R.L. de C.V.
        <br />
        <br />
        Note that we reserve the right to replace any Supplier of Record at any
        time and without notice.
        <br />
        <br />
        Other Gilbert Hotels entities (<b>“Gilbert Hotels Support Entities”</b>)
        may provide Services, including concierge, housekeeping, customer
        support, and other support services in connection with your use of the
        Properties. Gilbert Hotels Support Entities do not have any authority or
        obligation to provide Services or Property under the contract with you.
        Similarly, you do not have a contractual relationship with Gilbert
        Hotels Support Entities. Gilbert Hotels Support Entities are not
        authorized to act as an agent for any Supplier of Record.
      </p>
    </Detail>
  </Row>
);

export default SecondPTag;
