import React from 'react';
import { Row } from 'react-bootstrap';
import AlternativeAccommodations from '../AlternativeAccommodations';
import Cancellations from '../Cancellations';
import Modifications from '../Modifications';
import { Detail } from './styled';

const FeesandPayment: React.FC = () => (
  <Row>
    <Detail>
      <p>
        <b>6. Fees and Payment</b> Certain features of the Service may require
        you to pay one-time and/or recurring fees. Before you pay any such fees,
        you will have an opportunity to review and accept the fees that you will
        be charged. All fees are non-refundable, unless expressly provided
        otherwise in writing by us.
      </p>
      <div style={{ paddingLeft: '30px' }}>
        <p>
          <b>6.1 Fees.</b> We reserve the right to determine pricing for the
          Service and any products we offer for sale, including, without
          limitation, for On-Site Services, or Properties that you may reserve
          or subscribe to through the Service. We will make reasonable efforts
          to keep pricing information that is published on our website up to
          date, but may not necessarily provide all specific pricing information
          (e.g., subscription-related pricing) online. We encourage you to check
          our website periodically for current pricing information. We may
          change any of this pricing information (including by adding applicable
          taxes, fees or charges) at any time. We, in our sole discretion, may
          make promotional offers with different features and different pricing
          to any of our customers. These promotional offers, unless made to you,
          will not apply to you or this Agreement.
        </p>
        <p>
          <b>6.2 Currency.</b> If you request a reservation or purchase certain
          Services, including for On-Site Services, you will be notified of the
          currency in which you will be charged. This currency will be
          determined by us based on the Applicable Payment Method (defined in
          Section 5.3) and other factors in our reasonable discretion, such as
          the location of the Property that you are trying to reserve or
          subscribe to. For your convenience and if the currency you will be
          charged in is not the same as the currency applicable in your country,
          we may provide you with an indication of how much the price would
          approximately be in your own currency. Such indication is merely
          indicative, not binding, and will depend on the applicable exchange
          rate of the day. You acknowledge that the applicable exchange rate
          used for currency conversion processing may not be identical to the
          applicable market rate in effect at the specific time such processing
          occurs because: (i) we update the applicable exchange rate on a
          regular, but not real-time, basis; and (ii) the applicable exchange
          rate may include an incremental cost or margin that is not included in
          the applicable market rate.
        </p>
        <p>
          <b>6.3 Authorization.</b> You authorize us to charge all applicable
          sums (including but not limited to any applicable one-time and/or
          recurring security deposits, reservation fees, extended stay fees,
          subscription fees, and Service fees, including for On-Site Services,
          the Damaged Property Fee, and any fees, costs and penalties for
          overstaying your reservation or subscription, and including all
          applicable Taxes (defined in Section 6.9), which such Taxes may be
          charged on a different day or time than the sums to which they
          relate)) for the reservations, subscriptions, and/or other purchases
          that you make through the Service in accordance with Section 8.7, to
          the payment method specified in your account or that you otherwise
          indicate when you submit such reservation or enter into a Membership
          Agreement, Extended Stay Agreement and/or other Order Form to or with
          us (the <b>“Applicable Payment Method“</b>) on the date that any such
          sum is due. Additionally, if the Applicable Payment Method is a credit
          card, then a few days before your check-in: (a) we may seek
          pre-authorization of your credit card account prior to completing your
          reservation or entering into a Membership Agreement, Extended Stay
          Agreement or other Order Form with you to verify that the credit card
          is valid and has the necessary funds or credit available to cover your
          reservation, subscription, any security deposit or other purchase, or
          any potential Damaged Property Fee or incidentals that may apply; and
          (b) you authorize us to place a hold on such credit card for sums
          sufficient to cover any applicable Damaged Property Fee and any other
          fees that you owe us under this Agreement and may be held for a few
          days following your check-out. To the extent that Gilbert Hotels is
          charged any service fees in connection with your use of the Applicable
          Payment Method, you shall be responsible for such fees.
        </p>
      </div>
      <Cancellations />
      <AlternativeAccommodations />
      <Modifications />
      <div style={{ paddingLeft: '30px' }}>
        <p>
          <b>6.7 Repeated Cancellations.</b> If you repeatedly cancel or attempt
          to cancel confirmed reservations or purchased subscriptions, we
          reserve the right in our sole discretion to temporarily or permanently
          suspend or terminate your access to the Service.
        </p>
        <p>
          <b>6.8 Delinquent Accounts.</b> We may suspend or terminate your
          access to the Service or Properties if any amount associated with your
          account is due but unpaid. In addition, a delinquent account will be
          charged with fees or charges that are incidental to any chargeback or
          collection of any unpaid amount, including collection fees.
        </p>
        <p>
          <b>6.9 Taxes.</b> Sales and use tax, VAT, local hotel occupancy tax,
          tourist tax, city tax, lodging tax and other indirect taxes (“Taxes”)
          may be imposed on the amount charged for our Services, Properties, and
          other products offered in certain jurisdictions. The actual tax amount
          charged may vary depending on the rates in effect at the time of your
          Period of Occupancy (defined in Section 6.2). Certain local
          governmental agencies may require Gilbert Hotels to collect and remit
          occupancy taxes based on a percentage of the prices set by Gilbert
          Hotels, a fixed amount per day, or some other method.
        </p>
      </div>
    </Detail>
  </Row>
);

export default FeesandPayment;
