import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Container from '../../styled';
import LeituraFont from '../../Assets/Fonts/LeituraDisplayRoman.otf';

export const BGWrapper = styled.div``;

export const Itemrow = styled(Row)`
  margin-bottom: 48px;
  @media (max-width: 425px) {
    margin-bottom: 0px;
  }
`;

export const BGWrapperOverlay = styled.div`
  background: #dca73a;
  width: 100%;
  opacity: 0.1%;
  height: 100%;
`;

export const ContainerWrapper = styled(Container)`
  @media (max-width: 1600px) {
    padding: 0px 100px;
  }
  @media (max-width: 1380px) {
    padding: 0px 50px;
  }
  @media (max-width: 1024px) {
    padding: 0px 50px;
  }
  @media (max-width: 768px) {
    padding: 0px 15px 0px 25px;
  }
  @media (max-width: 425px) {
    padding: 0px 10px;
  }
  @media (max-width: 320px) {
    padding: 0px 15px;
  }
`;

export const ComponentsContainer = styled(Container)`
  position: relative;
`;

export const ItemWrapper = styled(Col)`
  padding-bottom: 50px;
  &:first-child {
    padding-left: 12px;
  }
  &:last-child {
    padding-right: 12px;
  }
`;

export const ColWrapper = styled(Col)`
  @media (max-width: 1024px) {
    width: 100% !important;
  }
`;

export const ItemBox = styled.div`
  border-radius: 15px;

  .image-contrainer {
    position: relative;
    .price-tag {
      flex-direction: row;
      align-items: flex-start;
      padding: 8px 15px;
      position: absolute;
      right: 0%;
      top: 0%;
      background: #212f52;
      border-radius: 0px 15px;
      padding: 10px 18px;
      h5 {
        display: flex;
        font-family: 'Arial';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        text-align: right;
        color: #ffffff;
        margin: 0px 0px;
        padding-right: 0px;
        padding-bottom: 0px;
      }
      p {
        display: flex;
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-align: right;
        color: #ffffff;
        margin: 0px 0px;
        justify-content: right;
      }
    }
    .image {
      height: 300px;
      background-repeat: round;
      background-size: cover;
    }
  }
`;

export const FeatureItemTitle = styled.h2`
  @font-face {
    font-family: 'Leitura Display';
    src: local('Leitura Display'), local('LeituraDisplay'),
      url(${LeituraFont}) format('truetype');
  }
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 30px;
  margin: 20px 0px;
  margin-bottom: 10px;
  color: #212f52;
`;
export const FeatureItemLocation = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #212f52;
  padding-bottom: 20px;
  margin: 0px;
`;

export const FeatureItemItem = styled.div`
  display: flex;
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 16px;
    color: #6a737b;
    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 0px;
  }
  .dot {
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 10px;
    height: 4px;
    width: 4px;
    background-color: #6a737b;
    border-radius: 5px;
    align-self: center;
  }
`;
