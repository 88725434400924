import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding-top: 60px;
`;

export const Title = styled.h2`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #212f52;
`;

export const PropertyImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    flex-wrap: inherit;
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Wrapper = styled.div`
  width: 174.57px;
  height: 140px;
  background: #d9d9d9;
  border-radius: 15px;
  background-size: cover;
  @media (max-width: 768px) {
    width: 163.57px;
  }
  @media (max-width: 425px) {
    width: 180.57px;
  }
`;

export const PropertyList = styled.div`
  margin: 0px 5px;
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #212f52;
    padding-top: 10px;
  }
`;
