import React from 'react';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import TermsofServiceContainer from '../../Components/TermsofServiceContainer';

const TermsofService: React.FC = () => (
  <div>
    <Header hasLogin />
    <TermsofServiceContainer />
    <Footer />
  </div>
);

export default TermsofService;
