import styled from 'styled-components';

export const MapContainer = styled.div`
  width: 100%;
  height: 585px;
  border-radius: 15px;
  overflow: hidden;
`;

export const MapDiv = styled.div`
  padding: 100px 0px 50px 0px;
  @media (max-width: 1024px) {
    margin-top: 50px;
    padding: 0px 10px 50px 10px;
  }
  @media (max-width: 768px) {
    margin-top: 100px;
    padding: 0px 10px 50px 10px;
  }
  @media (max-width: 770px) {
    margin-top: 150px;
  }
  @media (max-width: 425px) {
    margin-top: 200px;
  }
`;

export const Map = styled.div`
  height: 100%;
`;

export const Marker = styled.img``;

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  img {
    &:hover {
      transition: all 0.5s;
      transform: scale(1.5);
    }
  }
`;
