import moment from 'moment';
import React, { useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReservationConfirmation from '../Modal/ReservationConfirmation';
import {
  RowWrapper,
  CardHeader,
  DetailWrapper,
  ActionWrapper,
  ReservationDetailButton,
  ManageReservationGuidText,
  LocalInfoTitle,
  LocalInfoText,
} from './styled';

interface ReservationRightProps {
  reservations: any;
  getReservationData: () => void;
}

const ReservationDetailRight: React.FC<ReservationRightProps> = ({
  reservations,
  getReservationData,
}: ReservationRightProps) => {
  const today = moment(new Date()).format();
  const [isShowReservation, setIsShowReservation] = useState<boolean>(false);

  return (
    <>
      <div className="px-4">
        <RowWrapper>
          <CardHeader>Reservation confirmed. you’re all set!</CardHeader>
          <DetailWrapper>
            <ManageReservationGuidText>
              Access information will be sent to you by email 3 days prior to
              check-in. Need to make any updates? Manage your reservation below:
            </ManageReservationGuidText>

            {reservations?.map((item: any) => (
              // moment(item?.start_date, 'YYYY-MM-DD').format() > today ? (
              <div key={`1${item?.uuid}`}>
                {console.log('item', item)}
                <ActionWrapper>
                  <NavDropdown
                    title="Manage Reservation..."
                    className="stay-menu"
                    disabled={item?.status === 'cancelled'}
                  >
                    <NavDropdown.Item
                      as={Link}
                      to={`/add-guests/${item?.uuid}`}
                    >
                      Add guests
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={`/edit-check-in-out-time/${item?.uuid}`}
                    >
                      Edit check-in or checkout time
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={`/edit-check-in-out-date/${item?.uuid}`}
                    >
                      Change dates
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => setIsShowReservation(true)}
                    >
                      Cancel reservation
                    </NavDropdown.Item>
                  </NavDropdown>
                </ActionWrapper>
                <ReservationConfirmation
                  show={isShowReservation}
                  handleClose={() => setIsShowReservation(false)}
                  getReservationData={getReservationData}
                  detail={item}
                />
              </div>
            ))}
          </DetailWrapper>
        </RowWrapper>
        <RowWrapper className="mt-4">
          <CardHeader>Check-in instructions</CardHeader>
          <DetailWrapper>
            <ActionWrapper>
              <ReservationDetailButton>
                View Reservation Details
              </ReservationDetailButton>
            </ActionWrapper>
            <LocalInfoTitle className="py-5 text-center">
              Local Info
            </LocalInfoTitle>

            <div className="pb-4">
              <LocalInfoTitle className="pb-3">your space</LocalInfoTitle>
              <LocalInfoText className="pb-2">
                At this hotel, you&lsquo;ll love the building&lsquo;s fitness
                center and outdoor pool. Take advantage of the in-suite laundry
                and fully-equipped kitchen. Located in the heart of East Austin,
                you&lsquo;re minutes from downtown, and a quick walk away from
                wonderful restaurants, bars, and entertainment.
              </LocalInfoText>
            </div>
            <div className="pb-4">
              <LocalInfoTitle className="pb-3">
                Your neighborhood
              </LocalInfoTitle>
              <LocalInfoText className="pb-3">
                One of the fastest growing neighborhoods in Austin, Eastside is
                where old meets new. With eateries and boutiques popping up
                throughout the neighborhood, East Austin is bursting with great
                Mexican food, dive bars, and bodegas.
              </LocalInfoText>
            </div>
          </DetailWrapper>
        </RowWrapper>
      </div>
    </>
  );
};
export default ReservationDetailRight;
