import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 0px 60px;
  @media (max-width: 425px) {
    padding: 0px;
  }
`;

export const LeaveReplyDiv = styled.div`
  h6 {
    font-family: 'Leitura Display';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 30px;
    color: #212f52;
  }
  .form-control {
    background: #f9f9f9;
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #9f9fa3;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 10px;
    outline: none;
  }
`;

export const CheckBoxDiv = styled.div`
  padding-left: 10px;
  .form-check-input[type='checkbox'] {
    padding: 8px;
    border-radius: 4px;
    :hover {
      cursor: pointer;
    }
  }
  .form-check-input:checked {
    background-color: #212f52;
    border-color: #212f52;
  }
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    padding: 0px 10px;
  }
`;

export const PostComment = styled(Link)`
  background: #dca73a;
  border-radius: 33px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #212f52;
  text-decoration: none;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  .link:hover {
    color: #212f52;
  }
`;
