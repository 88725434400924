import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Container from '../../styled';
import LeituraFont from '../../Assets/Fonts/LeituraDisplayRoman.otf';

interface ItemImageProps {
  src: string;
}

export const ContantHeader = styled(Row)`
  position: relative;
`;

export const Title = styled.h1`
  @font-face {
    font-family: 'Leitura Display';
    src: local('Leitura Display'), local('LeituraDisplay'),
      url(${LeituraFont}) format('truetype');
  }
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 54px;
  line-height: 58px;
  color: #212f52;
  margin-bottom: 25px;
  @media (max-width: 425px) {
    font-size: 36px;
  }
`;

export const SubTitle = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #6a737b;
`;

export const BGWrapper = styled.div`
  background: rgba(220, 167, 58, 0.1);
  padding: 120px 0px;
  @media (max-width: 425px) {
    padding: 60px 0px;
  }
`;

export const ComponentsContainer = styled(Container)`
  position: relative;
`;

export const ItemBox = styled(Col)`
  display: flex;
  margin-bottom: 60px;
`;

export const ItemImage = styled.img<ItemImageProps>`
  position: static;
  width: 56px;
  height: 56px;
  left: 0px;
  top: 0px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const ItemText = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #212f52;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 30px;
  width: 50%;
`;

export const AppLinkImage = styled.img<ItemImageProps>`
  position: static;
  width: 176px;
  height: 60px;
  left: 0px;
  top: 0px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const ColWrapper = styled(Col)`
  @media (max-width: 1024px) {
    margin-left: 50px;
  }
  @media (max-width: 768px) {
    margin-left: 0px;
  }
`;
