import React from 'react';
import { AllTopicsDiv, FindAndBookingDiv, Heading } from './styled';

const FindAndBooking: React.FC = () => (
  <AllTopicsDiv>
    <Heading className="px-3 pt-3">
      Finding and Booking a Gilbert Hotels
    </Heading>
    <FindAndBookingDiv className="px-2">
      <ul className="pt-3">
        <li>How do I book a Gilbert Hotels?</li>
        <li>Are there any extra charges or fees?</li>
        <li>When and how do I pay?</li>
        <li>Is there a security deposit?</li>
        <li>How do I extend my booking?</li>
        <li>How do I cancel my stay?</li>
        <li>How do I request an invoice or receipt?</li>
        <li>My invoice is incorrect. How do I get it fixed?</li>
        <li>What is member pricing?</li>
        <li>Why do I need to create an account to make a reservation?</li>
      </ul>
    </FindAndBookingDiv>
  </AllTopicsDiv>
);

export default FindAndBooking;
