import React from 'react';
import Checkout1Container from '../../Components/Checkout1Container';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';

const Checkout1: React.FC = () => (
  <div>
    <Header hasLogin />
    <Checkout1Container />
    <Footer />
  </div>
);

export default Checkout1;
