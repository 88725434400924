import React from 'react';
import {
  BGWrapper,
  PropertyImages,
  PropertyList,
  Title,
  Wrapper,
} from './styled';

interface PropertyOverviewProps {
  photos: any;
}

const PropertyOverview: React.FC<PropertyOverviewProps> = ({
  photos,
}: PropertyOverviewProps) => (
  <BGWrapper>
    <Title>Property overview</Title>
    <PropertyImages>
      {photos?.map(
        (element: any) =>
          element?.list_name !== 'cover_image' &&
          element?.photos?.map(
            (item: any, i: number) =>
              i === 0 && (
                <PropertyList key={item?.location}>
                  <Wrapper
                    style={{
                      backgroundImage: `url(${item?.original})`,
                    }}
                  />
                  <p>{element?.title}</p>
                </PropertyList>
              ),
          ),
      )}
    </PropertyImages>
  </BGWrapper>
);

export default PropertyOverview;
