import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const RowWrapper = styled(Row)`
  padding: 0px 35px;
  @media (max-width: 1024px) {
    padding: 0px;
  }
`;

export const BGWrapper = styled.div`
  padding: 0px 35px;
  background: #f9f9f9;
  border-radius: 0px 0px 15px 15px;
  @media (max-width: 1024px) {
    padding: 0px 10px;
  }
  @media (max-width: 768px) {
    padding: 0px 35px;
  }
  @media (max-width: 320px) {
    padding: 0px 10px;
  }
`;

export const Line = styled.hr`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 3px double #caccd1;
`;

export const DatePickerWrapper = styled.div`
  display: flex;
  padding: 21px 14px;
  align-items: center;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 33px;
  position: relative;
  text-align: center;
  align-items: center;
  width: 100%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  &:hover {
    background: #212f5214;
  }
`;
