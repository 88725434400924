import React from 'react';
import { Row } from 'react-bootstrap';
import {
  BillItem,
  BillItemAmount,
  BillItemText,
  InnerPayment,
  Payment,
  PaymentWrapper,
  Title,
} from './styled';

interface PaymentSummaryProps {
  reservation: any;
}

const PaymentSummary: React.FC<PaymentSummaryProps> = ({
  reservation,
}: PaymentSummaryProps) => (
  <Row className="py-5">
    <Title className="pb-3">Payment Summary</Title>
    <div>
      <Payment>
        <PaymentWrapper>
          <BillItem>
            <div
              className="d-flex w-100 justify-content-between"
              key={reservation?.prices?.per_day_Price}
            >
              <BillItemText>
                US${reservation?.prices?.per_day_Price} x {reservation?.nights}{' '}
                nights
              </BillItemText>
              <BillItemAmount>
                US${reservation?.prices?.total_nights}
              </BillItemAmount>
            </div>
            <div
              className="d-flex w-100 justify-content-between"
              key={reservation?.discount}
            >
              <BillItemText style={{ color: '#DCA73A' }}>
                {reservation?.discount}% off - member rate
              </BillItemText>
              <BillItemAmount style={{ color: '#DCA73A' }}>
                US${reservation?.prices?.total_discount}
              </BillItemAmount>
            </div>
            <div
              className="d-flex w-100 justify-content-between"
              key={reservation?.nights}
            >
              <BillItemText>
                Your rate US$
                {reservation?.prices?.total_yourRates /
                  reservation?.nights} x {reservation?.nights}
                nights
              </BillItemText>
              <BillItemAmount>
                US${reservation?.prices?.total_yourRates}
              </BillItemAmount>
            </div>
            <div
              className="d-flex w-100 justify-content-between"
              key={reservation?.prices?.total_cleaning_fee}
            >
              <BillItemText>Cleaning Fee</BillItemText>
              <BillItemAmount>
                US${reservation?.prices?.total_cleaning_fee}
              </BillItemAmount>
            </div>
            <div
              className="d-flex w-100 justify-content-between"
              key={reservation?.prices?.total_tax}
            >
              <BillItemText>Tax</BillItemText>
              <BillItemAmount>
                US${reservation?.prices?.total_tax}
              </BillItemAmount>
            </div>
          </BillItem>
          <div className="d-flex w-100 justify-content-between pb-4">
            <BillItemText className="bold">Total</BillItemText>
            <BillItemAmount className="bold">
              ${reservation?.prices?.totalPrice}
            </BillItemAmount>
          </div>
          <div className="pb-3 px-3">
            <InnerPayment className="pt-3 px-3">
              <h6>
                Paid with {reservation?.payment?.card_details?.brand} ****
                {reservation?.payment?.card_details?.last4}
              </h6>
              <p>
                On your statement, this charge will appear as “Gilbert Hotels
                Inc.”
              </p>
            </InnerPayment>
          </div>
        </PaymentWrapper>
      </Payment>
    </div>
  </Row>
);
export default PaymentSummary;
