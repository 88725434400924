import React from 'react';
import BlogContainer from '../../Components/BlogContainer';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';

const Blog: React.FC = () => (
  <div>
    <Header hasLogin />
    <BlogContainer />
    <Footer />
  </div>
);

export default Blog;
