import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-bottom: 50px;
  @media (max-width: 425px) {
    padding-bottom: 20px;
  }
`;

export const Heading = styled.h1`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 34px;
  color: #212f52;
  padding-bottom: 50px;
  @media (max-width: 425px) {
    font-size: 24px;
    padding-bottom: 20px;
  }
`;

export const BuildingName = styled.h1`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 54px;
  line-height: 58px;
  color: #212f52;
  padding-bottom: 50px;
  @media (max-width: 425px) {
    font-size: 36px;
    padding-bottom: 20px;
  }
`;

export const ItemBox = styled.div`
  align-items: center;
  padding-bottom: 50px;
  @media (max-width: 425px) {
    padding-bottom: 10px;
  }

  img {
    border-radius: 15px;
    width: 100%;
    padding-bottom: 20px;
  }

  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #212f52;
  }
`;

export const Address = styled.h4`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #212f52;
  padding-top: 20px;
`;

export const BuildingFeatureItemBox = styled.div`
  display: flex;
  padding-bottom: 35px;
  @media (max-width: 425px) {
    padding-bottom: 20px;
  }
  img {
    align-self: start;
    width: 36px;
    margin-right: 36px;
  }

  h4 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #212f52;
    padding-bottom: 15px;
  }

  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #212f52;
  }
`;

export const MapContainer = styled.div`
  width: 100%;
  height: 400px;
  border-radius: 15px;
  overflow: hidden;
`;

export const Marker = styled.img``;

export const WrapperMarker = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  img {
    &:hover {
      transform: scale(1.5);
    }
  }
`;
