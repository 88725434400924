import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  z-index: 4;
  width: 80%;
  padding-top: 48px;
  @media (max-width: 1740px) {
    width: 100%;
  }
  @media (max-width: 425px) {
    padding-top: 20px;
  }
`;

export const DatePickerWrapper = styled.div`
  display: flex;
  padding: 12px 14px;
  align-items: center;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 33px;
  position: relative;
  text-align: center;
  align-items: center;
  width: 100%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  &:hover {
    background: #212f5214;
  }
`;

export const MainWrapper = styled.div`
  &:hover {
  }
`;

export const ColWrapper = styled(Col)`
  @media (max-width: 1024px) {
    padding: 10px 2px;
  }
`;
