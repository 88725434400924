import React from 'react';
import Header from '../../Components/Header';
import SearchContainer from '../../Components/SearchContainer';
import Footer from '../../Components/Footer';

const Searching: React.FC = () => (
  // <Body>
  <div>
    <Header hasLogin={false} />
    <SearchContainer />
    <Footer />
  </div>
  // </Body>
);

export default Searching;
