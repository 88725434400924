import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 0px 60px;
  padding-top: 48px !important;
  @media (max-width: 425px) {
    padding: 0px;
    padding-top: 10px !important;
  }
`;

export const BLogDeatils = styled.div`
  border-bottom: 1px solid #caccd1;
  h6 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #212f52;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 20px 0px;
  }
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    color: #212f52;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 20px 0px;
  }
`;

export const Wrapper = styled.div`
  background: #caccd1;
  height: 400px;
  width: 100%;
  margin: 5px 5px;
`;
