import styled from 'styled-components';

export const ComponentsContainer = styled.div`
  padding-right: 40px;
  @media (min-width: 576) {
    padding-right: 0px;
  }
`;

export const Payment = styled.div`
  background: #ffffff;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 15px;
`;

export const Title = styled.h6`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #212f52;
`;

export const BillItem = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #212f52;
  border-bottom: 2px #caccd1 solid;
`;

export const BillItemText = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 50px;
  color: #212f52;
  margin: 0px;
`;

export const BillItemAmount = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  text-align: right;
  color: #212f52;
  margin: 0px;
`;

export const InnerPayment = styled.div`
  background: #f9f9f9;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 10px;
  h6 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #212f52;
  }
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #6a737b;
  }
`;

export const PaymentWrapper = styled.div`
  padding: 0px 30px;
  padding-top: 40px;
  padding-bottom: 20px;
  @media (max-width: 320px) {
    padding: 0px 5px;
    padding-top: 40px;
    padding-bottom: 20px;
  }
`;
