import React from 'react';

interface CloseProps {
  fill: string;
}

const Heart = ({ fill }: CloseProps): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5013 13.5717L12.0013 20.9997L4.50128 13.5717C4.00658 13.0903 3.61692 12.5117 3.35683 11.8723C3.09673 11.2329 2.97184 10.5466 2.99002 9.85662C3.00819 9.1666 3.16904 8.48782 3.46244 7.86303C3.75583 7.23823 4.17541 6.68094 4.69476 6.22627C5.21411 5.77159 5.82198 5.42938 6.48009 5.22117C7.1382 5.01296 7.83228 4.94327 8.51865 5.01649C9.20501 5.08971 9.86878 5.30425 10.4682 5.64659C11.0675 5.98894 11.5895 6.45169 12.0013 7.00569C12.4148 6.45571 12.9374 5.99701 13.5364 5.65829C14.1353 5.31958 14.7978 5.10814 15.4822 5.03721C16.1666 4.96628 16.8584 5.03739 17.5141 5.24608C18.1697 5.45477 18.7753 5.79656 19.2928 6.25005C19.8104 6.70354 20.2287 7.25897 20.5217 7.88158C20.8147 8.50419 20.976 9.18057 20.9956 9.8684C21.0152 10.5562 20.8925 11.2407 20.6354 11.8789C20.3783 12.5172 19.9922 13.0955 19.5013 13.5777"
      stroke="#212F52"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Heart;
