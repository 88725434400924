import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { DayPicker } from 'react-day-picker';

export const DatePickerWrapper = styled(Col)`
  display: flex;
  padding: 21px 14px;
  align-items: center;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 33px;
  position: relative;
  align-items: center;
  width: 100%;
  @media (max-width: 767px) {
    border-right: 1px solid #caccd1;
    border-bottom: 1px solid #caccd1;
    height: auto;
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CalendarIconWrapper = styled.div`
  padding: 10px;
  :hover {
    background: #eff0f3;
    border-radius: 50%;
  }
`;
export const CalendarWrapper = styled.div`
  align-items: center;
`;

export const ArrowIconWrapper = styled.div`
  text-align: center;
  padding: 10px;
  :hover {
    background: #eff0f3;
    border-radius: 50%;
  }
`;

export const DateInput = styled.input`
  border: none;
  width: 100%;
  color: #212f52;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: #212f52;
  }
`;

export const DateRangePicker = styled(DayPicker)`
  position: absolute;
  background: white;
  z-index: 1;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  margin-top: 15px;
  padding: 15px;
  top: 70px;
  left: 0;
  @media (max-width: 425px) {
    display: none;
  }

  & .rdp-caption_label {
    text-align: center;
    vertical-align: middle;
    min-width: 32px;
    line-height: 24px;
    font-size: 12px;
    border-radius: 4px;
    white-space: nowrap;
    color: #212f52;
    cursor: pointer;
  }
  & .rdp-day {
    border-radius: 0;
    color: #212f52;
  }

  & .rdp-day:hover:not([disabled]) {
    background-color: #eee;
    color: #212f52;
  }

  & .rdp-day_range_start,
  .rdp-day_selected:focus:not([disabled]),
  .rdp-day_range_start:hover:not([disabled]) {
    background: #dca73a;
    border: none;
    border-radius: 50%;
  }

  & .rdp-day_range_end,
  .rdp-day_selected:focus:not([disabled]),
  .rdp-day_range_end:hover:not([disabled]) {
    background: #dca73a;
    border-radius: 50%;
    border: none;
  }
  & .rdp-day_range_middle {
    background: #eff0f3;
    color: #212f52;
  }

  & .rdp-cell {
    text-align: center;
    vertical-align: middle;
    min-width: 32px;
    width: 32px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    border-radius: 4px;
    white-space: nowrap;
    cursor: pointer;
    & .rdp-day {
      padding: 5px;
      width: 100%;
      height: 100%;
    }
  }

  & .rdp-head_cell {
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    min-width: 32px;
    width: 32px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    color: #212f52;
    border-radius: 4px;
    cursor: pointer;
  }

  & .rdp-nav {
    height: 27px;
    & button {
      height: 100%;
      width: 100%;
      color: #212f52;
      :hover {
        background-color: #eff0f3;
      }
      & svg {
        height: 12px;
        width: 12px;
      }
    }
  }
`;

export const DatePicker = styled(DayPicker)`
  position: absolute;
  background: white;
  z-index: 1;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  margin-top: 15px;
  padding: 15px;
  top: 70px;
  left: 0;
  display: none;
  @media (max-width: 425px) {
    left: 25px;
    display: block;
  }
  @media (max-width: 375px) {
    left: 0px;
  }
  @media (max-width: 320px) {
    left: -20px;
  }

  & .rdp-caption_label {
    text-align: center;
    vertical-align: middle;
    min-width: 32px;
    line-height: 24px;
    font-size: 12px;
    border-radius: 4px;
    white-space: nowrap;
    cursor: pointer;
    color: #212f52;
  }
  & .rdp-day {
    border-radius: 0;
  }

  & .rdp-day:hover:not([disabled]) {
    background-color: #eee;
    color: #000;
    border-radius: 5px;
  }

  & .rdp-day_range_start,
  .rdp-day_selected:focus:not([disabled]),
  .rdp-day_range_start:hover:not([disabled]) {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    background: #357ebd;
    border: none;
    color: #fff;
  }

  & .rdp-day_range_end,
  .rdp-day_selected:focus:not([disabled]),
  .rdp-day_range_end:hover:not([disabled]) {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    background: #357ebd;
    border: none;
    color: #fff;
  }
  & .rdp-day_range_middle {
    background: #ebf4f8;
    color: #000;
  }

  & .rdp-cell {
    text-align: center;
    vertical-align: middle;
    min-width: 32px;
    width: 32px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    border-radius: 4px;
    white-space: nowrap;
    cursor: pointer;
    & .rdp-day {
      width: 100%;
      height: 100%;
    }
  }

  & .rdp-head_cell {
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    min-width: 32px;
    width: 32px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
    color: #212f52;
  }

  & .rdp-nav {
    height: 27px;
    color: #212f52;
    display: flex;
    & button {
      height: 100%;
      width: 100%;
      & svg {
        height: 12px;
        width: 12px;
      }
    }
  }
`;
