import React, { useState } from 'react';
import {
  RowWrapper,
  CardHeader,
  CheckInOutTitle,
  CheckInOutDate,
  CheckInOutStandardTime,
  CheckInOutTimeBlockWraper,
  CheckInOutTimeBlock,
  CheckInOutTimeIcon,
  SeeMoreTimeLink,
  SaveButton,
  CancleButton,
} from './styled';
import CheckoutClockIcon from '../../Assets/Images/Icons/CheckoutClockIcon.svg';
import CheckInModal from '../Modal/CheckInModal';
import CheckOutModal from '../Modal/CheckOutModal';

const checkinTimeList = [
  { id: 1, time: '1:00 PM', disabled: true },
  { id: 2, time: '1:30 PM', disabled: true },
  { id: 3, time: '2:00 PM', disabled: true },
  { id: 4, time: '2:30 PM', disabled: true },
  { id: 5, time: '3:00 PM', disabled: false },
  { id: 6, time: '3:30 PM', disabled: false },
  { id: 7, time: '4:00 PM', disabled: false },
  { id: 8, time: '4:30 PM', disabled: false },
  { id: 9, time: '5:00 PM', disabled: false },
  { id: 10, time: '5:30 PM', disabled: false },
];

const checkoutTimeList = [
  { id: 1, time: '7:00 AM', disabled: true },
  { id: 2, time: '7:30 AM', disabled: true },
  { id: 3, time: '8:00 AM', disabled: true },
  { id: 4, time: '8:30 AM', disabled: true },
  { id: 5, time: '9:00 AM', disabled: false },
  { id: 6, time: '9:30 AM', disabled: false },
  { id: 7, time: '10:00 AM', disabled: false },
  { id: 8, time: '10:30 AM', disabled: false },
  { id: 9, time: '11:00 AM', disabled: false },
  { id: 10, time: '11:30 AM', disabled: false },
  { id: 11, time: '12:00 PM', disabled: false },
  { id: 12, time: '12:30 PM', disabled: false },
  { id: 13, time: '1:00 PM', disabled: false },
  { id: 14, time: '1:30 PM', disabled: false },
];

const EditCheckInOutTimeForm: React.FC = () => {
  const [selectedCheckinTime, setSelectedCheckinTime] = useState<number>(7);
  const [selectedCheckoutTime, setSelectedCheckoutTime] = useState<number>(5);
  const [isShowCheckIn, setIsShowCheckIn] = useState<boolean>(false);
  const [isShowCheckOut, setIsShowCheckOut] = useState<boolean>(false);
  return (
    <>
      <RowWrapper>
        <CardHeader className="pb-4">
          Update your check-in or checkout time
        </CardHeader>

        <div>
          <CheckInOutTitle>Check in</CheckInOutTitle>
          <CheckInOutDate>Monday, Oct 11, 2021</CheckInOutDate>
          <CheckInOutStandardTime>
            Our standard check-in time is 4:00 PM.
          </CheckInOutStandardTime>
          <CheckInOutTimeBlockWraper>
            {checkinTimeList.map(item => (
              <CheckInOutTimeBlock
                key={`99${item.id}`}
                className={`${item.disabled && `disabled`} ${
                  selectedCheckinTime === item.id && `selected`
                }`}
                onClick={() =>
                  !item.disabled && setSelectedCheckinTime(item.id)
                }
              >
                {item.time}
              </CheckInOutTimeBlock>
            ))}
          </CheckInOutTimeBlockWraper>
          <div className="d-flex pt-3">
            <CheckInOutTimeIcon src={CheckoutClockIcon} alt="alt-clock" />
            <SeeMoreTimeLink onClick={() => setIsShowCheckIn(true)}>
              Don{`'`}t see the time yoiu{`'`}re looking for?
            </SeeMoreTimeLink>
          </div>
        </div>

        <div className="pt-5">
          <CheckInOutTitle>Check out</CheckInOutTitle>
          <CheckInOutDate>Wednesday, Oct 13, 2021</CheckInOutDate>
          <CheckInOutStandardTime>
            Our standard checkout time is 11:00 AM.
          </CheckInOutStandardTime>
          <CheckInOutTimeBlockWraper>
            {checkoutTimeList.map(item => (
              <CheckInOutTimeBlock
                key={`33${item?.id}`}
                className={`${item.disabled && `disabled`} ${
                  selectedCheckoutTime === item.id && `selected`
                }`}
                onClick={() =>
                  !item.disabled && setSelectedCheckoutTime(item.id)
                }
              >
                {item.time}
              </CheckInOutTimeBlock>
            ))}
          </CheckInOutTimeBlockWraper>
          <div className="d-flex pt-3">
            <CheckInOutTimeIcon src={CheckoutClockIcon} alt="alt-clock" />
            <SeeMoreTimeLink onClick={() => setIsShowCheckOut(true)}>
              Don{`'`}t see the time yoiu{`'`}re looking for?
            </SeeMoreTimeLink>
          </div>
        </div>

        <div className="pt-4">
          <SaveButton>Save</SaveButton>
          <CancleButton>Cancel</CancleButton>
        </div>
      </RowWrapper>
      <CheckInModal
        show={isShowCheckIn}
        handleClose={() => setIsShowCheckIn(false)}
      />
      <CheckOutModal
        show={isShowCheckOut}
        handleClose={() => setIsShowCheckOut(false)}
      />
    </>
  );
};
export default EditCheckInOutTimeForm;
