import React from 'react';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import ViewAllPhotosContainer from '../../Components/ViewAllPhotosContainer';

const ViewAllPhoto: React.FC = () => (
  <div>
    <Header hasLogin />
    <ViewAllPhotosContainer />
    <Footer />
  </div>
);

export default ViewAllPhoto;
