import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-bottom: 50px;
  padding-top: 30px;
  .bold {
    font-weight: 700;
  }
`;

export const BillItem = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #212f52;
  border-bottom: 2px #caccd1 solid;
`;

export const BillItemText = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 50px;
  color: #212f52;
  margin: 0px;
`;

export const BillItemAmount = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  text-align: right;
  color: #212f52;
  margin: 0px;
`;

export const AddPromo = styled.p`
  cursor: pointer;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #6a737b;
  text-align: center;
  margin: 0px;
`;

export const AddButton = styled.button`
  position: absolute;
  border-radius: 33px;
  background: #dca73a;
  border: 0px;
  padding: 10px 20px;
  color: #212f52;
  margin: 10px;
`;

export const AddPromoCode = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  img {
    position: absolute;
    padding: 10px 10px;
  }
`;
