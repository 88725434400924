import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const RowWrapper = styled(Row)`
  padding: 50px 35px;
  @media (max-width: 768px) {
    padding: 0px 35px;
  }
  @media (max-width: 425px) {
    padding: 0px;
  }
`;

export const BGWrapper = styled.div`
  padding: 0px 35px;
  background: #f9f9f9;
  border-radius: 0px 0px 15px 15px;
  @media (max-width: 320px) {
    padding: 0px 10px;
  }
`;

export const Line = styled.div`
  hr {
    background: #caccd1;
    border: 1px #caccd1 solid;
  }
`;
