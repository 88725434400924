import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import {
  Wrapper,
  Heading,
  ItemWrapper,
  ItemBox,
  FeatureLable,
  FeatureItemWrapper,
  FeatureItemBox,
} from './styled';
import WifiIcon from '../../../Assets/Images/Icons/Amenities/WifiIcon.svg';
import AirConditioning from '../../../Assets/Images/Icons/Amenities/Feature/AirConditioning.svg';
import Elevator from '../../../Assets/Images/Icons/Amenities/Feature/Elevator.svg';
import FrontDesk from '../../../Assets/Images/Icons/Amenities/Feature/FrontDesk.svg';
import FreeParking from '../../../Assets/Images/Icons/Amenities/Feature/FreeParking.svg';
import CableTv from '../../../Assets/Images/Icons/Amenities/Feature/CableTv.svg';
import OutdoorSpace from '../../../Assets/Images/Icons/Amenities/Feature/OutdoorSpace.svg';
import Wifi from '../../../Assets/Images/Icons/Amenities/Feature/Wifi.svg';
import KidFriendly from '../../../Assets/Images/Icons/Amenities/Feature/KidFriendly.svg';
import PaidParking from '../../../Assets/Images/Icons/Amenities/Feature/PaidParking.svg';

interface AmenitiesProps {
  explore: any;
}

const feature = [
  { id: 1, image: AirConditioning, text: 'Air conditioning' },
  { id: 2, image: Elevator, text: 'Elevator' },
  { id: 3, image: FrontDesk, text: 'Front Desk' },
  { id: 4, image: FreeParking, text: 'Free Parking' },
  { id: 5, image: CableTv, text: 'Cable TV' },
  { id: 6, image: OutdoorSpace, text: 'Outdoor Space' },
  { id: 7, image: Wifi, text: 'WiFi' },
  { id: 8, image: KidFriendly, text: 'Kid Friendly' },
  { id: 9, image: PaidParking, text: 'Paid parking' },
];

const TheAmenities: React.FC<AmenitiesProps> = ({
  explore,
}: AmenitiesProps) => {
  let keyValue = 0;
  const [amenitiesState, setAmenitiesState] = useState<any>();
  useEffect(() => {
    if (explore?.amenities) {
      setAmenitiesState(Object?.values(explore?.amenities));
    }
  }, []);

  return (
    <>
      <Wrapper key={explore?.unit_id}>
        <Heading>The amenities</Heading>
        <ItemWrapper>
          {amenitiesState?.map((item: any) => {
            keyValue += item?.name?.length;
            return (
              <div key={keyValue}>
                {item?.available && (
                  <ItemBox>
                    <img src={WifiIcon} alt={`${item?.id}`} />
                    <p className="m-0">{item?.name}</p>
                  </ItemBox>
                )}
              </div>
            );
          })}
        </ItemWrapper>
        <FeatureLable>Feature Checklist</FeatureLable>
        <FeatureItemWrapper>
          {feature?.map(item => (
            <Col className="" xxl={4} xl={4} md={4} sm={4} key={`2${item?.id}`}>
              <FeatureItemBox className="m-0">
                <img src={item?.image} alt={`${item?.id}`} />
                <p>{item?.text}</p>
              </FeatureItemBox>
            </Col>
          ))}
        </FeatureItemWrapper>
      </Wrapper>
    </>
  );
};
export default TheAmenities;
