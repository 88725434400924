import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail } from './styled';

const ModificationofthisAgreement: React.FC = () => (
  <Row>
    <Detail>
      <p>
        <b>13. Modification of this Agreement.</b> We reserve the right to
        change this Agreement (including any of the Additional Terms in
        accordance with those agreements and applicable law) on a going-forward
        basis at any time. Please check this Agreement periodically for changes.
        If a change to this Agreement materially modifies your rights or
        obligations, we may require that you accept the modified Agreement in
        order to continue to use the Service or any Properties. Material
        modifications are effective upon your acceptance of the modified
        Agreement and we will provide you with reasonable notice prior to such
        changes taking effect. Please discontinue using the Service and any
        Property if you do not agree to these changes. Immaterial modifications
        are effective upon publication. Except as expressly permitted in this
        Section 12, this Agreement may be amended only by a written agreement
        signed by authorized representatives of the parties to this Agreement.
        Disputes arising under this Agreement will be resolved in accordance
        with the version of this Agreement that was in effect at the time the
        dispute arose.
      </p>
    </Detail>
  </Row>
);

export default ModificationofthisAgreement;
