import styled from 'styled-components';

export const SuspenseLoaderWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  div {
    display: flex;
  }
`;

export const SliderWrapper = styled.div``;
