// import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Container from '../../styled';

export const HeaderBG = styled.div`
  height: 70px;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #212f52;
`;

export const HeaderContainer = styled(Container)`
  height: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 320px) {
    padding: 0px;
  }
`;

export const HeaderItemWrapper = styled(Row)`
  width: 100%;
  margin: 0;
`;

export const HamBurgerMenuIcon = styled.img`
  cursor: pointer;
  background: #14254e !important;
`;

export const HeaderLogo = styled.img`
  cursor: pointer;
`;

export const RightSideItemWrapper = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: end;
  padding: 0px;
  margin: auto;
  .user-menu {
    a {
      width: fit-content;
      font-family: 'Arial';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      text-align: right;
      color: #ffffff;
    }
    .dropdown-menu {
      background: #f9f9f9;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      padding: 0px;
      a {
        font-weight: 400;
        text-align: left;
        width: 300px;
        background: #ffffff;
        color: #212f52;
        border-radius: 5px 5px 0px 0px;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        margin: 0px 0px;
        text-transform: none;
        padding: 20px;
        :hover {
          background: #eff0f3;
        }
      }
    }
  }
  .glob-menu {
    .dropdown-toggle {
      ::after {
        content: none;
      }
    }
    .dropdown-menu {
      button {
        width: 100%;
      }
    }
  }
`;

export const UserMenu = styled(Col)`
  display: flex;
  justify-content: end;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Sizediv = styled.div`
  display: none;
  @media (max-width: 1024px) {
  }
  @media (max-width: 768px) {
    display: block;
    background: #ffffff;
    .size-menu {
      color: black;
    }
  }
`;

export const LoginMenu = styled(Col)`
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const RightTextItem = styled.p`
  cursor: pointer;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: white;
  flex: none;
  order: 3;
  flex-grow: 0;
  margin: 0px 25px;
  &:last-child {
    margin: 0 0 0 25px;
  }
  @media (max-width: 1024px) {
    margin: 0px 10px;
    &:last-child {
      margin: 0 0 0 10px;
    }
  }
`;

export const ButtonDiv = styled.div`
  background-color: #dca73a;
  width: 100%;
  display: flex;
  cursor: pointer;
  border-radius: 33px;
  padding: 10px 30px;
  justify-content: center;
`;

export const RightIconItem = styled.img`
  position: static;
`;

export const MenuIcon = styled(Col)`
  margin: auto;
  padding: 0;

  .header-menu {
    a {
      width: fit-content;
      &:after {
        content: none;
      }
    }
    .dropdown-menu {
      background: #f9f9f9;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      padding: 0px;
      a {
        width: 300px;
        background: #ffffff;
        border-radius: 5px;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        margin: 0px 0px;
        padding: 20px;
        color: #212f52;
        :hover {
          background: #eff0f3;
        }
      }
    }
    .img-dropdown {
      cursor: pointer;
    }
  }
`;

export const LogoSection = styled(Col)`
  text-align: center;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const FindRoomButton = styled.button`
  background: #dca73a;
  border-radius: 3px;
  text-decoration: none;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #212f52;
  padding: 11px 30px;
  border: none;
  :hover {
    color: #212f52;
  }
  @media (max-width: 575px) {
    display: none;
  }
`;

export const SizeLanguage = styled.div`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  :hover {
    background: #eff0f3;
  }
  img {
    border-radius: 10px;
    height: 20px;
    width: 20px;
    margin-inline-end: 12px;
    object-fit: cover;
    margin: 10px;
  }
  .navdropdown-item {
    color: #212f52;
    font-size: 16px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: normal;
    :hover {
      background: #eff0f3;
    }
  }
`;

export const Language = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  height: 40px;
  padding: 11px 16px !important;
  :hover {
    background: #eff0f3;
  }
  img {
    border-radius: 10px;
    height: 20px;
    width: 20px;
    margin-inline-end: 12px;
    object-fit: cover;
  }
  .navdropdown-item {
    color: #212f52;
    font-size: 16px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: normal;
    :hover {
      background: #eff0f3;
    }
  }
`;

export const NavLanguageDiv = styled.div`
  .navdropdown-header {
    color: #212f52;
  }
`;
