import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-bottom: 50px;
  @media (max-width: 768px) {
    padding-bottom: 0px;
  }
  @media (max-width: 425px) {
    padding-bottom: 0px;
    padding-right: 20px;
  }
`;

export const Heading = styled.h1`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 34px;
  color: #212f52;
  padding-bottom: 50px;
  @media (max-width: 425px) {
    padding-bottom: 30px;
    font-size: 24px;
  }
`;

export const NeighborName = styled.h1`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 54px;
  line-height: 58px;
  color: #212f52;
  padding-bottom: 25px;
  @media (max-width: 425px) {
    padding-bottom: 30px;
    font-size: 36px;
  }
`;

export const NeighborDetail = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #212f52;
`;
