import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Button } from './styled';

interface PaymentModalProps {
  show: boolean;
  handleClose: () => void;
  id: string;
  UpdateReservationCollection: () => void;
}

const PaymentModal: React.FC<PaymentModalProps> = ({
  show,
  handleClose,
  id,
  UpdateReservationCollection,
}: PaymentModalProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      return;
    }

    const paymentConfirm: any = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_REDIRCT_URL}edit-check-in-out-date/${id}`,
      },
      redirect: 'if_required',
    });
    if (!paymentConfirm?.error) {
      UpdateReservationCollection();
      handleClose();
    }
    if (
      paymentConfirm?.error?.type === 'card_error' ||
      paymentConfirm?.error?.type === 'validation_error' ||
      paymentConfirm?.error?.type === 'invalid_request_error'
    ) {
      toast.error(paymentConfirm?.error?.message);
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PaymentElement id="payment-element" />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Close</Button>
        <Button
          onClick={handleSubmit}
          disabled={loading || !stripe || !elements}
        >
          Save{' '}
          {loading ? (
            <Spinner animation="border" variant="#212f52" size="sm" />
          ) : (
            ''
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentModal;
