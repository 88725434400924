import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BGWrapper = styled.div``;

export const ViewAllPhotosWrapper = styled.div`
  position: absolute;
  bottom: 100px;
  cursor: pointer;
  left: 130px;
  background: #fff;
  border-radius: 33px;
  padding: 10px 20px;
  @media (max-width: 425px) {
    left: 50px;
  }
`;

export const ViewAllPhotoLink = styled(Link)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #212f52;
  text-decoration: none;
  :hover {
    color: #212f52;
  }
`;

export const SliderWrapper = styled.div`
  padding-bottom: 10px;
  position: relative;
  .slick-slide {
    padding: 0px 15px;
  }
  .slick-prev {
    z-index: 10;

    &:before {
      content: none;
      color: '#000' !important;
    }
    img {
      background: rgba(33, 39, 55, 0.5);
      border-radius: 48px;
    }
    width: 153px;
    height: 100%;
  }
  .slick-next {
    top: 0;
    z-index: 10;
    &:before {
      content: none;
      color: '#000' !important;
    }
    width: 350px;
    height: 100%;
    img {
      background: rgba(33, 39, 55, 0.5);
      border-radius: 48px;
    }
    transform: matrix(-1, 0, 0, 1, 0, 0);
    right: 0px;
  }

  @media (max-width: 1320px) {
    .slick-prev {
      width: 250px;
    }
    .slick-next {
      width: 250px;
    }
  }

  @media (max-width: 1024px) {
    .slick-prev {
      width: 150px;
    }
    .slick-next {
      width: 150px;
    }
  }
  @media (max-width: 768px) {
    .slick-prev {
      width: 100px;
    }
    .slick-next {
      width: 100px;
    }
  }
`;

export const RoomImageBlock = styled.div`
  width: 100% !important;
  padding: 20px 0px;

  &:focus-visible {
    outline: none;
  }
  @media (max-width: 700px) {
    width: 450px !important;
    padding: 0 30px;
  }
  img {
    margin: auto;
    border-radius: 15px;
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100% !important;
  }
  @media (max-width: 700px) {
    padding: 50px 0px;
  }
  @media (max-width: 665px) {
    width: 550px !important;
  }
  @media (max-width: 590px) {
    width: 500px !important;
  }
  @media (max-width: 560px) {
    width: 450px !important;
  }
  @media (max-width: 500px) {
    width: 400px !important;
  }
  @media (max-width: 450px) {
    width: 350px !important;
  }
  @media (max-width: 400px) {
    width: 300px !important;
  }
  @media (max-width: 350px) {
    width: 250px !important;
  }
  border-radius: 15px;
`;
