import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail } from './styled';

const UserContentRepresentationsandWarranties: React.FC = () => (
  <Row>
    <Detail>
      <div style={{ paddingLeft: '30px' }}>
        <p>
          <b>11.4 User Content Representations and Warranties.</b> We disclaim
          any and all liability in connection with User Content. You are solely
          responsible for your User Content and the consequences of providing
          User Content via the Service. By providing User Content via the
          Service, you affirm, represent, and warrant that:
          <div style={{ paddingLeft: '30px', marginTop: '20px' }}>
            <p>
              11.4.1 you are the creator and owner of the User Content, or have
              the necessary licenses, rights, consents, and permissions to
              authorize us and our sublicensees to use and distribute your User
              Content as necessary to exercise the licenses granted by you in
              this Section 10, in the manner contemplated by us, the Service,
              and this Agreement;
            </p>
            <p>
              11.4.2 your User Content, and the use of your User Content as
              contemplated by this Agreement, does not and will not: (i)
              infringe, violate, or misappropriate any third party’s right,
              including any copyright, trademark, patent, trade secret, moral
              right, privacy right, right of publicity, or any other
              intellectual property or proprietary right; (ii) slander, defame,
              libel, or invade the right of privacy, publicity, or other
              property rights of any other individual or organization; or (iii)
              cause us to violate any law, rule, or regulation; and
            </p>
            <p>
              11.4.3 your User Content could not be deemed by a reasonable
              person to be objectionable, profane, indecent, pornographic,
              harassing, threatening, embarrassing, hateful, or otherwise
              inappropriate.
            </p>
          </div>
        </p>
      </div>
    </Detail>
  </Row>
);

export default UserContentRepresentationsandWarranties;
