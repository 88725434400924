import React from 'react';
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import SliderPrevIcon from '../../Assets/Images/Icons/SliderPrevIcon.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Header, SliderWrapper, TitleDiv, Wrapper } from './styled';
import HeartIcon from '../../Assets/Images/Icons/HeartIcon.svg';

const Favorites = [
  {
    id: 1,
    title: 'Hotel name here',
  },
  {
    id: 2,
    title: 'Hotel name here',
  },
  {
    id: 3,
    title: 'Hotel name here',
  },
  {
    id: 4,
    title: 'Hotel name here',
  },
  {
    id: 5,
    title: 'Hotel name here',
  },
  {
    id: 6,
    title: 'Hotel name here',
  },
];

const NextArrow = ({ className, onClick }: any) => (
  <div
    role="button"
    className={className}
    style={{ display: 'flex', alignItems: 'center' }}
    onClick={onClick}
    onKeyDown={() => {}}
    tabIndex={0}
  >
    <img src={SliderPrevIcon} alt="prev-icon" />
  </div>
);

const PrevArrow = ({ className, onClick }: any) => (
  <div
    role="button"
    className={className}
    style={{ display: 'flex', alignItems: 'center' }}
    onClick={onClick}
    onKeyDown={() => {}}
    tabIndex={-1}
  >
    <img src={SliderPrevIcon} alt="prev-icon" />
  </div>
);

const settings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 992,
      settings: {
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        centerMode: true,
        centerPadding: '50px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 425,
      settings: {
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 1,
      },
    },
  ],
};

const OurGuestFavorites: React.FC = () => (
  <Container>
    <Header className="pt-5">Our Guests Favorites:</Header>
    <div>
      <SliderWrapper>
        <Slider {...settings}>
          {Favorites.map(item => (
            <div className="d-flex px-1 py-2 ">
              <Container>
                <Wrapper>
                  <div>
                    <p>Avg. Rate $199</p>
                  </div>
                </Wrapper>
                <TitleDiv className="pt-2">
                  <p className="pt-1">{item.title}</p>
                  <img src={HeartIcon} alt="heart" className="heart" />
                </TitleDiv>
              </Container>
            </div>
          ))}
        </Slider>
      </SliderWrapper>
    </div>
  </Container>
);

export default OurGuestFavorites;
