import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail, DotTag, Questions } from './styled';

const Share: React.FC = () => (
  <>
    <Row>
      <Questions>4. How We Share Your Personal Information</Questions>
      <Detail>
        <p>
          We may employ third party companies and individuals to support our
          Services. We do not sell or otherwise disclose your Personal
          Information for purposes unrelated to our Services, unless otherwise
          described in this Policy or disclosed to you at the time your Personal
          Information is collected or shared. We may share your Personal
          Information under the following circumstances:
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          <b> Affiliates.</b> We may disclose the information we collect about
          you to our affiliates or subsidiaries; however, if we do so, their use
          and disclosure of your Personal Information will be subject to this
          Policy.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          <b> Service providers.</b> We may disclose Personal Information we
          collect about you to third party vendors, service providers,
          contractors or agents who perform functions on our behalf, including
          to facilitate our Services, to provide the Services on our behalf, to
          perform Site-related services (e.g., maintenance services, database
          management, web analytics, fraud detection services relating to the
          activity of Gilbert Hotels Users and improvement of the Site’s
          features), to perform background checks, to send marketing
          communications, to manage job applications that we receive via the
          career page on our Site, to aggregate reviews, or to assist us in
          analyzing how our Site and Services are used. We require these service
          providers to safeguard the privacy of Personal Information they
          process on our behalf.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          <b> Third party travel services, referral services and brokers.</b>
          When you book an Accommodation via a third party travel service or we
          receive your Personal Information through a referral service or
          broker, we may share your Personal Information with such service or
          broker in order to manage your booking or provide credit for
          referrals.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          <b> Landlords and property managers.</b> We may share reports with
          landlords and property managers regarding the use of their properties,
          such as cleaning reports. Additionally, we may share guest names,
          email addresses and dates of stay at their properties for security
          purposes (e.g., to keep out individuals who are not guests). For long
          term rentals, we may share guests’ name, email address, phone number,
          ID, date of birth and address with landlords. If permitted by
          applicable law, we may also share pass/fail results of background
          checks that we carry out on their guests based in the United States.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          <b> Advertising and analytics partners.</b> We may share Personal
          Information about you with third party advertising partners to serve
          advertisements regarding goods and services that may be of interest to
          you. We may also share information about the use of our Site and
          Services with third parties for analytics and optimization purposes.
          Some of our advertising partners are members of the Network
          Advertising Initiative (“NAI”) or the Digital Advertising Alliance
          (“DAA”). If you do not wish to receive personalized ads, please use
          the NAI’s Opt-Out Tool or the DAA’s Opt Out Tool to opt out of
          receiving web-based personalized ads from member companies. You can
          access any settings offered by your mobile operating system to limit
          ad tracking, or you can install the AppChoices mobile app to learn
          more about how you may opt out of personalized ads in mobile apps..
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          <b> Business transfers.</b> Gilbert Hotels may sell, transfer or
          otherwise share some or all of its assets, including your Personal
          Information, in connection with the consideration, negotiation, or
          completion of a merger, acquisition, reorganization or sale of assets
          or in the event of bankruptcy.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          <b> Compliance with laws and law enforcement; guarantee program.</b>
          Gilbert Hotels cooperates with government and law enforcement
          officials (e.g., immigration offices, customs authorities,
          anti-terrorism authorities) and private parties to enforce and comply
          with the law. We will disclose your Personal Information to government
          or law enforcement officials or private parties as we, in our sole
          discretion, believe necessary or appropriate to respond to claims and
          legal process (including but not limited to subpoenas), to protect the
          property and rights of Gilbert Hotels or a third party, to protect the
          safety of the public or any person, or to prevent or stop activity we
          may consider to be, or to pose a risk of being, any illegal, unethical
          or legally actionable activity. We will disclose any information about
          you to government or law enforcement officials and to our insurance
          services providers as we, in our sole discretion, believe necessary or
          appropriate to protect the property and rights of Gilbert Hotels or a
          third party, to protect the safety of the public or any person, or to
          prevent or stop activity we may consider to be, or to pose a risk of
          being, any illegal, unethical or legally actionable activity.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          <b> Consent.</b> We may also disclose your Personal Information with
          your permission. For example, with your consent, we may post your
          testimonial on the Site along with your name. If you would like your
          testimonial removed please contact us.
        </p>
      </Detail>
    </Row>
  </>
);

export default Share;
