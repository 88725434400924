import React from 'react';
import {
  Wrapper,
  Heading,
  ItemBox,
  Note,
  ColWrapper,
  RowWrapper,
} from './styled';
import City1 from '../../../Assets/Images/CitysImages/City1.jpg';
import City2 from '../../../Assets/Images/CitysImages/City2.jpg';
import City3 from '../../../Assets/Images/CitysImages/City3.jpg';

const feature = [
  { id: 1, image: City1, text: 'Bedroom' },
  { id: 2, image: City2, text: 'Bathroom' },
  { id: 3, image: City3, text: 'Kitchen' },
];

const TheSpace: React.FC = () => (
  <Wrapper>
    <Heading>The space</Heading>
    <RowWrapper className="m-0">
      {feature.map(item => (
        <ColWrapper xxl={4} xl={4} md={4} sm={12} key={item.id}>
          <ItemBox>
            <img src={item.image} alt={`${item.id}`} />
            <p className="m-0">{item.text}</p>
          </ItemBox>
        </ColWrapper>
      ))}
    </RowWrapper>
    <div className="d-flex">
      <Note>
        <b>Please note:</b> The layout, furniture, and decor of your spce may
        vary from what’s shown here
      </Note>
    </div>
  </Wrapper>
);
export default TheSpace;
