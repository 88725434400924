import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AppLinkImage, Details, Heading, HelpDetails, Ruler } from './styled';
import GooglePlaystoreIcon from '../../../Assets/Images/Icons/GooglePlaystoreIcon.svg';
import AppleAppstoreicon from '../../../Assets/Images/Icons/AppleAppstoreicon.svg';

const DownloadApp: React.FC = () => (
  <Row className="pt-4">
    <Heading className="pb-3">Download our app to:</Heading>
    <Details>
      &bull;&nbsp;&nbsp;Get notified when check-in instructions become
      available.
      <br />
      &bull;&nbsp;&nbsp;Request an early check-in or late checkout time.
      <br />
      &bull;&nbsp;&nbsp;Receive updates regarding the status of your requests.
      <br />
      &bull;&nbsp;&nbsp;Save check-in instructions for offline access in case
      you don’t have internet connection when you arrive.
      <br />
    </Details>
    <Row className="d-flex">
      <Col sm={12} md={12} lg={12}>
        <Row>
          <Col sm={6} md={6} lg={4}>
            <AppLinkImage src={GooglePlaystoreIcon} />
          </Col>
          <Col sm={6} md={6} lg={4}>
            <AppLinkImage src={AppleAppstoreicon} />
          </Col>
        </Row>
      </Col>
    </Row>
    <Ruler className="pt-5">
      <hr />
    </Ruler>
    <Heading className="pt-5">We’re here to help</Heading>
    <HelpDetails className="pt-3">
      In case you need to reach us, our customer support team is available 24/7
      to answer your questions.&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to="/get-help" className="helpLink">
        Get help
      </Link>
    </HelpDetails>
  </Row>
);

export default DownloadApp;
