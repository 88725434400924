import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const RowWrapper = styled(Row)`
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 30px;
  @media (max-width: 991px) {
    margin-top: 50px;
  }
  @media (max-width: 425px) {
    padding: 10px;
  }
`;

export const CardHeader = styled.h3`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #212f52;
  padding-bottom: 40px;
`;

export const ManageReservationGuidText = styled.h3`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #212f52;
  padding-bottom: 30px;
`;

export const DetailWrapper = styled.div`
  padding: 0px;
`;

export const LocalInfoTitle = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-transform: capitalize;
  color: #212f52;
`;

export const LocalInfoText = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212f52;
`;

export const StayDuration = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-transform: capitalize;
  color: #212f52;
`;

export const StayName = styled.p`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: #212f52;
`;

export const StayAddress = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #212f52;
`;

export const ActionWrapper = styled.div`
  display: flex;
  .stay-menu {
    width: 100%;
    .dropdown-toggle {
      height: 64px !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      background: #f9f9f9;
      border: 2px solid #212f52;
      box-sizing: border-box;
      border-radius: 5px;
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.1em;
      color: #212f52;
      outline: none;
      background: #f9f9f9;
      margin-left: 5px;
    }
    .dropdown-menu {
      padding: 0px;
      width: 99%;
      .dropdown-item {
        :hover {
          background: #eff0f3;
        }
        background: #f9f9f9;
        height: 50px;
        display: flex;
        align-items: center;
      }
    }
  }
`;

export const ReservationDetailButton = styled.button`
  background: #212f52;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 23px 0px;
  width: 100%;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.1em;
  margin-right: 5px;
`;
