import React from 'react';
import { Container } from 'react-bootstrap';
import ServiceBlock from '../../Common/ServiceBlock';
import ResponsibilityImage from '../../Assets/Images/ResponsibilityImage.jpg';
import ResponsibilityHero from '../ResponsibilityHero';
import OurGrowingCommitment from '../OurGrowingCommitment';
import ResponsibleBusinessHighlight from '../ResponsibleBusinessHighlight';

const ResponsbilityContainer: React.FC = () => (
  <>
    <ResponsibilityHero
      bgImage={ResponsibilityImage}
      title={
        <>
          Corporate
          <br />
          Responsibility &<br />
          Sustainability
          <br />
        </>
      }
    />
    <Container className="pb-5">
      <ServiceBlock
        isImageLeft={false}
        title="The Journey"
        texts={[
          `In 2021 Gilbert Hotels established a corporate responsibility & sustainability function. As a rapidly expanding modern business, we understand the importance of adopting responsible business practices early-on.
            We also recognize the growing trend for publicly traded companies to report against Environmental, Social and Governance (ESG) criteria. Though we are at the very beginning of our journey, we’ve taken deliberate steps to consider these crucial aspects of our business and how we will measure and transparently report against them to investors in the future.
            Broadly speaking, Gilbert Hotels’s goal is to foster the social, environmental and economic well-being of the communities we call home. To do this we’re developing initiatives, setting targets and forming partnerships through the framework of People, Place and Planet.`,
        ]}
      />
    </Container>
    <Container>
      <OurGrowingCommitment />
    </Container>
    <Container className="pb-5">
      <ResponsibleBusinessHighlight />
    </Container>
  </>
);

export default ResponsbilityContainer;
