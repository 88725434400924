import styled from 'styled-components';

import { CloseIcon } from '../../Icons';

export const StreetInputWrapper = styled.div`
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 35px;
  position: relative;
  padding: 10px 14px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;
  & > svg {
    cursor: pointer;
  }
  &:hover {
    background: #212f5214;
  }
`;

export const Close = styled(CloseIcon)`
  cursor: pointer;
`;

export const StreetInputBar = styled.input`
  padding-left: 10px;
  border: 0;
  outline: 0;
  width: 100%;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  background-color: transparent;
  color: #212f52;
  &::placeholder {
    color: #212f52;
  }
  &:hover {
    color: #212f52;
  }
`;
