import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail } from './styled';

const LimitationofLiability: React.FC = () => (
  <Row>
    <Detail>
      <p>
        <b>17. Limitation of Liability.</b>
      </p>
      <div style={{ paddingLeft: '30px', marginTop: '20px' }}>
        <p>
          <b>17.1</b> TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL
          ANY GILBERT HOTELS ENTITY BE LIABLE TO YOU FOR ANY INDIRECT,
          INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING
          DAMAGES FOR LOSS OF PROFITS, REPUTATION, GOODWILL, INCOME, SALES,
          REVENUE, BUSINESS, ANTICIPATED SAVINGS, OR DATA, OR FOR BUSINESS
          INTERRUPTION, WASTED MANAGEMENT OR OFFICE TIME, OR ANY OTHER
          INTANGIBLE LOSS.
        </p>
        <p>
          <b>17.2</b> TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL
          ANY GILBERT HOTELS ENTITY BE LIABLE TO YOU FOR ANY DAMAGES FOR
          PERSONAL OR BODILY INJURY OR EMOTIONAL DISTRESS, ARISING OUT OF OR
          RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS OR
          USE, THE SERVICE, ON-SITE SERVICES, PROPERTIES, OR ANY MATERIALS OR
          CONTENT AVAILABLE THROUGH THE SERVICE OR PROPERTIES, WHETHER BASED ON
          WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER
          LEGAL THEORY, AND WHETHER OR NOT ANY GILBERT HOTELS ENTITY HAS BEEN
          INFORMED OF THE POSSIBILITY OF DAMAGE. ANY USE OF THE SERVICE, ON-SITE
          SERVICES, PROPERTIES, OR ANY MATERIALS OR CONTENT AVAILABLE THROUGH
          THE SERVICE OR PROPERTIES IS AT YOUR AND YOUR GUESTS’ OWN RISK AND YOU
          AND YOUR GUESTS RELEASE, DISCHARGE, AND COVENANT NOT TO SUE ANY
          GILBERT HOTELS ENTITIES IN CONNECTION WITH ANY SUCH USE.
        </p>
        <p>
          <b>17.3</b> WE DO NOT EXCLUDE OR LIMIT IN ANY WAY OUR LIABILITY TO YOU
          WHERE IT WOULD BE UNLAWFUL TO DO SO. IN THE UK AND THE EU, THIS
          INCLUDES LIABILITY: FOR DEATH OR PERSONAL INJURY CAUSED BY OUR
          NEGLIGENCE OR THE NEGLIGENCE OF OUR EMPLOYEES, AGENTS, OR
          SUBCONTRACTORS; FOR FRAUD OR FRAUDULENT MISREPRESENTATION; OR FOR
          BREACH OF OUR OBLIGATION TO PERFORM THE SERVICE WITH REASONABLE CARE
          AND SKILL OR FAILURE TO PERFORM THE SERVICE IN ACCORDANCE WITH
          INFORMATION PROVIDED BY US TO YOU ABOUT US OR THE SERVICE.
        </p>
        <p>
          <b>17.4</b> EXCEPT AS PROVIDED IN SECTION 17.5 AND TO THE FULLEST
          EXTENT PERMITTED BY LAW, THE AGGREGATE LIABILITY OF THE GILBERT HOTELS
          ENTITIES TO YOU FOR ALL CLAIMS ARISING OUT OF OR RELATING TO THIS
          AGREEMENT OR TO THE USE OF OR ANY INABILITY TO USE ANY PORTION OF THE
          SERVICE, PROPERTIES, OR ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE
          SERVICE OR PROPERTIES, WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS
          LIMITED TO THE GREATER OF: (A) THE AMOUNT YOU HAVE PAID TO US FOR
          ACCESS TO AND USE OF THE SERVICE AND PROPERTIES IN THE 12 MONTHS PRIOR
          TO THE EVENT OR CIRCUMSTANCE GIVING RISE TO THE CLAIM; OR (B) $100;
          UNLESS THE UK HOTEL PROPRIETORS ’ ACT 1956 APPLIES, IN WHICH CASE OUR
          LIABILITY WILL BE LIMITED TO THE MAXIMUM PRESCRIBED UNDER THAT ACT.
        </p>
        <p>
          <b>17.5</b> EACH PROVISION OF THIS AGREEMENT THAT PROVIDES FOR A
          LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF
          DAMAGES IS INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES
          UNDER THIS AGREEMENT. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE
          BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS
          SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THIS AGREEMENT.
          THE LIMITATIONS IN THIS SECTION 16 WILL APPLY EVEN IF ANY LIMITED
          REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
        </p>
      </div>
    </Detail>
  </Row>
);

export default LimitationofLiability;
