import React from 'react';

import HomeHero from '../HomeHero';
import LandingPageCitysList from '../LandingPageCitysList';
import MobileAppLink from '../MobileAppLink';
import CitywiseHotels from '../CitywiseHotels';
import LandingHotelPlus from '../LandingHotelPlus';
import LandingVideoBlock from '../LandingVideoBlock';
import LandingTestimonials from '../LandingTestimonials';

const LandingContainer: React.FC = () => (
  <>
    <HomeHero />
    <LandingPageCitysList />
    <MobileAppLink />
    <CitywiseHotels />
    <LandingVideoBlock />
    <LandingTestimonials />
    <LandingHotelPlus />
  </>
);

export default LandingContainer;
