import React from 'react';
import AskAboutGroupRateContainer from '../../Components/AskAboutGroupRateContainer';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';

const AskAboutGroupRate: React.FC = () => (
  <div>
    <Header hasLogin />
    <AskAboutGroupRateContainer />
    <Footer />
  </div>
);

export default AskAboutGroupRate;
