import React from 'react';
import Header from '../../Components/Header';
import EditCheckInOutDateContainer from '../../Components/EditCheckInOutDateContainer';
import Footer from '../../Components/Footer';

const EditCheckInOutDate: React.FC = () => (
  <div>
    <Header hasLogin />
    <EditCheckInOutDateContainer />
    <Footer />
  </div>
);

export default EditCheckInOutDate;
