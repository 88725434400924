import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail } from './styled';

const Eligibility: React.FC = () => (
  <Row>
    <Detail>
      <p>
        <b>3. Eligibility.</b> You must be at least 18 years old to use the
        Service. By agreeing to this Agreement, you represent and warrant to us
        that: (a) you are (i) at least 18 years old, are an emancipated minor,
        or possess legal parental or guardian consent to agree to this
        Agreement; and (ii) fully able and competent to enter into this
        Agreement; (b) you have not previously been suspended or removed from
        the Service or any of our Properties; and (c) your Registration (defined
        in Section 3) and your use of the Service and our Properties is and will
        be in compliance with any and all applicable laws, rules, and
        regulations. If you are an organization (e.g., a company or school), the
        individual accepting this Agreement on your behalf represents and
        warrants that he or she has the authority to bind you to this Agreement
        and you agree to be bound by this Agreement.
      </p>
    </Detail>
  </Row>
);

export default Eligibility;
