import React from 'react';
import ResetPasswordContainer from '../../Components/ResetPasswordContainer';

const ResetPassword: React.FC = () => (
  <div>
    <ResetPasswordContainer />
  </div>
);

export default ResetPassword;
