import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { AuthProvider } from './Contexts/AuthContext';
import Landing from './Pages/Landing';
import Searching from './Pages/Searching';
import HotelDetail from './Pages/HotelDetail';
import CheckoutDetail from './Pages/CheckoutDetail';
import ReservationDetail from './Pages/ReservationDetail';
import AddGuests from './Pages/AddGuests';
import EditCheckInOutTime from './Pages/EditCheckInOutTime';
import MyStays from './Pages/MyStays';
// import Explore from './Pages/Explore/Explore';
import 'bootstrap/dist/css/bootstrap.min.css';
import MyStaysGuidebook from './Pages/MyStaysGuidebook';
import EditCheckInOutDate from './Pages/EditCheckInOutDate';
import Checkout1 from './Pages/Checkout1';
import EditProfile from './Pages/EditProfile';
import MyFavorite from './Pages/MyFavorite';
import GetHelp from './Pages/GetHelp';
import BusinessAndGroup from './Pages/BusinessAndGroup';
import AboutUs from './Pages/AboutUs';
import GetCorporeateRate from './Pages/GetCorporeateRate';
import AskAboutGroupRate from './Pages/AskAboutGroupRate';
import Responsibility from './Pages/Responsibility';
// import DelStatement from './Pages/DelStatement';
import Blog from './Pages/Blog';
import BlogExpanded from './Pages/BlogExpanded';
// import Exploration from './Pages/Exploration';
import 'react-toastify/dist/ReactToastify.css';
import { auth } from './Config/firebase';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsofService from './Pages/TermsofService';
import ResetPassword from './Pages/ResetPassword';
import Inbox from './Pages/Inbox';
import ViewAllPhoto from './Pages/ViewAllPhoto';
import ComingSoon from './Pages/ComingSoon';

const PrivateRoute = ({ component, isAuthenticated, ...rest }: any) => {
  const user = auth?.currentUser;
  const routeComponent = (props: any) =>
    user ? (
      React.createElement(component, props)
    ) : (
      <Redirect to={{ pathname: '/' }} />
    );
  return <Route {...rest} render={routeComponent} />;
};

const NavigateAdminPanel: React.FC = () => {
  useEffect(() => {
    window.location.replace(process.env.REACT_APP_ADMIN_URL);
  }, []);
  return <div />;
};

const App: React.FC = () => (
  <div>
    <AuthProvider>
      <Router>
        <Route exact path="/admin" component={NavigateAdminPanel} />
        <Switch>
          <PrivateRoute
            exact
            path="/my-guidebook"
            component={MyStaysGuidebook}
          />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route
            exact
            path="/view-all-photos/:unitId"
            component={ViewAllPhoto}
          />
          <Route exact path="/terms-of-service" component={TermsofService} />
          <Route exact path="/coming-soon" component={ComingSoon} />
          <Route exact path="/checkout1/:id" component={Checkout1} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/blog-expanded" component={BlogExpanded} />
          {/* <Route exact path="/exploration/:city/search" component={Exploration} /> */}
          <PrivateRoute exact path="/edit-profile" component={EditProfile} />
          <PrivateRoute exact path="/support/inbox" component={Inbox} />
          <PrivateRoute exact path="/my-favorites" component={MyFavorite} />
          <PrivateRoute exact path="/get-help" component={GetHelp} />
          <Route exact path="/support" component={GetHelp} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route
            exact
            path="/business-and-group"
            component={BusinessAndGroup}
          />
          <Route exact path="/responsibility" component={Responsibility} />
          {/* <Route exact path="/del-statement" component={DelStatement} /> */}
          <Route
            exact
            path="/business-and-group/business-inquiry"
            component={GetCorporeateRate}
          />
          <Route
            exact
            path="/business-and-group/group-inquiry"
            component={AskAboutGroupRate}
          />
          <Route
            exact
            path="/edit-check-in-out-date/:id"
            component={EditCheckInOutDate}
          />
          <Route
            exact
            path="/edit-check-in-out-time/:id"
            component={EditCheckInOutTime}
          />
          <Route exact path="/add-guests/:id" component={AddGuests} />
          <Route
            exact
            path="/reservation-detail/:id"
            component={ReservationDetail}
          />
          <PrivateRoute exact path="/my-stays" component={MyStays} />

          <PrivateRoute exact path="/checkout/:id" component={CheckoutDetail} />
          <Route exact path="/hotel-detail" component={HotelDetail} />
          <Route
            exact
            path="/destinations/:city/search"
            component={Searching}
          />
          <Route
            exact
            path="/destinations/:city/:id/search"
            component={HotelDetail}
          />
          <Route path="/" component={Landing} />
        </Switch>
      </Router>
      <ToastContainer theme="colored" />
    </AuthProvider>
  </div>
);

export default App;
