import styled from 'styled-components';

export const BGWrapper = styled.div`
  display: flex;
  padding: 60px 0px;
`;

export const Temp = styled.div`
  padding: 60px 0px;
`;
