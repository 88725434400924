import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import EditCheckInOutTimeForm from '../EditCheckInOutTimeForm';
import { BGWrapper } from './styled';

const EditCheckInOutTimeContainer: React.FC = () => (
  <BGWrapper>
    <Container>
      <Row>
        <Col className="m-auto" lg={6} md={8} sm={12}>
          <EditCheckInOutTimeForm />
        </Col>
      </Row>
    </Container>
  </BGWrapper>
);

export default EditCheckInOutTimeContainer;
