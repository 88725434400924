import React from 'react';
import {
  BGWrapper,
  Descripation,
  FirstInnerWrapper,
  FirstWrapper,
  FullWrapper,
  MainDiv,
  SecondMainInnerFirstDiv,
  SecondMainInnerSecondDiv,
  SecondWrapper,
  Title,
} from './styled';

interface FamilyroomProps {
  element: any;
}

const Familyroom: React.FC<FamilyroomProps> = ({
  element,
}: FamilyroomProps) => (
  <BGWrapper>
    <MainDiv>
      <FirstWrapper>
        <Title>{element?.title}</Title>
        <Descripation>
          {element?.room_feature?.map((item: any, i: number) =>
            i === 0 ? (
              <span key={`item${item?.id}`}>{item?.text}</span>
            ) : (
              <li key={`item${item?.id}`}>{item?.text}</li>
            ),
          )}
        </Descripation>
      </FirstWrapper>
      <SecondWrapper>
        <SecondMainInnerFirstDiv>
          <FullWrapper
            style={{
              backgroundImage: `url(${element?.photos[0]?.original})`,
            }}
          />
        </SecondMainInnerFirstDiv>
        <SecondMainInnerSecondDiv>
          <div style={{ width: '50%', padding: '5px' }}>
            <FirstInnerWrapper
              style={{
                backgroundImage: `url(${element?.photos[1]?.original})`,
              }}
            />
          </div>
          <div style={{ width: '50%', padding: '5px' }}>
            <FirstInnerWrapper
              style={{
                backgroundImage: `url(${element?.photos[2]?.original})`,
              }}
            />
          </div>
        </SecondMainInnerSecondDiv>
      </SecondWrapper>
    </MainDiv>
  </BGWrapper>
);

export default Familyroom;
