import React from 'react';
import {
  BGWrapper,
  Descripation,
  FirstInnerWrapper,
  FirstWrapper,
  FullWrapper,
  HostName,
  MainDiv,
  Quote,
  SecondInnerFirstDiv,
  SecondInnerSecondDiv,
  SecondInnerWrapper,
  SecondMainInnerFirstDiv,
  SecondMainInnerSecondDiv,
  SecondMainInnerThirdDiv,
  SecondWrapper,
  ThirdInnerFirstDiv,
  ThirdInnerSecondDiv,
  ThirdInnerWrapper,
  Title,
  WriteingWrapper,
} from './styled';
import QuoteIcon from '../../../Assets/Images/Icons/QuoteIcon.svg';

interface BedroomProps {
  element: any;
}

const Bedroom: React.FC<BedroomProps> = ({ element }: BedroomProps) => (
  <BGWrapper>
    <MainDiv>
      <FirstWrapper>
        <Title>{element?.title}</Title>
        <Descripation>
          {element?.room_feature?.map((item: any, i: number) =>
            i === 0 ? (
              <span key={`item${item?.id}`}>{item?.text}</span>
            ) : (
              <li key={`item${item?.id}`}>{item?.text}</li>
            ),
          )}
        </Descripation>
      </FirstWrapper>
      <SecondWrapper>
        <SecondMainInnerFirstDiv>
          <FullWrapper
            style={{
              backgroundImage: `url(${element?.photos[0]?.original})`,
            }}
          />
        </SecondMainInnerFirstDiv>
        <SecondMainInnerSecondDiv>
          <div style={{ width: '50%', padding: '5px' }}>
            <FirstInnerWrapper
              style={{
                backgroundImage: `url(${element?.photos[1]?.original})`,
              }}
            />
          </div>
          <div style={{ width: '50%', padding: '5px' }}>
            <FirstInnerWrapper
              style={{
                backgroundImage: `url(${element?.photos[2]?.original})`,
              }}
            />
          </div>
        </SecondMainInnerSecondDiv>
        <SecondMainInnerSecondDiv>
          <SecondInnerSecondDiv>
            <SecondInnerWrapper
              style={{
                backgroundImage: `url(${element?.photos[3]?.original})`,
              }}
            />
          </SecondInnerSecondDiv>
          <SecondInnerFirstDiv>
            <SecondInnerWrapper
              style={{
                backgroundImage: `url(${element?.photos[4]?.original})`,
              }}
            />
          </SecondInnerFirstDiv>
        </SecondMainInnerSecondDiv>
        <SecondMainInnerThirdDiv>
          <ThirdInnerFirstDiv>
            <WriteingWrapper>
              <div style={{ padding: '70px' }}>
                <img src={QuoteIcon} alt="quote" />
                <Quote>
                  Well equipt kitchen opens to sun room and back yard.
                </Quote>
                <HostName>Lisa, your host</HostName>
              </div>
            </WriteingWrapper>
          </ThirdInnerFirstDiv>
          <ThirdInnerSecondDiv>
            <ThirdInnerWrapper
              style={{
                backgroundImage: `url(${element?.photos[5]?.original})`,
              }}
            />
          </ThirdInnerSecondDiv>
        </SecondMainInnerThirdDiv>
      </SecondWrapper>
    </MainDiv>
  </BGWrapper>
);

export default Bedroom;
