import React from 'react';
import { Row } from 'react-bootstrap';
import { Heading, ParkingDetailsItemBox, Ruler } from './styled';

const details = [
  {
    id: 1,
    title: 'Street Parking (Free)',
    text: 'Free street parking: Free street parking can be found on the lower numbered streets south of this property, between Fourth Street and Second Street from Medina to Chicon. Free street parking is first come, first-served and can’t be guaranteed. Always follow posted signage and watch out for signs indicating that a residential permit is required.',
  },
  {
    id: 1,
    title: 'Street Parking (Paid)',
    text: 'Paid street parking: Paid hourly parking is available between Fourth Street and Sixth Street. The first two hours are $2 an hour, with the free gradually increasing depending on time of day. Parking for the full day is $39. Meters are free all day Sunday, as well as overnight Monday to Friday (6 p.m to 8 a.m). Be sure to read the parking terms at the kiosk as prices and hours may have changed. For remote payment options, we recommend downloading the Park ATX app.',
  },
  {
    id: 1,
    title: 'Nearby Parking Lot (Paid)',
    text: 'Paid parking lot: There is a paid parking garage located at the retail level of this building. The entrance to the garage is next to the leasing office in the same building as your Gilbert Hotels. Garage entry is ticketed and payment is made upon exit. When you arrive at the garage kiosk, you will be prompted to take a ticket. The parking arm will raise once you have pulled your ticket. Payment is made upon exit and is card-only. The cost for parking is displayed on the kiosk and is an hourly or daily rate. The cost is $5 per hour, or $25 per day. The garage`s 24-hour pay cycle begins at 3 a.m. There are no in and out privileges.',
  },
];

const Parking: React.FC = () => (
  <Row className="pt-5">
    <Heading>Parking</Heading>
    <div className="pt-4">
      {details.map(item => (
        <ParkingDetailsItemBox key={item.id}>
          <div className="pb-3">
            <h4 className="m-0">{item.title}</h4>
            <p className="m-0">{item.text}</p>
          </div>
        </ParkingDetailsItemBox>
      ))}
    </div>
    <Ruler className="pt-3">
      <hr />
    </Ruler>
  </Row>
);

export default Parking;
