import React from 'react';
import { Col, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { AddButton, FilterWrapper, RemoveButton, Title } from './styled';

import Minus from '../../Icons/Minus';
import Plus from '../../Icons/Plus';

interface FilterProps {
  setAddBedRoomsCount: () => void;
  setAddBedsCount: () => void;
  setAddBathRoomsCount: () => void;
  setRemoveBedRoomsCount: () => void;
  setRemoveBedsCount: () => void;
  setRemoveBathRoomsCount: () => void;
  bedRoomsCount: number;
  bedsCount: number;
  bathRoomsCount: number;
  setAirConditioning: (value: any) => void;
}

const Filter: React.FC<FilterProps> = ({
  setAddBedRoomsCount,
  setAddBedsCount,
  setAddBathRoomsCount,
  setRemoveBedRoomsCount,
  setRemoveBedsCount,
  setRemoveBathRoomsCount,
  bedRoomsCount,
  bedsCount,
  bathRoomsCount,
  setAirConditioning,
}) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  return (
    <FilterWrapper>
      <DropdownButton id="dropdown-item-button" title="Filters">
        <Row className="p-3">
          <Col lg={4} style={{ padding: '0 0 0 20px' }}>
            <Dropdown.ItemText>
              <b>Filters</b>
            </Dropdown.ItemText>
            <div
              className="d-flex mt-3 ml-1 justify-content-between"
              style={{ alignItems: 'flex-start' }}
            >
              <Title>Bedrooms (min)</Title>
              <div>
                <RemoveButton
                  type="button"
                  onClick={setRemoveBedRoomsCount}
                  disabled={bedRoomsCount === 0}
                >
                  <Minus fill="#212F52" />
                </RemoveButton>
                {bedRoomsCount || 0}
                <AddButton type="button" onClick={setAddBedRoomsCount}>
                  <Plus fill="#212F52" />
                </AddButton>
              </div>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ alignItems: 'flex-start' }}
            >
              <Title>Beds (min)</Title>
              <div>
                <RemoveButton
                  type="button"
                  onClick={setRemoveBedsCount}
                  disabled={bedsCount === 0}
                >
                  <Minus fill="#212F52" />
                </RemoveButton>
                {bedsCount}
                <AddButton type="button" onClick={setAddBedsCount}>
                  <Plus fill="#212F52" />
                </AddButton>
              </div>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ alignItems: 'flex-start' }}
            >
              <Title>Bathrooms (min)</Title>
              <div>
                <RemoveButton
                  type="button"
                  onClick={setRemoveBathRoomsCount}
                  disabled={bathRoomsCount === 0}
                >
                  <Minus fill="#212F52" />
                </RemoveButton>
                {bathRoomsCount}
                <AddButton type="button" onClick={setAddBathRoomsCount}>
                  <Plus fill="#212F52" />
                </AddButton>
              </div>
            </div>
          </Col>
          <Col lg={8} className="px-3" style={{ paddingRight: '0 !important' }}>
            <Dropdown.ItemText>
              <b>Other Features</b>
            </Dropdown.ItemText>
            <Row className="m-0">
              <Col lg={6} className="p-0">
                <div className="d-flex mt-3">
                  <input
                    type="checkbox"
                    name="airConditioning"
                    className="form-check-input"
                    style={{ margin: '0 20px 0 0' }}
                    defaultChecked={query.get('airConditioning') === 'true'}
                    onChange={setAirConditioning}
                  />
                  <p>Air Conditioning</p>
                </div>
                <div className="d-flex">
                  <input
                    type="checkbox"
                    name="cableTv"
                    defaultChecked={query.get('cableTv') === 'true'}
                    className="form-check-input"
                    style={{ margin: '0 20px 0 0' }}
                    onChange={setAirConditioning}
                  />
                  <p>Cable television</p>
                </div>
                <div className="d-flex">
                  <input
                    type="checkbox"
                    name="workspace"
                    defaultChecked={query.get('workspace') === 'true'}
                    className="form-check-input"
                    style={{ margin: '0 20px 0 0' }}
                    onChange={setAirConditioning}
                  />
                  <p>Workspace</p>
                </div>
                <div className="d-flex">
                  <input
                    type="checkbox"
                    name="kitchen"
                    defaultChecked={query.get('kitchen') === 'true'}
                    className="form-check-input"
                    style={{ margin: '0 20px 0 0' }}
                    onChange={setAirConditioning}
                  />
                  <p>Kichen</p>
                </div>
                <div className="d-flex">
                  <input
                    type="checkbox"
                    name="gym"
                    defaultChecked={query.get('gym') === 'true'}
                    className="form-check-input"
                    style={{ margin: '0 20px 0 0' }}
                    onChange={setAirConditioning}
                  />
                  <p>Fitness Center</p>
                </div>
              </Col>
              <Col lg={6}>
                <div className="d-flex mt-3">
                  <input
                    type="checkbox"
                    name="laundry"
                    defaultChecked={query.get('laundry') === 'true'}
                    className="form-check-input"
                    style={{ margin: '0 20px 0 0' }}
                    onChange={setAirConditioning}
                  />
                  <p>In-Suite Laundry</p>
                </div>
                <div className="d-flex">
                  <input
                    type="checkbox"
                    name="outdoorSpace"
                    defaultChecked={query.get('outdoorSpace') === 'true'}
                    className="form-check-input"
                    style={{ margin: '0 20px 0 0' }}
                    onChange={setAirConditioning}
                  />
                  <p>Outdoor space</p>
                </div>
                <div className="d-flex">
                  <input
                    type="checkbox"
                    name="freeParking"
                    defaultChecked={query.get('freeParking') === 'true'}
                    className="form-check-input"
                    style={{ margin: '0 20px 0 0' }}
                    onChange={setAirConditioning}
                  />
                  <p>Free Parking</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </DropdownButton>
    </FilterWrapper>
  );
};

export default Filter;
