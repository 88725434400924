import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import { DateRange } from 'react-day-picker';
import RoomDetailImageSlider from '../RoomDetailImageSlider';
import RoomDetails from '../RoomDetails';
import RoomCostDetails from '../RoomCostDetails';
import { db, functions } from '../../Config/firebase';
import { BGWrapper, LorderDiv } from './styled';
import AuthContext from '../../Contexts/AuthContext';
import LoginModal from '../LoginModal';
import SignupModal from '../SignupModal';
import AnimationLoader from '../../Common/Loader/AnimationLoader';

const HotelDetailContainer: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const paramsquery = new URLSearchParams(location?.search);
  const { id }: any = useParams();
  const authProvider = useContext(AuthContext);
  const currentUser = authProvider?.basicUserDetail;
  const callableGetQuote = functions.httpsCallable('ghotels-getQuote');
  const callablePaymentIntent = functions.httpsCallable(
    'ghotels-paymentIntent',
  );
  const [explore, setExplore] = useState<any>();
  const [unit, setUnit] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [bookLoading, setBookLoading] = useState<boolean>(false);
  const [priceLoading, setPriceLoading] = useState<boolean>(false);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [selectedRange, setSelectedRange] = useState<DateRange>(null);
  const [disableDatesData, setDisableDatesData] = useState<any>();
  const [errorCalendar, setErrorCalendar] = useState<any>();
  const [warningCalendar, setWraningCalendar] = useState<any>(null);
  const [loginModal, setLoginModal] = useState<boolean>(false);
  const [signupModal, setSignupModal] = useState<boolean>(false);
  const [fromValue, setFromValue] = useState<string | any>(
    paramsquery.get('startDate') || 'Check In',
  );
  const [toValue, setToValue] = useState<string | any>(
    paramsquery.get('endDate') || 'Check Out',
  );
  const [price, setPrice] = useState<any | null>(null);
  const [calendar, setCalendar] = useState<any | null>(null);

  useEffect(() => {
    setLoading(true);
    const getExploreData = async () => {
      const adUnitRef = await db.collection('ad-units').doc(id).get();
      const unitRef = await db.collection('units').doc(id).get();
      if (unitRef.exists) {
        setExplore(adUnitRef.data());
        setUnit(unitRef.data());
        setLoading(false);
      }
    };
    getExploreData();
  }, []);

  useEffect(() => {
    const getCalendarData = async () => {
      const calendarRef = await db
        .collection('calendar')
        .doc(`${id}_CURRENT`)
        .get();
      const daysRef = Object.values(calendarRef.data().days);
      setCalendar(daysRef);
      const tempData: any = [];
      daysRef.map((item: any) => {
        if (
          item.reservation.reservation_id &&
          item.date >= moment().format('YYYY-MM-DD')
        ) {
          tempData.push(item);
        }
        return tempData;
      });
      const reservationDatesBooked = tempData.reduce((res: any, obj: any) => {
        if (!res[obj.reservation.reservation_id])
          res[obj.reservation.reservation_id] = [];
        res[obj.reservation.reservation_id].push(obj);
        return res;
      }, {});
      const sortReservationStartandEndDate: any = Object.values(
        reservationDatesBooked,
      ).map((res: any) => {
        const sortedData = res.sort((a: any, b: any): any =>
          new Date(a.date).getTime() > new Date(b.date).getTime() ? 1 : -1,
        );

        if (sortedData?.length) {
          return {
            ...sortedData[0],
            start_date: sortedData[0].date,
            end_date: moment(sortedData[sortedData.length - 1].date).format(
              'YYYY-MM-DD',
            ),
          };
        }
        return sortedData;
      });
      const today = new Date();
      today.setDate(today.getDate() - 1);
      const pastdates = [{ from: new Date(1999, 1, 1), to: new Date(today) }];
      const disabledDates = await sortReservationStartandEndDate?.map(
        (item: any) => {
          const startDate: any = moment(item.start_date).add('1', 'days');
          const data = {
            date: new Date(item.date),
            from: new Date(startDate),
            to: new Date(item.end_date),
          };
          return data;
        },
      );
      if (disabledDates) {
        await setDisableDatesData([...disabledDates, ...pastdates]);
      }
    };
    getCalendarData();
  }, []);

  const handleEvent = (value: any) => {
    const startdate = moment(value.from).format('MM-DD-YYYY');
    const momentStartDate = moment(startdate).format('MM-DD-YYYY');
    setFromValue(momentStartDate);
    paramsquery.set('startDate', `${momentStartDate}`);
    const enddate = moment(value.to).format('MM-DD-YYYY');
    const momentendDate = moment(enddate).format('MM-DD-YYYY');
    setToValue(momentendDate);
    paramsquery.set('endDate', `${momentendDate}`);
    history.push({
      pathname: location.pathname,
      search: paramsquery.toString(),
    });
  };

  const getQuoteData = async (startDate: any, endDate: any) => {
    setPriceLoading(true);
    const getQuote = await callableGetQuote({
      unit_id: id,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
    });
    if (!getQuote?.data?.error) {
      setPrice(getQuote?.data);
      setPriceLoading(false);
    } else {
      toast.error(getQuote?.data?.error);
      setPriceLoading(false);
    }
  };

  useEffect(() => {
    if (fromValue !== 'Check In' && toValue !== 'Check Out') {
      getQuoteData(fromValue, toValue);
    }
  }, []);

  const handleRangeSelect: any = async (range1: DateRange | undefined) => {
    setErrorCalendar('');
    setWraningCalendar(null);
    setSelectedRange(null);
    if (fromValue !== 'Check In' && toValue !== 'Check Out') {
      setFromValue('Check In');
      setToValue('Check Out');
      console.log('if range1', range1);
      if (range1) {
        if (moment(range1?.from).format('YYYY-MM-DD') < fromValue) {
          setFromValue(moment(range1?.from).format('MM-DD-YYYY'));
          setToValue('');
        } else if (moment(range1?.to).format('YYYY-MM-DD') >= fromValue) {
          setFromValue(moment(range1?.to).format('MM-DD-YYYY'));
          setToValue('');
        } else {
          setFromValue(moment(range1?.from).format('MM-DD-YYYY'));
        }
      } else {
        setFromValue(moment(fromValue).format('MM-DD-YYYY'));
        setToValue('');
      }
    } else {
      console.log('else range1', range1);
      setSelectedRange({ ...range1 });
      const tempStartDate = moment(range1?.from, 'YYYY-MM-DD').startOf('day');
      const tempEndDate = moment(range1?.to, 'YYYY-MM-DD');
      const diffDays = tempEndDate.diff(tempStartDate, 'days');
      const findMinStay = await calendar.find(
        (item: any) => item?.date === moment(range1?.from).format('YYYY-MM-DD'),
      );
      if (findMinStay?.status?.available) {
        if (diffDays >= findMinStay?.min_stay) {
          if (range1?.from && range1?.to) {
            const tempdata: any = [];
            await disableDatesData.map((item: any) => {
              if (
                range1.from.getTime() <= item.from.getTime() &&
                range1.to.getTime() >= item.from.getTime()
              ) {
                tempdata.push(item);
              }
              return tempdata;
            });
            if (tempdata.length) {
              setErrorCalendar('Those dates are not available');
              setIsShow(false);
            }
            if (range1?.from) {
              handleEvent({ from: range1.from, to: range1.to });
            } else {
              setFromValue('');
            }
            if (range1?.to) {
              handleEvent({ from: range1.from, to: range1.to });
              setIsShow(false);
            } else {
              setToValue('');
            }
            getQuoteData(range1.from, range1.to);
          }
        } else {
          setPrice(null);
          setWraningCalendar(`${findMinStay?.min_stay}-nights minimum`);
        }
      } else {
        setPrice(null);
        setWraningCalendar(`checkout only`);
      }
    }
  };

  const getHoteldetails = async () => {
    if (currentUser) {
      if (
        fromValue === 'Check In' ||
        fromValue === ' Invalid date' ||
        toValue === ' Invalid date' ||
        toValue === 'Check Out'
      ) {
        setIsShow(true);
      } else {
        try {
          setBookLoading(true);
          const data: any = {
            room_id: id,
            current_user: currentUser?.uuid,
            start_date: fromValue,
            end_date: toValue,
          };
          const paymentIntentResponse = await callablePaymentIntent(data);
          console.log('paymentIntentResponse', paymentIntentResponse);
          if (!paymentIntentResponse?.data?.error) {
            const createCheckOutDoc = await db.collection('checkout').add({
              guest: {
                uuid: currentUser?.uuid,
                first_name: currentUser?.first_name,
                email: currentUser?.email,
                last_name: currentUser?.last_name || '',
                phone: '',
                picture: '',
              },
              picture: unit?.picture?.filter((item: any) => item?.isCurrent)[0]
                ?.original,
              address: unit?.address,
              unit_id: id,
              check_in_date: price?.startDate,
              check_out_date: price?.endDate,
              nights: price?.nights,
              prices: {
                discount: price?.discount,
                per_day_Price: price?.nightlyRate,
                total_nights: price?.memberTotal,
                total_discount: price?.memberDiscount,
                total_yourRates: price?.memberNightlyRate,
                total_cleaning_fee: '',
                total_tax: price?.taxes,
                totalPrice: price?.total,
                base_total: price?.baseTotal,
                currency: price?.currency,
              },
              payment: {
                client_secret: paymentIntentResponse?.data?.data.clientSecret,
                payment_intent: paymentIntentResponse?.data?.data?.id,
              },
            });
            if (createCheckOutDoc) {
              history.push(`/checkout/${createCheckOutDoc?.id}`);
            }
          } else {
            toast.error(paymentIntentResponse?.data?.error);
            setBookLoading(false);
          }
        } catch (error) {
          console.log('error', error);
          setBookLoading(false);
        }
      }
    } else {
      setLoginModal(true);
    }
  };

  return (
    <>
      {!loading ? (
        <BGWrapper>
          <RoomDetailImageSlider id={id} />
          <Container>
            <Row>
              <Col lg={8} md={12} sm={12}>
                <RoomDetails explore={explore} />
              </Col>
              <Col lg={4} md={12} sm={12}>
                <RoomCostDetails
                  setIsShow={setIsShow}
                  isShow={isShow}
                  explore={explore}
                  getHoteldetails={getHoteldetails}
                  fromValue={fromValue}
                  setFromValue={setFromValue}
                  toValue={toValue}
                  setToValue={setToValue}
                  bookLoading={bookLoading}
                  handleRangeSelect={handleRangeSelect}
                  selectedRange={selectedRange}
                  setSelectedRange={setSelectedRange}
                  disableDatesData={disableDatesData}
                  error={errorCalendar}
                  setErrorCalendar={setErrorCalendar}
                  priceLoading={priceLoading}
                  price={price}
                  warningCalendar={warningCalendar}
                />
              </Col>
            </Row>
          </Container>
        </BGWrapper>
      ) : (
        <LorderDiv>
          <AnimationLoader />
        </LorderDiv>
      )}
      <LoginModal
        show={loginModal}
        onCloseModal={setLoginModal}
        setSignupModal={setSignupModal}
      />
      <SignupModal show={signupModal} onCloseModal={setSignupModal} />
    </>
  );
};

export default HotelDetailContainer;
