import React from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  BookedDetails,
  CreateAccount,
  FormControl,
  Modal,
  NextButton,
  Title,
} from './styled';

const ConfirmationModal = ({ show, handleClose }: any): JSX.Element => (
  <Modal show={show} handleClose={handleClose} centered className="Width">
    <Title>Enter your booking site confirmation number:</Title>
    <FormControl className="pt-3">
      <Form.Control type="text" placeholder="Booking confirmation code" />
    </FormControl>
    <BookedDetails className="pt-3 pb-4">
      <p>
        Booked through Gilbert Hotels.com?{' '}
        <Link className="links" to="/">
          Login
        </Link>{' '}
        and visit the &quot;My Stays&quot; page for your check-in details.
        <br />
        Booked through Expedia? Check your email for instructions on how to
        receive your check-in details.
        <br />
        <br />
        Not what you are looking for?{' '}
        <Link className="links" to="/">
          Feel free to contact us.
        </Link>
      </p>
    </BookedDetails>
    <NextButton className="py-3">Next</NextButton>
    <CreateAccount className="pt-3 pb-3">
      <p>
        New to Gilbert Hotels?{' '}
        <Link className="links" to="/">
          Create an account
        </Link>
        <br />
        Forgot your password?{' '}
        <Link className="links" to="/">
          Reset password
        </Link>
      </p>
    </CreateAccount>
  </Modal>
);

export default ConfirmationModal;
