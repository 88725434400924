import styled from 'styled-components';
import { Col } from 'react-bootstrap';

export const DatePickerWrapper = styled(Col)`
  display: flex;
  align-items: center;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 33px;
  text-align: center;
  background: #f9f9f9;
  width: 100%;
  padding: 20px;
`;

export const Wrapper = styled.div``;

export const Line = styled.div`
  padding-top: 30px;
  display: flex;
  border-bottom: 2px #caccd1 solid;
`;

export const DateInput = styled.input`
  border: none;
  width: 100%;
  color: #212f52;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: #212f52;
  }
`;
