import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const ContantHeader = styled(Row)`
  position: relative;
`;

export const Title = styled.h1`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 52px;
  color: #212f52;
  @media (max-width: 425px) {
    font-size: 36px;
  }
`;

export const SubTitle = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212f52;
`;
