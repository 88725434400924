import React from 'react';
import Header from '../../Components/Header';
import ReservationDetailContainer from '../../Components/ReservationDetailContainer';
import Footer from '../../Components/Footer';

const ReservationDetail: React.FC = () => (
  <div>
    <Header hasLogin />
    <ReservationDetailContainer />
    <Footer />
  </div>
);

export default ReservationDetail;
