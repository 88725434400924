import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail } from './styled';

const Term: React.FC = () => (
  <Row>
    <Detail>
      <div style={{ paddingLeft: '30px' }}>
        <p>
          <b>14.1 Term.</b>
          <div style={{ paddingLeft: '30px', marginTop: '20px' }}>
            <p>
              <b>14.1.1 Term for STA/Hotel Reservations.</b> This Agreement as
              applicable to STA/Hotel Reservations and related Services and
              Properties is effective beginning when you accept it, or first
              download, install, or otherwise access or use the Service or
              Properties for STA/Hotel Reservations, and its term ends when the
              Agreement is terminated as described in Section 13.2.
            </p>
            <p>
              <b>14.1.2 Term for Extended Stay Reservations.</b> This Agreement
              as applicable to Extended Stay Reservations and related Services
              and Properties is effective beginning when you accept it, when you
              enter into an Extended Stay Agreement with us that incorporates
              this Terms of Service by reference, or when you first download,
              install, or otherwise access or use the Service or Properties for
              Extended Stay Reservations, and its term shall end in accordance
              with Section 13.2. The term and termination of any reservation
              (and the attendant right to use any Extended Stay Property) shall
              be as set forth in the applicable Extended Stay Agreement or
              Confirmation.
            </p>
            <p>
              <b>14.1.3 Term for Subscription Reservations.</b> This Agreement
              as applicable to Subscription Reservations and related Services
              and Properties is effective beginning when you accept it, when you
              enter into a Membership Agreement with us that incorporates this
              Terms of Service by reference, or when you first download,
              install, or otherwise access or use the Service or Properties for
              Subscription Reservations, and its term shall end in accordance
              with Section 13.2. The term and termination of any subscription
              (and the attendant right to use any Subscription Property) shall
              be as set forth in the applicable Membership Agreement.
            </p>
          </div>
        </p>
      </div>
    </Detail>
  </Row>
);

export default Term;
