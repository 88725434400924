import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail, LinkTag } from './styled';

const UseofBookedProperties: React.FC = () => (
  <Row>
    <Detail>
      <div style={{ paddingLeft: '30px' }}>
        <p>
          <b>7.2 Use of Booked Properties.</b>
          <div style={{ paddingLeft: '30px', marginTop: '20px' }}>
            <p>
              <b>7.2.1 Limited License to STA/HOTEL Properties.</b> Subject to
              your complete and ongoing compliance with this Agreement and
              subject to applicable law, we grant you a limited, exclusive
              (except as expressly provided in this Section 6.2),
              non-transferable, non-sublicensable (except as expressly provided
              in this Section 6.2), revocable license to occupy the specific
              unit at the specific Property for only the specific period of time
              that you have reserved under a STA/Hotel Reservation for personal
              (except as expressly provided in this Section 6.2) and
              non-commercial purposes only and for the maximum number of
              occupants permitted by us, as confirmed in writing by us (a
              <b>“Confirmation”</b>) and provided by us to you by email or other
              means (such specific type of reserved Property, the{' '}
              <b>“STA/Hotel Property”</b>
              and such specific reserved period of time, the{' '}
              <b>“STA/Hotel Period of Occupancy”</b>) and the right of ingress
              and egress to and from the STA/Hotel Property. For the avoidance
              of doubt, we are not obliged to make the STA/Hotel Property
              available, and a binding contract with respect to the STA/Hotel
              Property is not in place, until such time as you receive the
              foregoing Confirmation (at which point your reservation is deemed
              “completed”), and in no event shall you have any rights to
              renewal, extension, or recurring use of the STA/Hotel Property.
              You hereby agree to direct any repair, maintenance, and other
              operational questions, requests, or complaints to Gilbert Hotels
              at the contact information located at{' '}
              <LinkTag to="/">https://www.gilberthotels.com/help</LinkTag> and
              shall not direct any such communications to any Landlord or other
              non-Gilbert Hotels personnel.
            </p>
            <p>
              <b>7.2.2 Limited Right to Use Extended Stay Properties.</b> This
              Agreement shall not provide permission or the right to enter or
              use any Property that is the subject of an Extended Stay
              Reservation unless and until you enter into an Extended Stay
              Agreement or you receive a Confirmation from us identifying the
              applicable Property. For the avoidance of doubt, we are not
              obliged to make the Extended Stay Property available until such
              Extended Stay Agreement has been fully executed by you or you have
              received a Confirmation from us. The Extended Stay Agreement or
              Confirmation shall contain the terms of the applicable
              reservation, including the specific unit at the specific Property
              that is the subject of the reservation (the{' '}
              <b>“Extended Stay Property”</b>) and the term of the reservation
              (the <b>“Extended Stay Period of Occupancy”</b>). In no event
              shall you have any rights to renewal, extension, or recurring use
              of the Extended Stay Property, except as required by law. You
              hereby agree to direct any repair, maintenance and other
              operational questions, requests, or complaints to Gilbert Hotels
              at the contact information located at{' '}
              <LinkTag to="/">https://www.gilberthotels.com/help</LinkTag> and
              shall not direct any such communications to any Landlord or other
              non-Gilbert Hotels personnel.
            </p>
            <p>
              <b>7.2.3 Limited Right to Use Subscription Properties.</b> This
              Agreement shall not provide permission or the right to enter or
              use any Property that is the subject of a subscription unless and
              until you enter into a Membership Agreement identifying the
              applicable Property. For the avoidance of doubt, we are not
              obliged to make the Subscription Property available until such
              Membership Agreement has been fully executed by you and us. The
              Membership Agreement shall contain the terms of the applicable
              subscription, including the specific unit at the specific Property
              that is the subject of the subscription (the{' '}
              <b>“Subscription Property”,</b> and, together with a STA/Hotel
              Property, and an Extended Stay Property, each a{' '}
              <b>“Booked Property”</b>), the term of the subscription (the{' '}
              <b>“Subscription Period of Occupancy”</b> and, together with a
              STA/Hotel Period of Occupancy, and an Extended Stay Period of
              Occupancy, each a <b>“Period of Occupancy”</b>), any rights of
              transfer and entry by us or any third parties. In no event shall
              you have any rights to renewal, extension, or recurring use of the
              Subscription Property, except as required by law. You hereby agree
              to direct any repair, maintenance and other operational questions,
              requests, or complaints to Gilbert Hotels at the contact
              information located at{' '}
              <LinkTag to="/">https://www.gilberthotels.com/help</LinkTag> and
              shall not direct any such communications to any Landlord or other
              non-Gilbert Hotels personnel.
            </p>
            <p>
              <b>7.2.4</b> Notwithstanding the foregoing: (a) you may sublicense
              or permit a third party to use the Booked Property for Limited
              Non-Personal Use only provided you meet the conditions set forth
              in Section 6.1(i)-(iii).
            </p>
            <p>
              <b>7.2.5</b> We reserve the right at all times during the Period
              of Occupancy to enter and to permit other individuals to enter the
              Property at any time in the following circumstances or for the
              following purposes, subject to applicable law: (i) in response to
              your written or verbal request, upon your consent, or as necessary
              to provide the Service to you; (ii) to investigate, address, or
              prevent any actual or reasonably suspected or foreseeable past,
              current, or impending unlawful activity or damage to the Property
              (you expressly agree that we have the right to document by
              photograph, video or otherwise any such damage, and/or compliance
              with this Agreement and compliance with the House Rules, subject
              to and in compliance with the Privacy Policy, during such entry);
              (iii) to prevent or mitigate bodily harm to you or others that we
              reasonably expect has occurred or may occur; (iv) upon your breach
              or suspected breach of any provision of this Agreement, provided
              that we give you prior notice (e.g., by email, phone, text
              message, or knocking on the door); (v) for any purpose (including
              for repair and maintenance and to examine or show the same),
              provided that we give you reasonable notice (e.g., by email,
              phone, text message, or knocking on the door); (vi) to cooperate
              with requests from law enforcement officials to enter the
              Property; (vii) where we believe reasonably necessary to protect
              the personal safety, rights, or property of us, you, any third
              party, or to prevent fraud or other unlawful activity; and/or
              (viii) at any time that may be necessary or advisable in our sole
              discretion, such as in the event of an emergency.
            </p>
          </div>
        </p>
      </div>
    </Detail>
  </Row>
);

export default UseofBookedProperties;
