import React, { useContext, useEffect, useState } from 'react';
import { Container, FormControl, Modal, Spinner } from 'react-bootstrap';
import firebase from 'firebase/compat/app';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { reauthenticateWithCredential } from 'firebase/auth';
import {
  Button,
  ContainerWrapper,
  FieldDiv,
  Heading,
  Info,
  InputIcon,
  SaveUpdateButton,
  UpdateAccountDiv,
} from './styled';
import UserIcon from '../../../Assets/Images/Icons/UserIcon2.svg';
import EmailIcon from '../../../Assets/Images/Icons/EmailIcon.svg';
import AuthContext from '../../../Contexts/AuthContext';
import { auth, db } from '../../../Config/firebase';

const UpdateAccount: React.FC = () => {
  const authProvider = useContext(AuthContext);
  const [formData, setFormData] = useState<any>({
    first_name: '',
    last_name: '',
    email: '',
  });
  const [show, setShow] = useState<boolean>(false);
  const [passwordValue, setPasswordValue] = useState<string>('');

  const userid = auth?.currentUser?.uid;
  const basicUserDetail = authProvider?.basicUserDetail;
  useEffect(() => {
    setFormData({
      first_name: basicUserDetail.first_name,
      last_name: basicUserDetail.last_name,
      email: basicUserDetail.email,
    });
  }, []);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Email is not valid'),
  });

  const handleClose = () => setShow(false);
  const handleShow = async () => {
    try {
      const credential = firebase.auth.EmailAuthProvider.credential(
        auth.currentUser.email,
        passwordValue,
      );
      const successResponce = await reauthenticateWithCredential(
        auth.currentUser,
        credential,
      );
      // .then(usercred => {
      //   setShow(false);
      // });
      if (successResponce) {
        setShow(false);
      }
    } catch (error) {
      console.log('Error', error);
    }
  };

  const updateEmail = async (
    email: string,
    { setErrors, setSubmitting }: any,
  ) => {
    try {
      const updateUserEmail: any = await authProvider.updateEmail(email);
      setSubmitting(false);
    } catch (error: any) {
      switch (error.code) {
        case 'auth/requires-recent-login':
          setErrors({
            email:
              ' This operation is sensitive and requires recent authentication.',
          });
          setShow(true);
          break;
        case 'auth/user-token-expired':
          setErrors({
            email: ' Token Expired',
          });
          break;
        case 'auth/email-already-in-use':
          setErrors({
            email: 'The email address is already in use by another account',
          });
          break;

        default:
          setErrors({
            email: 'The email address is already in use by another account',
          });
          break;
      }
      setSubmitting(false);
    }
  };

  return (
    <>
      <ContainerWrapper>
        <UpdateAccountDiv>
          <FieldDiv>
            <Heading className="pt-3">Update Account</Heading>
            <Info>You can update your contact information here.</Info>
            <Formik
              initialValues={{
                first_name: formData?.first_name,
                email: formData?.email,
                last_name: formData?.last_name,
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={async (values, { setErrors, setSubmitting }) => {
                setSubmitting(true);
                try {
                  const { email } = values;
                  updateEmail(email, { setErrors, setSubmitting });
                  const docRef = db.collection('guests').doc(userid);
                  await docRef.update({
                    first_name: values.first_name,
                    last_name: values.last_name,
                    email: values.email,
                  });
                  setSubmitting(false);
                } catch (error) {
                  setSubmitting(false);
                }
              }}
            >
              {({
                touched,
                errors,
                values,
                isSubmitting,
                handleSubmit,
                handleChange,
                handleBlur,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="d-flex">
                    <InputIcon src={UserIcon} />
                    <FormControl
                      name="first_name"
                      placeholder="First Name"
                      type="text"
                      value={values?.first_name}
                      className={`edit-profile-input ${
                        touched.first_name && errors?.first_name
                          ? 'is-invalid'
                          : ''
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <ErrorMessage
                    component="div"
                    name="first_name"
                    className="invalid-feedback"
                  />
                  <div className="d-flex pt-4">
                    <InputIcon src={UserIcon} />
                    <FormControl
                      name="last_name"
                      placeholder="Last Name"
                      type="text"
                      value={values?.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`edit-profile-input ${
                        touched.last_name && errors?.last_name
                          ? 'is-invalid'
                          : ''
                      }`}
                    />
                  </div>
                  <ErrorMessage
                    component="div"
                    name="last_name"
                    className="invalid-feedback"
                  />
                  <div className="d-flex pt-4">
                    <InputIcon src={EmailIcon} />
                    <FormControl
                      name="email"
                      placeholder="Email"
                      type="email"
                      value={values?.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`edit-profile-input ${
                        touched.email && errors?.email ? 'is-invalid' : ''
                      }`}
                    />
                  </div>
                  <ErrorMessage
                    component="div"
                    name="email"
                    className="invalid-feedback"
                  />
                  {!isSubmitting ? (
                    <SaveUpdateButton disabled={isSubmitting} className="mt-4">
                      Save Updated Account
                    </SaveUpdateButton>
                  ) : (
                    <div className="text-center">
                      <Spinner animation="border" />
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </FieldDiv>
        </UpdateAccountDiv>
      </ContainerWrapper>

      <Modal show={show} onHide={handleClose}>
        {/* <CloseIcon src={CloseIconSvg} onClick={handleClose} /> */}
        <Container>
          <Modal.Body className="pt-5">
            <FormControl
              type="text"
              value={formData?.email}
              className="input"
              disabled
            />
            <FormControl
              type="password"
              className="input"
              onChange={e => setPasswordValue(e.target.value)}
              placeholder="Password"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button className="button-cancel" onClick={handleClose}>
              Close
            </Button>
            <Button className="button-submit" onClick={handleShow}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Container>
      </Modal>
    </>
  );
};
export default UpdateAccount;
