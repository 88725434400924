import React from 'react';
import {
  BGWrapper,
  Descripation,
  FirstWrapper,
  MainDiv,
  SecondWrapper,
  Title,
  Wrapper,
} from './styled';

interface FrontyardProps {
  element: any;
}

const Frontyard: React.FC<FrontyardProps> = ({ element }: any) => (
  <BGWrapper>
    <MainDiv>
      <FirstWrapper>
        <Title>{element?.title}</Title>
        <Descripation>
          {element?.room_feature?.map((item: any, i: number) =>
            i === 0 ? (
              <span key={`item${item?.id}`}>{item?.text}</span>
            ) : (
              <li key={`item${item?.id}`}>{item?.text}</li>
            ),
          )}
        </Descripation>
      </FirstWrapper>
      <SecondWrapper>
        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
          <div style={{ width: '50%', padding: '5px' }}>
            <Wrapper
              style={{
                backgroundImage: `url(${element?.photos[0]?.original})`,
              }}
            />
          </div>
          <div style={{ width: '50%', padding: '5px' }}>
            <Wrapper
              style={{
                backgroundImage: `url(${element?.photos[1]?.original})`,
              }}
            />
          </div>
        </div>
      </SecondWrapper>
    </MainDiv>
  </BGWrapper>
);

export default Frontyard;
