import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 0px 0px;
`;

export const Heading = styled.h6`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #212f52;
`;

export const AllTopicsDiv = styled.div`
  background: #ffffff;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 10px;
  height: 100%;
`;

export const StayingWithUsDiv = styled.div`
  display: flex;
  li {
    padding-bottom: 20px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212f52;
  }
`;
