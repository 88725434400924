import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail } from './styled';

const AlternativeAccommodations: React.FC = () => (
  <Row>
    <Detail>
      <div style={{ paddingLeft: '30px' }}>
        <p>
          <b>6.5 Alternative Accommodations.</b>
          <div style={{ paddingLeft: '30px', marginTop: '20px' }}>
            <p>
              <b>
                6.5.1 Alternative Accommodations for STA/Hotel Reservations.
              </b>{' '}
              In the event that a STA/Hotel Property (defined in Section 6.2)
              becomes unavailable for the whole or any part of the applicable
              STA/Hotel Period of Occupancy (defined in Section 6.2) for any
              reason, we will endeavor to arrange for suitable alternative
              accommodations during the Period of Occupancy. If we are unable to
              find suitable alternative accommodations during this period we
              have the right to cancel the applicable portion of the STA/Hotel
              Period of Occupancy and we will credit you with a full refund of
              pre-paid fees.
            </p>
            <p>
              <b>
                6.5.2 Alternative Accommodations for Extended Stay Reservations.
              </b>{' '}
              In the event that an Extended Stay Property (defined in Section
              6.2) becomes unavailable for the whole or any part of the
              applicable Extended Stay Period of Occupancy (defined in Section
              6.2) for any reason, we will endeavor to arrange for suitable
              alternative accommodations during such Period of Occupancy. If we
              are unable to find suitable alternative accommodations during this
              period, we have the right to cancel the applicable portion of the
              Extended Stay Period of Occupancy subject to the express terms of
              any applicable Extended Stay Agreement or applicable law and we
              will credit you with a full refund of pre-paid fees. If you do not
              accept a suitable alternative accommodation that we offer to you,
              then you are entitled to cancel the applicable reservation, any
              pre-paid fees will be refunded to you, and neither we nor any of
              our third-party suppliers will be liable to you for any further
              amounts relating to such reservation.
            </p>
            <p>
              <b>
                6.5.3 Alternative Accommodations for Subscription Reservations.
              </b>{' '}
              In the event that a Subscription Property (defined in Section 6.2)
              becomes unavailable for the whole or any part of the applicable
              Subscription Period of Occupancy (defined in Section 6.2) for any
              reason, we will endeavor to arrange for suitable alternative
              accommodations during such Period of Occupancy. If we are unable
              to find suitable alternative accommodations during this period, we
              have the right to cancel the applicable portion of the
              Subscription Period of Occupancy subject to the express terms of
              any applicable Membership Agreement or applicable law and we will
              credit you with a full refund of pre-paid fees. If you do not
              accept a suitable alternative accommodation that we offer to you,
              then you are entitled to cancel the applicable subscription, any
              pre-paid fees will be refunded to you, and neither we nor any of
              our third-party suppliers will be liable to you for any further
              amounts relating to such subscription.
            </p>
          </div>
        </p>
      </div>
    </Detail>
  </Row>
);

export default AlternativeAccommodations;
