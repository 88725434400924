import React from 'react';
import { Col } from 'react-bootstrap';

import {
  HeroBG,
  HeroBGOverlay,
  Herocontainer,
  HeroContant,
  HeroText,
  HeroTitle,
} from './styled';

const Hero = ({ bgImage, title, text }: any): JSX.Element => (
  <HeroBG src={bgImage}>
    <HeroBGOverlay />
    <Herocontainer fluid>
      <HeroContant>
        <Col xl={12} lg={12} md={12} sm={12}>
          <HeroTitle>{title}</HeroTitle>
          <Col lg={12} md={12} sm={12}>
            <HeroText>{text}</HeroText>
          </Col>
        </Col>
      </HeroContant>
    </Herocontainer>
  </HeroBG>
);

export default Hero;
