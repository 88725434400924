import React from 'react';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import MyStaysGuideBookContainer from '../../Components/MyStaysGuideBookContainer';

const MyStaysGuidebook: React.FC = () => (
  <div>
    <Header hasLogin />
    <MyStaysGuideBookContainer />
    <Footer />
  </div>
);

export default MyStaysGuidebook;
