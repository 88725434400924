import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: auto;
  padding-bottom: 50px;
`;

export const Image = styled.div`
  background: #caccd1;
  height: 220px;
  width: 220px;
  border-radius: 50%;
`;

export const LeaderName = styled.h3`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #212f52;
`;

export const LeaderPost = styled.h3`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #212f52;
`;
