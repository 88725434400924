import { serverTimestamp } from 'firebase/firestore';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Container, FormControl } from 'react-bootstrap';
import { db } from '../../Config/firebase';
import AuthContext from '../../Contexts/AuthContext';
import {
  ChatDiv,
  ChatMainWrapper,
  GuestMessageDiv,
  GuestMessageLi,
  IMessageSpan,
  InputDiv,
  RowWrpper,
  SendButton,
  SendMessageDiv,
  SendmessageLi,
  SendMessageSpan,
} from './styled';

function replaceWithBr(text: any) {
  return text.replace(/\n/g, '<br />');
}

const InboxContainer: React.FC = () => {
  const authProvider = useContext(AuthContext);
  const currentUser = authProvider?.basicUserDetail;
  const [threadMessages, setThredMessages] = useState<any | null>([]);
  const [length, setLength] = useState({
    items: Array.from({ length: 10 }),
  });

  const messageRef: any = useRef();

  const getThreadsData = async () => {
    await db
      .collection('threads')
      .doc(currentUser?.uuid)
      .collection('messages')
      .orderBy('created_at', 'desc')
      .limit(length.items.length)
      .onSnapshot(doc => {
        const tempData: any = [];
        doc.forEach(item => {
          tempData.push(item.data());
        });
        setThredMessages(tempData);
        setLength({
          items: length.items.concat(
            Array.from({
              length: 10,
            }),
          ),
        });
      });
  };

  useEffect(() => {
    getThreadsData();
  }, []);

  const fetchMoreData = async () => {
    await setTimeout(() => {
      getThreadsData();
    }, 1500);
  };

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [threadMessages]);

  return (
    <>
      <Container>
        <ChatMainWrapper
          onScroll={(e: any) => {
            if (
              e.target.scrollHeight ===
              Math.abs(e.target.scrollTop) + e.target.clientHeight
            ) {
              fetchMoreData();
            }
          }}
        >
          <ChatDiv>
            {threadMessages?.map((item: any) =>
              item?.user_id !== currentUser?.uuid ? (
                <div key={item?.created_at?.toDate().getTime()}>
                  <SendMessageDiv>
                    <SendmessageLi>
                      <p
                        style={{ margin: '0px' }}
                        dangerouslySetInnerHTML={{
                          __html: replaceWithBr(item?.content),
                        }}
                      />
                    </SendmessageLi>
                  </SendMessageDiv>
                  <SendMessageSpan>
                    {moment(item?.created_at?.toDate())
                      // .tz('America/Los_Angeles')
                      .format('MMM Do h:mm a z')}
                  </SendMessageSpan>
                </div>
              ) : (
                <div key={item?.created_at?.toDate().getTime()}>
                  <GuestMessageDiv>
                    <GuestMessageLi>
                      <p
                        style={{ margin: '0px' }}
                        dangerouslySetInnerHTML={{
                          __html: replaceWithBr(item?.content),
                        }}
                      />
                    </GuestMessageLi>
                  </GuestMessageDiv>
                  <IMessageSpan>
                    {moment(item?.created_at?.toDate())
                      // .tz('America/Los_Angeles')
                      .format('MMM Do h:mm a z')}
                  </IMessageSpan>
                </div>
              ),
            )}
          </ChatDiv>
        </ChatMainWrapper>
        <div ref={messageRef} />
        <div>
          <RowWrpper>
            <InputDiv>
              <Formik
                initialValues={{ message: '' }}
                onSubmit={async (values, { resetForm }) => {
                  await db
                    .collection('threads')
                    .doc(currentUser?.uuid)
                    .set(
                      {
                        guest: {
                          first_name: currentUser?.first_name,
                          last_name: currentUser?.last_name,
                          picture: currentUser?.picture,
                        },
                        last_message: {
                          content: values?.message,
                          created_at: serverTimestamp(),
                          user_id: currentUser?.uuid,
                          isRead: false,
                        },
                      },
                      { merge: true },
                    );
                  await db
                    .collection('threads')
                    .doc(currentUser?.uuid)
                    .collection('messages')
                    .add({
                      content: values?.message,
                      created_at: serverTimestamp(),
                      user_id: currentUser?.uuid,
                    });
                  resetForm({
                    values: {
                      message: '',
                    },
                  });
                }}
              >
                {({
                  values,
                  isSubmitting,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                }) => (
                  <Form onSubmit={handleSubmit} className="d-flex w-100">
                    <FormControl
                      type="input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="message"
                      value={values.message}
                      placeholder="Write a message or select a canned response"
                      style={{ border: 'none', width: '100%' }}
                      className="message-input"
                      autoComplete="off"
                    />
                    <SendButton
                      type="submit"
                      style={{ maxWidth: '70px' }}
                      disabled={isSubmitting}
                    >
                      Send
                    </SendButton>
                  </Form>
                )}
              </Formik>
            </InputDiv>
          </RowWrpper>
        </div>
      </Container>
    </>
  );
};
export default InboxContainer;
