import React, { useContext, useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { auth, db } from '../Config/firebase';
import Loader from '../Common/Loader';
import { SuspenseLoaderWrapper } from './styled';

interface AuthContextValue {
  currentUser?: firebase.User | null;
  basicUserDetail: any | null;
  login: (
    email: string,
    password: string,
  ) => Promise<firebase.auth.UserCredential>;
  signup: (
    email: string,
    password: string,
  ) => Promise<firebase.auth.UserCredential>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updateEmail: (email: string) => Promise<void>;
  updatePassword: (password: string) => Promise<void>;
  signinWithGoogle: (
    provider: firebase.auth.AuthProvider,
  ) => Promise<firebase.auth.UserCredential>;
  signInWithFacebook: (
    provider: firebase.auth.AuthProvider,
  ) => Promise<firebase.auth.UserCredential>;
  fetchSignInMethodsForEmail: (email: string) => Promise<Array<string>>;
  // reauthenticateWithCredential: (
  //   credential: firebase.auth.AuthCredential,
  // ) => Promise<firebase.auth.UserCredential>;
}

const AuthContext = React.createContext<AuthContextValue | null>(null);

export function useAuth(): AuthContextValue {
  return useContext(AuthContext);
}

const LayoutSplashScreen = () => (
  <SuspenseLoaderWrapper>
    <Loader />
  </SuspenseLoaderWrapper>
);

export const AuthProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);
  const [basicUserDetail, setBasicUserDetail] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  function signup(
    email: string,
    password: string,
  ): Promise<firebase.auth.UserCredential> {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(
    email: string,
    password: string,
  ): Promise<firebase.auth.UserCredential> {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout(): Promise<void> {
    setCurrentUser(null);
    setBasicUserDetail(null);
    return auth.signOut();
  }

  function resetPassword(email: string): Promise<void> {
    console.log('email', email);
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email: string): Promise<void> {
    if (currentUser !== null) return currentUser.updateEmail(email);
    return new Promise<void>(() => {});
  }

  // function reauthenticateWithCredential(
  //   credential: firebase.auth.AuthCredential,
  // ): Promise<firebase.auth.UserCredential> {
  //   if (currentUser !== null)
  //     return currentUser.reauthenticateWithCredential(credential);
  //   return new Promise<firebase.auth.UserCredential>(() => {});
  // }

  function updatePassword(password: string): Promise<void> {
    if (currentUser !== null) return currentUser.updatePassword(password);
    return new Promise<void>(() => {});
  }

  function fetchSignInMethodsForEmail(email: string): Promise<Array<string>> {
    return auth.fetchSignInMethodsForEmail(email);
  }

  function signinWithGoogle(
    provider: firebase.auth.AuthProvider,
  ): Promise<firebase.auth.UserCredential> {
    return auth.signInWithPopup(provider);
  }

  function signInWithFacebook(
    provider: firebase.auth.AuthProvider,
  ): Promise<firebase.auth.UserCredential> {
    return auth.signInWithPopup(provider);
  }

  useEffect(() => {
    (async () => {
      const unsubscribe: firebase.Unsubscribe = await auth.onAuthStateChanged(
        async user => {
          const usersClaims = await auth.currentUser?.getIdTokenResult();
          if (usersClaims?.claims?.isAdmin || !user) {
            setLoading(false);
            logout();
          }
          if (usersClaims?.claims?.isAdmin !== true && user) {
            db.collection('guests')
              .doc(user.uid)
              .get()
              .then(doc => {
                if (doc.exists) {
                  const data = doc.data();
                  setCurrentUser(user);
                  setBasicUserDetail(data);
                  setLoading(false);
                } else {
                  console.log('No such document!');
                }
              })
              .catch(error => {
                console.log('Error getting document:', error);
              });
          }
        },
      );
      return unsubscribe;
    })();
  }, []);

  const value: AuthContextValue = {
    currentUser,
    basicUserDetail,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    signinWithGoogle,
    signInWithFacebook,
    fetchSignInMethodsForEmail,
    // reauthenticateWithCredential,
  };
  return (
    <React.Suspense fallback={<LayoutSplashScreen />}>
      <AuthContext.Provider value={value}>
        {loading ? <LayoutSplashScreen /> : children}
      </AuthContext.Provider>
    </React.Suspense>
  );
};

export default AuthContext;
