import React from 'react';

interface CloseProps {
  fill: string;
}

const Close = ({ fill }: CloseProps): JSX.Element => (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.999 40.25C32.5259 40.25 40.249 32.5269 40.249 23C40.249 13.4731 32.5259 5.74998 22.999 5.74998C13.4721 5.74998 5.74902 13.4731 5.74902 23C5.74902 32.5269 13.4721 40.25 22.999 40.25Z"
      fill="#E9E9E9"
    />
    <path
      d="M18.9336 27.0659L27.0653 18.9341"
      stroke="#212F52"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.9336 18.9342L27.0653 27.0659"
      stroke="#212F52"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Close;
