import React from 'react';
import { Row } from 'react-bootstrap';
import GoogleMapReact from 'google-map-react';
import {
  Address,
  GuidebookAddressTitle,
  GuidebookTitle,
  Ruler,
  MapContainer,
} from './styled';

const CheckInInstruction: React.FC = () => (
  <Row>
    <GuidebookTitle>
      Check-in instructions
      <br />
      for your Austin stay.
    </GuidebookTitle>
    <GuidebookAddressTitle>
      <p className="pb-2">Your hotel is located at:</p>
      <p className="address">
        1211 E 5th St
        <br /> Austin, TX, United States
      </p>
    </GuidebookAddressTitle>
    <MapContainer>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_APIKEY }}
        defaultCenter={{
          lat: 40.689404,
          lng: -74.044812,
        }}
        defaultZoom={11}
      />
    </MapContainer>
    <Address>
      <b>Nearest Public Transportation:</b>&nbsp; Plaza Saltillo
    </Address>
    <Ruler>
      <hr />
    </Ruler>
  </Row>
);

export default CheckInInstruction;
