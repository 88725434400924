import styled from 'styled-components';

export const Heading = styled.h6`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #212f52;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 20px 0px;
`;

export const Details = styled.div`
  p {
    font-family: 'Arial';
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: #212f52;
    flex: none;
    order: 1;
    flex-grow: 1;
    margin: 0px 10px;
  }
`;

export const DotTag = styled.span`
  font-size: 22px;
`;
