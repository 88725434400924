import React, { useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Col,
  FormControl,
  FormLabel,
  FormSelect,
  Row,
  Spinner,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { Wrapper, ItemBlock, Item, ButtonLink, EditButton } from './styled';
import { db } from '../../../Config/firebase';

interface GuestInfoProps {
  currentUser: any;
  billingData: any;
}

const GuestInfo: React.FC<GuestInfoProps> = ({
  currentUser,
  billingData,
}: GuestInfoProps) => {
  const { id }: any = useParams();
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    emailId: Yup.string().required('Required Info').email('Email is not valid'),
    confirmEmail: Yup.string()
      .oneOf([Yup.ref('emailId'), null], 'email must match')
      .email('Confirm Email is not valid'),
    firstName: Yup.string().required('Required Info'),
    lastName: Yup.string().required('Required Info'),
    countryCode: Yup.string().required('Required Info'),
    phoneNo: Yup.number()
      .typeError('Only number is allowed')
      .required('Required Info')
      .min(10, 'Minimum 10 digit required'),
    birthdayMonth: Yup.string().required('Required Info'),
    birthdayDate: Yup.string().required('Required Info'),
    birthdayYear: Yup.string().required('Required Info'),
  });

  const handleEdit = async (formValue: {
    emailId: string;
    firstName: string;
    lastName: string;
    countryCode: any;
    phoneNo: number;
    birthdayMonth: number;
    birthdayDate: number;
    birthdayYear: number;
    confirmEmail: string;
  }) => {
    setLoading(true);
    try {
      const {
        countryCode,
        phoneNo,
        birthdayMonth,
        birthdayDate,
        birthdayYear,
        firstName,
        lastName,
        emailId,
      } = formValue;
      const docData = {
        first_name: firstName,
        last_name: lastName,
        email: emailId,
        phone: phoneNo,
        country_code: countryCode,
        birthday: {
          date: birthdayDate,
          month: birthdayMonth,
          year: birthdayYear,
        },
      };
      await setDoc(doc(db, 'checkout', id), docData, { merge: true });
      setLoading(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <Wrapper>
        {/* <Title className="pb-4">Guest Info</Title> */}
        <ItemBlock>
          <Col xl={8} lg={8} md={8} sm={12}>
            {!show ? (
              <>
                <Item>{billingData?.guest?.email}</Item>
                <Item>{billingData?.guest?.first_name}</Item>
                <Item>{billingData?.guest?.phone}</Item>
              </>
            ) : (
              <Formik
                initialValues={{
                  ...billingData,
                  emailId: billingData?.guest?.email,
                  confirmEmail: billingData?.guest?.email,
                  firstName: billingData?.guest?.first_name,
                  lastName: billingData?.guest?.last_name,
                  countryCode: '',
                  phoneNo: '',
                  birthdayMonth: '',
                  birthdayDate: '',
                  birthdayYear: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleEdit}
              >
                {({
                  touched,
                  errors,
                  values,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row className="mb-2">
                      <Col xl={12} lg={12} md={12} sm={12}>
                        <FormLabel className="guest-info-label">
                          Email address
                        </FormLabel>
                        <FormControl
                          type="text"
                          name="emailId"
                          value={values?.emailId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`guest-info-formcontrol ${
                            touched?.emailId && errors?.emailId
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="emailId"
                          className="invalid-feedback"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xl={6} lg={6} md={6} sm={12}>
                        <FormLabel className="guest-info-label">
                          First name
                        </FormLabel>
                        <FormControl
                          type="text"
                          name="firstName"
                          value={values?.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`guest-info-formcontrol ${
                            touched?.firstName && errors?.firstName
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="firstName"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={12}>
                        <FormLabel className="guest-info-label">
                          Last name
                        </FormLabel>
                        <FormControl
                          type="text"
                          name="lastName"
                          value={values?.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`guest-info-formcontrol ${
                            touched?.lastName && errors?.lastName
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="lastName"
                          className="invalid-feedback"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xl={4} lg={4} md={4} sm={12}>
                        <FormLabel className="guest-info-label">
                          Country code
                        </FormLabel>
                        <FormSelect
                          name="countryCode"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={billingData?.guest?.country_code}
                          // disabled={currentUser?.country_code}
                          className={`guest-info-formcontrol ${
                            touched?.countryCode && errors?.countryCode
                              ? 'is-invalid'
                              : ''
                          }`}
                        >
                          <option>select</option>
                          <option value="IND +91">IND +91</option>
                          <option value="USA +1">USA +1</option>
                        </FormSelect>
                        <ErrorMessage
                          component="div"
                          name="countryCode"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col xl={8} lg={8} md={8} sm={12}>
                        <FormLabel className="guest-info-label">
                          Phone number
                        </FormLabel>
                        <FormControl
                          type="text"
                          name="phoneNo"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={billingData?.guest?.phone}
                          // disabled={currentUser?.phone}
                          className={`guest-info-formcontrol ${
                            touched?.phoneNo && errors?.phoneNo
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="phoneNo"
                          className="invalid-feedback"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <FormLabel className="guest-info-label">
                        Birthdate
                      </FormLabel>
                      <Col xl={4} lg={4} md={4} sm={12}>
                        <FormSelect
                          name="birthdayMonth"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={billingData?.guest?.birthday?.month}
                          // disabled={currentUser?.birthday?.month}
                          className={`guest-info-formcontrol ${
                            touched?.birthdayMonth && errors?.birthdayMonth
                              ? 'is-invalid'
                              : ''
                          }`}
                        >
                          <option>Month</option>
                          <option value="1">January </option>
                          <option value="2">February</option>
                          <option value="3">March</option>
                          <option value="4">April</option>
                          <option value="5">May </option>
                          <option value="6">June</option>
                          <option value="7">July</option>
                          <option value="8">August</option>
                          <option value="9">September </option>
                          <option value="10">October</option>
                          <option value="11">November</option>
                          <option value="12">December</option>
                        </FormSelect>
                        <ErrorMessage
                          component="div"
                          name="birthdayMonth"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col xl={4} lg={4} md={4} sm={12}>
                        <FormSelect
                          name="birthdayDate"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={billingData?.guest?.birthday?.date}
                          // disabled={currentUser?.birthday?.date}
                          className={`guest-info-formcontrol ${
                            touched?.birthdayDate && errors?.birthdayDate
                              ? 'is-invalid'
                              : ''
                          }`}
                        >
                          <option>Day</option>
                          <option value="1">1 </option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5 </option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9 </option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14 </option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18 </option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22 </option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                          <option value="26">26</option>
                          <option value="27">27 </option>
                          <option value="28">28</option>
                          <option value="29">29</option>
                          <option value="30">30</option>
                          <option value="31">31</option>
                        </FormSelect>
                        <ErrorMessage
                          component="div"
                          name="birthdayDate"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col xl={4} lg={4} md={4} sm={12}>
                        <FormSelect
                          name="birthdayYear"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={billingData?.guest?.birthday?.year}
                          // disabled={currentUser?.birthday?.year}
                          className={`guest-info-formcontrol ${
                            touched?.birthdayYear && errors?.birthdayYear
                              ? 'is-invalid'
                              : ''
                          }`}
                        >
                          <option>Year</option>
                          <option value="2022">2022 </option>
                          <option value="2021">2021</option>
                          <option value="2020">2020</option>
                          <option value="2019">2019</option>
                          <option value="2018">2018 </option>
                          <option value="2017">2017</option>
                          <option value="2016">2016</option>
                          <option value="2015">2015</option>
                          <option value="2014">2014 </option>
                          <option value="2013">2013</option>
                          <option value="2012">2012</option>
                          <option value="2011">2011</option>
                          <option value="2010">2010</option>
                          <option value="2009">2009 </option>
                          <option value="2008">2008</option>
                          <option value="2007">2007</option>
                          <option value="2006">2006</option>
                          <option value="2005">2005 </option>
                          <option value="2004">2004</option>
                          <option value="2003">2003</option>
                          <option value="2002">2002</option>
                          <option value="2001">2001 </option>
                          <option value="2000">2000</option>
                          <option value="1999">1999</option>
                          <option value="1998">1998</option>
                          <option value="1997">1997</option>
                          <option value="1996">1996 </option>
                          <option value="1995">1995</option>
                          <option value="1994">1994</option>
                          <option value="1993">1993</option>
                          <option value="1992">1992</option>
                        </FormSelect>
                        <ErrorMessage
                          component="div"
                          name="birthdayYear"
                          className="invalid-feedback"
                        />
                      </Col>
                    </Row>
                    {!loading ? (
                      <EditButton disabled={billingData?.guest?.phone}>
                        Edit
                      </EditButton>
                    ) : (
                      <div className="text-center">
                        <Spinner animation="border" variant="warning" />
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            )}
          </Col>
          <Col xl={4} lg={4} md={4} sm={12}>
            {!show ? (
              <div className="text-end">
                {billingData?.payment?.status !== 'succeeded' && (
                  <ButtonLink onClick={() => setShow(true)}>
                    Edit Info
                  </ButtonLink>
                )}
              </div>
            ) : (
              <div className="text-end">
                <ButtonLink onClick={() => setShow(false)}>
                  Hide Info
                </ButtonLink>
              </div>
            )}
          </Col>
        </ItemBlock>
      </Wrapper>
    </>
  );
};
export default GuestInfo;
