import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail } from './styled';

const NoticeRegardingApple: React.FC = () => (
  <Row>
    <Detail>
      <p>
        <b>20. Notice Regarding Apple.</b> This Section 19 applies only to the
        extent you are using our mobile application on an Apple-branded product
        (our <b>“App”</b>). You acknowledge that this Agreement is between you
        and Gilbert Hotels only, not with Apple Inc. (<b>“Apple”</b>), and Apple
        is not responsible for the Service or the content thereof. The license
        granted to you in Section 6.1 of this Agreement with respect to our App
        is limited to your use of such App on any Apple-branded products that
        you own or control and as permitted by the Usage Rules set forth in the
        App Store Terms of Service, except that such App may be accessed and
        used by other accounts associated with you via Family Sharing or volume
        purchasing. Apple has no obligation to furnish any maintenance and
        support services with respect to the Service. If the Service fails to
        conform to any applicable warranty, you may notify Apple and Apple will
        refund any applicable purchase price for the mobile application to you;
        and, to the maximum extent permitted by applicable law, Apple has no
        other warranty obligation with respect to the Service. Apple is not
        responsible for addressing any claims by you or any third party relating
        to the Service or your possession and/or use of the Service, including:
        (a) product liability claims; (b) any claim that the Service fails to
        conform to any applicable legal or regulatory requirement; or (c) claims
        arising under consumer protection or similar legislation. Apple is not
        responsible for the investigation, defense, settlement, and/or discharge
        of any third-party claim that the Service and/or your possession and use
        of the Service infringe a third party’s intellectual property rights.
        You agree to comply with any applicable third-party terms when using the
        Service. Apple and Apple’s subsidiaries are third-party beneficiaries of
        this Agreement, and upon your acceptance of this Agreement, Apple will
        have the right (and will be deemed to have accepted the right) to
        enforce this Agreement against you as a third-party beneficiary of this
        Agreement. You hereby represent and warrant that: (i) you are not
        located in a country that is subject to a U.S. Government embargo, or
        that has been designated by the U.S. Government as a “ terrorist
        supporting” country; and (ii) you are not listed on any U.S. Government
        list of prohibited or restricted parties.
      </p>
    </Detail>
  </Row>
);

export default NoticeRegardingApple;
