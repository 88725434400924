import React from 'react';

import { Wrapper, HotelName, HotelAddress } from './styled';

interface BasicDetailProps {
  explore: any;
}

const BasicDetail: React.FC<BasicDetailProps> = ({
  explore,
}: BasicDetailProps) => (
  <>
    <Wrapper>
      <HotelName>{explore?.title}</HotelName>
      <HotelAddress className="pb-4">
        {explore?.address?.street}, {explore?.address?.city}
      </HotelAddress>
    </Wrapper>
  </>
);
export default BasicDetail;
