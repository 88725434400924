import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail, PTag } from './styled';

const ServiceOverview: React.FC = () => (
  <Row>
    <PTag>1.</PTag>
    <Detail>
      <div style={{ paddingLeft: '30px' }}>
        <p>
          <b>1.1 Service Overview.</b> Gilbert Hotels Hospitality USA Inc.
          acquires rights and/or interests in accommodation for the onward
          supply of accommodation to travelers staying in the United States and
          countries other than Canada and Mexico. Hospitalité Gilbert Hotels
          Canada Inc. acquires rights and/or interests in accommodation for the
          onward supply of accommodation to travelers staying in Canada. Gilbert
          Hotels Stay México, S. de R.L. de C.V. acquires rights and/or
          interests in apartment accommodation for the onward supply of
          accommodation to travelers staying in Mexico. Gilbert Hotels Hotels
          México S. de R.L. de C.V. acquires rights and/or interests in hotel
          accommodation for the onward supply of accommodation to travelers
          staying in Mexico. We provide information on physical properties
          managed, owned, licensed, leased, rented, and/or developed by us or
          any of our affiliates (each a <b>“Property”</b> and collectively the
          <b>“Properties”</b> ). You may use the Service to make legitimate
          reservations for certain Properties for short-term rentals (each a
          <b>“STA/Hotel Reservation”</b>), longer-term accommodations (each an
          <b>“Extended Stay Reservation”</b>) or membership subscriptions (each
          a<b>“Subscription Reservation”</b> and, together with a STA/Hotel
          Reservation and an Extended Stay Reservation, each a{' '}
          <b>“Reservation”</b>) and to obtain from us our express (i.e.,
          written) permission for you to use such Properties for non-commercial
          purposes only for a certain period of time, in all cases, only in
          accordance with the terms and conditions of this Agreement. The
          <b>“Service(s)”</b> excludes any and all Properties and any and all
          rights of use or possession therein or thereof, but includes services
          provided by us or a third party service provider to you on or around a
          Booked Property (defined in Section 6.2) in connection with your
          permitted use of the Properties (if applicable), whether you reserve
          such Properties directly from us or a third party (
          <b>“On-Site Services”</b>). For the avoidance of doubt, a
          <b>“Subscription Reservation”</b> is a reservation for a stay at one
          of our Properties that is made in connection with a purchased
          subscription, which is deemed entered into and purchased immediately
          upon your execution of an Order Form (defined in Section 3) for such
          subscription (such Order Form, a <b>“Membership Agreement”</b>), a
          <b>“STA/Hotel Reservation”</b> is a reservation for a short length
          stay at one of our Properties that is made outside and separate from
          any purchased subscription or Membership Agreement and an{' '}
          <b>“Extended Stay Reservation”</b> is a reservation for an
          intermediate or long term stay at one of our Properties that is made
          outside and separate from any purchased subscription or Membership
          Agreement, but that may be made in connection with an Order Form for
          such reservation (such Order Form, an <b>“Extended Stay Agreement”</b>
          ) which is deemed entered into and purchased immediately upon either
          your execution of such Order Form for such reservation or your
          reservation of the Extended Stay Property and your payment of any
          portion of the Extended Stay Reservation fee.
        </p>
        <p>
          <b>1.2 Supplier of Record.</b> Depending on the location of the
          Properties, the following Gilbert Hotels affiliates will be the
          supplier of record with respect to our Services:
        </p>
        <div style={{ paddingLeft: '30px' }}>
          <p>
            1.2.1 Gilbert Hotels Hospitality USA Inc., a Delaware corporation,
            located at 101 15th Street, San Francisco, CA 94103, Delaware file
            number 5736491.
          </p>
          <p>
            1.2.2 Hospitalité Gilbert Hotels Canada Inc., a Quebec corporation
            located at 201-15 rue Marie-Anne O., Montréal, Quebec, Canada H2W
            1B6, numéro d&apos;entreprise du Québec 1174937749.
          </p>
          <p>
            1.2.3 Gilbert Hotels Stay México, S. de R.L. de C.V., a Mexico
            corporation located at Avenida Insurgentes Sur 1915 Interior 602,
            Colonia Guadalupe Inn, Alcaldía Álvaro Obregón, Código Postal 01020,
            En la Ciudad de Mexico.
          </p>
          <p>
            1.2.4 Gilbert Hotels Hotels México, S. de R.L. de C.V., a Mexico
            corporation located at Avenida Insurgentes Sur 1915 Interior 602,
            Colonia Guadalupe Inn, Alcaldía Álvaro Obregón, Código Postal 01020,
            En la Ciudad de Mexico (each of the foregoing, individually,
            <b>“Supplier of Record”</b> or collectively,{' '}
            <b>“Suppliers of Record”</b>).
          </p>
        </div>
      </div>
    </Detail>
  </Row>
);

export default ServiceOverview;
