import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const SendMessageDiv = styled.div`
  padding: 20px;
  padding-bottom: 0px;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start;
  align-items: flex-start;
  flex: 1 1 100%;
  max-width: 75%;
`;

export const GuestMessageDiv = styled.div`
  padding: 20px;
  padding-bottom: 0px;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start flex-end;
  flex: 1 1 100%;
  margin-right: 16px;
`;

export const GuestMessageLi = styled.li`
  padding: 16px;
  border-radius: 8px;
  line-height: 24px;
  transition: max-height 0.3s;
  word-break: break-word;
  overflow-wrap: anywhere;
  border: 1px solid #e5e2e9;
  display: flex;
  padding: 10px;
  max-width: 75%;
`;

export const SendmessageLi = styled.li`
  background: #f8f7f9;
  padding: 16px;
  border-radius: 8px;
  line-height: 24px;
  transition: max-height 0.3s;
  word-break: break-word;
  overflow-wrap: anywhere;
  display: flex;
  padding: 10px;
  width: auto;
`;

export const RowWrpper = styled(Row)`
  opacity: 1;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  top: 90%;
  margin-top: 30px;
  margin-left: 3px;
`;

export const InputDiv = styled.div`
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  flex: 1 1 0%;
  place-content: stretch flex-start;
  align-items: stretch;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 20%);
  border-radius: 32px;
  padding: 14px 16px;
  margin-bottom: 20px;
  background: #fff;
  justify-content: space-between;
`;

export const SendButton = styled.button`
  color: #fff;
  background: #212f52;
  border: none;
  width: 100px;
  border-radius: 20px;
`;

export const ChatMainWrapper = styled.div`
  height: calc(100vh - 300px);
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  margin-top: 30px;
  padding: 0px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #caccd1;
    border-right: none;
    border-left: none;
    border-radius: 10px;
  }
`;

export const ChatDiv = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const SendMessageSpan = styled.span`
  place-content: flex-start;
  display: flex;
  font-size: 0.75em;
  color: #b3afbb;
  margin-left: 20px;
`;

export const IMessageSpan = styled.span`
  place-content: flex-end;
  display: flex;
  font-size: 0.75em;
  color: #b3afbb;
  margin-right: 35px;
`;
