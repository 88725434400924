import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ComponentsContainer = styled.div`
  padding-right: 40px;
  @media (max-width: 768px) {
    padding-right: 10px;
  }
`;

export const Rowwrapper = styled(Row)`
  padding-top: 48px;
  padding-bottom: 48px;
  @media (max-width: 425px) {
    padding-top: 0px;
    padding-bottom: 20px;
  }
`;

export const Bookbutton = styled.button`
  border: none;
  width: 100%;
  height: 65px;
  left: 0px;
  top: 276px;
  background: #dca73a;
  border-radius: 33px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: 50px;
  font-size: 20px;
  line-height: 23px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #212f52;
`;

export const GrayText = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6a737b;
  a {
    color: #6a737b;
  }
`;

export const Wrapper = styled.div`
  padding-bottom: 20px;
`;

export const Title = styled.h4`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #212f52;
  @media (max-width: 425px) {
    padding-left: 25px;
  }
`;

export const ItemBlock = styled(Row)`
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 30px;
`;

export const Payment = styled.div`
  background: #ffffff;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 15px;
`;

export const BillItem = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #212f52;
  border-bottom: 2px #caccd1 solid;
`;

export const BillItemText = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 50px;
  color: #212f52;
  margin: 0px;
`;

export const BillItemAmount = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  text-align: right;
  color: #212f52;
  margin: 0px;
`;

export const InnerPayment = styled.div`
  background: #f9f9f9;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 10px;
  h6 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #212f52;
  }
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #6a737b;
  }
`;

export const NextLink = styled(Link)`
  color: #212f52;
  text-decoration: none;
  display: flex;
  justify-content: end;
  &:hover {
    color: #212f52;
  }
`;

export const PaymentLoaderWrapper = styled.div`
  margin: 5% 50%;
`;
