import React, { useRef } from 'react';
import Slider from 'react-slick';
import {
  BGWrapper,
  ItemBox,
  ItemWrapper,
  FeatureItemTitle,
  FeatureItemItem,
  SliderButtons,
  SliderWrapper,
  RowWrapper,
} from './styled';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HeaderComponent from './HeaderComponent';
import FetureImage1 from '../../Assets/Images/FeaturedHotels/FetureImage1.jpg';
import FetureImage2 from '../../Assets/Images/FeaturedHotels/FetureImage2.jpg';
import FetureImage3 from '../../Assets/Images/FeaturedHotels/FetureImage3.jpg';
import FetureImage4 from '../../Assets/Images/FeaturedHotels/FetureImage4.jpg';
import SliderPrevIcon from '../../Assets/Images/Icons/SliderPrevIcon.svg';

const hotelsList = [
  {
    id: 1,
    image: FetureImage1,
    price: 'US $100',
    smallText: 'Avg Rate',
    title: 'Stunning 2BR at The Heid',
    ietms: ['2 Bedrooms', '2 Bathrooms'],
  },
  {
    id: 2,
    image: FetureImage2,
    price: 'US $100',
    smallText: 'Avg Rate',
    title: 'Stunning 2BR at The Heid',
    ietms: ['2 Bedrooms', '2 Bathrooms'],
  },
  {
    id: 3,
    image: FetureImage3,
    price: 'US $100',
    smallText: 'Avg Rate',
    title: 'Stunning 2BR at The Heid',
    ietms: ['2 Bedrooms', '2 Bathrooms'],
  },
  {
    id: 4,
    image: FetureImage4,
    price: 'US $100',
    smallText: 'Avg Rate',
    title: 'Stunning 2BR at The Heid',
    ietms: ['2 Bedrooms', '2 Bathrooms'],
  },
  {
    id: 5,
    image: FetureImage4,
    price: 'US $100',
    smallText: 'Avg Rate',
    title: 'Stunning 2BR at The Heid',
    ietms: ['2 Bedrooms', '2 Bathrooms'],
  },
];

const settings = {
  centerPadding: '500px',
  slidesToShow: 4,
  slidesToScroll: 1,
  dots: true,
  infinite: true,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const FeaturedStays: React.FC = () => {
  const sliderRef = useRef<any>();
  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  };

  const gotoNext = () => {
    sliderRef.current.slickNext();
  };

  return (
    <BGWrapper>
      <HeaderComponent
        title="Featured stays"
        text="From spacious apartments to sunny studios,
        each Gilbert Hotels is designed to comfort and delight."
      />
      <SliderButtons>
        <button className="prev-btn" type="button" onClick={gotoPrev}>
          <img src={SliderPrevIcon} alt="prev-btn" />
        </button>
        <button className="next-btn" type="button" onClick={gotoNext}>
          <img src={SliderPrevIcon} alt="next-btn" />
        </button>
      </SliderButtons>
      <RowWrapper style={{ overflowX: 'auto' }}>
        <SliderWrapper className="p-0">
          <Slider ref={sliderRef} {...settings}>
            {hotelsList.map(item => (
              <ItemWrapper lg={3} md={4} sm={12} key={item.id}>
                <ItemBox>
                  <div className="image-contrainer">
                    <div className="price-tag">
                      <h5>{item.price}</h5>
                    </div>
                    <div className="image" />
                    {/* <img src={item.image} alt={`${item.id}`} /> */}
                  </div>
                  <FeatureItemTitle>Trendy Studio at The Heid</FeatureItemTitle>
                  <div className="d-flex">
                    <FeatureItemItem>
                      <p>Callowhill, Philadelphia</p>
                    </FeatureItemItem>
                  </div>
                </ItemBox>
              </ItemWrapper>
            ))}
          </Slider>
        </SliderWrapper>
      </RowWrapper>
    </BGWrapper>
  );
};
export default FeaturedStays;
