import React from 'react';
import { Row } from 'react-bootstrap';
import UserContentRepresentationsandWarranties from '../UserContentRepresentationsandWarranties';
import { Detail } from './styled';

const UserContent: React.FC = () => (
  <Row>
    <Detail>
      <p>
        <b>11. User Content.</b>
      </p>
      <div style={{ paddingLeft: '30px' }}>
        <p>
          <b>11.1 User Content Generally.</b> Certain features of the Service
          may permit users to submit content to the Service, including messages,
          reviews, ratings, and other types of works (“User Content”) and to
          publish User Content on the Service. You retain any copyright and
          other proprietary rights that you may hold in the User Content that
          you post to the Service.
        </p>
        <p>
          <b>11.2 Ratings and Reviews.</b> Within a certain timeframe after
          completing a reservation, you and other users may provide us with a
          public review (<b>“Review”</b>) and/or rating (<b>“Rating”</b>)
          regarding your or such other user’s experience with the Service or at
          a Booked Property. Reviews and Ratings reflect the opinions of the
          individual or entity that leaves such Review or Rating, do not reflect
          our opinion, and may be incorrect or misleading. We may, but are not
          obliged to, verify any Reviews or Ratings for accuracy. You
          acknowledge and agree that if you provide a Review or Rating, such
          Review or Rating must be accurate, may not contain any offensive or
          defamatory language, may be surfaced anywhere (including publicly) on
          our Service or a third-party service as permitted by us, and
          constitute “User Content.”
        </p>
        <p>
          <b>11.3 Limited License Grant to Us.</b> By providing User Content to
          or via the Service, you grant us an unrestricted, worldwide,
          perpetual, irrevocable, non-exclusive, freely transferable and
          sublicensable (including through multiple tiers), royalty-free, and
          fully-paid right and license to exploit the User Content, in whole or
          in part, in any manner (including in any language or any media formats
          and through any media channels now known or hereafter developed) and
          for any purpose with no obligation or accounting to you.
        </p>
      </div>
      <UserContentRepresentationsandWarranties />
      <div style={{ paddingLeft: '30px' }}>
        <p>
          <b>11.5 User Content Disclaimer.</b> We are under no obligation to
          edit or control User Content that you or other users post or publish
          and will not be in any way responsible or liable for User Content. We
          may, however, at any time and without prior notice, screen, remove,
          edit, or block any User Content that in our sole judgment violates
          this Agreement or is otherwise objectionable. You understand that when
          using the Service you may be exposed to User Content from a variety of
          sources and acknowledge that User Content may be inaccurate,
          offensive, indecent, or objectionable. You agree to waive, and do
          waive, any legal or equitable right or remedy you have or may have
          against us with respect to User Content. If notified by a user or
          content owner that User Content allegedly does not conform to this
          Agreement, we may investigate the allegation and determine in our sole
          discretion whether to remove the User Content, which we reserve the
          right to do at any time and without notice. For clarity, we do not
          permit copyright-infringing activities on the Service.
        </p>
        <p>
          <b>11.6 Monitoring Content.</b> We do not control and do not have any
          obligation to monitor: (a) User Content; (b) any content made
          available by third parties; or (c) the use of the Service by its
          users. You acknowledge and agree that we reserve the right to, and may
          from time to time, monitor any and all information transmitted or
          received through the Service for operational and other purposes. If at
          any time we choose to monitor the content, we still assume no
          responsibility or liability for content or any loss or damage incurred
          as a result of the use of content. During monitoring, information may
          be examined, recorded, copied, and used in accordance with our Privacy
          Policy (if applicable).
        </p>
      </div>
    </Detail>
  </Row>
);

export default UserContent;
