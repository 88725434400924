import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail } from './styled';

const ProhibitedConduct: React.FC = () => (
  <Row>
    <Detail>
      <div style={{ paddingLeft: '30px' }}>
        <p>
          <b>8.5 Prohibited Conduct.</b> In addition to all other rules and
          restrictions contained in the Agreement (including in any Additional
          Terms such as any applicable Order Form, Confirmation, Membership
          Agreement, Extended Stay Agreement and/or House Rules), in connection
          with your use of the Service and Properties, you may not and you agree
          that you will not (except and solely to the extent such restriction is
          impermissible under applicable law):
          <div style={{ paddingLeft: '30px', marginTop: '20px' }}>
            <p>
              8.5.1 Use the Service or any Property for any illegal purpose or
              in violation of any local, state, national, or international law
              (e.g., you may not use your, our, or a third-party Internet
              connection or device on any of our Properties to illegally
              download or share files through BitTorrent, other peer-to-peer
              protocols, or otherwise);
            </p>
            <p>
              8.5.2 Impersonate any individual or organization, or falsify or
              otherwise misrepresent yourself (including your age or date of
              birth) or your affiliation with any individual or organization;
            </p>
            <p>
              8.5.3 Register for more than one Gilbert Hotels account or
              Register on behalf of an individual other than yourself, except as
              expressly permitted under a Limited Use Agreement between you and
              us;
            </p>
            <p>
              8.5.4 Use the Service or any Property for any commercial or other
              purpose that is not expressly permitted under this Agreement (for
              the avoidance of doubt, you may not resell or transfer to any
              other individual or organization any reservation or subscription
              you make or enter into with us, except as expressly permitted
              under a Limited Use Agreement between you and us), or make false,
              fraudulent, or speculative reservations or subscriptions;
            </p>
            <p>
              8.5.5 Reproduce, distribute, publicly display, or publicly perform
              any aspect of the Service or make modifications to any aspect of
              the Service or any Property;
            </p>
            <p>
              8.5.6 Bring into or onto any Property any illegal, dangerous or
              hazardous substance or item (including items that are likely to
              increase the risk of fire or explosion) or any firearms and other
              weapons (except those rightfully possessed by law enforcement
              officials or licensed security guards);
            </p>
            <p>
              8.5.7 Attempt to decipher, decompile, disassemble, or reverse
              engineer any of the software used to provide the Service;
            </p>
            <p>
              8.5.8 Use, display, mirror, or frame any aspect of the Service
              (including any layout or design of any webpage or form contained
              therein), our name, or any of our trademarks, logos, or other
              proprietary information, without our express written consent;
            </p>
            <p>
              8.5.9 Copy, store, or otherwise access any information accessible
              through the Service or any Property for purposes not expressly
              permitted under this Agreement;
            </p>
            <p>
              8.5.10 Use manual or automated software, devices, scripts, robots,
              or other means or processes to access, “scrape,” “crawl,” or
              “spider” any web pages or other services contained in the Service
              or to otherwise collect information or interact with the Service;
            </p>
            <p>
              8.5.11 Systematically retrieve data or other content from the
              Service to create or compile, directly or indirectly, in one or
              more downloads, a collection, compilation, database, directory, or
              the like, whether by manual or automated methods;
            </p>
            <p>
              8.5.12 Interfere or tamper with, circumvent, damage, or test the
              vulnerability of any aspect of the Service or Properties
              (including any physical item therein), whether by manual or
              automated methods, including through the use of viruses, cancel
              bots, Trojan horses, harmful code, flood pings, denial-of-service
              attacks, packet or IP spoofing, forged routing of email address
              information, or similar methods or technology;
            </p>
            <p>
              8.5.13 Infringe, misappropriate, or violate the rights of any
              individual or organization, including their intellectual property
              rights (including rights to trade secrets and moral rights), or
              their privacy, publicity, confidentiality, contractual, property,
              or other rights;
            </p>
            <p>
              8.5.14 Use the Service or any Property to transmit, distribute,
              post, or submit any information concerning any other individual or
              organization without such individual’s or organization’s
              permission, including photographs, personal contact information,
              or credit, debit, calling card, or other account numbers;
            </p>
            <p>
              8.5.15 Engage in any conduct that: (i) would give rise to civil
              liability; (ii) is fraudulent, false, misleading, or deceptive;
              (iii) is defamatory, obscene, pornographic, vulgar, or offensive;
              (iv) promotes or constitutes discrimination, bigotry, racism,
              hatred, harassment, or harm against any individual or group or
              otherwise threatens or embarrasses any individual or group; (v) is
              violent or threatening or promotes violence or actions that are
              threatening to any other individual or organization; (vi) promotes
              illegal or harmful activities or substances; or (vii) is
              restricted or prohibited by or under any of our Additional Terms
              (defined in Section 18.5);
            </p>
            <p>
              8.5.16 Perform any activity or create any condition in or on any
              Booked Property, Amenities, or Property that could create a
              nuisance, annoyance, or disturbance to any other residents or
              neighbors of surrounding properties.
            </p>
            <p>
              8.5.17 Attempt to do any of the foregoing or assist or advocate,
              encourage, permit, or assist any third party in doing any of the
              foregoing.
            </p>
          </div>
        </p>
      </div>
    </Detail>
  </Row>
);

export default ProhibitedConduct;
