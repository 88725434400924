import styled from 'styled-components';

export const ExpectDetail = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 20px 0px;
`;

export const ExpectTitle = styled.h6`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 20px 0px;
`;

export const Wrapper = styled.div`
  background: #caccd1;
  border-radius: 15px;
  height: 250px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 20px 0px;
`;

export const Heading = styled.h6`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #212f52;
`;

export const ExpectImage = styled.img``;
