import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BeforeCheckInBox = styled.div`
  background: #ffffff;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 15px;
`;

export const Question = styled.h6`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #212f52;
`;

export const Details = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #212f52;
`;

export const Heading = styled.h6`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #212f52;
`;

export const ItemBlock = styled.div`
  display: flex;
  .form-check-input[type='checkbox'] {
    padding: 10px;
    border-radius: 12px;
    :hover {
      cursor: pointer;
    }
  }
  .form-check-input:checked {
    background-color: #212f52;
    border-color: #212f52;
    position: relative;
  }
  h6 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #212f52;
    padding-left: 25px;
  }
  .verify-link {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
    text-decoration-line: underline;
    color: #f14336;
  }
`;

export const VerifyInfo = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212f52;
  padding-right: 166px;
  @media (max-width: 768px) {
    padding-right: 0px;
  }

  padding-left: 46px;
  display: flex;
`;

export const RulerDiv = styled.div`
  padding-left: 10px;
`;

export const Ruler = styled.div`
  border-left: 1px #e9e9e9 solid;
  height: 100%;
  position: absolute;
`;

export const DownloadApp = styled.div`
  padding-top: 198px;
`;

export const Sent = styled(Link)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-decoration-line: underline;
  display: flex;
  color: #9f9fa3;
`;

export const DownloadAppInfo = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212f52;
  padding-right: 166px;
  @media (max-width: 768px) {
    padding-right: 0px;
  }

  padding-left: 37px;
`;

export const AppLink = styled(Link)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #212f52;
`;

export const WaitInDetail = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212f52;
  padding-right: 166px;
  @media (max-width: 768px) {
    padding-right: 0px;
  }

  padding-left: 37px;
`;

export const ItemBlock1 = styled.div`
  display: flex;
  h6 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #212f52;
    padding-left: 13px;
  }
`;
