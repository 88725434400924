import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BlogNavbarDiv, NavbarWrapper, NavDiv, SearchBar } from './styled';
import SearchIcon from '../../Assets/Images/Icons/SearchIcon.svg';

const BlogNavbar: React.FC = () => (
  <BlogNavbarDiv>
    <NavbarWrapper className="justify-content-center">
      <NavDiv>
        <Nav.Link as={Link} to="/" className="px-3">
          Travel
        </Nav.Link>
        <Nav.Link as={Link} to="/" className="px-3">
          Culture
        </Nav.Link>
        <Nav.Link as={Link} to="/" className="px-3">
          News
        </Nav.Link>
        <Nav.Link as={Link} to="/" className="px-3">
          Impact
        </Nav.Link>
        <Nav.Link as={Link} to="/" className="px-3">
          Design
        </Nav.Link>
      </NavDiv>
      <SearchBar className="px-3">
        <input type="text" placeholder="Search" className="p-2" />
        <img src={SearchIcon} alt="search" />
      </SearchBar>
    </NavbarWrapper>
  </BlogNavbarDiv>
);

export default BlogNavbar;
