import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  BGWrapper,
  CorporateLink,
  Details,
  Heading,
  ImageDiv,
  ListItem,
  Rowcontainer,
} from './styled';

interface ServiceBlockProps {
  isImageLeft: boolean;
  title: string | any;
  texts: Array<string> | null | undefined;
  button?: string | null | undefined;
  lists?: Array<string> | null | undefined;
  buttonLink?: string | null | undefined;
}

const ServiceBlock: React.FC<ServiceBlockProps> = ({
  isImageLeft,
  title,
  texts,
  button,
  buttonLink,
  lists,
}) => (
  <BGWrapper>
    <Container>
      <Rowcontainer>
        {!isImageLeft && (
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={12}
            sm={12}
            className="align-self-center"
          >
            <Container className="">
              <Heading>{title}</Heading>
              {texts.map(item => (
                <Details key={item}>{item}</Details>
              ))}
              <ul>
                {lists &&
                  lists.map(item => <ListItem key={item}>{item}</ListItem>)}
              </ul>
              {button && (
                <div className="mb-5">
                  <CorporateLink to={buttonLink}>{button}</CorporateLink>
                </div>
              )}
            </Container>
          </Col>
        )}
        <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
          <ImageDiv />
        </Col>
        {isImageLeft && (
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={12}
            sm={12}
            className="align-self-center"
          >
            <Container className="">
              <Heading>{title}</Heading>
              {texts.map(item => (
                <Details key={item}>{item}</Details>
              ))}
              <ul>
                {lists &&
                  lists.map(item => <ListItem key={item}>{item}</ListItem>)}
              </ul>
              {button && (
                <CorporateLink to={buttonLink}>{button}</CorporateLink>
              )}
            </Container>
          </Col>
        )}
      </Rowcontainer>
    </Container>
  </BGWrapper>
);

ServiceBlock.defaultProps = {
  button: null,
  lists: null,
  buttonLink: '/',
};

export default ServiceBlock;
