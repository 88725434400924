import styled from 'styled-components';
import Modalcontainer from '../../../Common/Modal';

export const Title = styled.p`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #212f52;
`;

export const Description = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212f52;
`;

export const Modal = styled(Modalcontainer)`
  max-width: 530px;
  & .modal-content {
    border-radius: 15px;
  }
`;

export const Footer = styled.div`
  margin: 50px 0 50px;
`;

export const Button = styled.button`
  &.button-submit {
    width: 200px;
    height: 64px;
    background: #212f52;
    border-radius: 5px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
`;
