import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Loader from '../../Common/Loader';
import { db } from '../../Config/firebase';
import Checkout1Details from '../Checkout1Details';
import CheckoutCost1Details from '../CheckoutCost1Details';
import { BGWrapper } from './styled';

const Checkout1Container: React.FC = () => {
  const { id }: any = useParams();
  const [reservation, setReservation] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const getReservations = async () => {
      const q = query(
        collection(db, '/reservations'),
        where('checkout_id', '==', id),
      );
      const querySnapshot = await getDocs(q);
      setReservation({
        ...querySnapshot?.docs[0]?.data(),
        id: querySnapshot?.docs[0]?.id,
      });
      setLoading(false);
    };
    getReservations();
  }, []);

  return (
    <>
      {!loading ? (
        <BGWrapper>
          <Container>
            <Row>
              <Col lg={8} md={12} sm={12}>
                <Checkout1Details reservation={reservation} />
              </Col>
              <Col lg={4} md={12} sm={12}>
                <CheckoutCost1Details reservation={reservation} />
              </Col>
            </Row>
          </Container>
        </BGWrapper>
      ) : (
        <div style={{ margin: '20% 35%' }}>
          <Loader />
        </div>
      )}
    </>
  );
};

export default Checkout1Container;
