import React from 'react';
import { Col } from 'react-bootstrap';
import { ContantHeader, Title, SubTitle } from './style';

interface HeaderComponentProps {
  title: string;
  text: string;
}

const HeaderComponent: React.FC<HeaderComponentProps> = ({ title, text }) => (
  <ContantHeader>
    <Col lg={4} md={8} sm={12}>
      <Title>{title}</Title>
      <SubTitle>{text}</SubTitle>
    </Col>
  </ContantHeader>
);

export default HeaderComponent;
