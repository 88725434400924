import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const PrivacyPolicyTitle = styled.h1`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 60px;
  color: #212f52;
  @media (max-width: 425px) {
    font-size: 36px;
  }
`;

export const LastUpdated = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6a737b;
`;

export const CuriousAbout = styled.div`
  h6 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #212f52;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 20px 0px;
  }
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212f52;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 20px 0px;
  }
`;

export const LinkTag = styled(Link)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212f52;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 20px 0px;
  :hover {
    color: #212f52;
  }
`;
