import React from 'react';

const AnimationLoader = (): JSX.Element => (
  <div className="wrapper-payment">
    <div className="circle" />
    <div className="circle" />
    <div className="circle" />
    <div className="shadow" />
    <div className="shadow" />
    <div className="shadow" />
    <span>Loading</span>
  </div>
);

export default AnimationLoader;
