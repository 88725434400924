import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail } from './styled';

const Ownership: React.FC = () => (
  <Row>
    <Detail>
      <p>
        <b>9. Ownership; Proprietary Rights.</b> The Service is owned and
        operated by us. The visual interfaces, graphics, design, compilation,
        information, data, computer code (including source code or object code),
        products, software, services, and all other elements of the Service (
        <b>“Materials”</b>) provided by us are protected by intellectual
        property and other laws. All Materials included in the Service are our
        and/or our third-party licensors’ property. Except as expressly
        authorized by us, you may not make use of the Materials. We reserve all
        rights to the Materials not granted expressly in this Agreement.
      </p>
    </Detail>
  </Row>
);

export default Ownership;
