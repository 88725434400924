import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-bottom: 50px;
  padding: 0px 11px 50px 11px;
  @media (max-width: 320px) {
    padding: 0px 5px;
    padding-bottom: 50px;
  }
`;

export const Title = styled.h4`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #212f52;
`;

export const ItemBlock = styled(Row)`
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 30px;
  @media (max-width: 375px) {
    padding: 30px 10px;
  }
  @media (max-width: 320px) {
    padding: 30px 0px;
  }
`;

export const Item = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #212f52;
  margin: 0px;
  padding: 10px 0px;
`;

export const ButtonLink = styled.button`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #212f52;
  background: none;
  border: none;
`;

export const EditButton = styled.button`
  border: none;
  width: 100%;
  height: 65px;
  left: 0px;
  top: 276px;
  background: #dca73a;
  border-radius: 33px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: 50px;
  font-size: 20px;
  line-height: 23px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #212f52;
`;
