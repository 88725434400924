import styled from 'styled-components';
import { NavDropdown, Row } from 'react-bootstrap';
import Container from '../../styled';

const FooterMain = styled.div`
  background: #212f52;
  min-height: 690px;
`;

const FooterContainer = styled(Container)`
  color: #fff;
  padding-top: 100.3px;
`;

const Logo = styled.img`
  @media (max-width: 768px) {
    display: flex;
    margin: auto;
  }
`;

const Label = styled.p`
  margin-bottom: 30px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #caccd1;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const ListingSection = styled.div``;

const List = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  color: #ffffff;
  margin: 0;
`;

const LanguageDropDown = styled(NavDropdown)`
  padding: 7px 20px;
  background: #212f52;
  color: #fff !important;
  border-radius: 50px;
  width: 170px;
  height: 54px;
  border: 1px;
  border: 1px solid #fff;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position-x: 88%;
  background-position-y: 12px;
  &:focus {
    outline: none;
  }
  .nav-link {
    background: #212f52 !important;
    color: #fff !important;
  }
  .dropdown-toggle::after {
    margin-left: 2em;
  }
  .dropdown-item {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #212f52;
    padding: 10px 20px;
  }
`;

const CompanyInfo = styled(Row)`
  margin-top: 70px;
`;

const ContactInfo = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: block;
  }
`;

const Copyrights = styled.div``;

const CompanyInfoLabel = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #caccd1;
`;

const SearchDiv = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-end;
  input {
    background: #f9f9f9;
    border-radius: 27px;
    width: 100%;
    border: none;
    padding: 16px 20px;
    &:hover {
      outline: none;
    }
    &:focus {
      outline: none;
    }
  }
  img {
    position: absolute;
    flex: none;
    order: 1;
    flex-grow: 0;
    padding-right: 10px;
  }
`;

const SearchDetails = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #caccd1;
`;

export {
  FooterMain as default,
  FooterContainer,
  Logo,
  Label,
  ListingSection,
  List,
  LanguageDropDown,
  CompanyInfo,
  ContactInfo,
  Copyrights,
  CompanyInfoLabel,
  SearchDiv,
  SearchDetails,
};
