import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { db } from '../../Config/firebase';
import ReservationDetailLeft from '../ReservationDetailLeft';
import ReservationDetailRight from '../ReservationDetailRight';
import { BGWrapper } from './styled';

const ReservationDetailContainer: React.FC = () => {
  const { id }: any = useParams();

  const [reservations, setReservations] = useState<any>();
  const [loading, setloading] = useState<boolean>(false);

  const getReservationData = async () => {
    setloading(true);
    const q = doc(db, `/reservations/${id}`);

    const promises: any = [];
    await getDoc(q).then(value => {
      promises.push({
        ...value?.data(),
        id: value.id,
      });
    });
    Promise.all(promises).then(results => {
      setReservations(results);
      setloading(false);
    });
  };

  useEffect(() => {
    getReservationData();
  }, []);

  return (
    <BGWrapper>
      <Container>
        <Row>
          <Col lg={6} md={12} sm={12}>
            <ReservationDetailLeft
              reservations={reservations}
              loading={loading}
            />
          </Col>
          <Col lg={6} md={12} sm={12}>
            <ReservationDetailRight
              reservations={reservations}
              getReservationData={getReservationData}
            />
          </Col>
        </Row>
      </Container>
    </BGWrapper>
  );
};

export default ReservationDetailContainer;
