import React from 'react';
import { Modal as ModalContainer } from 'react-bootstrap';
import { Header, Body } from './styled';

const Modal = ({
  children,
  show,
  handleClose,
  className,
}: any): JSX.Element => (
  <ModalContainer
    show={show}
    onHide={handleClose}
    centered
    dialogClassName={className}
  >
    <Header closeButton />
    <Body>{children}</Body>
  </ModalContainer>
);

export default Modal;
