import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail } from './styled';

const Disclaimers: React.FC = () => (
  <Row>
    <Detail>
      <p>
        <b>16. Disclaimers; No Warranties.</b>
      </p>
      <div style={{ paddingLeft: '30px', marginTop: '20px' }}>
        <p>
          <b>16.1</b> THE SERVICE, PROPERTIES, AND ALL MATERIALS AND CONTENT
          AVAILABLE THROUGH THE SERVICE AND PROPERTIES ARE PROVIDED “AS IS” AND
          ON AN “AS AVAILABLE” BASIS. WE DISCLAIM ALL WARRANTIES OF ANY KIND,
          WHETHER EXPRESS OR IMPLIED, RELATING TO THE SERVICE, PROPERTIES, AND
          ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE AND
          PROPERTIES, INCLUDING: (A) ANY IMPLIED WARRANTY OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT; AND (B)
          ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR TRADE.
        </p>
        <p>
          <b>16.2</b> WE DO NOT WARRANT THAT THE SERVICE, PROPERTIES, MATERIALS
          OR CONTENT AVAILABLE THROUGH THE SERVICE AND PROPERTIES, OR ANY
          PORTION OF ANY OF THE FOREGOING WILL BE UNINTERRUPTED, SECURE, OR FREE
          OF ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS, AND WE DO NOT WARRANT
          THAT ANY OF THOSE ISSUES WILL BE CORRECTED.
        </p>
        <p>
          <b>16.3</b> WE DO NOT MAKE ANY WARRANTY REGARDING THE QUALITY OF ANY
          ASPECT OF THE SERVICE, PROPERTIES, OR MATERIALS OR CONTENT AVAILABLE
          THROUGH THE SERVICE OR PROPERTIES, OR THE ACCURACY, TIMELINESS,
          TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF THE FOREGOING.
        </p>
        <p>
          <b>16.4</b> YOU UNDERSTAND AND AGREE THAT WE SHALL NOT BE RESPONSIBLE
          OR LIABLE FOR THE ACTIONS OF ANY THIRD PARTY (INCLUDING PETS) OR ANY
          CONDITION OR CIRCUMSTANCE (E.G., CONSTRUCTION) OUTSIDE A PROPERTY. FOR
          THE AVOIDANCE OF DOUBT, YOU UNDERSTAND AND AGREE THAT WE ARE NOT
          RESPONSIBLE FOR ANY AND ALL CLAIMS RESULTING FROM THE MISCONDUCT OF
          ANY LANDLORD, PROPERTY OWNER, PROPERTY MANAGER, OTHER GUEST OR
          RESIDENT AND THAT SUCH CLAIMS ARE TO BE BROUGHT AGAINST SUCH LANDLORD,
          PROPERTY OWNER, PROPERTY MANAGER, OTHER GUEST OR RESIDENT DIRECTLY.
        </p>
        <p>
          <b>16.5</b> OTHER THAN AS SET OUT IN SECTION 16 BELOW, NO ADVICE OR
          INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE
          SERVICE, PROPERTIES, ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE
          SERVICE OR PROPERTIES, OR ANY GILBERT HOTELS ENTITIES WILL CREATE ANY
          WARRANTY REGARDING ANY OF THE FOREGOING THAT IS NOT EXPRESSLY STATED
          IN THIS AGREEMENT. OTHER THAN AS SET OUT IN SECTION 16 BELOW, WE ARE
          NOT RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT FROM THE SERVICE,
          PROPERTIES, OR ANY OTHER USER, OR ANY GUESTS, INVITEES, OR PETS, OF
          THE SAME. YOU UNDERSTAND AND AGREE THAT YOU USE ANY ASPECT OF THE
          SERVICE AND PROPERTIES AT YOUR OWN DISCRETION AND RISK, AND THAT WE
          ARE NOT RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR
          COMPUTER SYSTEM, MOBILE DEVICE, OR ANY PHYSICAL BELONGINGS USED IN
          CONNECTION WITH YOUR USE OF THE SERVICE OR PROPERTIES) OR ANY LOSS OF
          DATA, INCLUDING USER CONTENT, OR ANY LOSS, DAMAGE, OR THEFT OF ANY OF
          YOUR PROPERTY OR THE PROPERTY OF YOUR GUESTS OR OTHERS KEPT OR STORED
          IN OR ABOUT THE PROPERTIES OR ANY PERSONAL INJURY OR DEATH OR PET
          INJURY OR DEATH.
        </p>
        <div style={{ paddingLeft: '30px' }}>
          <p>
            <b>16.5.1</b> WE DO NOT CONTROL ANY FEES THAT MAY BE CHARGED TO YOU
            BY ANY THIRD PARTY RELATING TO ON-SITE SERVICES OR OUR COLLECTION OF
            ANY FEES THAT YOU OWE US UNDER THIS AGREEMENT, AND WE DISCLAIM ALL
            LIABILITY IN CONNECTION WITH THE FOREGOING.
          </p>
        </div>
        <p>
          <b>16.6</b> WE MAY USE THE SERVICES OF A REAL ESTATE BROKER, AGENT,
          REFERRAL SERVICE OR SIMILAR PARTY (THE “BROKER”). YOU UNDERSTAND,
          ACKNOWLEDGE AND AGREE THAT THE BROKER SOLELY REPRESENTS GILBERT HOTELS
          AND HAS NO OBLIGATION OR DUTY TO YOU, EXCEPT AS REQUIRED BY LAW AND
          UNLESS OTHERWISE AGREED IN WRITING BY YOU AND GILBERT HOTELS. IN
          EITHER CIRCUMSTANCE, YOU HAVE THE SOLE OBLIGATION TO PROTECT YOUR
          INTERESTS. YOU UNDERSTAND, ACKNOWLEDGE AND AGREE THAT THE BROKER SHALL
          HAVE NO EXPRESS OR IMPLIED RIGHT OR AUTHORITY TO CREATE ANY OBLIGATION
          ON BEHALF OF GILBERT HOTELS OR BIND GILBERT HOTELS TO ANY CONTRACT,
          AGREEMENT OR UNDERTAKING. NO ADVICE OR INFORMATION, WHETHER ORAL OR
          WRITTEN, OBTAINED BY YOU FROM THE BROKER WILL CREATE ANY OBLIGATION,
          REPRESENTATION OR WARRANTY BY GILBERT HOTELS.
        </p>
        <p>
          <b>16.7</b> THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS
          SECTION APPLY TO THE FULLEST EXTENT PERMITTED BY LAW. WE DO NOT
          DISCLAIM ANY WARRANTY OR OTHER RIGHT THAT WE ARE PROHIBITED FROM
          DISCLAIMING UNDER APPLICABLE LAW.
        </p>
        <p>
          <b>16.8</b> “WE”, AS USED IN THIS SECTION 15, REFERS TO US AS WELL AS
          THE APPLICABLE LANDLORD(S), PROPERTY OWNER(S), AND PROPERTY
          MANAGER(S).
        </p>
      </div>
    </Detail>
  </Row>
);

export default Disclaimers;
