import React from 'react';
import { Title, Description, Modal, Footer, Button } from './styled';

const CheckInModal = ({ show, handleClose }: any): JSX.Element => (
  <Modal show={show} handleClose={handleClose} className="Width">
    <Title>Wondering if you can check in earlier?</Title>
    <Description>
      {
        " Early check-in is a popular request, but our cleaning schedule doesn't always allow for it. If your Hotel is ready early, we'll notify you."
      }
    </Description>
    <Description>
      Take a look 1-2 days before check-in to see if anything opens up.
    </Description>
    <Footer>
      <Button className="button-submit">Ok</Button>
    </Footer>
  </Modal>
);

export default CheckInModal;
