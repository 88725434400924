import React from 'react';
import Header from '../../Components/Header';
import MyStaysContainer from '../../Components/MyStaysContainer';
import Footer from '../../Components/Footer';

const CheckoutDetail: React.FC = () => (
  <div>
    <Header hasLogin />
    <MyStaysContainer />
    <Footer />
  </div>
);

export default CheckoutDetail;
