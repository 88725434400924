import React from 'react';

import { StreetInputWrapper, StreetInputBar, Close } from './styled';

const StreetInput: React.FC = () => (
  <StreetInputWrapper>
    <StreetInputBar placeholder="Address or Area" />
    <Close fill="#fff" />
  </StreetInputWrapper>
);

export default StreetInput;
