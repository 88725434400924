import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import LocationIcon from '../../Assets/Images/Icons/Location.png';
import { Wrapper } from './styled';

const Marker = ({ tooltip, text, onClick }: any): JSX.Element => {
  const popover = (
    <Popover>
      <Popover.Header className="popover-header">
        {tooltip?.title}
      </Popover.Header>
      <Popover.Body className="popover-body">
        {tooltip?.address?.street}
      </Popover.Body>
    </Popover>
  );

  const onMarkerClick = () => {
    onClick();
  };

  return (
    <Wrapper onClick={onMarkerClick}>
      <OverlayTrigger
        delay={{ show: 100, hide: 100 }}
        trigger={['hover', 'focus', 'click']}
        placement="top"
        overlay={popover}
      >
        <img src={LocationIcon} alt={text} width="30" />
      </OverlayTrigger>
    </Wrapper>
  );
};

export default Marker;
