import React from 'react';

import { Wrapper, HotelName, HotelAddress } from './styled';

interface BasicDetailProps {
  reservation: any;
}

const BasicDetail: React.FC<BasicDetailProps> = ({
  reservation,
}: BasicDetailProps) => (
  <Wrapper>
    <HotelName>{reservation?.explore?.title}</HotelName>
    <HotelAddress className="pb-4">
      {reservation?.explore?.address?.street},{' '}
      {reservation?.explore?.address?.city}
    </HotelAddress>
  </Wrapper>
);
export default BasicDetail;
