import React from 'react';
import { Link } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { Details, Heading, Question, QuestionDetails } from './styled';

const CancellationPolicy: React.FC = () => (
  <Row className="py-3">
    <Heading>Cancellation Policy</Heading>
    <Details className="pt-3">
      During these uncertain times, we’ve increased flexibility for reservations
      made on gilberthotels.com. Cancel up to 3 days before arrival for a full
      refund. Modify your booking any time, subject to refund limitations.
    </Details>
    <Question className="pt-4">Questions?</Question>
    <QuestionDetails className="pt-3">
      If you have questions about your reservation, visit our help center or
      contact us at
      <br />
      <Link to="/" className="reservations-link">
        reservations@gilberthotels.com
      </Link>{' '}
      or by texting or calling +1 (000) 000-0000.
    </QuestionDetails>
  </Row>
);
export default CancellationPolicy;
