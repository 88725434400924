import { collection, getDocs, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';

import { db } from '../../../Config/firebase';

import { SearchInputWrapper, SelectCityBox, Search } from './styled';

interface SearchInputProps {
  setSelectedCity: (value: {
    key: string;
    label: string;
    value: string;
  }) => void;
  openCityMenu: boolean;
  setOpenCityMenu: (value: boolean) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
  setSelectedCity,
  openCityMenu,
  setOpenCityMenu,
}: SearchInputProps) => {
  const [cities, setCities] = useState<any>([]);

  useEffect(() => {
    const getCityLiist = async () => {
      const q = query(collection(db, 'cities'));
      const querySnapshot = await getDocs(q);
      const tempdata: any = [];
      querySnapshot.forEach(doc => {
        tempdata.push({
          label: doc.data().name,
          value: doc.data().name,
          key: doc.data().name,
        });
      });
      setCities(tempdata);
    };
    getCityLiist();
  }, []);

  return (
    <SearchInputWrapper>
      <Search fill="#fff" />
      <SelectCityBox>
        <ReactSelect
          className="city-list"
          isMulti={false}
          styles={{
            control: () => ({
              border: 0,
              display: 'flex',
            }),
            indicatorSeparator: () => ({ display: 'none' }),
            dropdownIndicator: () => ({ display: 'none' }),
            // option: () => ({
            // cursor: 'pointer',
            // padding: '8px 12px',
            // background: '#DEEBFF',
            // color: 'inherit',
            // display: 'block',
            // WebkitUserSelect: 'none',
            // MozUserSelect: 'none',
            // userSelect: 'none',
            // msUserSelect: 'none',
            // WebkitTapHighlightColor: 'red',
            // boxSizing: 'border-box',
            // }),
          }}
          placeholder="Search city"
          onChange={setSelectedCity}
          options={cities}
          menuIsOpen={openCityMenu}
          onMenuOpen={() => setOpenCityMenu(true)}
          onMenuClose={() => setOpenCityMenu(false)}
        />
      </SelectCityBox>
    </SearchInputWrapper>
  );
};
export default SearchInput;
