import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding-top: 50px;
`;

export const Title = styled.h2`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #212f52;
`;

export const MainDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const FirstWrapper = styled.div`
  width: 40%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SecondWrapper = styled.div`
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SecondInnerSecondDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Descripation = styled.ul`
  display: flex;
  gap: 35px;
  padding: 0px;
  li {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #212f52;
  }
  span {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #212f52;
  }
`;

export const Wrapper = styled.div`
  background: #d9d9d9;
  border-radius: 15px;
  background-size: cover;
  height: 295px;
  @media (max-width: 425px) {
    height: 140px;
  }
`;

export const FullWrapper = styled.div`
  height: 600px;
  background: #d9d9d9;
  border-radius: 15px;
  background-size: cover;
  @media (max-width: 425px) {
    height: 280px;
  }
`;

export const WriteingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  height: 400px;
  background: #f9f9f9;
`;

export const HostName = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #212f52;
`;

export const Quote = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #6a737b;
`;
