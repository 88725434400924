import styled from 'styled-components';

export const GuestSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  color: #212f52;
  border-radius: 33px;
  position: relative;
  padding: 20px 26px;
  width: 100%;
  @media (max-width: 1456px) {
    padding: 20px 15px;
  }
  @media (max-width: 1325px) {
    padding: 20px 10px;
  }
  @media (max-width: 1125px) {
    padding: 20px 5px;
  }
  @media (max-width: 1064px) {
    padding: 20px 1px;
  }
  @media (max-width: 1024px) {
    padding: 20px 5px;
  }
  @media (max-width: 990px) {
    padding: 20px 26px;
  }
  @media (max-width: 768px) {
    height: auto;
  }
  @media (max-width: 425px) {
    height: auto;
    flex-direction: row;
  }
  &:hover {
    background: #212f5214;
  }
`;

export const GuestCounter = styled.div`
  color: #212f52;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    width: 200px;
    margin-top: 15px;
  }
`;

export const Counter = styled.div``;

export const RemoveButton = styled.button`
  border: 0;
  background-color: transparent;
`;
export const AddButton = styled.button`
  border: 0;
  background-color: transparent;
`;
