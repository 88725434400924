import React from 'react';
import { GuestSection, Counter, AddButton, RemoveButton } from './styled';

import Minus from '../../Icons/Minus';
import Plus from '../../Icons/Plus';

interface GuestInputProps {
  setAddGuestCount: () => void;
  setRemoveGuestCount: () => void;
  count: number;
}

const GuestInput: React.FC<GuestInputProps> = ({
  setAddGuestCount,
  setRemoveGuestCount,
  count,
}: GuestInputProps) => (
  <GuestSection>
    {/* <GuestCounter> */}
    Guest
    <Counter>
      <RemoveButton
        type="button"
        onClick={setRemoveGuestCount}
        disabled={count === 0}
      >
        <Minus fill="#212F52" />
      </RemoveButton>
      {count}
      <AddButton type="button" onClick={setAddGuestCount}>
        <Plus fill="#212F52" />
      </AddButton>
    </Counter>
    {/* </GuestCounter> */}
  </GuestSection>
);

export default GuestInput;
