import styled from 'styled-components';
import { Row } from 'react-bootstrap';

interface ItemImageProps {
  src: string;
}

export const RowWrapper = styled(Row)`
  margin: 0px 70px;
  @media (max-width: 425px) {
    margin: 0px 10px;
  }
`;

export const DetailWrapper = styled.div`
  padding: 0px;
`;

export const Img = styled.div`
  display: flex;
  @media (max-width: 425px) {
    display: block;
  }
`;

export const BeforeCheckInBox = styled.div`
  background: #ffffff;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 15px;
`;

export const ItemBlock = styled.div`
  display: flex;
  .form-check-input[type='checkbox'] {
    padding: 10px;
    border-radius: 12px;
    :hover {
      cursor: pointer;
    }
  }
  .form-check-input:checked {
    background-color: #212f52;
    border-color: #212f52;
    position: relative;
  }
  h6 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #212f52;
    padding-left: 25px;
  }
  .verify-link {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
    text-decoration-line: underline;
    color: #f14336;
  }
`;

export const VerifyInfo = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212f52;
  padding-right: 60px;
  @media (max-width: 768px) {
    padding-right: 0px;
  }

  padding-left: 46px;
  display: flex;
`;

export const StayDuration = styled.p`
  padding-top: 20px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #212f52;
`;

export const StayName = styled.p`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: #212f52;
`;

export const StayAddress = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #212f52;
`;

export const ConfirmationTitle = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: #212f52;
`;

export const ConfirmationText = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #212f52;
`;

export const CheckInOutTitle = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: #212f52;
`;

export const CheckOutTitle = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: #212f52;
  text-align: end;
`;

export const CheckInOutText = styled.div`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #212f52;
`;

export const ActionWrapper = styled.div``;

export const AppText = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #212f52;
`;

export const AppLinkImage = styled.img<ItemImageProps>`
  position: static;
  width: 150px;
  height: 51px;
  left: 0px;
  top: 0px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const SpinnerDiv = styled.div`
  text-align: center;
  margin-top: 50%;
`;

export const ReservationDetailButton = styled.button`
  background: #212f52;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 23px 0px;
  width: 100%;
  margin-bottom: 20px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.1em;
  margin-right: 5px;
`;
