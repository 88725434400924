import React from 'react';
import Footer from '../../Components/Footer';
import GetCorporeatRateContainer from '../../Components/GetCorporeatRateContainer';
import Header from '../../Components/Header';

const GetCorporeateRate: React.FC = () => (
  <div>
    <Header hasLogin />
    <GetCorporeatRateContainer />
    <Footer />
  </div>
);

export default GetCorporeateRate;
