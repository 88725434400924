import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 50px 0px;
`;

export const ContactInformationDiv = styled.div`
  h6 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #212f52;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: 50px;
    margin-bottom: 20px;
    @media (max-width: 425px) {
      margin-top: 20px;
    }
  }
  .form-label {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #212f52;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 7px 0px;
  }
  .form-control {
    background: #ffffff;
    border: 2px solid #caccd1;
    box-sizing: border-box;
    border-radius: 5px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 7px 0px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #9f9fa3;
    outline: 0;
    &:focus {
      box-shadow: none;
    }
  }
  button {
    background: #dca73a;
    border-radius: 33px;

    border: none;
  }
  .form-select {
    border: 2px solid #caccd1;
    box-sizing: border-box;
    border-radius: 5px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 7px 0px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #9f9fa3;
    &:focus {
      box-shadow: none;
    }
  }
`;

export const Required = styled.label`
  color: red;
`;

export const CheckInCheckOutDiv = styled.div`
  display: flex;
  background: #ffffff;
  border: 2px solid #caccd1;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 7px 0px;
  .start-date {
    width: 50%;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    color: #9f9fa3;
    border: none;
    :focus {
      outline: none;
    }
    @media (max-width: 425px) {
      width: 100%;
    }
  }

  .end-date {
    width: 50%;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #9f9fa3;
    display: flex;
    border: none;
    :focus {
      outline: none;
    }
    @media (max-width: 425px) {
      width: 100%;
    }
  }
`;

export const Buttondiv = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 48px;
  @media (max-width: 425px) {
    justify-content: center;
  }
`;

export const SubmitLink = styled(Link)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #212f52;
  text-decoration: none;
  &:hover {
    color: #212f52;
  }
  @media (max-width: 320px) {
    font-size: 16px;
  }
`;
