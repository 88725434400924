import React from 'react';
import { Details, DotTag, Heading } from './styled';

const ThirdParties: React.FC = () => (
  <>
    <Heading>Information We Collect from Third Parties.</Heading>
    <Details>
      <p>
        <DotTag className="px-1">&bull;</DotTag>
        <b> Other Members.</b> We may obtain Personal Information about you from
        another Member who adds you to their reservation. If you are not a
        Member you will be invited to become a Member. If you are already a
        Member and accept the other Member’s invitation to their reservation,
        that reservation will be linked to your account.
      </p>
      <p>
        <DotTag className="px-1">&bull;</DotTag>
        <b> Third party accounts.</b> We may obtain Personal Information about
        you from third party authentication and social networking services
        (“SNS”), such as Apple, Facebook, Google and LinkedIn, to link your
        Gilbert Hotels Account with third party accounts (“Third Party Account”)
        by either: (i) providing your Third Party Account login information to
        Gilbert Hotels through the Service; or (ii) allowing Gilbert Hotels to
        access your Third Party Account, as is permitted under the applicable
        terms and conditions that govern your use of each Third Party Account.
        We may receive from such third parties the following types of Personal
        Information:
      </p>
      <div className="px-3">
        <p>
          <DotTag className="px-1">&#8728;</DotTag>
          <b> Apple:</b> email, first name, last name.
        </p>
        <p>
          <DotTag className="px-1">&#8728;</DotTag>
          <b> Facebook:</b> ID, cover photo, email, first name, last name, age
          range, link, gender, location, picture, timezone, date of last update,
          user verification.
        </p>
        <p>
          <DotTag className="px-1">&#8728;</DotTag>
          <b> Google:</b> Google ID, name, profile URL, email address.
        </p>
        <p>
          <DotTag className="px-1">&#8728;</DotTag>
          <b> LinkedIn:</b> basic profile, email address.
        </p>
        <p>
          We may also receive any other information you authorize Gilbert Hotels
          to access by authorizing the SNS to provide such information.
        </p>
      </div>
      <p>
        <DotTag className="px-1">&bull;</DotTag>
        <b> Third party partners and travel services.</b> We may receive your
        booking information via our partners (e.g., Booking.com, Expedia,
        Airbnb), such as your name, email address, phone number, information
        available in your user account with a third party travel service and
        your Gilbert Hotels stay information (e.g., information on the Gilbert
        Hotels unit you are staying in).
      </p>
      <p>
        <DotTag className="px-1">&bull;</DotTag>
        <b> Background checks.</b> If you book an Accommodation in the United
        States, we may engage with a vendor to conduct a background check for
        use in our trust and safety, fraud prevention and risk assessment
        efforts, and/or to comply with landlord requirements. We may receive
        your name, date of birth, address and the results of the background
        check. Our vendor will use U.S. county, state and federal criminal
        databases and reports from U.S. credit agencies to run the checks.
      </p>
      <p>
        <DotTag className="px-1">&bull;</DotTag>
        <b> Vendor and partner relationships.</b> We receive Personal
        Information of employees and other individuals who are contact persons
        at our partners and vendors, such as contact details and other
        information relevant to the particular business relationship.
      </p>
    </Details>
  </>
);

export default ThirdParties;
