import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ExpectDetail, ExpectTitle, Heading, Wrapper } from './styled';

const Expect = [
  {
    id: 1,
    title: 'Arrival guide',
    details:
      "We'll send you and email with arrival instructions 3 days before your check-in date.",
  },
  {
    id: 2,
    title: 'Self check-in',
    details:
      'Check-in is a breeze when you follow the instructions in your arrival guide to enter your hotel.',
  },
  {
    id: 3,
    title: '24/7 Support',
    details:
      'Message or call our team at any time if there is anything we can do to improve your stay.',
  },
];

const WhatToExpect: React.FC = () => (
  <Row className="py-3">
    <Heading>What to Expect</Heading>
    <Row>
      {Expect.map(item => (
        <Col xxl={4} xl={4} lg={4} md={4} sm={4}>
          <Wrapper />
          <ExpectTitle>{item.title}</ExpectTitle>
          <ExpectDetail>{item.details}</ExpectDetail>
        </Col>
      ))}
    </Row>
  </Row>
);
export default WhatToExpect;
