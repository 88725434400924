import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 60px 0px;
  @media (max-width: 768px) {
    padding-bottom: 20px !important;
  }
  @media (max-width: 425px) {
    padding-bottom: 20px !important;
  }
`;

export const Heading = styled.h6`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 60px;
  color: #212f52;
  @media (max-width: 425px) {
    font-size: 36px;
  }
`;

export const SearchDiv = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-start;
  padding-top: 48px;
  padding-bottom: 48px;
  @media (max-width: 425px) {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  input {
    width: 100%;
    background: #f9f9f9;
    border: 1px solid #caccd1;
    box-sizing: border-box;
    border-radius: 5px;
  }
  img {
    position: absolute;
    flex: none;
    order: 1;
    flex-grow: 0;
    padding-right: 10px;
  }
`;

export const CheckInDiv = styled.div`
  background: #ffffff;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
`;

export const CheckInDetail = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #212f52;
  flex: none;
  order: 1;
  flex-grow: 0;
  @media (max-width: 320px) {
    font-size: 16px;
  }
`;

export const LateCheckout = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #212f52;
  display: flex;
  @media (max-width: 425px) {
    font-size: 18px;
  }
  @media (max-width: 320px) {
    font-size: 16px;
  }
`;

export const Details = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #212f52;
`;

export const ContactUsLink = styled(Link)`
  color: #212f52;
  &:hover {
    color: #212f52;
  }
`;
