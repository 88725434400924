import React from 'react';
import { Col, Row } from 'react-bootstrap';
import TermsofService from '../TermsofService';
import { BGWrapper, ContainerWrapper } from './styled';

const TermsofServiceContainer: React.FC = () => (
  <BGWrapper>
    <ContainerWrapper>
      <Row>
        <Col className="m-auto" lg={8} md={8} sm={12}>
          <TermsofService />
        </Col>
      </Row>
    </ContainerWrapper>
  </BGWrapper>
);

export default TermsofServiceContainer;
