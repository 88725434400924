import React from 'react';

import { Wrapper, HotelName, HotelAddress } from './styled';

interface BasicDetailProps {
  billingData: any;
}

const BasicDetail: React.FC<BasicDetailProps> = ({
  billingData,
}: BasicDetailProps) => (
  <Wrapper>
    <HotelName>{billingData?.address?.display}</HotelName>
    <HotelAddress className="pb-4">
      {billingData?.address?.street}, {billingData?.address?.city}
    </HotelAddress>
  </Wrapper>
);
export default BasicDetail;
