import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { BGWrapper, BLogDeatils, Wrapper } from './styled';

const PimmsCup: React.FC = () => (
  <BGWrapper>
    <Container>
      <BLogDeatils>
        <h6>Pimm’s Cup – England</h6>
        <p>
          Let’s start our journey in London, the birthplace of the Pimm’s Cup.
          First invented in the 1800s by James Pimm, this sweet summer cocktail
          is the official drink of the Wimbledon tennis tournament. It’s
          traditionally made with gin liqueur (Pimm’s No. 1) and sparkling
          lemonade and served over ice in a highball glass garnished with fruit.
        </p>
        <h6>Irish coffee – Ireland</h6>
        <p>
          Next up is Ireland and its irresistible irish coffee. The perfect
          complement to a stormy winter night next to a fire. Chef Joe Sheridan
          first created it in 1943 on a flight out of Foynes Airbase that had to
          turn around. He wanted to bring a little warmth to the flight’s weary
          travelers, and it worked. Reportedly, it was such a hit that there was
          complete silence once the drink was served. If you want to make it
          yourself, just grab some fine Irish whiskey, whipping cream (not the
          can stuff), brown sugar, and high-quality coffee. Be sure to float the
          cream on top by pouring it over the back of a spoon.
        </p>
        <Row>
          <Col lg={6} md={6} sm={6}>
            <Wrapper />
          </Col>
          <Col lg={6} md={6} sm={6}>
            <Wrapper />
          </Col>
        </Row>
        <h6>Aperol Spritz – Italy</h6>
        <p>
          From the United Kingdom, we head across the English Channel to Italy
          for one of our favorite aperitifs, the Aperol Spritz. First invented
          in 1919, this low-ABV aperitif is served before dinner to awake the
          appetite. But we think it’s terrific any time of day. The simple
          combination of Aperol, prosecco, and a splash of soda is perfect for
          those late summer afternoons with friends.
        </p>
        <h6>Manhattan – United States</h6>
        <p>
          No one knows the exact origins of the manhattan. The only thing people
          agree on is that it was invented in Manhattan around the 1880s and
          that it’s delicious. This cocktail was one of the first to use
          vermouth, and it predates the other vermouth heavies, like the martini
          and the Rob Roy. Whether at home or a rooftop bar watching the sunset
          over NYC, the manhattan is sure to please.
        </p>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Wrapper />
          </Col>
        </Row>
        <h6>Paloma – Mexico City</h6>
        <p>
          Last on our list is one of our personal favorites: the paloma. When
          most people think of Mexico, they think margaritas, but palomas are
          what the locals drink. While its true origins are unknown, this
          pink-hued crowd-pleaser of tequila, grapefruit, and lime is all you
          need on a hot summer day. It’s sweet, sour, and a touch bitter all at
          the same time. Add a little salt to the rim, and you’ve got all four
          taste sensations in every sip.
        </p>
      </BLogDeatils>
    </Container>
  </BGWrapper>
);

export default PimmsCup;
