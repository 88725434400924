import React from 'react';
import Slider from 'react-slick';
import { ReadMore, SliderWrapper, Wrapper } from './styled';
import SliderPrevIcon from '../../Assets/Images/Icons/SliderPrevIcon.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const BlogDetails = [
  {
    id: 1,
    title: 'TRAVEL',
    text: 'A taste of the world through cocktails',
    date: 'SEPTEMBER 17, 2021',
    link: 'CONTINUE READING',
    to: '/',
  },
  {
    id: 2,
    title: 'CULTURE',
    text: 'Ini Archibong: Artist in Residence',
    date: 'AUGUST 31, 2021',
    link: 'CONTINUE READING',
    to: '/',
  },
  {
    id: 3,
    title: 'NEWS',
    text: 'Traveling for work? Stay with us',
    date: 'JULY 28, 2021',
    link: 'CONTINUE READING',
    to: '/',
  },
  {
    id: 4,
    title: 'DESIGN',
    text: 'Make your patio the place to be this summer',
    date: 'JULY 23, 2021',
    link: 'CONTINUE READING',
    to: '/',
  },
  {
    id: 5,
    title: 'IMPACT',
    text: 'Providing support for those impacted in Surfside',
    date: 'JUNE 29, 2021',
    link: 'CONTINUE READING',
    to: '/',
  },
];

const NextArrow = ({ className, onClick }: any) => (
  <div
    role="button"
    className={className}
    style={{ display: 'flex', alignItems: 'center' }}
    onClick={onClick}
    onKeyDown={() => {}}
    tabIndex={0}
  >
    <img src={SliderPrevIcon} alt="prev-icon" />
  </div>
);

const PrevArrow = ({ className, onClick }: any) => (
  <div
    role="button"
    className={className}
    style={{ display: 'flex', alignItems: 'center' }}
    onClick={onClick}
    onKeyDown={() => {}}
    tabIndex={-1}
  >
    <img src={SliderPrevIcon} alt="prev-icon" />
  </div>
);

const settings = {
  centerMode: true,
  centerPadding: '70px',
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 1,
      },
    },
  ],
};

const BlogSlider: React.FC = () => (
  <SliderWrapper>
    <Slider {...settings}>
      {BlogDetails.map(item => (
        <div className="d-flex justify-content-center py-5" key={item?.id}>
          <Wrapper>
            <h6 className="pt-5">{item.title}</h6>
            <p className="pt-2">{item.text}</p>
            <ReadMore
              to={`${item.to}`}
              className="col d-flex justify-content-center py-3"
            >
              Read more
            </ReadMore>
          </Wrapper>
        </div>
      ))}
    </Slider>
  </SliderWrapper>
);

export default BlogSlider;
