import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 0px 60px;
  @media (max-width: 425px) {
    padding: 0px;
  }
`;

export const BasicPost = styled.div`
  h6 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #212f52;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 30px 0px;
  }
  p {
    font-family: 'Leitura Display';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 45px;
    text-align: center;
    color: #212f52;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 30px 0px;
  }
  .date {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #212f52;
    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 30px 0px;
  }
`;

export const BLogDeatils = styled.div`
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    color: #212f52;
  }
`;

export const Wrapper = styled.div`
  background: #caccd1;
  height: 400px;
  width: 100%;
  margin: 5px 5px;
`;
