import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const RowWrapper = styled(Row)`
  border-left: 2px #caccd1 solid;
  padding: 0px 50px;
  @media (max-width: 768px) {
    border: none;
    padding-top: 0px !important;
  }
  @media (max-width: 425px) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding: 0px 24px;
  }
`;

export const DatePickerWrapper = styled.div`
  display: flex;
  padding: 21px 14px;
  align-items: center;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 33px;
  position: relative;
  text-align: center;
  align-items: center;
  width: 100%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  &:hover {
    background: #212f5214;
  }
`;

export const ErrorDiv = styled.div`
  color: rgb(193, 53, 21);
  margin-top: 10px;
  text-align: center;
  font-size: 12px !important;
  line-height: 16px !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    'Helvetica Neue', sans-serif !important;
  font-weight: 400 !important;
`;

export const WarningDiv = styled.div`
  color: #dca73a;
  margin-top: 10px;
  text-align: center;
  font-size: 12px !important;
  line-height: 16px !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    'Helvetica Neue', sans-serif !important;
  font-weight: 400 !important;
  margin-bottom: 10px;
  margin-top: 0px;
`;
