import React from 'react';
import moment from 'moment';
import { Wrapper, Heading, ItemBox } from './styled';
import MapIcon from '../../../Assets/Images/Icons/MapIcon.svg';
import ParkingIcons from '../../../Assets/Images/Icons/ParkingIcons.svg';
import WheelchairIcon from '../../../Assets/Images/Icons/WheelchairIcon.svg';
import WarningIcon from '../../../Assets/Images/Icons/WarningIcon.svg';
import Restricted from '../../../Assets/Images/Icons/Restricted.svg';
import TrueIcon from '../../../Assets/Images/Icons/TrueIcon.svg';

interface ThingsToNoteProps {
  explore: any;
}

const ThingsToNote: React.FC<ThingsToNoteProps> = ({
  explore,
}: ThingsToNoteProps) => {
  const BuildingFeature = [
    {
      id: 1,
      image: MapIcon,
      title: [
        `Check-in is at ${moment
          .parseZone(explore?.check_in, 'h:mm a')
          .format('h:mm a')}`,
        `Checkout is at ${moment
          .parseZone(explore?.check_out, 'h:mm a')
          .format('h:mm a')}`,
      ],
      text: [
        'You may request early check-in and/or late check-out after booking.',
        'Our team will do out best to accommodate any requests based on availability.',
      ],
    },
    {
      id: 2,
      image: ParkingIcons,
      title: ['Parking'],
      text: [
        'There is no free steet parking nearby',
        'Paid street parking nearby',
        'There are no free parking lots nearby',
        'There’s a paid parking lot nearby',
      ],
    },
    {
      id: 3,
      image: WheelchairIcon,
      title: ['Accessibility'],
      text: [
        'Wheelchair accessibility not available',
        'Elevators are not available',
      ],
    },
    {
      id: 4,
      image: WarningIcon,
      title: ['House Rules'],
      text: [
        explore?.rules?.smoking && 'No smoking',
        explore?.rules?.pets && 'No pets (not even really cute ones)',
        explore?.rules?.parties && 'No parties (not even really quite ones)',
      ],
      note: '* Please be respectful of your neighbors and keep noise to a minimam from 10:00 pm - 8:00 am',
    },
    {
      id: 5,
      image: Restricted,
      title: ['Please note'],
      list: [
        'Room service is unavailable. Stairs are required to enter this property. Your king room may have two single beds that can be seperated.',
      ],
    },
    {
      id: 6,
      image: TrueIcon,
      title: ['Flexible cancellation'],
      text: [
        'We offer flexible cancellations for all bookings. Select the Flex Rate to cancel your booking up to 3 days before check-in and receive a full refund',
        'For longer stays that are paid monthly, we require at least 30 days notice to cancel or modify without fees.',
      ],
    },
  ];
  return (
    <Wrapper>
      <Heading>Things to note</Heading>
      <div className="pt-5">
        {BuildingFeature.map(item => (
          <ItemBox key={`0${item.id}`}>
            <img src={item.image} alt={`${item.id}`} />
            <div>
              {item?.title &&
                item.title.map(title => <h4 className="m-0">{title}</h4>)}
              {item?.text &&
                item.text.map(text => <p className="m-0">{text}</p>)}
              {item?.note && <p className="note m-0">{item?.note}</p>}
              {item?.list &&
                item.list.map(list => <li className="m-0">{list}</li>)}
            </div>
          </ItemBox>
        ))}
      </div>
    </Wrapper>
  );
};
export default ThingsToNote;
