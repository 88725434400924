import React, { useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import {
  Buttondiv,
  CheckInCheckOutDiv,
  ContactInformationDiv,
  Required,
  SubmitLink,
} from './styled';
import CheckInCheckOutIcon from '../../../Assets/Images/Icons/CheckInCheckOutIcon.svg';

const ContactInformation: React.FC = () => {
  const [startDate, setStartDate] = useState<Date>(new Date('2014/02/08'));
  const [endDate, setEndDate] = useState<Date>(new Date('2014/02/10'));
  return (
    <Col lg={10} md={10} sm={12}>
      <ContactInformationDiv>
        <h6>Contact information</h6>
        <Row>
          <Col lg={6} md={12} sm={12}>
            <Form.Label>
              First Name <Required>*</Required>{' '}
            </Form.Label>
            <Form.Control type="text" />
          </Col>
          <Col lg={6} md={12} sm={12}>
            <Form.Label>
              Last Name <Required>*</Required>{' '}
            </Form.Label>
            <Form.Control type="text" />
          </Col>
          <Col lg={6} md={12} sm={12}>
            <Form.Label>
              Country Code <Required>*</Required>{' '}
            </Form.Label>
            <Form.Select>
              <option value="Select1">Select1</option>
              <option value="Select2">Select2</option>
              <option value="Select3">Select3</option>
              <option value="Select4">Select4</option>
            </Form.Select>
          </Col>
          <Col lg={6} md={12} sm={12}>
            <Form.Label>
              Phone number <Required>*</Required>{' '}
            </Form.Label>
            <Form.Control type="text" />
          </Col>
          <Col lg={12} md={12} sm={12}>
            <Form.Label>
              Email <Required>*</Required>{' '}
            </Form.Label>
            <Form.Control type="email" />
          </Col>
        </Row>
        <h6 className="pt-5">Event details</h6>
        <Row>
          <Col lg={6} md={12} sm={12}>
            <Form.Label>
              Event type <Required>*</Required>{' '}
            </Form.Label>
            <Form.Select>
              <option value="Select1">Select1</option>
              <option value="Select2">Select2</option>
              <option value="Select3">Select3</option>
              <option value="Select4">Select4</option>
            </Form.Select>
          </Col>
          <Col lg={6} md={12} sm={12}>
            <Form.Label>
              Destination city <Required>*</Required>{' '}
            </Form.Label>
            <Form.Select>
              <option value="Select1">Select1</option>
              <option value="Select2">Select2</option>
              <option value="Select3">Select3</option>
              <option value="Select4">Select4</option>
            </Form.Select>
          </Col>
        </Row>
        <h6 className="pt-5">Party details</h6>
        <Row>
          <Col lg={6} md={12} sm={12}>
            <Form.Label>
              Number of guests <Required>*</Required>{' '}
            </Form.Label>
            <Form.Control type="number" />
          </Col>
          <Col lg={6} md={12} sm={12}>
            <Form.Label>
              Number of rooms <Required>*</Required>{' '}
            </Form.Label>
            <Form.Control type="number" />
          </Col>
          <Col lg={12} md={12} sm={12}>
            <CheckInCheckOutDiv>
              <DatePicker
                className="start-date py-2 px-2"
                selected={startDate}
                onChange={setStartDate}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
              <img src={CheckInCheckOutIcon} alt="arrow" />
              <DatePicker
                className="end-date py-2 px-2"
                selected={endDate}
                onChange={setEndDate}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </CheckInCheckOutDiv>
          </Col>
        </Row>
        <h6 className="pt-5">Additional information</h6>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Form.Label>Questions, concerns, comments</Form.Label>
            <Form.Control type="text" />
          </Col>
        </Row>
        <Buttondiv>
          <button type="submit" className="py-3 px-5">
            <SubmitLink to="/">Submit business inquiry</SubmitLink>
          </button>
        </Buttondiv>
      </ContactInformationDiv>
    </Col>
  );
};
export default ContactInformation;
