import React from 'react';

import {
  Wrapper,
  HotelName,
  HotelAddress,
  HotelText,
  ItemBox,
  ItemBoxWrapper,
} from './styled';
import BedroomIcon from '../../../Assets/Images/Icons/BedroomIcon.svg';
import BathtubIcon from '../../../Assets/Images/Icons/BathtubIcon.svg';
import SquareFeetIcon from '../../../Assets/Images/Icons/SquareFeetIcon.svg';

interface BasicDetailProps {
  explore: any;
}

const BasicDetail: React.FC<BasicDetailProps> = ({
  explore,
}: BasicDetailProps) => (
  <>
    <Wrapper key={explore?.id}>
      <HotelName>{explore?.title}</HotelName>
      <HotelAddress className="pb-4">
        {explore?.address?.street}, {explore?.address?.city}
      </HotelAddress>
      <ItemBoxWrapper>
        {explore?.capacity?.bedrooms === 0 ? (
          ''
        ) : (
          <>
            <ItemBox>
              <img src={BedroomIcon} alt={`${explore?.id}`} />
              <p className="m-0">{explore?.capacity?.bedrooms} Bedrooms</p>
            </ItemBox>
          </>
        )}
        {explore?.capacity?.bathrooms === 0 ? (
          ''
        ) : (
          <>
            <ItemBox>
              <img src={BathtubIcon} alt={`${explore?.id}`} />
              <p className="m-0">{explore?.capacity?.bathrooms} Bathrooms</p>
            </ItemBox>
          </>
        )}
        {explore?.capacity?.bathrooms === 0 ? (
          ''
        ) : (
          <>
            <ItemBox>
              <img src={SquareFeetIcon} alt={`${explore?.id}`} />
              <p className="m-0">586 Sq ft</p>
            </ItemBox>
          </>
        )}
      </ItemBoxWrapper>
      <HotelText>{explore?.description}</HotelText>
    </Wrapper>
  </>
);
export default BasicDetail;
