import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { BGWrapper, HeaderDiv, Share, ShareDiv } from './styled';
import BcakIcon from '../../Assets/Images/Icons/BackIcon.svg';
import ShareIcon from '../../Assets/Images/Icons/ShareIcon.svg';
import PropertyOverview from './PropertyOverview';
import LivingRoom from './LivingRoom';
import FullKitchen from './FullKitchen';
import Bedroom from './Bedroom';
import FullBathroom from './FullBathroom';
import HalfBathroom from './HalfBathroom';
import Diningroom from './Diningroom';
import Familyroom from './Familyroom';
import CommonSpace from './CommonSpace';
import Entrance from './Entrance';
import Entry from './Entry';
import Exterior from './Exterior';
import Patio from './Patio';
import Backyard from './Backyard';
import Frontyard from './Frontyard';
import Heart from '../Icons/Heart';
import { db } from '../../Config/firebase';
import Loader from '../../Common/Loader';

const ViewAllPhotosContainer: React.FC = () => {
  const history = useHistory();
  const [color, setColor] = useState<string>('white');
  const [count, setCount] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [photos, setPhotos] = useState<any | null>([]);
  const { unitId }: any = useParams();

  const goBackEvent = () => {
    history.goBack();
  };

  useEffect(() => {
    const getViewAllImages = async () => {
      if (unitId) {
        setLoading(true);
        const photosRef: any = await db.collection('photos').doc(unitId).get();
        setPhotos(Object.values(photosRef.data()));
        setLoading(false);
      }
    };
    getViewAllImages();
  }, []);

  return (
    <BGWrapper>
      {loading ? (
        <div style={{ margin: '18% 0px' }}>
          <Loader />
        </div>
      ) : (
        <Container>
          <HeaderDiv>
            <div>
              <img
                src={BcakIcon}
                alt="back"
                onClick={goBackEvent}
                aria-hidden="true"
                style={{ cursor: 'pointer' }}
              />
            </div>
            <div className="d-flex">
              <ShareDiv>
                <img src={ShareIcon} alt="share" />
                <Share>Share</Share>
              </ShareDiv>
              <ShareDiv
                className="mx-2"
                onClick={() => {
                  if (count === 1) {
                    setCount(count + 1);
                    setColor('red');
                  } else if (count === 2) {
                    setCount(count - 1);
                    setColor('white');
                  }
                }}
              >
                <Heart fill={color} />
                <Share>Save</Share>
              </ShareDiv>
            </div>
          </HeaderDiv>
          <PropertyOverview photos={photos} />
          {photos?.map(
            (element: any) =>
              element?.list_name === 'livingroom' && (
                <LivingRoom element={element} />
              ),
          )}
          {photos?.map((element: any) => (
            <div key={element?.list_name}>
              {element?.list_name === 'fullkitchen' && (
                <FullKitchen element={element} />
              )}
            </div>
          ))}
          {photos?.map((element: any) => (
            <div key={element?.list_name}>
              {element?.list_name === 'bedroom' && (
                <Bedroom element={element} />
              )}
            </div>
          ))}
          {photos?.map((element: any) => (
            <div key={element?.list_name}>
              {element?.list_name === 'fullbathroom' && (
                <FullBathroom element={element} />
              )}
            </div>
          ))}
          {photos?.map((element: any) => (
            <div key={element?.list_name}>
              {element?.list_name === 'halfbathroom' && (
                <HalfBathroom element={element} />
              )}
            </div>
          ))}
          {photos?.map((element: any) => (
            <div key={element?.list_name}>
              {element?.list_name === 'diningroom' && (
                <Diningroom element={element} />
              )}
            </div>
          ))}
          {photos?.map((element: any) => (
            <div key={element?.list_name}>
              {element?.list_name === 'familyroom' && (
                <Familyroom element={element} />
              )}
            </div>
          ))}
          {photos?.map((element: any) => (
            <div key={element?.list_name}>
              {element?.list_name === 'commonspace' && (
                <CommonSpace element={element} />
              )}
            </div>
          ))}
          {photos?.map((element: any) => (
            <div key={element?.list_name}>
              {element?.list_name === 'entrance' && (
                <Entrance element={element} />
              )}
            </div>
          ))}
          {photos?.map((element: any) => (
            <div key={element?.list_name}>
              {element?.list_name === 'entry' && <Entry element={element} />}
            </div>
          ))}
          {photos?.map((element: any) => (
            <div key={element?.list_name}>
              {element?.list_name === 'exterior' && (
                <Exterior element={element} />
              )}
            </div>
          ))}
          {photos?.map((element: any) => (
            <div key={element?.list_name}>
              {element?.list_name === 'patio' && <Patio element={element} />}
            </div>
          ))}
          {photos?.map((element: any) => (
            <div key={element?.list_name}>
              {element?.list_name === 'backyard' && (
                <Backyard element={element} />
              )}
            </div>
          ))}
          {photos?.map((element: any) => (
            <div key={element?.list_name}>
              {element?.list_name === 'frontyard' && (
                <Frontyard element={element} />
              )}
            </div>
          ))}
        </Container>
      )}
    </BGWrapper>
  );
};

export default ViewAllPhotosContainer;
