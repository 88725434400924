import styled from 'styled-components';

export const Blog = styled.div`
  margin: 5%;
  display: flex;
  h6 {
    font-family: 'Leitura Display';
    font-style: normal;
    font-weight: 400;
    font-size: 82px;
    line-height: 89px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #212f52;
    @media (max-width: 425px) {
      font-size: 50px;
    }
    @media (max-width: 320px) {
      font-size: 40px;
    }
  }
  @media (max-width: 425px) {
    margin: 3%;
  }
`;

export const CheckoutMoreDiv = styled.div`
  background-color: #f9f9f9;
  padding-top: 100px;
  @media (max-width: 768px) {
    padding-top: 50px;
  }
  @media (max-width: 425px) {
    padding-top: 50px;
  }
`;
