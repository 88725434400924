import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail } from './styled';

const Indemnity: React.FC = () => (
  <Row>
    <Detail>
      <p>
        <b>15. Indemnity.</b> To the fullest extent permitted by law, you are
        responsible for your use of the Service and Properties, and you will
        defend and indemnify us and our officers, directors, employees,
        contractors, consultants, affiliates, subsidiaries, agents, and
        Landlords (and their property managers, service providers, and agents)
        (individually a “Gilbert Hotels Entity” and collectively the “Gilbert
        Hotels Entities”) from and against every claim brought by a third party,
        and any related liability, direct or indirect damage, loss, and expense,
        including reasonable attorneys’ fees and costs, arising out of or
        connected with: (a) your, Your Guests’, and/or your or their pets’ use
        of, or misuse of, the Service or your, Your Guests’, and/or your or
        their pets’ use, occupation, or misuse of the Properties; (b) your or
        Your Guests’ breach or violation of any portion of this Agreement, any
        representation, warranty, or agreement referenced in this Agreement, or
        any applicable law, rule, or regulation (including those put in place by
        Landlords from time to time); (c) your or Your Guests’ infringement,
        misappropriation, or violation of any third party’s right, including any
        intellectual property rights (including rights to trade secrets and
        moral rights), or any privacy, publicity, confidentiality, contractual,
        property, or other rights; (d) any dispute or issue between you and/or
        Your Guests and any third party; or (e) any injury, death, or damages
        sustained by any individual or entity, or to the property of the same
        (including any pets), where such injury, death, or damage is caused by
        your, Your Guests’, or your or their pets’ acts or omissions. We reserve
        the right, at our own expense, to assume the exclusive defense and
        control of any matter otherwise subject to indemnification by you
        (without limiting your indemnification obligations with respect to that
        matter), and in that case, you agree to cooperate with our defense of
        those claims.
      </p>
    </Detail>
  </Row>
);

export default Indemnity;
