import React from 'react';
import { AllTopicsDiv, Heading, StayingWithUsDiv } from './styled';

const StayingWithUs: React.FC = () => (
  <AllTopicsDiv>
    <Heading className="px-3 pt-3">Staying with us</Heading>
    <StayingWithUsDiv className="px-2">
      <ul className="pt-3">
        <li>What are you house rules?</li>
        <li>How do I request an early chech-in or a late checkout?</li>
        <li>Do you provide daily housekeeping service?</li>
        <li>How do I request a pack &apos;n play?</li>
        <li>Can I request additional beds if I want extra guests to stay?</li>
        <li>How do I request extra coffee?</li>
        <li>What items are provided in a Gilbert Hotels?</li>
        <li>How do I request a noise machine?</li>
        <li>Do I need to bring my own toiletries?</li>
        <li>How do I find or report a lost item?</li>
        <li>How do I request extra towels?</li>
        <li>How do I request extra sheets?</li>
        <li>How do I request extra pillows?</li>
        <li>Need a place to store your begs?</li>
        <li>What do you offer for special occasions?</li>
        <li>How do I report an issue during my stay?</li>
        <li>Can I receive mail & packages at my Gilbert Hotels? </li>
        <li>I want to talk to you about a recent stay, how do I do that?</li>
        <li>Will my Gilbert Hotels have cable television?</li>
        <li>Our response to Covid-19</li>
        <li>How does Chromecast work?</li>
      </ul>
    </StayingWithUsDiv>
  </AllTopicsDiv>
);

export default StayingWithUs;
