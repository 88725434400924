import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 0px 300px;
`;

export const Heading = styled.h6`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #212f52;
`;

export const Gym = styled.div`
  background: #ffffff;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 15px;
  b {
    font-weight: 700;
  }
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #212f52;
  }
`;

export const Ruler = styled.div`
  hr {
    background: #caccd1;
    border: 1px #caccd1 solid;
  }
`;

export const Clock = styled.div`
  display: flex;
  img {
    padding-right: 23px;
    padding-top: 4px;
  }
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #212f52;
    margin: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

export const Pool = styled.div`
  background: #ffffff;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 15px;
  b {
    font-weight: 700;
  }
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #212f52;
  }
`;

export const TimesDetails = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #212f52;
`;
