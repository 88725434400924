import React from 'react';
import { Col } from 'react-bootstrap';
import { RowWrapper, Header, SubHeader, LinkButton } from './styled';
import Image from './Inverster';

const WorkWithUs: React.FC = () => (
  <RowWrapper className="mb-5">
    <Col xxl={5} xl={5} lg={5} md={12} sm={12}>
      <div className="d-flex h-100 align-items-center">
        <div className="p-4 ">
          <Header>Work with us</Header>
          <SubHeader>
            We hope to become the most admired hospitality brand in the world.
            Help us get there.
          </SubHeader>
          <LinkButton to="/">Browse open positions</LinkButton>
        </div>
      </div>
    </Col>
    <Col xxl={7} xl={7} lg={7} md={12} sm={12} className="p-0">
      <Image />
    </Col>
  </RowWrapper>
);
export default WorkWithUs;
