import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BGWrapper, CardDiv, Wrapper } from './styled';

const BlogDetails = [
  {
    id: 1,
    title: 'TRAVEL',
    text: 'A taste of the world through cocktails',
    date: 'SEPTEMBER 17, 2021',
    link: 'CONTINUE READING',
    to: '/',
  },
  {
    id: 2,
    title: 'CULTURE',
    text: 'Ini Archibong: Artist in Residence',
    date: 'AUGUST 31, 2021',
    link: 'CONTINUE READING',
    to: '/',
  },
  {
    id: 3,
    title: 'NEWS',
    text: 'Traveling for work? Stay with us',
    date: 'JULY 28, 2021',
    link: 'CONTINUE READING',
    to: '/',
  },
];

const BlogPostExpanded: React.FC = () => (
  <BGWrapper>
    <Container>
      <Row>
        {BlogDetails.map(item => (
          <Col lg={4} md={6} sm={12} className="pb-5">
            <CardDiv>
              <Wrapper />
              <Card.Body>
                <Card.Title>{item.title}</Card.Title>
                <Card.Text>{item.text}</Card.Text>
                <Card.Text className="card-date">{item.date}</Card.Text>
                <Card.Link
                  as={Link}
                  to={item.to}
                  className="col d-flex justify-content-center continue-reading"
                >
                  {item.link}
                </Card.Link>
              </Card.Body>
            </CardDiv>
          </Col>
        ))}
      </Row>
    </Container>
  </BGWrapper>
);

export default BlogPostExpanded;
