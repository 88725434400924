import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail, DotTag, Questions } from './styled';

const AboutYou: React.FC = () => (
  <>
    <Row>
      <Questions>3. When We Use Personal Information About You</Questions>
      <Detail>
        <p>
          If you are located in the European Economic Area (“EEA”), we process
          your Personal Information only based on a valid legal ground,
          including when:
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          <b> Consent.</b> You have consented to the use of your Personal
          Information, for example for marketing purposes or to track your
          online activities via cookies and similar technologies.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          <b> Contract.</b> We need your Personal Information to provide you
          with our Services, for example for account registration and management
          or to respond to your inquiries.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          <b> Legal obligation.</b> We have a legal obligation to use your
          Personal Information, for example to comply with tax and accounting
          obligations, or anti-terrorism laws.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          <b> Legitimate interest.</b> We or a third party have a legitimate
          interest in using your Personal Information. In particular, we have a
          legitimate interest in using your Personal Information for product
          development and internal analytics purposes, and otherwise to improve
          the safety, security, and performance of our Services. We only rely on
          our or a third party’s legitimate interests to process your Personal
          Information when these interests are not overridden by your rights and
          interests.
        </p>
      </Detail>
    </Row>
  </>
);

export default AboutYou;
