import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail } from './styled';

const AccountRegistration: React.FC = () => (
  <Row>
    <Detail>
      <p>
        <b>4. Account Registration</b> To access certain features of the
        Service, you may be required to register for an account (collectively,
        <b>“Register”</b>; and such process of Registering,{' '}
        <b>“Registration”</b>) and/or to enter into a membership agreement,
        sublease agreement, extended stay agreement, limited use agreement or
        other type of agreement or order form signed by us and you and made
        under and part of this Agreement (<b>“Order Form”</b>). When you
        Register, you may be required to provide us with some information about
        yourself, such as your name, email address, home address, date of birth,
        and other contact information. You agree that the information you
        provide to us is accurate and that you will keep it accurate and
        up-to-date at all times. When you Register, you may be asked to provide
        a password. You are solely responsible for maintaining the
        confidentiality of your account and password, and you accept
        responsibility for all activities that occur under your account. If you
        believe that your account is no longer secure, then you must immediately
        notify us at reservations@gilberthotels.com.
      </p>
    </Detail>
  </Row>
);

export default AccountRegistration;
