import React from 'react';
import {
  ContainerWrapper,
  Heading,
  IllustrationDiv,
  IllustrationImage,
} from './styled';
import IllustrationIcon from '../../Assets/Images/Icons/IllustrationIcon.svg';

const Illustration: React.FC = () => (
  <ContainerWrapper>
    <IllustrationDiv>
      <Heading className="pt-3">
        Crumbs! You haven&apos;t added any favorites yet.
        <br />
        Tap the heart icon on listings you like to add them to your favorites.
      </Heading>
      <div className="d-flex px-5 pt-4 pb-3">
        <IllustrationImage src={IllustrationIcon} alt="image" />
      </div>
    </IllustrationDiv>
  </ContainerWrapper>
);

export default Illustration;
