import styled from 'styled-components';

export const GrayImageBox = styled.div`
  padding: 60px 0px;
  height: 550px;
  background: #caccd1;
`;

export const Temp = styled.div`
  padding: 60px 0px;
`;
