import React from 'react';
import { Col, Row } from 'react-bootstrap';

import {
  Wrapper,
  SortByWrapper,
  Label,
  LanguageDropDown,
  TextFilterd,
} from './styled';

const filterList = ['Top Rated', 'Low to High', 'High to Low'];

interface ShortByfilterProps {
  searchList: any;
  city: string;
}

const ShortByfilter: React.FC<ShortByfilterProps> = ({
  searchList,
  city,
}: ShortByfilterProps) => (
  <Wrapper>
    <Row className="mb-4">
      <Col xxl={6} xl={6} lg={6} md={6} sm={12} className="mt-5">
        <SortByWrapper>
          <Label>Sort by</Label>
          <LanguageDropDown>
            {filterList?.map((res: string) => (
              <option value={res} key={`filter-${res}`}>
                {res}
              </option>
            ))}
          </LanguageDropDown>
        </SortByWrapper>
      </Col>
      <Col xxl={6} xl={6} lg={6} md={6} sm={12} className="mt-5 align-self-end">
        <TextFilterd className="d-flex">
          {searchList?.length} <p> result(s) for </p> {city}
        </TextFilterd>
      </Col>
    </Row>
  </Wrapper>
);

export default ShortByfilter;
