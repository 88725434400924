import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail, LinkTag } from './styled';

const Miscellaneous: React.FC = () => (
  <Row>
    <Detail>
      <p>
        <b>19. Miscellaneous.</b>
      </p>
      <div style={{ paddingLeft: '30px', marginTop: '20px' }}>
        <p>
          <b>19.1 General Terms..</b> This Agreement, together with the Privacy
          Policy (if applicable) and any other agreements expressly incorporated
          by reference into this Agreement, is the entire and exclusive
          understanding and agreement between you and Gilbert Hotels regarding
          your use of the Service and Properties, and supersedes any previous
          marketing information, representations or agreements of any kind or
          nature between the parties relating to the subject matter hereof and
          whether recorded in writing, or otherwise. You may not assign or
          transfer this Agreement or your rights under this Agreement, in whole
          or in part, by operation of law or otherwise, without our prior
          written consent, which may be withheld if, for example, we reasonably
          determine that the potential assignee or transferee is, or is likely
          to be, a competitor of us, or appears unwilling to or incapable of
          strictly complying with this Agreement. We may assign or transfer this
          Agreement, in whole or in part, at any time without notice or consent.
          The failure to require performance of any provision of this Agreement
          will not affect our right to require performance at any other time
          after that, nor will a waiver by us of any breach or default of this
          Agreement, or any provision of this Agreement, be a waiver of any
          subsequent breach or default or a waiver of the provision itself. The
          waiver by us of any provision of the Agreement will not be deemed to
          be a waiver of any subsequent breach of the same or any other
          provision of the Agreement, nor will any custom or practice which may
          develop between the parties be construed to waive or to lessen our
          right to insist upon your performance of all the provisions of the
          Agreement, or support a claim of detrimental reliance by you. The
          specification in the Agreement of certain acts or omissions as bases
          for removing you from any Property shall not be construed as limiting
          our rights to remove you for any other reason allowed by applicable
          law. Our acceptance of a partial payment of any fees or other amounts
          owed to us pursuant to this Agreement will not constitute a waiver of
          our right to the full amount due, nor will our acceptance of fees or
          other amounts owed to us pursuant to this Agreement paid late ever
          constitute a waiver of our right to terminate this Agreement and
          remove you from any Property for such habitual late payment. Use of
          section headers in this Agreement is for convenience only and will not
          have any impact on the interpretation of any provision. Nothing in
          this Agreement will affect your statutory rights as a consumer.
          Throughout this Agreement the use of the word “including” means
          “including but not limited to”. If any part of this Agreement is held
          to be invalid or unenforceable, the unenforceable part will be given
          effect to the greatest extent possible, and the remaining parts will
          remain in full force and effect.
        </p>
        <p>
          <b>
            19.2 Governing Law and Jurisdiction for Users Outside the UK and EU.
          </b>
          This Agreement is governed by the laws of the State of California
          without regard to conflict of law principles. You and Gilbert Hotels
          submit to the personal and exclusive jurisdiction of the state and
          federal courts located within the County of San Francisco, California
          for resolution of any lawsuit or court proceeding permitted under this
          Agreement. We operate the Service from our offices in California, and
          we make no representation that Materials included in the Service are
          appropriate or available for use in other locations.
        </p>
        <p>
          <b>19.3 Governing Law and Jurisdiction for Users In the UK or EU..</b>{' '}
          This Agreement is governed by the laws of England and Wales. The
          courts of England and Wales will have non-exclusive jurisdiction for
          resolution of any lawsuit or court proceeding permitted under this
          Agreement, which means that as a consumer you may only bring any
          lawsuit or court proceedings against us in a court in your country of
          residence or the courts of England and Wales. If Gilbert Hotels wishes
          to enforce any of its rights against you, we may do so only in the
          courts of your country of residence. In addition, please note that
          disputes may be submitted for online resolution to the European
          Commission Online Dispute Resolution platform.
        </p>
        <p>
          <b>19.4 Third Party Rights.</b> This Agreement is between you and us.
          Except where otherwise provided for in this Agreement or in a Limited
          Use Agreement, no other person shall have any rights to enforce any of
          its terms, whether under the UK Contract (Rights of Third Parties Act)
          1999 or otherwise.
        </p>
        <p>
          <b>19.5 Additional Terms.</b> Your use of the Service and Properties
          is subject to all additional terms, policies, rules, guidelines, or
          instructions applicable to the Service or Properties or certain
          features of the Service or Properties that we may post on, link to
          from, or provide you with in writing (e.g., by email, text message, or
          posted at or provided in the applicable Property) including through
          the Service or applicable Property, including any Order Forms (and all
          exhibits, attachments, and addenda thereto), Confirmations and House
          Rules available at{' '}
          <LinkTag to="/">https://www.gilberthotels.com/house-rules</LinkTag>{' '}
          (the
          <b>“Additional Terms”</b>). All Additional Terms are incorporated by
          this reference into, and made a part of, this Agreement.
        </p>
        <p>
          <b>19.6 Privacy Policy..</b> If you are based outside the UK and EU,
          Your use of the Service and Properties is also subject to our Privacy
          Policy that we post on our website, as it may be updated from time to
          time (our{' '}
          <LinkTag to="/privacy-policy">
            <b>“Privacy Policy”</b>
          </LinkTag>
          ). Please read our Privacy Policy carefully for information relating
          to our collection, use, storage, disclosure of your personal
          information. By accepting this Agreement, you agree to the sharing of
          your personal information in compliance with the Privacy Policy and
          all applicable law, for the purpose of and in connection with
          providing the Services to you.
        </p>
        <p>
          <b>19.7 Consent to Electronic Communications.</b> By using the Service
          and Properties, you acknowledge that the Service includes certain
          electronic communications from us such as SMS text communications and
          as further described in our{' '}
          <LinkTag to="/privacy-policy">“Privacy Policy”</LinkTag> (if
          applicable) and you consent to receiving such electronic
          communications. Please read our{' '}
          <LinkTag to="/privacy-policy">“Privacy Policy”</LinkTag> to learn more
          about our electronic communications practices. You agree that any
          notices, agreements, disclosures, or other communications that we send
          to you electronically will satisfy any legal communication
          requirements, including that those communications be in writing.
          Gilbert Hotels provides concierge support and other services for our
          guests via SMS on an opt in basis. For more information click{' '}
          <LinkTag to="/">here</LinkTag>
        </p>
        <p>
          <b>19.8 Contact Information.</b> You may contact us at 101 15th
          Street, San Francisco, CA 94103, Attention: Office of the General
          Counsel. If you have a question or complaint, you may contact us by
          sending correspondence to that address or by contacting us at the
          contact information located at{' '}
          <LinkTag to="/">https://www.gilberthotels.com/help.</LinkTag> You can
          access a printable version of these Terms of Service by clicking{' '}
          <LinkTag to="/">here.</LinkTag>
        </p>
        <p>
          <b>19.9 Notice to California Residents.</b> If you are a California
          resident, under California Civil Code Section 1789.3, you may contact
          the Complaint Assistance Unit of the Division of Consumer Services of
          the California Department of Consumer Affairs in writing at 1625 N.
          Market Blvd., Suite S-202, Sacramento, California 95834, or by
          telephone at (800) 952-5210 in order to resolve a complaint regarding
          the Service or Properties, or to receive further information regarding
          use of the Service or Properties.
        </p>
        <p>
          <b>19.10 No Support.</b> We are under no obligation to provide support
          for the Service or Properties. In instances where we may offer
          support, the support will be subject to published policies.
        </p>
        <p>
          <b>19.11 Force Majeure.</b> We will not be liable to you or be deemed
          to be in breach of this Agreement by reason of any delay in
          performing, or any failure to perform, any of our obligations, if the
          delay or failure was due to any cause beyond our reasonable control
          including but not limited to severe weather, power, or other utility
          cut-off, burglary, natural disaster, strikes, governmental action,
          terrorism, war, civil unrest, or other similar events of “force
          majeure”.
        </p>
        <p>
          <b>19.12 Attorneys’ Fees and Expenses.</b> If we are required to
          engage legal counsel to enforce the conditions of this Agreement, to
          collect any amounts due, to remove you or your belongings from a
          Property, you shall be responsible for all lawyers’ fees and expenses
          incurred in connection with the foregoing.
        </p>
        <p>
          <b>19.13 International Use.</b> The Service and Properties are
          intended for visitors located within the United States, Canada,
          Mexico, United Arab Emirates, European Union and United Kingdom. We
          make no representation that the Service is appropriate or available
          for use outside of these countries or territories. Access to the
          Service or Properties from or in countries or territories or by
          individuals where or for whom such access is illegal is prohibited.
        </p>
      </div>
    </Detail>
  </Row>
);

export default Miscellaneous;
