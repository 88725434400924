import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  BGWrapper,
  ComponentsContainer as Container,
  ItemBox,
  ItemImage,
  AppLinkImage,
  ItemText,
  ColWrapper,
  ContantHeader,
  SubTitle,
  Title,
} from './Style';
import CustomerServiceIcon from '../../Assets/Images/Icons/CustomerServiceIcon.svg';
import WifiIcon from '../../Assets/Images/Icons/WifiIcon.svg';
import LocationThinLineIcon from '../../Assets/Images/Icons/LocationThinLineIcon.svg';
import CheckoutClockIcon from '../../Assets/Images/Icons/CheckoutClockIcon.svg';
import GooglePlaystoreIcon from '../../Assets/Images/Icons/GooglePlaystoreIcon.svg';
import AppleAppstoreicon from '../../Assets/Images/Icons/AppleAppstoreicon.svg';

const feature = [
  { id: 1, src: CustomerServiceIcon, text: '24/7 In-app customer service' },
  { id: 2, src: WifiIcon, text: 'One-tap WiFi access' },
  { id: 3, src: LocationThinLineIcon, text: 'Neighborhood guides' },
  { id: 4, src: CheckoutClockIcon, text: 'Request late checkout' },
];

const MobileAppLink: React.FC = () => (
  <BGWrapper>
    <Container fluid>
      <Col lg={6} md={9} sm={12}>
        <ContantHeader>
          <Title>
            Everything you need
            <br /> is just a tap away
          </Title>
          <SubTitle>
            From fresh towels to late checkout , out app puts <br />
            you in control. Your stay, your way.
          </SubTitle>
        </ContantHeader>
      </Col>
      <Row className="pt-5">
        <Col sm={12} md={9} lg={6}>
          <Row>
            {feature.map(item => (
              <ItemBox lg={6} md={6} sm={6} key={item.id}>
                <ItemImage src={item.src} />
                <ItemText>{item.text}</ItemText>
              </ItemBox>
            ))}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} lg={6}>
          <Row>
            <Col sm={6} md={6} lg={4}>
              <AppLinkImage src={GooglePlaystoreIcon} />
            </Col>
            <ColWrapper sm={6} md={6} lg={4}>
              <AppLinkImage src={AppleAppstoreicon} />
            </ColWrapper>
          </Row>
        </Col>
      </Row>
    </Container>
  </BGWrapper>
);

export default MobileAppLink;
