import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BasicPost, BGWrapper, BLogDeatils, Wrapper } from './styled';

const BlogExpandedDetail: React.FC = () => (
  <BGWrapper>
    <Container>
      <BasicPost>
        <Row className="justify-content-center">
          <Col lg={6} md={8} sm={12}>
            <h6>DESIGN</h6>
            <p>Make your patio the place to be this summer</p>
            <p className="date">SEPTEMBER 17, 2021</p>
          </Col>
        </Row>
      </BasicPost>
      <Container>
        <BLogDeatils>
          <p>
            Whether you’re traveling or at home, cocktails are a great way to
            experience a bit of the world. We’ve put together a list of some of
            our favorite cocktails from some of our favorite places.
          </p>
          <Row>
            <Col lg={4} md={4} sm={4}>
              <Wrapper />
            </Col>
            <Col lg={8} md={8} sm={8}>
              <Wrapper />
            </Col>
          </Row>
        </BLogDeatils>
      </Container>
    </Container>
  </BGWrapper>
);

export default BlogExpandedDetail;
