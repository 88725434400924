import styled from 'styled-components';
import Modalcontainer from '../../../Common/Modal';

export const Title = styled.p`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #212f52;
`;

export const Description = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212f52;
`;

export const Modal = styled(Modalcontainer)`
  max-width: 530px;
  & .modal-content {
    border-radius: 15px;
  }
`;

export const Footer = styled.div`
  margin: 50px 0 50px;
`;

export const Button = styled.button`
  &.button-submit {
    width: 298px;
    height: 64px;
    background: #212f52;
    border-radius: 5px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    @media (max-width: 425px) {
      width: 350px;
      line-height: 30px;
      margin-bottom: 10px;
    }
    @media (max-width: 375px) {
      width: 300px;
      line-height: 30px;
      margin-bottom: 10px;
    }
    @media (max-width: 320px) {
      width: 265px;
      line-height: 30px;
      margin-bottom: 10px;
    }
  }
  &.button-cancel {
    background: #ffffff;
    border: 2px solid #212f52;
    box-sizing: border-box;
    border-radius: 5px;
    width: 160px;
    height: 64px;
    margin-left: 10px;
    @media (max-width: 425px) {
      width: 330px;
      line-height: 30px;
      margin-bottom: 10px;
    }
    @media (max-width: 375px) {
      width: 250px;
      margin-left: 25px;
    }
    @media (max-width: 320px) {
      width: 180px;
      margin-left: 40px;
    }
  }
`;
