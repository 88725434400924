import styled from 'styled-components';

export const Wrapper = styled.div``;

export const LanguageDropDown = styled.select`
  width: 260px;
  padding: 14px 20px;
  background: #ffffff;
  color: #fff;
  border-radius: 50px;
  width: 170px;
  height: 54px;
  border: 1px solid #caccd1;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #212f52;
  flex: none;
  order: 0;
  flex-grow: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1L7 7L13 1' stroke='#212F52' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 88%;
  background-position-y: 12px;
`;

export const SortByWrapper = styled.div`
  display: flex;
`;

export const Label = styled.p`
  margin: auto;
  padding-right: 20px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.1em;
  color: #212f52;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const TextFilterd = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #212f52;
  justify-content: right;
  @media (max-width: 425px) {
    justify-content: start;
  }
  p {
    font-weight: 400;
    padding: 0px 5px;
  }
`;
