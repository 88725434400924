import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 0px 60px;
  @media (max-width: 425px) {
    padding: 0px 10px;
  }
`;

export const Heading = styled.h6`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 55px;
  color: #212f52;
  @media (max-width: 425pxpx) {
    font-size: 36px;
  }
`;

export const Details = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #212f52;
`;

export const HighlightDiv = styled.div`
  h6 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #212f52;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 20px 0px;
  }
  li {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #212f52;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 5px 20px;
  }
`;

export const HighlightLink = styled(Link)`
  color: #212f52;
  &:hover {
    color: #212f52;
  }
`;
