import React, { useState, useContext } from 'react';
import { Container, Row, Col, FormControl, Spinner } from 'react-bootstrap';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import firebase from 'firebase/compat/app';
import { db } from '../../Config/firebase';
import AuthContext from '../../Contexts/AuthContext';
import {
  SignupModalWrapper,
  CloseIcon,
  ModalTitle,
  ResetPasswordLink,
  LoginButton,
  SocialLoginButton,
  SocialIcon,
  SocialButtonText,
  ModalBottomText,
  OrText,
  EmailSubscribeText,
  EmailSubscribeCheckBox,
  SignupTitleWrapper,
} from './Style';
import CloseIconSvg from '../../Assets/Images/Icons/CloseIcon.svg';
import GoogleIcon from '../../Assets/Images/Icons/GoogleIcon.svg';
import FacebookIcon from '../../Assets/Images/Icons/FacebookIcon.svg';
import LinkedinIcon from '../../Assets/Images/Icons/LinkedinIcon.svg';
import AppleIcon from '../../Assets/Images/Icons/AppleIcon.svg';

interface ModalProps {
  show: boolean;
  onCloseModal: (value: boolean) => void;
}

interface FirstValues {
  email: string;
}

const SignupModal: React.FC<ModalProps> = ({ show, onCloseModal }) => {
  const [state, setStep] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<any>('');

  const authProvider = useContext(AuthContext);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .email('Email is not valid'),
  });

  // formik form validation
  const secondValidationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .email('Email is not valid'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Min 8 character required'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .min(8, 'Min 8 character required')
      .oneOf([Yup.ref('password')], 'passwords must match'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
  });

  // signup with email
  const handleSignup = async (
    formValue: {
      email: string;
      password: string;
      confirmPassword: string;
      firstName: string;
      lastName: string;
    },
    { setErrors }: any,
  ) => {
    try {
      setLoading(true);
      const { email, password, firstName, lastName } = formValue;
      const signupAuth = await authProvider.signup(email, password);
      if (signupAuth.user.email) {
        db.collection('guests')
          .doc(signupAuth.user.uid)
          .set({
            email: signupAuth.user.email,
            first_name: firstName,
            last_name: lastName,
            phone: '',
            picture: '',
            address: {
              apartment: '',
              areas: [],
              city: '',
              coordinates: '',
              country: '',
              display: '',
              number: '',
              postcode: '',
              state: '',
              street: '',
            },
            reminders: {
              email: false,
              sms: false,
            },
            reservation_updates: {
              email: false,
              sms: false,
            },
            updates: false,
            uuid: signupAuth.user.uid,
            verified: false,
          });
        setLoading(false);
        onCloseModal(false);
        toast.success('Register Success');
        setErrorMessage(null);
      }
    } catch (error: any) {
      setLoading(false);
      switch (error.code) {
        case 'auth/user-not-found':
          toast.error(
            'There is no user record corresponding to this identifier.',
          );
          setErrors({
            email: 'There is no user record corresponding to this identifier.',
          });
          break;
        case 'auth/email-already-in-use':
          toast.error(
            'The email address is already in use by another account.',
          );
          setErrors({
            email: 'The email address is already in use by another account.',
          });
          break;
        case 'auth/wrong-password':
          toast.error(
            'The password is invalid or the user does not have a password.',
          );
          setErrors({
            password:
              'The password is invalid or the user does not have a password.',
          });
          break;
        default:
          setErrorMessage(
            'There is no user record corresponding to this identifier.',
          );
          break;
      }
      toast.error(errorMessage);
    }
  };

  // sign up with google
  const signInWithGoogle = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const signUpDetails = await authProvider.signinWithGoogle(provider);
      db.collection('guests')
        .doc(signUpDetails?.user?.uid)
        .set({
          email: signUpDetails?.user?.email,
          first_name: signUpDetails?.user.displayName,
          last_name: '',
          phone: '',
          picture: '',
          address: {
            apartment: '',
            areas: [],
            city: '',
            coordinates: '',
            country: '',
            display: '',
            number: '',
            postcode: '',
            state: '',
            street: '',
          },
          reminders: {
            email: false,
            sms: false,
          },
          reservation_updates: {
            email: false,
            sms: false,
          },
          updates: false,
          uuid: signUpDetails.user.uid,
          verified: false,
        });
      toast.success('SignUp Successfully');
      onCloseModal(false);
    } catch (error: any) {
      const errorcode = error.code;
      toast.error(errorcode);
    }
  };

  const getSignInMethodsForEmail = async () => {
    try {
      const provider = await authProvider.fetchSignInMethodsForEmail(value);
      if (provider.length === 0) {
        setStep(2);
      }
      if (provider.includes('password')) {
        toast.error('Already user register');
      }
    } catch (error: any) {
      switch (error.code) {
        case 'auth/invalid-email':
          setErrorMessage('Email is required');
          break;
        default:
          setErrorMessage('Email is required');
          break;
      }
    }
  };

  // sign up with facebook
  const signInWithFacebook = async () => {
    try {
      const provider = new firebase.auth.FacebookAuthProvider();
      const signUpdetails = await authProvider.signInWithFacebook(provider);
      db.collection('guests')
        .doc(signUpdetails?.user?.uid)
        .set({
          email: signUpdetails?.user?.email,
          first_name: signUpdetails?.user?.displayName,
          last_name: '',
          phone: '',
          picture: signUpdetails?.user?.photoURL,
          address: {
            apartment: '',
            areas: [],
            city: '',
            coordinates: '',
            country: '',
            display: '',
            number: '',
            postcode: '',
            state: '',
            street: '',
          },
          reminders: {
            email: false,
            sms: false,
          },
          reservation_updates: {
            email: false,
            sms: false,
          },
          updates: false,
          uuid: signUpdetails.user.uid,
          verified: false,
        });
      toast.dark('SignUp Successfully');
      onCloseModal(false);
    } catch (error: any) {
      const errorcode = error.code;
      toast.error(errorcode);
    }
  };

  return (
    <>
      <SignupModalWrapper
        show={show}
        onHide={() => {
          onCloseModal(false);
        }}
        size="md"
        centered
        dialogClassName="signup-dialog"
      >
        <CloseIcon src={CloseIconSvg} onClick={() => onCloseModal(false)} />
        {state === 1 ? (
          <>
            <SignupTitleWrapper>
              <ModalTitle>Sign up for access to member pricing</ModalTitle>
            </SignupTitleWrapper>
            <Formik
              initialValues={{ email: '' }}
              validationSchema={validationSchema}
              onSubmit={(values: FirstValues, { setErrors }: any) => {
                setErrors();
              }}
            >
              {({
                touched,
                errors,
                handleSubmit,
                handleChange,
                handleBlur,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div style={{ margin: '50px 0px' }}>
                    <FormControl
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      onChange={e => {
                        handleChange(e);
                        setValue(e.target.value);
                        setErrorMessage(null);
                      }}
                      onBlur={handleBlur}
                      className={`input m-0 p-0 pb-3 ${
                        touched.email && errors?.email ? 'is-invalid' : ''
                      }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="email"
                      className="invalid-feedback"
                    />
                  </div>
                  <LoginButton onClick={() => getSignInMethodsForEmail()}>
                    Continue
                  </LoginButton>
                  <OrText>or continue with</OrText>
                </Form>
              )}
            </Formik>

            <Row>
              <Col lg={6} md={6} sm={12}>
                <SocialLoginButton onClick={signInWithGoogle}>
                  <SocialIcon src={GoogleIcon} />
                  <SocialButtonText>Google</SocialButtonText>
                </SocialLoginButton>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <SocialLoginButton>
                  <SocialIcon src={LinkedinIcon} />
                  <SocialButtonText>LinkedIn</SocialButtonText>
                </SocialLoginButton>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <SocialLoginButton onClick={signInWithFacebook}>
                  <SocialIcon src={FacebookIcon} />
                  <SocialButtonText>Facebook</SocialButtonText>
                </SocialLoginButton>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <SocialLoginButton>
                  <SocialIcon src={AppleIcon} />
                  <SocialButtonText>Apple</SocialButtonText>
                </SocialLoginButton>
              </Col>
            </Row>
            <div style={{ margin: '50px 31px' }}>
              <ModalBottomText>
                By providing your email address, you agree to our{' '}
                <ResetPasswordLink to="/privacy-policy" className="mx-1">
                  Privacy Policy
                </ResetPasswordLink>{' '}
                and{' '}
                <ResetPasswordLink to="/terms-of-service" className="mx-1">
                  Terms of Service
                </ResetPasswordLink>
              </ModalBottomText>
            </div>
          </>
        ) : (
          <>
            <ModalTitle>
              Looks like you&apos;re new Please create an account
            </ModalTitle>
            <Container>
              <Formik
                initialValues={{
                  email: value,
                  password: '',
                  confirmPassword: '',
                  firstName: '',
                  lastName: '',
                }}
                enableReinitialize
                validationSchema={secondValidationSchema}
                onSubmit={handleSignup}
              >
                {({
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="mb-5">
                      <FormControl
                        name="email"
                        type="email"
                        value={values.email}
                        placeholder="Email Address"
                        className={`input ${
                          touched.email && errors?.email ? 'is-invalid' : ''
                        }`}
                        onChange={event => {
                          handleChange(event);
                          setErrorMessage(null);
                        }}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        component="div"
                        name="email"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="mb-5">
                      <FormControl
                        type="password"
                        name="password"
                        placeholder="Password"
                        className={`input ${
                          touched.password && errors?.password
                            ? 'is-invalid'
                            : ''
                        }`}
                        onChange={event => {
                          handleChange(event);
                          setErrorMessage(null);
                        }}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        component="div"
                        name="password"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-5">
                      <FormControl
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        className={`input ${
                          touched.confirmPassword && errors?.confirmPassword
                            ? 'is-invalid'
                            : ''
                        }`}
                        onChange={event => {
                          handleChange(event);
                          setErrorMessage(null);
                        }}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        component="div"
                        name="confirmPassword"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-5">
                      <FormControl
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        className={`input ${
                          touched.firstName && errors?.firstName
                            ? 'is-invalid'
                            : ''
                        }`}
                        onChange={event => {
                          handleChange(event);
                          setErrorMessage(null);
                        }}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        component="div"
                        name="firstName"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-5">
                      <FormControl
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                        className={`input ${
                          touched.lastName && errors?.lastName
                            ? 'is-invalid'
                            : ''
                        }`}
                        onChange={event => {
                          handleChange(event);
                          setErrorMessage(null);
                        }}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        component="div"
                        name="lastName"
                        className="invalid-feedback"
                      />
                    </div>
                    <EmailSubscribeCheckBox className="d-flex mt-2">
                      <input type="checkbox" className="form-check-input" />
                      <EmailSubscribeText className="label">
                        Receive occasional news about Gilbert Hotels, new
                        locations, and special deals.
                      </EmailSubscribeText>
                    </EmailSubscribeCheckBox>
                    {!loading ? (
                      <LoginButton disabled={isSubmitting}>
                        Continue
                      </LoginButton>
                    ) : (
                      <div className="text-center">
                        <Spinner animation="border" variant="warning" />
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
              <div className="m-4">
                <ModalBottomText>
                  By providing your email address, you agree to our{' '}
                  <ResetPasswordLink to="/" className="mx-1">
                    Privacy Policy
                  </ResetPasswordLink>
                  and
                  <ResetPasswordLink to="/" className="mx-1">
                    Terms of Service
                  </ResetPasswordLink>
                </ModalBottomText>
              </div>
            </Container>
          </>
        )}
      </SignupModalWrapper>
    </>
  );
};

export default SignupModal;
