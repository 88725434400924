import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  BGWrapper,
  Details,
  Heading,
  HighlightDiv,
  HighlightLink,
} from './styled';

const ResponsibleBusinessHighlight: React.FC = () => (
  <Container>
    <BGWrapper>
      <Container>
        <Row>
          <Col lg={7} md={12} sm={12} className="pt-5">
            <Heading>Responsible business highlights:</Heading>
            <Row>
              <Col lg={7} md={12} sm={12}>
                <Details>
                  First steps toward our commitment to people, place and planet
                </Details>
              </Col>
            </Row>
            <HighlightDiv>
              <h6>People</h6>
              <ul>
                <li>
                  {' '}
                  <HighlightLink to="/del-statement">
                    DEI statement
                  </HighlightLink>{' '}
                </li>
                <li>
                  Launched DEI initiative focused on employee & guest policies
                </li>
                <li>
                  Established a voting policy granting time-off to engage in
                  local US elections
                </li>
                <li>
                  Initiated Employee Resource Groups aka Gilbert Hotels Circles
                  (i.e. #Womxn, #blackout & #LGBTQIA+)
                </li>
                <li>
                  Instituted Volunteer Time Off (VTO) as a Gilbert Hotels
                  employee benefit
                </li>
                <li>
                  Updated{' '}
                  <HighlightLink to="/">Data Privacy Policy</HighlightLink>{' '}
                </li>
                <li>
                  Adopted a{' '}
                  <HighlightLink to="/">Supplier Code of Conduct</HighlightLink>{' '}
                  in alignment with international standards
                </li>
              </ul>
            </HighlightDiv>
            <HighlightDiv>
              <h6>Place</h6>
              <ul>
                <li>
                  Launched Gilbert Hotels’s first CSR public-private partnership
                  with{' '}
                  <HighlightLink to="/">Lyft & Off-Their-Plate</HighlightLink>{' '}
                </li>
                <li>
                  Provided Gilbert Hotels rooms to those in need - healthcare
                  workers, disaster relief, patients seeking care
                </li>
                <li>Donated $500K in furniture to charities globally</li>

                <li>
                  Piloted partnership with local artists in several Gilbert
                  Hotels cities
                </li>
                <li>
                  A growing number of buildings in our portfolio have historic,
                  adaptive reuse or sustainability qualities
                </li>
              </ul>
            </HighlightDiv>
            <HighlightDiv>
              <h6>Planet</h6>
              <ul>
                <li>Developing a decarbonization roadmap</li>
                <li>
                  Establishing a waste minimization and mitigation strategy
                </li>
                <li>Defining a plan to conserve water</li>

                <li>
                  Considering appropriate sustainability reporting frameworks
                  i.e. SASB
                </li>
              </ul>
            </HighlightDiv>
          </Col>
        </Row>
      </Container>
    </BGWrapper>
  </Container>
);

export default ResponsibleBusinessHighlight;
