import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import Container from '../../styled';
import LeituraFont from '../../Assets/Fonts/LeituraDisplayRoman.otf';

interface HeroBGProps {
  src: string;
}

export const HeroBG = styled.div<HeroBGProps>`
  background-image: url(${p => p.src});
  background-size: cover;
  width: 100%;
  @media (max-width: 1024px) {
    height: 675px;
  }

  @media (max-width: 768px) {
    height: 600px;
  }
`;

export const Herocontainer = styled(Container)``;

export const HeroBGOverlay = styled.div`
  height: inherit;
  background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 46.77%);
`;

export const HeroContant = styled(Row)`
  padding-top: 196px;
  position: relative;

  @media (max-width: 1024px) {
    padding-top: 150px;
  }

  @media (max-width: 768px) {
    padding-top: 100px;
  }
`;

export const HeroTitle = styled.h1`
  @font-face {
    font-family: 'Leitura Display';
    src: local('Leitura Display'), local('LeituraDisplay'),
      url(${LeituraFont}) format('truetype');
  }
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 82px;
  line-height: 89px;
  color: #212f52;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px 20px;
  max-width: 477px;
  @media (max-width: 425px) {
    font-size: 50px;
    line-height: 60px;
  }

  @media (max-width: 375px) {
    font-size: 40px;
    line-height: 50px;
  }

  @media (max-width: 320px) {
    font-size: 36px;
    line-height: 44px;
  }
`;

export const HeroText = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #6a737b;
  margin: 0;
  width: 317px;
  @media (max-width: 320px) {
    font-size: 18px;
    width: 280px;
  }
`;

export const HeroWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
`;

export const HeroPlaceDetail = styled.div<HeroBGProps>`
  display: flex;
  align-items: center;
  padding-top: 122px;

  @media (max-width: 1024px) {
    padding-top: 100px;
  }

  @media (max-width: 768px) {
    padding-top: 70px;
  }

  @media (max-width: 425px) {
    padding-top: 50px;
  }
  :before {
    display: block;
    content: ' ';
    background-image: url(${p => p.src});
    background-size: 25px 25px;
    height: 25px;
    width: 25px;
  }

  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #212f52;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 10px;
    @media (max-width: 320px) {
      margin: 0px 5px;
    }
    :first-child {
      padding-right: 20px;
      border-right: #212f52 1px solid;
      @media (max-width: 320px) {
        padding-right: 10px;
      }
    }
  }
`;
