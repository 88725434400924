import React from 'react';
import { Container } from 'react-bootstrap';
import { RowWrapper, Header, SubHeader, LeaderWrapper } from './styled';
import Leader from './Leader';

const Ourleadership: React.FC = () => (
  <RowWrapper>
    <Container>
      <Header className="pt-3 text-center">Our leadership</Header>
      <SubHeader className="text-center">Our leadership</SubHeader>
      <LeaderWrapper>
        <Leader name="Francis Davidson" post="Co-founder & CEO" />
        <Leader name="Francis Davidson" post="Co-founder & CEO" />
        <Leader name="Francis Davidson" post="Co-founder & CEO" />
        <Leader name="Francis Davidson" post="Co-founder & CEO" />
        <Leader name="Francis Davidson" post="Co-founder & CEO" />
        <Leader name="Francis Davidson" post="Co-founder & CEO" />
        <Leader name="Francis Davidson" post="Co-founder & CEO" />
        <Leader name="Francis Davidson" post="Co-founder & CEO" />
        <Leader name="Francis Davidson" post="Co-founder & CEO" />
        <Leader name="Francis Davidson" post="Co-founder & CEO" />
        <Leader name="Francis Davidson" post="Co-founder & CEO" />
        <Leader name="Francis Davidson" post="Co-founder & CEO" />
        <Leader name="Francis Davidson" post="Co-founder & CEO" />
      </LeaderWrapper>
    </Container>
  </RowWrapper>
);
export default Ourleadership;
