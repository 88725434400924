import React from 'react';
import { Container } from 'react-bootstrap';
import BlogNavbar from '../../Common/BlogNavbar';
import BlogExpandedDetail from '../BlogExpandedDetail';
import BlogPostExpanded from '../BlogPostExpanded';
import LeaveReply from '../LeaveReply';
import PimmsCup from '../PimmsCup';
import { Blog, BlogPostDiv } from './styled';

const BlogExpandedContainer: React.FC = () => (
  <>
    <Container fluid>
      <Blog className="justify-content-center">
        <h6>Journal</h6>
      </Blog>
    </Container>
    <BlogNavbar />
    <Container>
      <BlogExpandedDetail />
      <PimmsCup />
      <LeaveReply />
    </Container>
    <BlogPostDiv>
      <Container>
        <BlogPostExpanded />
      </Container>
    </BlogPostDiv>
  </>
);

export default BlogExpandedContainer;
