import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BGWrapper, Commitment, Heading } from './styled';

const commitment = [
  {
    id: 1,
    title: 'People',
    text: 'Our commitment to the core of our business; our employees & guests.We are proud that the Gilbert Hotels community values every person by prioritizing diversity, belonging, growth, safety, mental and physical health.',
  },
  {
    id: 2,
    title: 'Place',
    text: 'Our commitment to the communities Gilbert Hotels calls home. Through local engagement we commit to supporting our communities.',
  },
  {
    id: 1,
    title: 'Planet',
    text: 'Our commitment to the environment and minimizing our footprint. At Gilbert Hotels we recognize that our choices have an impact. We strive to preserve the Earth’s natural systems through a continuous drive towards sustainability.',
  },
];

const OurGrowingCommitment: React.FC = () => (
  <Container>
    <BGWrapper>
      <Container>
        <Row>
          <Col lg={4} md={6} sm={12} className="pt-5">
            <Heading>Our growing commitments to People, Place & Planet</Heading>
          </Col>
        </Row>
        <Row>
          {commitment.map(item => (
            <Col lg={4} md={6} sm={12} key={`${item?.title}`}>
              <Commitment>
                <h6>{item.title}</h6>
                <p>{item.text}</p>
              </Commitment>
            </Col>
          ))}
        </Row>
      </Container>
    </BGWrapper>
  </Container>
);

export default OurGrowingCommitment;
