import React from 'react';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { DateInput } from './styled';
import CalanderIcon from '../../../Assets/Images/Icons/CalanderIcon.svg';
import DateArrowIcon from '../../../Assets/Images/Icons/DateArrowIcon.svg';

interface BookingDateSelectorProps {
  reservation: any;
}

const BookingDateSelector: React.FC<BookingDateSelectorProps> = ({
  reservation,
}: BookingDateSelectorProps) => (
  <>
    <div className="d-flex">
      <img src={CalanderIcon} alt="calander" className="date-picker-icon" />
      <Row className="date-item-container m-auto">
        <div className="d-flex">
          <Col className="p-0" lg={5} md={5} sm={5} key={reservation?.[0]?.id}>
            <DateInput
              placeholder="Check In"
              value={moment(reservation?.checkInDate).format('ddd, MMMM DD')}
              disabled
            />
          </Col>
          <Col className="p-0" lg={2} md={2} sm={2}>
            <img src={DateArrowIcon} alt="arrow" />
          </Col>
          <Col className="p-0" lg={5} md={5} sm={5}>
            <DateInput
              placeholder="Check Out"
              value={moment(reservation?.checkOutDate).format('ddd, MMMM DD')}
              disabled
            />
          </Col>
        </div>
      </Row>
    </div>
  </>
);
export default BookingDateSelector;
