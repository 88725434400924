import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail, LinkTag } from './styled';

const DisputeResolutionandArbitration: React.FC = () => (
  <Row>
    <Detail>
      <p>
        <b>18. Dispute Resolution and Arbitration.</b>
      </p>
      <div style={{ paddingLeft: '30px', marginTop: '20px' }}>
        <p>
          <b>18.1 Application of this Section 17.</b> This Section 17 shall only
          apply if your country of residence is outside of the UK and the EU.
        </p>
        <p>
          <b>18.2 Generally.</b> In the interest of resolving disputes between
          you and Gilbert Hotels in the most expedient and cost effective manner
          you and Gilbert Hotels agree that, except as described in Section
          17.3, every dispute arising in connection with this Agreement will be
          resolved by binding arbitration. Arbitration is less formal than a
          lawsuit in court. Arbitration uses a neutral arbitrator instead of a
          judge or jury, may allow for more limited discovery than in court, and
          can be subject to very limited review by courts. Arbitrators can award
          the same damages and relief that a court can award. This agreement to
          arbitrate disputes includes all claims arising out of or relating to
          any aspect of this Agreement, whether based in contract, tort,
          statute, fraud, misrepresentation, or any other legal theory, and
          regardless of whether a claim arises during or after the termination
          of this Agreement. YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO
          THIS AGREEMENT, YOU AND GILBERT HOTELS ARE EACH WAIVING THE RIGHT TO A
          TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.
        </p>
        <p>
          <b>18.3 Exceptions.</b> Despite the provisions of Section 17.2,
          nothing in this Agreement will be deemed to waive, preclude, or
          otherwise limit the right of either party to: (a) bring an individual
          action in small claims court; (b) pursue an enforcement action through
          the applicable federal, state, or local agency if that action is
          available; (c) seek injunctive relief in a court of law in aid of
          arbitration; or (d) to file suit in a court of law to address an
          intellectual property infringement claim.
        </p>
        <p>
          <b>18.4 Arbitrator.</b> Any arbitration between you and Gilbert Hotels
          will be settled under the Federal Arbitration Act and administered by
          the American Arbitration Association (<b>“AAA”</b>) under its Consumer
          Arbitration Rules (collectively, <b>“AAA Rules”</b>) as modified by
          this Agreement. The AAA Rules and filing forms are available online at
          www.adr.org, by calling the AAA at{' '}
          <LinkTag to="/">1-800-778-7879,</LinkTag> or by contacting Gilbert
          Hotels. The arbitrator shall have exclusive authority to resolve any
          dispute relating to the interpretation, applicability, or
          enforceability of this binding arbitration agreement.
        </p>
        <p>
          <b>18.5 Notice of Arbitration; Process.</b> A party who intends to
          seek arbitration must first send a written notice of the dispute to
          the other party by certified U.S. Mail or by Federal Express
          (signature required) or, only if that other party has not provided a
          current physical address, then by email (
          <b>“Notice of Arbitration”</b>). Gilbert Hotels’s address for Notice
          is: Gilbert Hotels Holdings Inc., 101 15th Street, San Francisco, CA
          94103, Attention: Office of the General Counsel. The Notice of
          Arbitration must: (a) describe the nature and basis of the claim or
          dispute; and (b) set forth the specific relief sought (<b>“Demand”</b>
          ). The parties will make good faith efforts to resolve the claim
          directly, but if the parties do not reach an agreement to do so within
          30 days after the Notice of Arbitration is received, you or Gilbert
          Hotels may commence an arbitration proceeding. All arbitration
          proceedings between the parties will be confidential unless otherwise
          agreed by the parties in writing. During the arbitration, the amount
          of any settlement offer made by you or Gilbert Hotels must not be
          disclosed to the arbitrator until after the arbitrator makes a final
          decision and award, if any. If the arbitrator awards you an amount
          higher than the last written settlement amount offered by Gilbert
          Hotels in settlement of the dispute prior to the award, Gilbert Hotels
          will pay to you the higher of: (i) the amount awarded by the
          arbitrator; or (ii) $10,000.
        </p>
        <p>
          <b>18.6 Fees.</b> If you commence arbitration in accordance with this
          Agreement, Gilbert Hotels will reimburse you for your payment of the
          filing fee, unless your claim is for more than $10,000, in which case
          the payment of any fees will be decided by the AAA Rules. Any
          arbitration hearing will take place at a location to be agreed upon in
          the County of San Francisco, California, but if the claim is for
          $10,000 or less, you may choose whether the arbitration will be
          conducted: (a) solely on the basis of documents submitted to the
          arbitrator; (b) through a non-appearance based telephone hearing; or
          (c) by an in-person hearing as established by the AAA Rules in the
          county (or parish) of your billing address. If the arbitrator finds
          that either the substance of your claim or the relief sought in the
          Demand is frivolous or brought for an improper purpose (as measured by
          the standards set forth in Federal Rule of Civil Procedure 11(b)),
          then the payment of all fees will be governed by the AAA Rules. In
          that case, you agree to reimburse Gilbert Hotels for all monies
          previously disbursed by it that are otherwise your obligation to pay
          under the AAA Rules. Regardless of the manner in which the arbitration
          is conducted, the arbitrator must issue a reasoned written decision
          sufficient to explain the essential findings and conclusions on which
          the decision and award, if any, are based. The arbitrator may make
          rulings and resolve disputes as to the payment and reimbursement of
          fees or expenses at any time during the proceeding and upon request
          from either party made within 14 days of the arbitrator’s ruling on
          the merits.
        </p>
        <p>
          <b>18.7 No Class Actions.</b> YOU AND GILBERT HOTELS AGREE THAT EACH
          MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL
          CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS
          OR REPRESENTATIVE PROCEEDING. Further, unless both you and Gilbert
          Hotels agree otherwise, the arbitrator may not consolidate more than
          one person’s claims, and may not otherwise preside over any form of a
          representative or class proceeding.
        </p>
        <p>
          <b>18.8 Modifications to this Arbitration Provision.</b>
        </p>
        <div style={{ paddingLeft: '30px' }}>
          <p>
            <b>
              18.8.1 Modifications to this Arbitration Provision for STA/Hotel
              Reservations.
            </b>{' '}
            If Gilbert Hotels makes any future change to this arbitration
            provision, other than a change to Gilbert Hotels’s address for
            Notice of Arbitration, you may reject the change by sending us
            written notice within 30 days of the change to Gilbert Hotels’s
            address for Notice of Arbitration, in which case your account with
            Gilbert Hotels and this Agreement will be immediately terminated and
            this arbitration provision, as in effect immediately prior to the
            changes you rejected, will survive.
          </p>
          <p>
            <b>
              18.8.2 Modifications to this Arbitration Provision for Extended
              Stay Reservations.{' '}
            </b>{' '}
            If Gilbert Hotels makes any future change to this arbitration
            provision, other than a change to Gilbert Hotels’s address for
            Notice of Arbitration, you may reject the change by sending us
            written notice within 30 days of the change to Gilbert Hotels’s
            address for Notice of Arbitration, in which case your account with
            Gilbert Hotels will be immediately terminated (but this Agreement as
            applicable to Extended Stay Reservations, including any applicable
            Extended Stay Agreement, will not terminate except in accordance
            with such Extended Stay Agreement) and this arbitration provision,
            as in effect immediately prior to the changes you rejected, will
            survive.
          </p>
          <p>
            <b>
              18.8.3 Modifications to this Arbitration Provision for
              Subscription Reservations.
            </b>{' '}
            If Gilbert Hotels makes any future change to this arbitration
            provision, other than a change to Gilbert Hotels’s address for
            Notice of Arbitration, you may reject the change by sending us
            written notice within 30 days of the change to Gilbert Hotels’s
            address for Notice of Arbitration, in which case your account with
            Gilbert Hotels will be immediately terminated (but this Agreement as
            applicable to Subscription Reservations, including any applicable
            Membership Agreement, will not terminate except in accordance with
            such Membership Agreement) and this arbitration provision, as in
            effect immediately prior to the changes you rejected, will survive.
          </p>
        </div>
        <p>
          <b>18.9 Enforceability.</b> If any portion of this Section 17 is found
          to be unenforceable, then the entirety of this Section 17 (except this
          Section 17.9) will be null and void and, in that case, the parties
          agree that the exclusive jurisdiction and venue described in Sections
          18.2 or 18.3, or in any Order Form, as applicable, will govern any
          action arising out of or related to this Agreement.
        </p>
      </div>
    </Detail>
  </Row>
);

export default DisputeResolutionandArbitration;
