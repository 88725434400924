import React from 'react';
import { Details, DotTag, Heading, LinkTag } from './styled';

const CollectAutomatically: React.FC = () => (
  <>
    <Heading>Information We Collect Automatically.</Heading>
    <Details>
      <p>
        <DotTag className="px-1">&bull;</DotTag>
        <b> Online usage data.</b> We may automatically collect the following
        information about your use of our Services through cookies, web beacons,
        and similar technologies: your domain name; your browser type; operating
        system type, name, and version; page views; links you click; your IP
        address; location information; the length of time you visit or use our
        Services; referring URL; access date and time; mobile device ID;
        language information; device name and model; and other information about
        your activities within the Services and communications that we send you.
        Please see the section “Cookies and Other Tracking Mechanisms” below for
        more information. Where required under applicable law, we will only
        perform such data collection with your consent.
      </p>
      <p>
        <DotTag className="px-1">&bull;</DotTag>
        <b> Property usage data.</b> We automatically collect your Gilbert
        Hotels stay history (e.g., to identify a return guest). We may also
        automatically collect information regarding the use of an Accommodation
        (e.g., when a door was unlocked). We also deploy certain types of
        sensors in our Accommodations to help us provide a great experience for
        all our guests and to enforce our{' '}
        <LinkTag to="/"> House Rules,</LinkTag> such as noise detection systems
        (which only registers decibel levels, not conversations) and smoking
        detection devices. We may also use or have access to security cameras in
        certain common areas of buildings housing our Accommodations (e.g., in
        lobbies or monitoring public entrances). Where required under applicable
        law, we will only collect property usage data with your consent.
      </p>
      <p>
        <DotTag className="px-1">&bull;</DotTag>
        <b> Wi-Fi usage data.</b> Gilbert Hotels manages the guest W-Fi network
        on some of its properties. If you connect to a Gilbert Hotels-managed
        guest Wi-Fi network offered on our premises, we temporarily log certain
        information about your connection necessary to provide and secure our
        Wi-Fi network, such as the IP address and MAC address of the device used
        to connect to our network, as well as the IP address of the destination
        to which the device is connecting. We do not access the content of your
        electronic communications when using our guest Wi-Fi network.
      </p>
    </Details>
  </>
);

export default CollectAutomatically;
