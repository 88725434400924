import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-bottom: 50px;
  padding-top: 30px;
  .bold {
    font-weight: 700;
  }
`;

export const BillItem = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #212f52;
  border-bottom: 2px #caccd1 solid;
`;

export const BillItemText = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 50px;
  color: #212f52;
  margin: 0px;
`;

export const BillItemAmount = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  text-align: right;
  color: #212f52;
  margin: 0px;
`;

export const AddPromo = styled.p`
  cursor: pointer;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #6a737b;
  text-align: center;
  margin: 0px;
`;

export const Bookbutton = styled.button`
  border: none;
  width: 100%;
  height: 65px;
  left: 0px;
  top: 276px;
  background: #dca73a;
  border-radius: 33px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 20px 0px;
  font-size: 20px;
  line-height: 23px;
`;

export const GrayText = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #9f9fa3;
`;
