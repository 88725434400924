import React from 'react';
import {
  BGWrapper,
  Descripation,
  FirstWrapper,
  MainDiv,
  SecondInnerMainDiv,
  SecondWrapper,
  Title,
  Wrapper,
} from './styled';

interface EntranceProps {
  element: any;
}

const Entrance: React.FC<EntranceProps> = ({ element }: EntranceProps) => (
  <BGWrapper>
    <MainDiv>
      <FirstWrapper>
        <Title>{element?.title}</Title>
        <Descripation>
          {element?.room_feature?.map((item: any, i: number) =>
            i === 0 ? (
              <span key={`item${item?.id}`}>{item?.text}</span>
            ) : (
              <li key={`item${item?.id}`}>{item?.text}</li>
            ),
          )}
        </Descripation>
      </FirstWrapper>
      <SecondWrapper>
        <SecondInnerMainDiv>
          <div style={{ width: '50%', padding: '5px' }}>
            <Wrapper
              style={{
                backgroundImage: `url(${element?.photos[0]?.original})`,
              }}
            />
          </div>
          <div style={{ width: '50%', padding: '5px' }}>
            <Wrapper
              style={{
                backgroundImage: `url(${element?.photos[1]?.original})`,
              }}
            />
          </div>
        </SecondInnerMainDiv>
      </SecondWrapper>
    </MainDiv>
  </BGWrapper>
);

export default Entrance;
