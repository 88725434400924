import styled from 'styled-components';

import { SearchIcon } from '../../Icons';

export const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Search = styled(SearchIcon)``;

export const SearchInputBar = styled.input`
  padding-left: 30px;
  border: 0;
  outline: 0;
  width: 100%;
`;

export const SelectCityBox = styled.div`
  width: 100%;
  #react-select-3-placeholder {
    color: #212f52;
  }
  #react-select-3-option {
    cursor: pointer;
  }
  #react-select-3-MenuList {
    cursor: pointer;
  }
`;
