import styled from 'styled-components';
import Modalcontainer from '../../../Common/Modal';

export const Modal = styled(Modalcontainer)`
  & .modal-content {
    border-radius: 15px;
    height: 100%;
    min-height: 320px;
  }
`;

export const ModalTitle = styled.h2`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #212f52;
`;

export const ForgetPasswordButton = styled.button`
  width: 100%;
  background: #dca73a;
  border: none;
  border-radius: 33px;
  color: #212f52;
  padding: 10px 0px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  outline: none !important;
  box-shadow: none;
  &:hover {
    color: #212f52;
    background: #dca73a;
    outline: none !important;
    box-shadow: none;
  }
  &:focus {
    color: #212f52;
    background: #dca73a;
    outline: none !important;
    box-shadow: none;
  }
`;
