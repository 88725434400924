import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { DateRange } from 'react-day-picker';
import { Elements } from '@stripe/react-stripe-js';
import firebase from 'firebase/compat/app';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { db, functions } from '../../Config/firebase';
import {
  Box,
  Title,
  Content,
  Label,
  Notes,
  Button,
  DatePickerWrapper,
  BackLink,
  LinkWrapper,
  DateRangePicker,
  DateInput,
  DateRangeWrapper,
  BillItem,
  BillItemText,
  BillItemAmount,
  DatePicker,
} from './styled';

import CalanderIcon from '../../Assets/Images/Icons/CalanderIcon.svg';
import DoubleLeftArrows from '../../Assets/Images/Icons/DoubleLeftArrows.svg';
import BlueArrow from '../../Assets/Images/Icons/BlueArrow.svg';
import AuthContext from '../../Contexts/AuthContext';
import PaymentModal from './PaymentModal';

const stripePromise = loadStripe(
  'pk_test_51KpPUxDLkhxgaBQSErQOPF7qLangEpR7zGwIBDSWEsqbtNXuBHIJ6hWo2c1xOTvGWXXeUWjkg5Jv27Ioe7jkpyQb00YVNihGbX',
);

const EditCheckInOutDateForm = (): JSX.Element => {
  const history = useHistory();
  const { id }: any = useParams();
  const authProvider = useContext(AuthContext);
  const currentUser = authProvider?.basicUserDetail;
  const [isShow, setIsShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [changeLoading, setChangeLoading] = useState<boolean>(false);
  const [priceLoading, setPriceLoading] = useState<boolean>(false);
  const [fromValue, setFromValue] = useState<string | null>('Check In');
  const [toValue, setToValue] = useState<string | null>('Check Out');
  const [disableDatesData, setDisableDatesData] = useState<any>();
  const [selectedRange, setSelectedRange] = useState<DateRange>();
  const [buttonText, setButtonText] = useState(false);
  const [myStay, setMyStay] = useState<any>();
  const [unit, setUnit] = useState<any>();
  const [paymentIntent, setPaymentIntent] = useState<any>();
  const [price, setPrice] = useState<any>({});
  const [show, setShow] = useState<boolean>(false);
  const wrapperRef = useRef(null);
  const callableGetChangeDateQuote = functions.httpsCallable(
    'ghotels-getChangeDateQuote',
  );
  const callablePaymentRefund = functions.httpsCallable(
    'ghotels-paymentRefund',
  );
  const callableChangeDatePaymentIntent = functions.httpsCallable(
    'ghotels-changeDatePaymentIntent',
  );

  useEffect(() => {
    const getReservationData = async () => {
      setLoading(true);
      const reservaionRef = await db.collection('reservations').doc(id).get();
      setFromValue(reservaionRef?.data()?.start_date);
      setToValue(reservaionRef?.data()?.end_date);
      if (reservaionRef?.data()?.unit_id) {
        const calendarRef = await db
          .collection('calendar')
          .doc(`${reservaionRef?.data()?.unit_id}_CURRENT`)
          .get();
        const daysRef = Object.values(calendarRef?.data()?.days);
        const tempData: any = [];
        daysRef.map((item: any) => {
          if (
            item.reservation.reservation_id &&
            item.date >= moment().format('YYYY-MM-DD') &&
            item.reservation.reservation_id !== reservaionRef?.id
          ) {
            tempData.push(item);
          }
          return tempData;
        });
        const reservationDatesBooked = tempData.reduce((res: any, obj: any) => {
          if (!res[obj.reservation.reservation_id])
            res[obj.reservation.reservation_id] = [];
          res[obj.reservation.reservation_id].push(obj);
          return res;
        }, {});
        const sortReservationStartandEndDate: any = Object.values(
          reservationDatesBooked,
        ).map((res: any) => {
          const sortedData = res.sort((a: any, b: any): any =>
            new Date(a.date).getTime() > new Date(b.date).getTime() ? 1 : -1,
          );

          if (sortedData?.length) {
            return {
              ...sortedData[0],
              start_date: sortedData[0].date,
              end_date: moment(sortedData[sortedData.length - 1].date).format(
                'YYYY-MM-DD',
              ),
            };
          }
          return sortedData;
        });
        const today = new Date();
        today.setDate(today.getDate() - 1);
        const pastdates = [{ from: new Date(1999, 1, 1), to: new Date(today) }];
        const disabledDates = await sortReservationStartandEndDate?.map(
          (item: any) => {
            const data = {
              date: new Date(item.date),
              from: new Date(item.start_date),
              to: new Date(item.end_date),
            };
            return data;
          },
        );
        if (disabledDates) {
          await setDisableDatesData([...disabledDates, ...pastdates]);
        }
      }
      setLoading(false);
    };
    getReservationData();
  }, []);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const handleShow = () => {
    setIsShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const UpdateReservationCollection = async () => {
    await db
      .collection('reservations')
      .doc(id)
      .set(
        {
          check_in_time: `${moment(fromValue).format('YYYY-MM-DD')}T${
            unit?.check_in
          }`,
          check_out_time: `${moment(toValue).format('YYYY-MM-DD')}T${
            unit?.check_out
          }`,
          start_date: moment(fromValue).format('YYYY-MM-DD'),
          end_date: moment(toValue).format('YYYY-MM-DD'),
          invoice: {
            base_amount: price?.baseTotal,
            sub_total: price?.memberTotal,
            tax_amount: price?.taxes,
            total_price: price?.total,
          },
          nights: price?.nights,
        },
        { merge: true },
      );
  };

  const updateCheckOutCollection = async (paymentDetail: any) => {
    await db
      .collection('checkout')
      .doc(myStay?.checkout_id)
      .set(
        {
          check_in_date: moment(fromValue).format('YYYY-MM-DD'),
          check_out_date: moment(toValue).format('YYYY-MM-DD'),
          extra_payments: firebase.firestore.FieldValue.arrayUnion({
            prices: {
              discount: price?.discount,
              per_day_Price: price?.nightlyRate,
              total_nights: price?.memberTotal,
              total_discount: price?.memberDiscount,
              total_yourRates: price?.memberNightlyRate,
              total_cleaning_fee: '',
              total_tax: price?.taxes,
              totalPrice: price?.total,
              base_total: price?.baseTotal,
              currency: price?.currency,
            },
            payment: {
              client_secret: paymentDetail?.clientSecret,
              payment_intent: paymentDetail?.id,
            },
          }),
          nights: price?.nights,
        },
        { merge: true },
      );
  };

  const handleClick = async () => {
    setChangeLoading(true);
    const responce =
      Math.sign(price?.total - myStay?.invoice?.total_price) === -1
        ? 'Refund'
        : 'Charge';
    await db
      .collection('checkout')
      .doc(myStay?.checkout_id)
      .set(
        {
          refund:
            Math.sign(price?.total - myStay?.invoice?.total_price) === -1
              ? Math.abs(price?.total - myStay?.invoice?.total_price).toFixed(2)
              : '',
          charge:
            Math.sign(price?.total - myStay?.invoice?.total_price) === 1
              ? price?.total - myStay?.invoice?.total_price
              : '',
        },
        { merge: true },
      );
    if (responce === 'Charge') {
      const checkRef = await db
        .collection('checkout')
        .doc(myStay?.checkout_id)
        .get();
      const data: any = {
        reservation_id: id,
        current_user: currentUser?.uuid,
        start_date: moment(fromValue).format('YYYY-MM-DD'),
        end_date: moment(toValue).format('YYYY-MM-DD'),
        old_price: myStay?.invoice?.total_price,
        key: checkRef?.data()?.extra_payments?.length
          ? checkRef?.data()?.extra_payments?.length
          : 0,
      };
      const responceChangeDatePaymentIntent: any =
        await callableChangeDatePaymentIntent(data);
      setPaymentIntent(responceChangeDatePaymentIntent?.data?.data);
      if (responceChangeDatePaymentIntent?.data?.data?.client_secret) {
        updateCheckOutCollection(responceChangeDatePaymentIntent?.data?.data);
        setShow(true);
      }
      if (price?.total - myStay?.invoice?.total_price === 0) {
        console.log('price', price?.total - myStay?.invoice?.total_price);
        await db
          .collection('checkout')
          .doc(myStay?.checkout_id)
          .set(
            {
              check_in_date: moment(fromValue).format('YYYY-MM-DD'),
              check_out_date: moment(toValue).format('YYYY-MM-DD'),
              nights: price?.nights,
            },
            { merge: true },
          );
        await db
          .collection('reservations')
          .doc(id)
          .set(
            {
              check_in_time: `${moment(fromValue).format('YYYY-MM-DD')}T${
                unit?.check_in
              }`,
              check_out_time: `${moment(toValue).format('YYYY-MM-DD')}T${
                unit?.check_out
              }`,
              start_date: moment(fromValue).format('YYYY-MM-DD'),
              end_date: moment(toValue).format('YYYY-MM-DD'),
              nights: price?.nights,
            },
            { merge: true },
          );
      }
    } else {
      const responcePaymentRefund = await callablePaymentRefund(id);
      if (responcePaymentRefund) {
        UpdateReservationCollection();
      }
    }
    setChangeLoading(false);
  };

  useEffect(() => {
    if (toValue !== 'Check Out' && fromValue !== 'Check In') {
      const fromDate: any = moment(new Date(fromValue)).toDate();
      const toDate: any = moment(new Date(toValue)).toDate();
      setSelectedRange({
        ...selectedRange,
        from: fromValue.length === 10 ? fromDate : undefined,
        to: toValue.length === 10 ? toDate : undefined,
      });
      setFromValue(
        fromValue?.length === 10
          ? moment(fromValue).format('y-MM-DD')
          : 'Check In',
      );
      setToValue(
        toValue?.length === 10
          ? moment(toValue).format('y-MM-DD')
          : 'Check Out',
      );
    }
  }, [fromValue, toValue]);

  const handleRangeSelect: any = async (range1: DateRange | undefined) => {
    setSelectedRange({ ...range1 });
    if (range1?.from && range1?.to) {
      if (range1?.from) {
        setFromValue(moment(range1?.from).format('MM-DD-YYYY'));
      } else {
        setFromValue('');
      }
      if (range1?.to) {
        setToValue(moment(range1?.to).format('MM-DD-YYYY'));
      } else {
        setToValue('');
      }
      setButtonText(true);
      setIsShow(false);
      setPriceLoading(true);
      const myStayRef = await db.collection('reservations').doc(id).get();
      setMyStay(myStayRef?.data());
      const unitRef = await db
        .collection('units')
        .doc(myStayRef?.data()?.unit_id)
        .get();
      setUnit(unitRef?.data());
      const getChangeDateQuote = await callableGetChangeDateQuote({
        unit_id: unitRef?.id,
        start_date: moment(range1.from).format('YYYY-MM-DD'),
        end_date: moment(range1.to).format('YYYY-MM-DD'),
      });
      setPrice(getChangeDateQuote.data);
      setPriceLoading(false);
    }
  };

  const clientSecret = paymentIntent?.client_secret;
  const options = {
    clientSecret,
  };

  return (
    <>
      <Box>
        <Title>Change Date</Title>
        <Content>
          <Label>When would you like to stay with us?</Label>
          <div ref={wrapperRef}>
            <DateRangeWrapper>
              <DatePickerWrapper
                onClick={() => {
                  setIsShow(true);
                }}
              >
                {loading ? (
                  <div className="d-flex w-100 justify-content-center">
                    <Spinner animation="border" variant="#212f52" size="sm" />
                  </div>
                ) : (
                  <>
                    <img src={CalanderIcon} alt="to-date" />
                    <DateInput
                      placeholder="Check In"
                      value={
                        fromValue === 'Invalid date' ? 'Check In' : fromValue
                      }
                      disabled
                    />
                    <img src={BlueArrow} alt="arrow" />
                    <DateInput
                      placeholder="Check Out"
                      value={toValue === 'Invalid date' ? 'Check Out' : toValue}
                      disabled
                    />
                  </>
                )}
              </DatePickerWrapper>
              {isShow ? (
                <>
                  <DateRangePicker
                    className="Selectable"
                    mode="range"
                    defaultMonth={new Date(fromValue)}
                    selected={selectedRange}
                    onSelect={(range1: DateRange | undefined) => {
                      if (fromValue !== 'Check In' && toValue !== 'Check Out') {
                        setSelectedRange(null);
                        setFromValue('');
                        setToValue('');
                        if (range1) {
                          if (
                            moment(range1?.from).format('YYYY-MM-DD') <
                            fromValue
                          ) {
                            setFromValue(
                              moment(range1?.from).format('MM-DD-YYYY'),
                            );
                            setToValue('');
                          } else if (
                            moment(range1?.to).format('YYYY-MM-DD') >= fromValue
                          ) {
                            setFromValue(
                              moment(range1?.to).format('MM-DD-YYYY'),
                            );
                            setToValue('');
                          } else {
                            setFromValue(
                              moment(range1?.from).format('MM-DD-YYYY'),
                            );
                          }
                        } else {
                          setFromValue(moment(fromValue).format('MM-DD-YYYY'));
                          setToValue('');
                        }
                      } else {
                        handleRangeSelect(range1);
                      }
                    }}
                    numberOfMonths={2}
                    disabled={disableDatesData}
                  />
                  <DatePicker
                    className="Selectable"
                    mode="range"
                    defaultMonth={new Date(fromValue)}
                    selected={selectedRange}
                    onSelect={(range1: DateRange | undefined) => {
                      if (fromValue !== 'Check In' && toValue !== 'Check Out') {
                        setSelectedRange(null);
                        setFromValue('');
                        setToValue('');
                        if (range1) {
                          if (
                            moment(range1?.from).format('YYYY-MM-DD') <
                            fromValue
                          ) {
                            setFromValue(
                              moment(range1?.from).format('MM-DD-YYYY'),
                            );
                            setToValue('');
                          } else if (
                            moment(range1?.to).format('YYYY-MM-DD') >= fromValue
                          ) {
                            setFromValue(
                              moment(range1?.to).format('MM-DD-YYYY'),
                            );
                            setToValue('');
                          } else {
                            setFromValue(
                              moment(range1?.from).format('MM-DD-YYYY'),
                            );
                          }
                        } else {
                          setFromValue(moment(fromValue).format('MM-DD-YYYY'));
                          setToValue('');
                        }
                      } else {
                        handleRangeSelect(range1);
                      }
                    }}
                    numberOfMonths={1}
                    disabled={disableDatesData}
                  />
                </>
              ) : null}
            </DateRangeWrapper>
          </div>
          {!buttonText ? (
            <>
              <Notes>
                Modify the check-in or check-out date to see a preview of the
                adjusted costs.
              </Notes>
              <Notes>
                If you are unable to extend your reservation, please contact our
                guest services team by text: 01 (000) 123-4567 and they‘ll be
                happy to help.
              </Notes>
            </>
          ) : null}
          {priceLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="#212f52" size="sm" />
            </div>
          ) : (
            price.total && (
              <>
                <BillItem>
                  {price?.nightlyRate && (
                    <div className="d-flex w-100 justify-content-between">
                      <BillItemText>
                        US${price?.nightlyRate} × {price?.nights} nights
                      </BillItemText>
                      <BillItemAmount>US${price?.baseTotal}</BillItemAmount>
                    </div>
                  )}
                  {price?.memberDiscount && (
                    <div className="d-flex w-100 justify-content-between">
                      <BillItemText style={{ color: '#DCA73A' }}>
                        {price?.discount}% off - member rate
                      </BillItemText>
                      <BillItemAmount style={{ color: '#DCA73A' }}>
                        -US${price?.memberDiscount}
                      </BillItemAmount>
                    </div>
                  )}
                  {price?.memberTotal && (
                    <div className="d-flex w-100 justify-content-between">
                      <BillItemText>
                        Your rate US${price?.memberNightlyRate} x{' '}
                        {price?.nights}
                        nights
                      </BillItemText>
                      <BillItemAmount>US${price?.memberTotal}</BillItemAmount>
                    </div>
                  )}
                  {price?.taxes && (
                    <div className="d-flex w-100 justify-content-between">
                      <BillItemText>Tax</BillItemText>
                      <BillItemAmount>US${price?.taxes}</BillItemAmount>
                    </div>
                  )}
                </BillItem>
                {price?.total && (
                  <div className="d-flex w-100 justify-content-between">
                    <BillItemText className="bold">Total</BillItemText>
                    <BillItemAmount className="bold">
                      ${price?.total}
                    </BillItemAmount>
                  </div>
                )}
                {price?.total && (
                  <div className="d-flex w-100 justify-content-between">
                    <BillItemText className="bold">
                      {Math.sign(
                        price?.total - myStay?.invoice?.total_price,
                      ) === -1
                        ? 'Refund'
                        : 'Charge'}
                    </BillItemText>
                    <BillItemAmount className="bold">
                      $
                      {Math.abs(
                        price?.total - myStay?.invoice?.total_price,
                      ).toFixed(2)}
                    </BillItemAmount>
                  </div>
                )}
              </>
            )
          )}
        </Content>
        {!buttonText ? (
          <Button onClick={handleShow}>Change Dates</Button>
        ) : (
          <Button
            onClick={handleClick}
            disabled={!price?.total || changeLoading}
          >
            {changeLoading ? (
              <Spinner animation="border" variant="#212f52" size="sm" />
            ) : (
              'Continue'
            )}
          </Button>
        )}
      </Box>
      <LinkWrapper>
        <BackLink
          onClick={() => {
            history.goBack();
          }}
        >
          <img src={DoubleLeftArrows} alt="left-arrow" />
          Go back to My Stays
        </BackLink>
      </LinkWrapper>

      {paymentIntent && (
        <Elements stripe={stripePromise} options={options}>
          <PaymentModal
            show={show}
            handleClose={handleClose}
            id={id}
            UpdateReservationCollection={UpdateReservationCollection}
          />
        </Elements>
      )}
    </>
  );
};

export default EditCheckInOutDateForm;
