import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import {
  Buttondiv,
  ContactInformationDiv,
  Required,
  SubmitLink,
} from './styled';

const ContactInformation: React.FC = () => (
  <Col lg={10} md={10} sm={12}>
    <ContactInformationDiv>
      <h6>Contact information</h6>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <Form.Label>
            First Name <Required>*</Required>{' '}
          </Form.Label>
          <Form.Control type="text" />
        </Col>
        <Col lg={6} md={12} sm={12}>
          <Form.Label>
            Last Name <Required>*</Required>{' '}
          </Form.Label>
          <Form.Control type="text" />
        </Col>
        <Col lg={6} md={12} sm={12}>
          <Form.Label>
            Company / Organization <Required>*</Required>{' '}
          </Form.Label>
          <Form.Control type="text" />
        </Col>
        <Col lg={6} md={12} sm={12}>
          <Form.Label>Industry (Optional)</Form.Label>
          <Form.Control type="text" />
        </Col>
        <Col lg={6} md={12} sm={12}>
          <Form.Label>
            Country Code <Required>*</Required>{' '}
          </Form.Label>
          <Form.Select>
            <option value="Select1">Select1</option>
            <option value="Select2">Select2</option>
            <option value="Select3">Select3</option>
            <option value="Select4">Select4</option>
          </Form.Select>
        </Col>
        <Col lg={6} md={12} sm={12}>
          <Form.Label>
            Phone number <Required>*</Required>{' '}
          </Form.Label>
          <Form.Control type="text" />
        </Col>
        <Col lg={12} md={12} sm={12}>
          <Form.Label>
            Work Email <Required>*</Required>{' '}
          </Form.Label>
          <Form.Control type="email" />
        </Col>
      </Row>
      <h6>Company address</h6>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <Form.Label>
            Address <Required>*</Required>{' '}
          </Form.Label>
          <Form.Control type="text" />
        </Col>
        <Col lg={6} md={12} sm={12}>
          <Form.Label>Apt, suite, etc. (Optional)</Form.Label>
          <Form.Control type="text" />
        </Col>
        <Col lg={12} md={12} sm={12}>
          <Form.Label>
            City <Required>*</Required>{' '}
          </Form.Label>
          <Form.Control type="text" />
        </Col>
        <Col lg={4} md={12} sm={12}>
          <Form.Label>
            Country <Required>*</Required>{' '}
          </Form.Label>
          <Form.Select>
            <option value="Select1">Select1</option>
            <option value="Select2">Select2</option>
            <option value="Select3">Select3</option>
            <option value="Select4">Select4</option>
          </Form.Select>
        </Col>
        <Col lg={4} md={12} sm={12}>
          <Form.Label>State / Region</Form.Label>
          <Form.Control type="text" />
        </Col>
        <Col lg={4} md={12} sm={12}>
          <Form.Label>Postal code</Form.Label>
          <Form.Control type="text" />
        </Col>
      </Row>
      <h6 className="pt-5">Travel needs</h6>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Form.Label>Cities of interest</Form.Label>
          <Form.Control type="text" />
        </Col>
        <Col lg={6} md={12} sm={12}>
          <Form.Label>No. of employees (Optional)</Form.Label>
          <Form.Control type="text" />
        </Col>
        <Col lg={6} md={12} sm={12}>
          <Form.Label>
            Estimated no. of nights/yr <Required>*</Required>{' '}
          </Form.Label>
          <Form.Select>
            <option value="Select1">Select1</option>
            <option value="Select2">Select2</option>
            <option value="Select3">Select3</option>
            <option value="Select4">Select4</option>
          </Form.Select>
        </Col>
      </Row>
      <Buttondiv>
        <button type="submit" className="py-3 px-5">
          <SubmitLink to="/">Submit business inquiry</SubmitLink>
        </button>
      </Buttondiv>
    </ContactInformationDiv>
  </Col>
);

export default ContactInformation;
