import React from 'react';
import GoogleMapReact from 'google-map-react';
import { MapContainer, MapDiv } from './styled';
import Marker from './marker';

interface SearchingFilteredMapProps {
  searchList: any;
}
const SearchingFilteredMap: React.FC<SearchingFilteredMapProps> = ({
  searchList,
}: SearchingFilteredMapProps) => {
  const goToViolation = (id: any) => {
    const violation = document.getElementById(id);
    document.getElementById('list-container').scrollTo({
      top: violation.offsetTop - 314,
      behavior: 'smooth',
    });
  };
  return (
    <MapDiv>
      {console.log('searchList', searchList)}
      <MapContainer>
        {searchList && searchList.length ? (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_APIKEY,
            }}
            defaultCenter={{
              lat: Number(
                Object.values(searchList[0]?.address?.coordinates)?.[0],
              ),
              lng: Number(
                Object.values(searchList[0]?.address?.coordinates)?.[1],
              ),
            }}
            defaultZoom={15}
          >
            {searchList.map((ele: any) => (
              <Marker
                key={`1${ele?.unit_id}`}
                text="city"
                onClick={() => goToViolation(ele?.unit_id)}
                tooltip={ele}
                lat={Object.values(ele?.address?.coordinates)[0]}
                lng={Object.values(ele?.address?.coordinates)[1]}
              />
            ))}
          </GoogleMapReact>
        ) : null}
      </MapContainer>
    </MapDiv>
  );
};

export default SearchingFilteredMap;
