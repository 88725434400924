import React from 'react';
import { Row } from 'react-bootstrap';
import ModificationoftheService from '../ModificationoftheService';
import Term from '../Term';
import Termination from '../Termination';
import { Detail } from './styled';

const TermTerminationandModificationoftheService: React.FC = () => (
  <Row>
    <Detail>
      <p>
        <b>14. Term, Termination and Modification of the Service.</b>
      </p>
      <Term />
      <Termination />
      <div style={{ paddingLeft: '30px' }}>
        <p>
          <b>14.3 Effect of Termination.</b> Upon termination of this Agreement:
          (a) all of our applicable licenses to you, any permission or right to
          use the applicable Properties hereunder, and all applicable Periods of
          Occupancy automatically terminate and you must immediately cease all
          use of the applicable Service and, subject to applicable law, the
          applicable Properties; (b) you will no longer be authorized to access
          your account or the applicable Service or, subject to applicable law,
          any applicable Properties; (c) you must pay us any unpaid amount that
          was due prior to termination; and (d) all payment obligations accrued
          prior to termination and Sections 5, 6.3, 7, 8, 9, 10, 13.3, 14, 15,
          16, 17, and 18 will survive.
        </p>
      </div>
      <ModificationoftheService />
    </Detail>
  </Row>
);

export default TermTerminationandModificationoftheService;
