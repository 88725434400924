import React, { useEffect, useRef } from 'react';
import { DateRange } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import DateArrowIcon from '../../../Assets/Images/Icons/DateArrowIcon.svg';
import CalanderIcon from '../../../Assets/Images/Icons/CalanderIcon.svg';
import { DateInput, DateRangePicker, DatePicker } from './styled';

interface BookingDateSelectorProps {
  isShow: boolean;
  setIsShow: (value: boolean) => void;
  setFromValue: (value: string) => void;
  setToValue: (value: string) => void;
  fromValue: string;
  toValue: string;
  handleRangeSelect: (range1: DateRange) => any | void;
  selectedRange: any;
  setSelectedRange: (value: any) => void | DateRange;
  disableDatesData: any;
  setErrorCalendar: (value: string) => void;
}

const BookingDateSelector: React.FC<BookingDateSelectorProps> = ({
  isShow,
  setIsShow,
  setFromValue,
  setToValue,
  fromValue,
  toValue,
  handleRangeSelect,
  selectedRange,
  setSelectedRange,
  disableDatesData,
  setErrorCalendar,
}: BookingDateSelectorProps) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    setErrorCalendar('');
    if (disableDatesData) {
      setSelectedRange({
        ...selectedRange,
        from: fromValue?.length === 10 ? new Date(fromValue) : undefined,
        to: toValue?.length === 10 ? new Date(toValue) : undefined,
      });
      const tempdata: any = [];
      disableDatesData.map((item: any) => {
        if (
          (new Date(fromValue).getTime() <= item.from.getTime() &&
            new Date(toValue).getTime() >= item.from.getTime()) ||
          moment(fromValue).isBetween(item.from, item.to, undefined, '[]')
        ) {
          tempdata.push(item);
        }
        return tempdata;
      });
      if (tempdata.length) {
        setErrorCalendar('Those dates are not available');
      }
      setFromValue(
        fromValue?.length === 10
          ? moment(fromValue).format('y-MM-DD')
          : 'Check In',
      );
      setToValue(
        toValue?.length === 10
          ? moment(toValue).format('y-MM-DD')
          : 'Check Out',
      );
    }
  }, [fromValue, toValue]);

  return (
    <>
      <div ref={wrapperRef}>
        <img src={CalanderIcon} alt="calander" className="date-picker-icon" />
        <Row
          className="date-item-container m-auto"
          onClick={() => setIsShow(true)}
        >
          <div className="d-flex">
            <Col className="p-0 d-flex" lg={5} md={5} sm={5}>
              <DateInput
                placeholder="Check In"
                value={fromValue === 'Invalid date' ? 'Check In' : fromValue}
                disabled
              />
            </Col>
            <Col className="p-0" lg={2} md={2} sm={2}>
              <img src={DateArrowIcon} alt="arrow" />
            </Col>
            <Col className="p-0" lg={5} md={5} sm={5}>
              <DateInput
                placeholder="Check Out"
                value={toValue === 'Invalid date' ? 'Check Out' : toValue}
                disabled
              />
            </Col>
          </div>
        </Row>
        {isShow ? (
          <>
            <DatePicker
              className="Selectable rdp-header"
              mode="range"
              selected={selectedRange}
              onSelect={(range1: DateRange | undefined) => {
                handleRangeSelect(range1);
              }}
              numberOfMonths={1}
              defaultMonth={
                (fromValue !== 'Check In' && new Date(fromValue)) ||
                (toValue !== 'Check Out' && new Date(toValue)) ||
                new Date()
              }
              disabled={disableDatesData}
            />
            <DateRangePicker
              className="Selectable rdp-header"
              mode="range"
              selected={selectedRange}
              onSelect={(range1: DateRange | undefined) => {
                handleRangeSelect(range1);
              }}
              numberOfMonths={2}
              defaultMonth={
                (fromValue !== 'Check In' && new Date(fromValue)) ||
                (toValue !== 'Check Out' && new Date(toValue)) ||
                new Date()
              }
              disabled={disableDatesData}
            />
          </>
        ) : null}
      </div>
    </>
  );
};

export default BookingDateSelector;
