import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  RowWrapper,
  CardHeader,
  CardDescription,
  ActionWrapper,
  SendInviteButton,
  InputField,
  InputIcon,
} from './styled';
import UserIcon from '../../Assets/Images/Icons/UserIcon.svg';
import EmailIcon from '../../Assets/Images/Icons/EmailIcon.svg';

const AddGuestForm: React.FC = () => (
  <RowWrapper>
    <CardHeader className="pb-4">Add Guests</CardHeader>
    <CardDescription>
      Bringing friends to Austin? Invite guests to allow them to view your stay
      in the Gilbert Hotels app and receive check-in instructions. Guests will
      receive an email letting them know you&apos;ve invited them.
    </CardDescription>

    <Row>
      <Col xl={10} lg={10} md={12} sm={12}>
        <ActionWrapper className="mt-5">
          <div className="d-flex">
            <InputIcon src={UserIcon} />
            <InputField placeholder="First name" />
          </div>
          <div className="d-flex">
            <InputIcon src={UserIcon} />

            <InputField placeholder="Last name" />
          </div>
          <div className="d-flex">
            <InputIcon src={EmailIcon} className="m-1" />
            <InputField placeholder="Email" />
          </div>
          <SendInviteButton className="mt-4">Send Invite</SendInviteButton>
        </ActionWrapper>
      </Col>
    </Row>
  </RowWrapper>
);
export default AddGuestForm;
