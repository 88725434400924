import React, { useContext, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import { FormControl, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { ForgetPasswordButton, Modal, ModalTitle } from './styled';
import AuthContext from '../../../Contexts/AuthContext';

const ForgetPasswordModal = ({ show, handleClose }: any): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const authProvider = useContext(AuthContext);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .email('Email is not valid'),
  });
  return (
    <>
      <Modal show={show} handleClose={handleClose} centered className="Width">
        <ModalTitle>Forget Password</ModalTitle>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          onSubmit={async (value, { setErrors }) => {
            setLoading(true);
            try {
              const { email } = value;
              await authProvider.resetPassword(email);
              setLoading(false);
            } catch (error: any) {
              setErrors(error.code);
              toast.error(error.code);
              setLoading(false);
            }
          }}
        >
          {({
            touched,
            errors,
            isSubmitting,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (
            <Form onSubmit={handleSubmit}>
              <FormControl
                name="email"
                placeholder="Enter email"
                onChange={handleChange}
                onBlur={handleBlur}
                className={`forget-password-input ${
                  touched.email && errors?.email ? 'is-invalid' : ''
                }`}
              />
              <ErrorMessage
                component="div"
                name="email"
                className="invalid-feedback mb-2"
              />
              <ForgetPasswordButton disabled={isSubmitting}>
                {loading && (
                  <>
                    <Spinner animation="border" variant="warning" size="sm" />
                    &nbsp;
                  </>
                )}
                Save
              </ForgetPasswordButton>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
export default ForgetPasswordModal;
