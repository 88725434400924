import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import LeituraFont from '../../Assets/Fonts/LeituraDisplayRoman.otf';
import Container from '../../styled';

interface CityImageProps {
  src: string;
}

export const BGWrapper = styled.div`
  background: #f9f9f9;
  width: 100%;
  background: rgba(220, 167, 58, 0.1);
  padding: 150px 0px;
  padding-bottom: 100px;
  @media (max-width: 425px) {
    padding: 60px 0px;
  }
`;

export const Herocontainer = styled(Container)``;

export const HeroWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
`;

export const CityBox = styled(Col)`
  &.middle-section {
    padding-left: 105px;
    padding-right: 105px;
    & > div {
      width: 100%;
      height: 606px;
      background-repeat: round;
      background-size: cover;
    }
  }
  &.side-section {
    padding-left: 20px;
    padding-right: 20px;
    & > div {
      height: 250px;
      background-repeat: round;
      background-size: cover;
    }
  }
  @media (max-width: 425px) {
    padding-bottom: 20px;
  }
`;

export const CityImage = styled.img<CityImageProps>`
  width: 95%;
  border-radius: 15px;
`;

export const CityBoxText = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #212f52;
  padding-top: 20px;
`;

export const CityBoxLink = styled(Link)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-decoration-line: underline;
  color: #212f52;
  &:hover {
    color: #212f52;
  }
`;

export const CityBoxTitle = styled.h5`
  @font-face {
    font-family: 'Leitura Display';
    src: local('Leitura Display'), local('LeituraDisplay'),
      url(${LeituraFont}) format('truetype');
  }
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 30px;
  color: #212f52;
`;

export const ViewMoreButton = styled.button`
  background: #212f52;
  border-radius: 33px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  margin: 0px 10px;
`;
