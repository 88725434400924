import styled from 'styled-components';

export const Button = styled.button`
  background: #212f52;
  border: 0px;
  border-radius: 33px;
  padding: 10px 35px;
  color: #fff;
`;

export const tempButton = styled.button`
  background: #212f52;
  border: 0px;
  border-radius: 33px;
  padding: 10px 35px;
`;
