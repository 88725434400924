import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { RowWrapper, Header, SubHeader, LeaderWrapper } from './styled';
import Inverster from './Inverster';

const InversterBlock: React.FC = () => (
  <RowWrapper>
    <Col xxl={8} xl={8} lg={8} md={12} sm={12}>
      <LeaderWrapper>
        <Row className="m-auto">
          <Col xxl={4} xl={4} lg={4} md={4} sm={4} className="d-flex">
            <Inverster />
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} sm={4} className="d-flex">
            <Inverster />
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} sm={4} className="d-flex">
            <Inverster />
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} sm={4} className="d-flex">
            <Inverster />
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} sm={4} className="d-flex">
            <Inverster />
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} sm={4} className="d-flex">
            <Inverster />
          </Col>
        </Row>
      </LeaderWrapper>
    </Col>
    <Col xxl={4} xl={4} lg={4} md={12} sm={12} className="m-auto">
      <Header>Backed by top investors</Header>
      <SubHeader>
        Gilbert Hotels has raised over $560 million in venture capital,
        including a $200 million Series E round led by Fidelity, WestCap, and
        Inovia Capital. Other investors include Valor Equity, Greenoaks Capital,
        Greylock Partners, Spark Capital, Atreides Management, Tao Capital
        Partners, and other leading technology and hospitality-focused
        investors.
      </SubHeader>
    </Col>
  </RowWrapper>
);
export default InversterBlock;
