import React from 'react';
import { Row } from 'react-bootstrap';
import CollectAutomatically from '../CollectAutomatically';
import DirectlyFromYou from '../DirectlyFromYou';
import ThirdParties from '../ThirdParties';
import { Detail, Questions } from './styled';

const PersonalInfoCollect: React.FC = () => (
  <>
    <Row>
      <Questions>
        1. What Personal Information Do We Collect About You?
      </Questions>
      <Detail>
        Where applicable, we indicate whether and why you must provide us with
        your Personal Information, as well as the consequences of failing to do
        so. If you do not provide Personal Information when requested, you may
        not be able to benefit from our Services if that information is
        necessary to provide you with them or if we are legally required to
        collect it. We may collect Personal Information about you directly from
        you and from third parties, as well as automatically through your use of
        our Services.
      </Detail>
    </Row>
    <DirectlyFromYou />
    <CollectAutomatically />
    <ThirdParties />
  </>
);

export default PersonalInfoCollect;
