import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const Header = styled(Modal.Header)`
  border: 0;
  padding: 20.58px;
`;

export const Body = styled(Modal.Body)`
  padding: 0 30px;
`;
