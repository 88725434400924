import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import Container from '../../styled';

import LeituraFont from '../../Assets/Fonts/LeituraDisplayRoman.otf';

interface HeroBGProps {
  src: string;
}

export const HeroBG = styled.div<HeroBGProps>`
  background-image: url(${p => p.src});
  height: 600px;
  width: 100%;
  @media (max-width: 1024px) {
    height: 600px;
  }

  @media (max-width: 768px) {
    height: 600px;
  }
`;

export const Herocontainer = styled(Container)``;

export const HeroBGOverlay = styled.div`
  height: inherit;
  background: #000000;
  opacity: 0.5;
  position: absolute;
  width: 100%;
`;

export const HeroContant = styled(Row)`
  padding-top: 196px;
  position: relative;

  @media (max-width: 1024px) {
    padding-top: 150px;
  }

  @media (max-width: 768px) {
    padding-top: 100px;
  }
`;

export const HeroTitle = styled.h1`
  @font-face {
    font-family: 'Leitura Display';
    src: local('Leitura Display'), local('LeituraDisplay'),
      url(${LeituraFont}) format('truetype');
  }
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 82px;
  line-height: 89px;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 30px 0px;
  @media (max-width: 425px) {
    font-size: 60px;
    line-height: 60px;
  }

  @media (max-width: 375px) {
    font-size: 50px;
    line-height: 50px;
  }

  @media (max-width: 320px) {
    font-size: 44px;
    line-height: 44px;
  }
`;

export const HeroText = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 30px 0px;
  @media (max-width: 320px) {
    font-size: 18px;
  }
`;
