import styled from 'styled-components';

export const MainDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16%;
`;

export const SaveButton = styled.button`
  min-width: 100px;
  height: 36px;
  background: #dca73a;
  border: none;
  color: #212f52;
`;
