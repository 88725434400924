import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import NotificationPreferences from './NotificationPreferences';
import { BGWrapper } from './styled';
import UpdateAccount from './UpdateAccount';
import UpdatePassword from './UpdatePassword';

const EditProfileContainer: React.FC = () => (
  <BGWrapper>
    <Container>
      <Row>
        <Col className="m-auto" lg={6} md={8} sm={12}>
          <UpdateAccount />
          <UpdatePassword />
          <NotificationPreferences />
        </Col>
      </Row>
    </Container>
  </BGWrapper>
);

export default EditProfileContainer;
