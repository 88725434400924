import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 0px 60px;
  padding-top: 60px;
  @media (max-width: 425px) {
    padding: 0px 30px;
    padding-top: 60px;
  }
  @media (max-width: 320px) {
    padding: 0px;
    padding-top: 60px;
  }
`;

export const CardDiv = styled(Card)`
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #e9e9e9;
  .card-title {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #212f52;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 20px 0px;
  }
  .card-text {
    font-family: 'Leitura Display';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    color: #212f52;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 20px 0px;
    @media (max-width: 768px) {
      font-size: 21px;
    }
    @media (max-width: 425px) {
      font-size: 28px;
    }
  }
  .card-date {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #212f52;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    margin: 20px 0px;
  }
  .continue-reading {
    text-align: center;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2em;
    text-decoration: underline;
    -webkit-text-decoration-color: #dca73a;
    text-decoration-color: #dca73a;
    color: #212f52;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    margin: 20px 0px;
  }
`;

export const Wrapper = styled.div`
  background: #caccd1;
  border-radius: 15px;
  height: 367px;
`;

export const ReadMore = styled(Link)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #212f52;
  flex: none;
  text-decoration: underline;
  -webkit-text-decoration-color: #dca73a;
  text-decoration-color: #dca73a;
  order: 0;
  flex-grow: 0;
  margin: 6px 0px;
`;
