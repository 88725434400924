import React from 'react';
import ApartmentGuide from './ApartmentGuide';
import BuildingGuide from './BuildingGuide';
import CheckInDetails from './CheckInDetails';
import CheckInInstruction from './CheckInInstruction';
import DownloadApp from './DownloadApp';
import LuggageStorage from './LuggageStorage';
import MailAndPackages from './MailAndPackages';
import Parking from './Parking';
import { BGWrapper, ContainerWrapper } from './styled';

const GuideBook: React.FC = () => (
  <ContainerWrapper>
    <BGWrapper>
      <CheckInInstruction />
      <CheckInDetails />
      <Parking />
      <LuggageStorage />
      <BuildingGuide />
      <ApartmentGuide />
      <MailAndPackages />
      <DownloadApp />
    </BGWrapper>
  </ContainerWrapper>
);

export default GuideBook;
