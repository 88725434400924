import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import {
  BGWrapper,
  CheckInDetail,
  CheckInDiv,
  ContactUsLink,
  Details,
  Heading,
  LateCheckout,
  SearchDiv,
} from './styled';
import SearchIcon from '../../Assets/Images/Icons/SearchIcon.svg';
import SecurityIcon from '../../Assets/Images/Icons/SecurityIcon.svg';
import CalanderYellowIcon from '../../Assets/Images/Icons/CalanderYellowIcon.svg';
import HealthIcon from '../../Assets/Images/Icons/HealthIcon.svg';
import AllTopics from './AllTopics';

const GetHelpContainer: React.FC = () => (
  <BGWrapper>
    <Container>
      <Row>
        <Heading>Hi! What can we help you with?</Heading>
        <SearchDiv>
          <img src={SearchIcon} alt="arrow" className="px-3 py-2" />
          <input type="text" placeholder="Search" className="px-5 py-3" />
        </SearchDiv>
        <Row>
          <Col lg={4} md={12} sm={12} className="py-2">
            <CheckInDiv className="py-5">
              <img src={SecurityIcon} alt="Security" className="px-3" />
              <CheckInDetail className="pt-3">How do I check-in?</CheckInDetail>
            </CheckInDiv>
          </Col>
          <Col lg={4} md={12} sm={12} className="py-2">
            <CheckInDiv className="pt-4 pb-4">
              <img src={CalanderYellowIcon} alt="Security" className="px-3" />
              <LateCheckout className="pt-4 pb-2">
                How do I request an early check-in or a late checkout?
              </LateCheckout>
            </CheckInDiv>
          </Col>
          <Col lg={4} md={12} sm={12} className="py-2">
            <CheckInDiv className="py-5">
              <img src={HealthIcon} alt="Security" className="px-3" />
              <CheckInDetail className="pt-3">
                Our response to Covid-19
              </CheckInDetail>
            </CheckInDiv>
          </Col>
        </Row>
        <AllTopics />
        <Details className="pt-3">
          Not what you&apos;re looking for?{' '}
          <ContactUsLink to="/">Feel free to contact us.</ContactUsLink>
        </Details>
      </Row>
    </Container>
  </BGWrapper>
);

export default GetHelpContainer;
