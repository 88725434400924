import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 0px 300px;
`;

export const Heading = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #212f52;
`;

export const ParkingDetailsItemBox = styled.div`
  h4 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #212f52;
  }
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #212f52;
  }
`;

export const Ruler = styled.div`
  hr {
    border: 1px #caccd1 solid;
  }
`;
