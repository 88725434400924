import styled from 'styled-components';
import LeituraFont from '../../../Assets/Fonts/LeituraDisplayRoman.otf';

export const Wrapper = styled.div`
  padding-bottom: 50px;
  @media (max-width: 425px) {
    padding-bottom: 20px;
  }
`;

export const HotelName = styled.h1`
  @font-face {
    font-family: 'Leitura Display';
    src: local('Leitura Display'), local('LeituraDisplay'),
      url(${LeituraFont}) format('truetype');
  }
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 54px;
  line-height: 58px;
  color: #212f52;
  @media (max-width: 768px) {
    padding-top: 20px;
  }
  @media (max-width: 425px) {
    font-size: 36px;
  }
`;

export const HotelAddress = styled.h3`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 34px;
  color: #212f52;
  padding-top: 15px;
  @media (max-width: 425px) {
    font-size: 24px;
  }
`;

export const HotelText = styled.h6`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  color: #212f52;
`;

export const ItemBox = styled.div`
  display: flex;
  align-items: center;
  padding-right: 30px;
  img {
    padding-right: 13px;
  }
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #212f52;
    @media (max-width: 1024px) {
    }
    @media (max-width: 425px) {
      font-size: 18px;
    }
  }
`;

export const ItemBoxWrapper = styled.div`
  display: flex;
  @media (max-width: 425px) {
    display: block;
  }
`;
