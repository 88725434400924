import React, { useState } from 'react';
import { Row, Col, Form, Spinner } from 'react-bootstrap';
import Persona, { Client } from 'persona';
import {
  AppLink,
  BeforeCheckInBox,
  Details,
  DownloadAppInfo,
  Heading,
  ItemBlock,
  ItemBlock1,
  Sent,
  VerifyInfo,
  WaitInDetail,
} from './styled';
import KeyIcon from '../../../Assets/Images/Icons/KeyIcon.svg';
// import VerifyModal from '../../Modal/VerifyModal';
interface BeforeCheckInProps {
  reservation: any;
}

const BeforeCheckIn: React.FC<BeforeCheckInProps> = ({
  reservation,
}: BeforeCheckInProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const handelclick = () => {
    if (reservation?.id) {
      const client: Client = new Persona.Client({
        templateId: 'itmpl_nwwqnnLxyCjvhraMC3DxddPs',
        environment: 'sandbox',
        referenceId: reservation?.id,
        onLoad: () => {
          setLoading(true);
          setDisableButton(true);
        },
        onReady: () => {
          setLoading(false);
          client.open();
        },
        onComplete: ({ inquiryId, status, fields }) => {
          if (status === 'completed') {
            setDisableButton(false);
          }
        },
        onCancel: ({ inquiryId, sessionToken }) => {
          setDisableButton(false);
        },
        onError: error => {
          setDisableButton(false);
        },
      });
    }
  };
  return (
    <Row className="py-3">
      <div>
        <BeforeCheckInBox className="px-4">
          <Heading className="pt-4">Before check-in</Heading>
          <Details>
            Please confirm a few details before staying with us.
          </Details>
          <ItemBlock className="pt-5">
            <div className="d-flex w-100 justify-content-between">
              <Col xl={8} lg={8} md={8} sm={8}>
                <div className="d-flex">
                  <Form.Check
                    type="checkbox"
                    id="default"
                    checked={
                      reservation?.document_verification?.status === 'completed'
                    }
                  />
                  {reservation?.document_verification?.status !==
                  'completed' ? (
                    <h6 className="pt-2">Verify ID</h6>
                  ) : (
                    <h6 className="pt-2">Verified I.D.</h6>
                  )}
                </div>
              </Col>
              {reservation?.document_verification?.status !== 'completed' && (
                <Col xl={4} lg={4} md={4} sm={4}>
                  <div className="d-flex justify-content-end">
                    {!loading ? (
                      <button
                        type="submit"
                        className="verify-link"
                        id="verify-link"
                        style={{ background: 'none', border: '0px ' }}
                        onClick={() => handelclick()}
                        disabled={disableButton}
                      >
                        Verify
                      </button>
                    ) : (
                      <div className="text-center">
                        <Spinner animation="border" variant="dark" />
                      </div>
                    )}
                  </div>
                </Col>
              )}
            </div>
          </ItemBlock>{' '}
          <VerifyInfo className="pt-2">
            We require a government-issued ID of the credit card holder to
            ensure the safety of our guests and to prevent fraud.Your privacy
            and identification handled securely.
            <br />
            <br />
            Credit card holder:{' '}
            {reservation?.document_verification?.fields?.nameFirst?.value}{' '}
            {reservation?.document_verification?.fields?.nameLast?.value}
            <br />
            You&apos;re all set! Thanks for verifying your identity.
          </VerifyInfo>
          <ItemBlock className="pt-5">
            <div className="d-flex w-100 justify-content-between">
              <Col xl={8} lg={8} md={8} sm={8}>
                <div className="d-flex">
                  <Form.Check type="checkbox" id="default" />
                  <h6 className="pt-2">Download the app</h6>
                </div>
              </Col>
              <Col xl={4} lg={4} md={4} sm={4}>
                <div className="d-flex justify-content-end pt-1">
                  <Sent to="/" className="pt-1">
                    sent
                  </Sent>
                </div>
              </Col>
            </div>
          </ItemBlock>
          <DownloadAppInfo className="pt-2">
            Request early check-in, late check-out, and receive updates about
            your reservation with our app.
            <br />
            <br />
            Download on the <AppLink to="/">App Store</AppLink> or{' '}
            <AppLink to="/">Google Play</AppLink>
          </DownloadAppInfo>
          <ItemBlock1 className="pt-5">
            <img src={KeyIcon} alt="key" />
            <h6 className="pt-2">Wait for check-in instructions</h6>
          </ItemBlock1>
          <WaitInDetail className="pb-3">
            You’ll receive access instructions three days prior to your check-in
            date
          </WaitInDetail>
        </BeforeCheckInBox>
        {/* <VerifyModal show={openModal} handleClose={setOpenModal} /> */}
      </div>
    </Row>
  );
};
export default BeforeCheckIn;
