import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import BookingDateSelector from '../SearchingFilter/BookingDateSelector';
import SearchInput from './SearchInput';
import {
  SearchBarContainer,
  SearchBaxItemWrapper,
  SearchBar as Search,
  GuestSection,
  GuestCounter,
  SearchButton,
  Counter,
  AddButton,
  RemoveButton,
  DatePickerWrapper,
} from './styled';
import { Users } from '../Icons';
import Minus from '../Icons/Minus';
import Plus from '../Icons/Plus';

const SearchBar: React.FC = () => {
  const history = useHistory();
  const [fromValue, setFromValue] = useState<string | null>();
  const [toValue, setToValue] = useState<string | null>();
  const [count, setCount] = useState<number>(1);
  const [openCityMenu, setOpenCityMenu] = useState<boolean>(false);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);

  const handleAddCount = () => {
    setCount(count + 1);
  };

  const handleRemoveCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const handleCityChange = (cityData: {
    key: string;
    label: string;
    value: string;
  }) => {
    setOpenCityMenu(false);
    setSelectedCity(cityData.key);
  };

  const gotoSearchPage = () => {
    if (!selectedCity) {
      setOpenCityMenu(true);
      // return;
    } else {
      let string = `/destinations/${selectedCity}/search`;
      if (count > 1) {
        string = `guests=${count}`;
      }
      if (fromValue) {
        string += fromValue === 'Check In' ? '' : `&startDate=${fromValue}`;
      }
      if (toValue) {
        string += toValue === 'Check Out' ? '' : `&endDate=${toValue}`;
      }

      history.push(string);
    }
  };

  const handleEvent = (data: any) => {
    setFromValue(moment(data.from).format('MM-DD-YYYY'));
    setToValue(moment(data.to).format('MM-DD-YYYY'));
  };

  return (
    <SearchBarContainer>
      <div>
        <SearchBaxItemWrapper>
          <Search lg={3} md={12} sm={12}>
            <SearchInput
              setSelectedCity={handleCityChange}
              openCityMenu={openCityMenu}
              setOpenCityMenu={setOpenCityMenu}
            />
          </Search>
          <DatePickerWrapper>
            <BookingDateSelector
              setHandleEvent={handleEvent}
              isShowIcon
              fromValue={fromValue}
              setFromValue={setFromValue}
              toValue={toValue}
              setToValue={setToValue}
            />
          </DatePickerWrapper>
          <GuestSection lg={5} md={12} sm={12}>
            <GuestCounter>
              <Users fill="#212F52" />
              <h6>Guests</h6>
              <Counter>
                <RemoveButton
                  type="button"
                  onClick={handleRemoveCount}
                  disabled={count === 0}
                >
                  <Minus fill="#212F52" />
                </RemoveButton>
                <div
                  className="mx-3"
                  style={{ color: '#212F52', fontSize: '20px' }}
                >
                  {count}
                </div>
                <AddButton type="button" onClick={handleAddCount}>
                  <Plus fill="#212F52" />
                </AddButton>
              </Counter>
            </GuestCounter>
            <SearchButton
              id="searchbar-btn"
              type="button"
              onClick={gotoSearchPage}
            >
              Search
            </SearchButton>
          </GuestSection>
        </SearchBaxItemWrapper>
      </div>
    </SearchBarContainer>
  );
};

export default SearchBar;
