import React from 'react';
import { Row } from 'react-bootstrap';
import { Heading, MailAndPakagesDetails, Ruler, Start } from './styled';

const MailAndPackages: React.FC = () => (
  <Start>
    <Heading>Mail & Packages</Heading>
    <MailAndPakagesDetails className="pt-3">
      Unlike traditional hotels, most hotels do not have a front desk or onsite
      concierge. Because of this, we cannot guarantee receipt of packages or
      mail sent to the property. Packages sent to the property will be rejected.
      Gilbert Hotels is not liable for lost or stolen mail and packages.
      <br />
      <br />
      We recommend using a nearby public pick-up location where your package can
      be securely held until you are ready to retrieve it.
      <br />
      <br />
      The following pickup locations are located near your building:
      <br />
      <br />
      Amazon Hub Locker - Representation - 30 N IH 35, at Chevron, Austin, TX
      78701
      <br />
      <br />
      The UPS Store - 500 E 4th St, Austin, TX 78701
      <br />
      <br />
      FedEx Print & Ship Center - 327 Congress Ave., 100 Austin, Texas 78701
      <br />
      <br />
      USPS will hold items addressed with &ldquo;General Delivery&rdquo; for 30
      days. You may pick up packages from USPS when they are addressed in this
      format. Recipient address must be formatted as follows:
      <br />
      <br />
      Guest Name
      <br />
      <br />
      GENERAL DELIVERY
      <br />
      <br />
      Austin, Texas 78701-9998
      <br />
      <br />
      If you are staying longer than 30 days and need to receive mail, please
      reach out to our guest experience team at 617-300-0956.
    </MailAndPakagesDetails>
    <Ruler className="pt-4">
      <hr />
    </Ruler>
  </Start>
);

export default MailAndPackages;
