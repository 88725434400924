import styled from 'styled-components';
import { Container as container } from 'react-bootstrap';

const Container = styled(container)`
  height: 100%;
  padding: 0 160px;

  @media (max-width: 1320px) {
    padding: 0 90px;
  }

  @media (max-width: 1024px) {
    padding: 0 60px;
  }
  @media (max-width: 768px) {
    padding: 0 30px;
  }
`;

export default Container;
