import React from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import InboxContainer from '../../Components/InboxContainer/inbox';

const Inbox: React.FC = () => (
  <div>
    <Header hasLogin={false} />
    <InboxContainer />
    <Footer />
  </div>
);

export default Inbox;
