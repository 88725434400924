import React from 'react';
import BasicDetail from './BasicDetail';
import BookingDateSelector from './BookingDateSelector';
import { RowWrapper, BGWrapper, Line, DatePickerWrapper } from './styled';
import BillDetail from './BillDetail';
import HotelImage from './HotelImage';

interface CheckoutCostDetailsProps {
  billingData: any;
}

const CheckoutCostDetails: React.FC<CheckoutCostDetailsProps> = ({
  billingData,
}: CheckoutCostDetailsProps) => (
  <RowWrapper className="py-5">
    <HotelImage billingData={billingData} />
    <BGWrapper className="bg pt-5">
      <BasicDetail billingData={billingData} />
      <DatePickerWrapper>
        <BookingDateSelector billingData={billingData} />
      </DatePickerWrapper>
      <Line />
      <BillDetail billingData={billingData} />
    </BGWrapper>
  </RowWrapper>
);
export default CheckoutCostDetails;
