import React, { useState } from 'react';
import moment from 'moment';
import { Spinner, Col, Form } from 'react-bootstrap';
import Persona, { Client } from 'persona';
import {
  RowWrapper,
  DetailWrapper,
  StayDuration,
  ActionWrapper,
  ConfirmationTitle,
  ConfirmationText,
  CheckInOutTitle,
  CheckInOutText,
  AppLinkImage,
  AppText,
  SpinnerDiv,
  CheckOutTitle,
  ItemBlock,
  VerifyInfo,
  BeforeCheckInBox,
  Img,
} from './styled';
import GooglePlaystoreIcon from '../../Assets/Images/Icons/GooglePlaystoreIcon.svg';
import AppleAppstoreicon from '../../Assets/Images/Icons/AppleAppstoreicon.svg';

interface ReservationProps {
  reservations: any;
  loading: boolean;
}

const ReservationDetailLeft: React.FC<ReservationProps> = ({
  reservations,
  loading,
}: ReservationProps) => {
  const [loadings, setLoadings] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const handelclick = (id: string) => {
    if (id) {
      const client: Client = new Persona.Client({
        templateId: 'itmpl_nwwqnnLxyCjvhraMC3DxddPs',
        environment: 'sandbox',
        referenceId: id,
        onLoad: () => {
          setLoadings(true);
          setDisableButton(true);
        },
        onReady: () => {
          setLoadings(false);
          client.open();
        },
        onComplete: ({ inquiryId, status, fields }) => {
          if (status === 'completed') {
            setDisableButton(false);
          }
        },
        onCancel: ({ inquiryId, sessionToken }) => {
          setDisableButton(false);
        },
        onError: error => {
          setDisableButton(false);
        },
      });
    }
  };

  return (
    <>
      {!loading ? (
        <div>
          {reservations?.map((item: any) => (
            <div key={item?.id}>
              <RowWrapper>
                <img src={item?.unit_picture} alt="hotel-pic" />
                <DetailWrapper>
                  <StayDuration>
                    {moment(item?.start_date).format('MMMM DD')} -{' '}
                    {moment(item?.end_date).format('MMMM DD')}
                  </StayDuration>
                  <ActionWrapper>
                    <div className="d-flex w-100 justify-content-between pb-4">
                      <ConfirmationTitle>Confirmation code:</ConfirmationTitle>
                      <ConfirmationText>
                        {item?.reservation_code}
                      </ConfirmationText>
                    </div>
                    <div className="d-flex w-100 justify-content-between pb-3">
                      <div>
                        <CheckInOutTitle className="text-align-end pb-2">
                          CHECK IN
                        </CheckInOutTitle>
                        <CheckInOutText className="text-align-end">
                          {moment(item?.start_date).format('dddd')}
                          <br />
                          {moment(item?.start_date).format('MMM DD, YYYY')}
                          <br />
                          {moment(item?.check_in_time).format('HH:mm A')}
                        </CheckInOutText>
                      </div>
                      <div>
                        <CheckOutTitle className="pb-2">
                          CHECK OUT
                        </CheckOutTitle>
                        <CheckInOutText className="text-align-end text-end">
                          {moment(item?.end_date).format('dddd')}
                          <br />
                          {moment(item?.end_date).format('MMM DD, YYYY')}
                          <br />
                          {moment(item?.check_out_time).format('HH:mm A')}
                        </CheckInOutText>
                      </div>
                    </div>
                    <BeforeCheckInBox>
                      <div className="p-4">
                        <ItemBlock>
                          <div className="d-flex w-100 justify-content-between">
                            <Col xl={8} lg={8} md={8} sm={8}>
                              <div className="d-flex">
                                <Form.Check
                                  type="checkbox"
                                  id="default"
                                  checked={
                                    item?.document_verification?.status ===
                                    'completed'
                                  }
                                />
                                {item?.document_verification?.status !==
                                'completed' ? (
                                  <h6 className="pt-2">Verify ID</h6>
                                ) : (
                                  <h6 className="pt-2">Verified I.D.</h6>
                                )}
                              </div>
                            </Col>
                            {item?.document_verification?.status !==
                              'completed' && (
                              <Col xl={4} lg={4} md={4} sm={4}>
                                <div className="d-flex justify-content-end">
                                  {!loadings ? (
                                    <button
                                      type="submit"
                                      className="verify-link"
                                      style={{
                                        background: 'none',
                                        border: '0px ',
                                      }}
                                      onClick={() => handelclick(item?.id)}
                                      disabled={disableButton}
                                    >
                                      Verify
                                    </button>
                                  ) : (
                                    <div className="text-center">
                                      <Spinner
                                        animation="border"
                                        variant="dark"
                                      />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            )}
                          </div>
                        </ItemBlock>{' '}
                        <VerifyInfo className="pt-2">
                          We require a government-issued ID of the credit card
                          holder to ensure the safety of our guests and to
                          prevent fraud.Your privacy and identification handled
                          securely.
                          <br />
                          <br />
                          Credit card holder:{' '}
                          {
                            item?.document_verification?.fields?.nameFirst
                              ?.value
                          }{' '}
                          {item?.document_verification?.fields?.nameLast?.value}
                          <br />
                          You&apos;re all set! Thanks for verifying your
                          identity.
                        </VerifyInfo>
                      </div>
                    </BeforeCheckInBox>
                    <div className="pt-4">
                      <AppText>
                        Download the app to keep your reservation details at
                        hand.
                      </AppText>
                    </div>
                    <Img>
                      <AppLinkImage className="px-2" src={AppleAppstoreicon} />
                      <AppLinkImage
                        className="px-1"
                        src={GooglePlaystoreIcon}
                      />
                    </Img>
                  </ActionWrapper>
                </DetailWrapper>
              </RowWrapper>
            </div>
          ))}
        </div>
      ) : (
        <SpinnerDiv className="text-center">
          <Spinner animation="border" variant="dark" />
        </SpinnerDiv>
      )}
    </>
  );
};
export default ReservationDetailLeft;
