import React from 'react';
import { Row } from 'react-bootstrap';
import {
  ByClicking,
  Detail,
  LastUpdated,
  LinkTag,
  PleaseRead,
  TermsofServiceHeading,
} from './styled';

const Title: React.FC = () => (
  <Row>
    <TermsofServiceHeading>
      Gilbert Hotels Terms of Service
    </TermsofServiceHeading>
    <LastUpdated>Last Updated: Aug 09, 2021</LastUpdated>
    <Detail>
      <p>
        Welcome, and thank you for your interest in Gilbert Hotels (
        <b>“Gilbert Hotels,” “we,” or “us”</b>) and our website at
        www.gilberthotels.com, along with our related websites, networks,
        applications, mobile applications, and other services provided by us
        (collectively, the <b>“Service(s)”</b>). The terms “Gilbert Hotels”,
        “we”, “us” refers to Gilbert Hotels Holdings Inc., a Delaware
        corporation and its subsidiaries and affiliates. These Terms of Service
        (together with the Additional Terms (defined in Section 18.5), the
        <b>“Agreement”</b>) and, for users outside the United Kingdom and the
        European Union, our{' '}
        <LinkTag to="/privacy-policy">Privacy Policy</LinkTag> (defined in
        Section 18.6)) govern your use of the Service and Properties (defined in
        Section 1). This Agreement is a legally binding contract between you and
        us regarding your use of the Service and Properties. Failure to use the
        Service or Properties in accordance with this Agreement may subject you
        to civil and criminal penalties.
      </p>
    </Detail>
    <PleaseRead>
      PLEASE READ THE FOLLOWING TERMS AND CONDITIONS CAREFULLY.
    </PleaseRead>
    <ByClicking>
      <p>
        <b>
          BY CLICKING “I ACCEPT” (OR WORDS TO SIMILAR EFFECT) BY DOWNLOADING,
          INSTALLING, OR OTHERWISE ACCESSING OR USING THE SERVICE OR PROPERTIES,
          OR BY SIGNING A SEPARATE AGREEMENT WITH GILBERT HOTELS THAT EXPRESSLY
          INCORPORATES BY REFERENCE THIS AGREEMENT,
        </b>{' '}
        YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A CONDITION TO YOUR
        USE OF THE SERVICE OR PROPERTIES, YOU AGREE TO BE BOUND BY, THIS
        AGREEMENT. IF YOU ARE NOT ELIGIBLE, OR DO NOT AGREE TO BE BOUND BY THIS
        AGREEMENT, THEN YOU DO NOT HAVE OUR PERMISSION OR ANY RIGHT TO USE THE
        SERVICE OR PROPERTIES. YOUR USE OF THE SERVICE OR PROPERTIES, AND OUR
        PROVISION OF THE SERVICE TO YOU AND EXPRESS LICENSE OR PERMISSION FOR
        YOU TO USE THE PROPERTIES (IF APPLICABLE), CONSTITUTE AN AGREEMENT
        BETWEEN US AND YOU TO BE BOUND BY THIS AGREEMENT.
      </p>
      <p>
        <b>
          ARBITRATION NOTICE FOR USERS OUTSIDE THE UNITED KINGDOM (“UK”) AND THE
          EUROPEAN UNION (“EU”).
        </b>{' '}
        Except for certain kinds of disputes described in Section 17, you agree
        that disputes arising under this Agreement will be resolved by binding,
        individual arbitration , and BY ACCEPTING THIS AGREEMENT, YOU AND
        GILBERT HOTELS ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
        PARTICIPATE IN ANY CLASS ACTION OR REPRESENTATIVE PROCEEDING. YOU AGREE
        TO GIVE UP YOUR RIGHT TO GO TO COURT to assert or defend your rights
        under this contract (except for matters that may be taken to small
        claims court). Your rights will be determined by a NEUTRAL ARBITRATOR
        and NOT a judge or jury. (See Section 17 for more details.)
      </p>
    </ByClicking>
  </Row>
);

export default Title;
