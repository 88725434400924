import styled from 'styled-components';

export const Wrapper = styled.div``;

export const HotelName = styled.h1`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #212f52;
`;

export const HotelAddress = styled.h3`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #6a737b;
`;
