import styled from 'styled-components';

export const PriceWrapper = styled.div`
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 33px;
  display: flex;
  justify-content: center;
  padding: 15px 14px;
  #dropdown-item-button {
    background: none;
    outline: none;
    box-shadow: none;
    color: #212f52;
    border: none;
    :focus-visible {
      outline: none;
      box-shadow: none;
    }
    .btn:focus {
      outline: none;
      box-shadow: none;
    }
  }
  &:hover {
    background: #212f5214;
  }
`;

export const PriceTitle = styled.h6`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #212f52;
`;

export const DropDownMinMaxPrice = styled.div`
  font-family: Arial;
  font-size: 15px;
  font-weight: 800;
  line-height: 16px;
  color: #212f52;
  padding: 10px 12px;
`;
