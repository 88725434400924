import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, Spinner } from 'react-bootstrap';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import {
  ComponentsContainer,
  GrayText,
  Bookbutton,
  Wrapper,
  Title,
  ItemBlock,
  Payment,
  BillItem,
  BillItemText,
  BillItemAmount,
  InnerPayment,
  Rowwrapper,
} from './styled';
import GuestInfo from './GuestInfo';
import UserIcon from '../../Assets/Images/Icons/UserIcon.svg';
import CreditCardIcon from '../../Assets/Images/Icons/CreditCardIcon.svg';
import BeforeCheckIn from '../Checkout1Details/BeforeCheckIn';

interface CheckoutDetailsProps {
  currentUser: string;
  billingData: any;
  loading: boolean;
  reservation: any;
}

const CheckoutDetails: React.FC<CheckoutDetailsProps> = ({
  currentUser,
  billingData,
  loading,
  reservation,
}: CheckoutDetailsProps) => {
  const { id }: any = useParams();
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { email }: any = billingData;

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);

    const paymentConfirm: any = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_REDIRCT_URL}checkout/${id}`,
        receipt_email: email,
      },
    });
    if (
      paymentConfirm?.error?.type === 'card_error' ||
      paymentConfirm?.error?.type === 'validation_error' ||
      paymentConfirm?.error?.type === 'invalid_request_error'
    ) {
      toast.error(paymentConfirm?.error?.message);
    }
    setIsLoading(false);
  };

  return (
    <Rowwrapper>
      <ComponentsContainer>
        {billingData && billingData?.payment?.status !== 'succeeded' && (
          <Row>
            <div className="d-flex pt-5">
              <Col lg={1} md={1} sm={1}>
                <img src={UserIcon} alt="user-icon" />
              </Col>
              <Col lg={11} md={11} sm={11}>
                <Title className="pb-4 pt-2">Guest Info</Title>
              </Col>
            </div>
          </Row>
        )}
        <Row className="justify-content-end">
          <Col lg={11} md={11} sm={11}>
            {billingData && billingData?.payment?.status !== 'succeeded' && (
              <GuestInfo currentUser={currentUser} billingData={billingData} />
            )}
            {billingData?.payment?.status === 'succeeded' && (
              <BeforeCheckIn reservation={reservation} />
            )}
          </Col>
        </Row>
        <Row>
          <div className="d-flex">
            <Col lg={1} md={1} sm={1}>
              <img src={CreditCardIcon} alt="credit-card-icon" />
            </Col>
            <Col lg={11} md={11} sm={11}>
              {billingData?.payment?.status !== 'succeeded' ? (
                <Title className="pb-4">Payment Info</Title>
              ) : (
                <Title className="pb-3">Payment Summary</Title>
              )}
            </Col>
          </div>
        </Row>
        <Row className="justify-content-end">
          <Col lg={11} md={11} sm={11}>
            {billingData?.payment?.status !== 'succeeded' && (
              <Wrapper>
                <ItemBlock>
                  <form onSubmit={handleSubmit}>
                    <PaymentElement id="payment-element" />
                    {isLoading ? (
                      <div className="text-center">
                        <Spinner animation="border" variant="dark" />
                      </div>
                    ) : (
                      billingData?.payment?.status !== 'succeeded' && (
                        <Bookbutton
                          disabled={isLoading || !stripe || !elements}
                        >
                          Complete Booking
                        </Bookbutton>
                      )
                    )}
                  </form>
                </ItemBlock>
              </Wrapper>
            )}
            {billingData?.payment?.status === 'succeeded' && (
              <Row>
                {loading ? (
                  'Loadding...'
                ) : (
                  <div>
                    <Payment>
                      <div className="pt-4 px-3 pb-2">
                        <BillItem>
                          <div
                            className="d-flex w-100 justify-content-between"
                            key={billingData?.prices?.per_day_Price}
                          >
                            <BillItemText>
                              US${billingData?.prices?.per_day_Price} x{' '}
                              {billingData?.nights} nights
                            </BillItemText>
                            <BillItemAmount>
                              US${billingData?.prices?.base_total}
                            </BillItemAmount>
                          </div>
                          <div
                            className="d-flex w-100 justify-content-between"
                            key={billingData?.prices?.discount}
                          >
                            <BillItemText style={{ color: '#DCA73A' }}>
                              {billingData?.prices?.discount}% off - monthly
                              member rate
                            </BillItemText>
                            <BillItemAmount style={{ color: '#DCA73A' }}>
                              US${billingData?.prices?.total_discount}
                            </BillItemAmount>
                          </div>
                          <div
                            className="d-flex w-100 justify-content-between"
                            key={billingData?.nights}
                          >
                            <BillItemText>
                              Your rate US$
                              {billingData?.prices?.total_yourRates} x{' '}
                              {billingData?.nights} nights
                            </BillItemText>
                            <BillItemAmount>
                              US${billingData?.prices?.total_nights}
                            </BillItemAmount>
                          </div>
                          <div
                            className="d-flex w-100 justify-content-between"
                            key={billingData?.prices?.total_tax}
                          >
                            <BillItemText>Tax</BillItemText>
                            <BillItemAmount>
                              US${billingData?.prices?.total_tax}
                            </BillItemAmount>
                          </div>
                        </BillItem>

                        <div className="d-flex w-100 justify-content-between pb-4">
                          <BillItemText className="bold">Total</BillItemText>
                          <BillItemAmount className="bold">
                            ${billingData?.prices?.totalPrice}
                          </BillItemAmount>
                        </div>
                        <div className="pb-3 px-3">
                          <InnerPayment className="pt-3 px-3">
                            <h6>
                              Paid with{' '}
                              <span>
                                {(billingData?.payment?.card_details?.brand)
                                  .charAt(0)
                                  .toUpperCase() +
                                  (billingData?.payment?.card_details?.brand).slice(
                                    1,
                                  )}
                              </span>{' '}
                              ****
                              {billingData?.payment?.card_details?.last4}{' '}
                              {`(00/${billingData?.payment?.card_details?.exp_month}/${billingData?.payment?.card_details?.exp_year})`}
                            </h6>
                            <p>
                              On your statement, this charge will appear as
                              “Gilbert Hotels Inc.”
                            </p>
                          </InnerPayment>
                        </div>
                      </div>
                    </Payment>
                  </div>
                )}
              </Row>
            )}
          </Col>
        </Row>
        <GrayText className="pt-3">
          By selecting to complete this booking, I agree to the{' '}
          <Link to="/">House Rules</Link> and&nbsp;
          <Link to="/">Cancellaion Policy</Link>&nbsp;,
          <Link to="/terms-of-service">Terms of Service</Link>&nbsp;and&nbsp;
          <Link to="/privacy-policy">Privacy Policy</Link>. I also agree to pay
          the total amount shown, which includes Occupancy Taxes.
        </GrayText>
      </ComponentsContainer>
    </Rowwrapper>
  );
};
export default CheckoutDetails;
