import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { collection, getDocs, query } from 'firebase/firestore';
import {
  BGWrapper,
  Herocontainer as Container,
  CityBox,
  CityBoxText,
  CityBoxTitle,
  ImageDiv,
} from './Style';
import LandingComponentHeader from '../../Common/LandingComponentHeader';
import City1 from '../../Assets/Images/CitysImages/City1.jpg';
import { db } from '../../Config/firebase';

const LandingPageCitysList: React.FC = () => {
  const [cities, setCities] = useState<any>([]);

  useEffect(() => {
    const getCityLiist = async () => {
      const q = query(collection(db, 'cities'));
      const querySnapshot = await getDocs(q);
      const tempdata: any = [];
      querySnapshot.forEach(doc => {
        tempdata.push(doc?.data());
      });
      setCities(tempdata);
    };
    getCityLiist();
  }, []);

  return (
    <BGWrapper>
      <Container fluid>
        <Col lg={6} md={8} sm={12}>
          <LandingComponentHeader
            title="A world of choice"
            text="From a room for a night to a loft for as long as you like, there’s
          a Gilbert Hotels for every occasion."
          />
        </Col>
        <Row style={{ justifyContent: 'center' }}>
          {cities.map((item: any) => (
            <CityBox
              className="side-section"
              lg={4}
              md={4}
              sm={4}
              key={item.units}
            >
              <ImageDiv style={{ backgroundImage: `url(${City1})` }} />
              <CityBoxText>{item.units} Neighborhoods</CityBoxText>
              <CityBoxTitle>{item.name}</CityBoxTitle>
            </CityBox>
          ))}
        </Row>
      </Container>
    </BGWrapper>
  );
};

export default LandingPageCitysList;
