import React from 'react';
import BlogExpandedContainer from '../../Components/BlogExpandedContainer';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';

const BlogExpanded: React.FC = () => (
  <div>
    <Header hasLogin />
    <BlogExpandedContainer />
    <Footer />
  </div>
);

export default BlogExpanded;
