import React from 'react';
import EditProfileContainer from '../../Components/EditProfileContainer';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';

const EditProfile: React.FC = () => (
  <div>
    <Header hasLogin />
    <EditProfileContainer />
    <Footer />
  </div>
);

export default EditProfile;
