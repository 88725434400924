import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail, LinkTag } from './styled';

const Cancellations: React.FC = () => (
  <Row>
    <Detail>
      <div style={{ paddingLeft: '30px' }}>
        <p>
          <b>6.4 Cancellations.</b>
          <div style={{ paddingLeft: '30px', marginTop: '20px' }}>
            <p>
              <b>6.4.1 Cancellations for STA/Hotel Reservations.</b> We will
              charge all applicable sums due for the STA/Hotel Reservations that
              you make through the Service at the time such reservations are
              requested. You may cancel a completed STA/Hotel Reservation at any
              time, and will be entitled to a refund, subject to the version of
              our <LinkTag to="/">cancellation policy</LinkTag> posted on our
              website and effective at the time your reservation was completed.
              Our cancellation policy is available online. YOU ACKNOWLEDGE AND
              AGREE THAT IF YOU REQUEST OR COMPLETE A STA/HOTEL RESERVATION FOR
              ONE OF OUR PROPERTIES THROUGH A THIRD PARTY OR THIRD-PARTY
              WEBSITE, STRICTER CANCELLATION POLICIES MAY APPLY. PLEASE REVIEW
              SUCH THIRD-PARTY POLICIES BEFORE SUBMITTING YOUR STA/HOTEL
              RESERVATION REQUEST AND MAKE SURE YOU AGREE WITH THE APPLICABLE
              CANCELLATION PERIOD.
            </p>
            <p>
              <b>6.4.2 Cancellations for Extended Stay Reservations.</b> We will
              charge all applicable sums due for an Extended Stay Reservation at
              the time the applicable Extended Stay Agreement is fully executed
              by you and us. You may cancel your reservation request at any time
              before the applicable Extended Stay Agreement is fully executed.
              Once an Extended Stay Agreement is fully executed, you may not
              cancel the applicable reservation, except as may be otherwise
              expressly provided in such Extended Stay Agreement or other Order
              Form. YOU ACKNOWLEDGE AND AGREE THAT IF YOU REQUEST OR COMPLETE AN
              EXTENDED STAY RESERVATION FOR ONE OF OUR PROPERTIES THROUGH A
              THIRD PARTY OR THIRD-PARTY WEBSITE, STRICTER CANCELLATION POLICIES
              MAY APPLY. PLEASE REVIEW SUCH THIRD-PARTY POLICIES BEFORE
              SUBMITTING YOUR EXTENDED STAY RESERVATION REQUEST AND MAKE SURE
              YOU AGREE WITH THE APPLICABLE CANCELLATION PERIOD.
            </p>
            <p>
              <b>6.4.3 Cancellations for Subscription Reservations.</b> We will
              charge all applicable sums due for a subscription at the time the
              applicable Membership Agreement is fully executed by you and us.
              You may cancel your subscription request at any time before the
              applicable Membership Agreement is fully executed. Once a
              Membership Agreement is fully executed, you may not cancel the
              applicable subscription or any Subscription Reservation, except as
              may be otherwise expressly provided in such Membership Agreement.
              YOU ACKNOWLEDGE AND AGREE THAT IF YOU PURCHASE A SUBSCRIPTION OR
              REQUEST OR COMPLETE A SUBSCRIPTION RESERVATION FOR ONE OF OUR
              PROPERTIES THROUGH A THIRD PARTY OR THIRD-PARTY WEBSITE, STRICTER
              CANCELLATION POLICIES MAY APPLY. PLEASE REVIEW SUCH THIRD-PARTY
              POLICIES BEFORE PURCHASING YOUR SUBSCRIPTION OR SUBMITTING YOUR
              SUBSCRIPTION RESERVATION REQUEST AND MAKE SURE YOU AGREE WITH THE
              APPLICABLE CANCELLATION PERIOD.
            </p>
          </div>
        </p>
      </div>
    </Detail>
  </Row>
);

export default Cancellations;
