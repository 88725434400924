import React from 'react';
import { Col } from 'react-bootstrap';
import {
  BGWrapper,
  BGOverlay,
  Title,
  Text,
  BlockContainer as Container,
  Contant,
  Videobutton,
} from './styled';
import VideoBlockBG from '../../Assets/Images/VideoBlockBG.jpg';

const LandingVideoBlock: React.FC = () => (
  <BGWrapper src={VideoBlockBG}>
    <BGOverlay>
      <Container fluid>
        <Contant>
          <Col xl={5} lg={4} md={6} sm={12}>
            <Title>Hotel amenities without hotel formatlity</Title>
            <Col lg={8} md={8} sm={12}>
              <Text>
                Inspiring, award-wining design meets modern, mobil-first
                service. Welcome to the future of hospitality.
              </Text>
            </Col>
          </Col>
          <Videobutton>Play Video</Videobutton>
        </Contant>
      </Container>
    </BGOverlay>
  </BGWrapper>
);

export default LandingVideoBlock;
