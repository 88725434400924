import React from 'react';
import Footer from '../../Components/Footer';
import GetHelpContainer from '../../Components/GetHelpContainer';
import Header from '../../Components/Header';

const GetHelp: React.FC = () => (
  <div>
    <Header hasLogin />
    <GetHelpContainer />
    <Footer />
  </div>
);

export default GetHelp;
