import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail } from './styled';

const Modifications: React.FC = () => (
  <Row>
    <Detail>
      <div style={{ paddingLeft: '30px' }}>
        <p>
          <b>6.6 Modifications.</b>
          <div style={{ paddingLeft: '30px', marginTop: '20px' }}>
            <p>
              <b>6.6.1 Modifications for STA/Hotel Reservations.</b> You may be
              able to modify your STA/Hotel Reservation, subject to additional
              Taxes, fees and charges, for example, by purchasing additional
              services and amenities (e.g., paid parking and extra cleaning
              services) or by modifying the applicable services or dates of your
              existing reservation. For the avoidance of doubt, if you modify or
              extend the dates of your STA/Hotel Reservation, you will be
              charged the applicable Property’s then-current market rate, which
              may be different (higher or lower) than the rates applicable to
              your existing reservation, for the modified dates. You will have
              an opportunity to review and accept any additional fees and/or
              charges prior to modifying such reservation.
            </p>
            <p>
              <b>6.6.2 Modifications for Extended Stay Reservations.</b> You may
              be able to modify portions of an Extended Stay Reservation,
              subject to any additional Taxes, fees and charges, for example, by
              purchasing or cancelling additional services and amenities (e.g.,
              paid parking and extra cleaning services). You will have an
              opportunity to review and accept any such additional fees and/or
              charges prior to modifying the same. You may not however, modify
              the terms of any Extended Stay Agreement, including the dates or
              term of your reservation, except as expressly set forth in such
              Extended Stay Agreement or Order Form.
            </p>
            <p>
              <b>6.6.3 Modifications for Subscription Reservations.</b> You may
              be able to modify portions of a Subscription Reservation, subject
              to any additional Taxes , fees and charges, for example, by
              purchasing or cancelling additional services and amenities (e.g.,
              paid parking and extra cleaning services). You will have an
              opportunity to review and accept any such additional fees and/or
              charges prior to modifying the same. You may not however, modify
              the terms of any Membership Agreement, including the dates or term
              of your subscription, except as expressly set forth in such
              Membership Agreement.
            </p>
          </div>
        </p>
      </div>
    </Detail>
  </Row>
);

export default Modifications;
