import React from 'react';
import { Row } from 'react-bootstrap';
import {
  CuriousAbout,
  LastUpdated,
  LinkTag,
  PrivacyPolicyTitle,
} from './styled';

const Title: React.FC = () => (
  <Row>
    <PrivacyPolicyTitle>Privacy Policy</PrivacyPolicyTitle>
    <LastUpdated>Last updated: April 8, 2021</LastUpdated>
    <CuriousAbout>
      <h6>Curious about how Gilbert Hotels uses data?</h6>
      <p>
        Gilbert Hotels Holdings Inc., its subsidiaries and affiliates (“Gilbert
        Hotels”, “we” or “us”) provides this Privacy Policy (this “Policy”) to
        inform you of our policies and procedures regarding the collection, use
        and disclosure of Personal Information we receive from users of
        www.gilberthotels.com (the “Site”), our mobile applications (the “Apps”)
        and other products and services (collectively, the “Services”). This
        Policy also tells you about your rights and choices with respect to your
        Personal Information, and how you can contact us if you have any
        questions or concerns. Unless otherwise defined in this Policy, terms
        used in this Policy have the same meanings as in our Terms of Service:{' '}
        <LinkTag to="/terms-of-service">
          https://www.gilberthotels.com/terms-of-service
        </LinkTag>{' '}
        For the purpose of this Policy, “Personal Information” means any
        information relating to an identified or identifiable individual.
      </p>
    </CuriousAbout>
  </Row>
);

export default Title;
