import React from 'react';
import {
  BGWrapper,
  Descripation,
  FirstWrapper,
  FullWrapper,
  MainDiv,
  SecondInnerSecondDiv,
  SecondWrapper,
  Title,
  Wrapper,
} from './styled';

interface BackyardProps {
  element: any;
}

const Backyard: React.FC<BackyardProps> = ({ element }: BackyardProps) => (
  <BGWrapper>
    <MainDiv>
      <FirstWrapper>
        <Title>{element?.title}</Title>
        <Descripation>
          {element?.room_feature?.map((item: any, i: number) =>
            i === 0 ? (
              <span key={`item${item?.id}`}>{item?.text}</span>
            ) : (
              <li key={`item${item?.id}`}>{item?.text}</li>
            ),
          )}
        </Descripation>
      </FirstWrapper>
      <SecondWrapper>
        <div style={{ width: '100%', padding: '5px' }}>
          <FullWrapper
            style={{ backgroundImage: `url(${element?.photos[0]?.original})` }}
          />
        </div>
        <SecondInnerSecondDiv>
          <div style={{ width: '50%', padding: '5px' }}>
            <Wrapper
              style={{
                backgroundImage: `url(${element?.photos[1]?.original})`,
              }}
            />
          </div>
          <div style={{ width: '50%', padding: '5px' }}>
            <Wrapper
              style={{
                backgroundImage: `url(${element?.photos[2]?.original})`,
              }}
            />
          </div>
        </SecondInnerSecondDiv>
        <SecondInnerSecondDiv>
          <div style={{ width: '50%', padding: '5px' }}>
            <Wrapper
              style={{
                backgroundImage: `url(${element?.photos[3]?.original})`,
              }}
            />
          </div>
          <div style={{ width: '50%', padding: '5px' }}>
            <Wrapper
              style={{
                backgroundImage: `url(${element?.photos[4]?.original})`,
              }}
            />
          </div>
        </SecondInnerSecondDiv>
      </SecondWrapper>
    </MainDiv>
  </BGWrapper>
);

export default Backyard;
