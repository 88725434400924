import React from 'react';
import {
  BGWrapper,
  Descripation,
  FirstWrapper,
  HostName,
  MainDiv,
  Quote,
  SecondInnerFirstDiv,
  SecondInnerMainDiv,
  SecondInnerSecondDiv,
  SecondWrapper,
  Title,
  Wrapper,
  WriteingWrapper,
} from './styled';
import QuoteIcon from '../../../Assets/Images/Icons/QuoteIcon.svg';

interface EntryProps {
  element: any;
}

const Entry: React.FC<EntryProps> = ({ element }: EntryProps) => (
  <BGWrapper>
    <MainDiv>
      <FirstWrapper>
        <Title>{element?.title}</Title>
        <Descripation>
          {element?.room_feature?.map((item: any, i: number) =>
            i === 0 ? (
              <span key={`item${item?.id}`}>{item?.text}</span>
            ) : (
              <li key={`item${item?.id}`}>{item?.text}</li>
            ),
          )}
        </Descripation>
      </FirstWrapper>
      <SecondWrapper>
        <SecondInnerMainDiv>
          <SecondInnerFirstDiv>
            <WriteingWrapper>
              <div style={{ padding: '70px' }}>
                <img src={QuoteIcon} alt="quote" />
                <Quote>
                  Well equipt kitchen opens to sun room and back yard.
                </Quote>
                <HostName>Lisa, your host</HostName>
              </div>
            </WriteingWrapper>
          </SecondInnerFirstDiv>
          <SecondInnerSecondDiv>
            <Wrapper
              style={{
                backgroundImage: `url(${element?.photos[0]?.original})`,
                height: '400px',
              }}
            />
          </SecondInnerSecondDiv>
        </SecondInnerMainDiv>
      </SecondWrapper>
    </MainDiv>
  </BGWrapper>
);

export default Entry;
