import React from 'react';
import ComingSoonContainer from '../../Components/ComingSoonContainer';
import Header from '../../Components/Header';

const ComingSoon: React.FC = () => (
  <div>
    <Header hasLogin />
    <ComingSoonContainer />
  </div>
);

export default ComingSoon;
