import React from 'react';
import {
  BGWrapper,
  Descripation,
  FirstWrapper,
  LeftFirstWrapper,
  LeftSecondWrapper,
  MainDiv,
  RightWrapper,
  SecondMainDiv,
  SecondMainInnerLeftDiv,
  SecondMainInnerRightDiv,
  SecondWrapper,
  Title,
} from './styled';

interface HalfBathroomProps {
  element: any;
}

const HalfBathroom: React.FC<HalfBathroomProps> = ({
  element,
}: HalfBathroomProps) => (
  <BGWrapper>
    <MainDiv>
      <FirstWrapper>
        <Title>{element?.title}</Title>
        <Descripation>
          {element?.room_feature?.map((item: any, i: number) =>
            i === 0 ? (
              <span key={`item${item?.id}`}>{item?.text}</span>
            ) : (
              <li key={`item${item?.id}`}>{item?.text}</li>
            ),
          )}
        </Descripation>
      </FirstWrapper>
      <SecondWrapper>
        <SecondMainDiv>
          <SecondMainInnerLeftDiv>
            <LeftFirstWrapper
              style={{
                backgroundImage: `url(${element?.photos[0]?.original})`,
              }}
            />
            <LeftSecondWrapper
              style={{
                backgroundImage: `url(${element?.photos[1]?.original})`,
              }}
            />
          </SecondMainInnerLeftDiv>
          <SecondMainInnerRightDiv>
            <RightWrapper
              style={{
                backgroundImage: `url(${element?.photos[2]?.original})`,
              }}
            />
          </SecondMainInnerRightDiv>
        </SecondMainDiv>
      </SecondWrapper>
    </MainDiv>
  </BGWrapper>
);

export default HalfBathroom;
