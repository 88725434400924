import styled from 'styled-components';

export const RowWrapper = styled.div`
  background: #f9f9f9;
  margin-top: 48px;
  padding-top: 48px;
`;

export const Header = styled.h3`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 52px;
  color: #212f52;
`;

export const SubHeader = styled.h3`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212f52;
`;

export const LeaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 48px;
`;
