import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import 'rheostat/initialize';
import Rheostat from 'rheostat';
import { DropDownMinMaxPrice, PriceWrapper } from './styled';

interface PriceProps {
  setMin: (value: any) => void;
  setMax: (value: any) => void;
  min: number;
  max: number;
}

const Price: React.FC<PriceProps> = ({
  setMin,
  setMax,
  min,
  max,
}: PriceProps) => (
  <PriceWrapper>
    <DropdownButton id="dropdown-item-button" title="Price">
      <div className="dropdown-box-price">
        <Dropdown.Header className="dropdown-header-price">
          Price per night{' '}
        </Dropdown.Header>
        <div className="dropdown-button-price">
          <Rheostat
            min={1}
            max={1000}
            values={[min || 1, max || 1000]}
            onChange={e => {
              setMin(e?.values);
              setMax(e?.values);
            }}
          />
        </div>
      </div>
      <DropDownMinMaxPrice>
        US${min} - US${max}
      </DropDownMinMaxPrice>
    </DropdownButton>
  </PriceWrapper>
);

export default Price;
