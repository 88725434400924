import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import GuideBook from '../GuideBook';
import { BGWrapper } from './styled';

const MyStaysGuideBookContainer: React.FC = () => (
  <BGWrapper>
    <Container>
      <Row>
        <Col className="m-auto" lg={8} md={8} sm={12}>
          <GuideBook />
        </Col>
      </Row>
    </Container>
  </BGWrapper>
);

export default MyStaysGuideBookContainer;
