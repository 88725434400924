import React from 'react';
import { AllTopicsDiv, CheckingInDiv, Heading } from './styled';

const CheckingIn: React.FC = () => (
  <AllTopicsDiv>
    <Heading className="px-3 pt-3">Checking In</Heading>
    <CheckingInDiv className="px-2">
      <ul className="pt-3">
        <li>How do I check-in?</li>
        <li>Will an employee meet me at my Gilbert Hotels?</li>
        <li>What parking options are available?</li>
        <li>How do I access the Wi-Fi?</li>
        <li>What if booked through a third party?</li>
        <li>What&apos;s your online check-in?</li>
      </ul>
    </CheckingInDiv>
  </AllTopicsDiv>
);

export default CheckingIn;
