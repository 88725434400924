import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SliderWrapper = styled.div`
  background: #caccd1;
  padding: 0px 50px;
  max-height: 512px;
  @media (max-width: 425px) {
    padding: 0px 20px;
  }
  .slick-slide {
    padding: 0px 0px;
  }
  .slick-prev {
    z-index: 10;

    &:before {
      content: none;
      color: '#000' !important;
    }
    height: 100%;
  }
  .slick-next {
    top: 0;
    z-index: 10;
    &:before {
      content: none;
      color: '#000' !important;
    }
    height: 100%;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    right: -20px;
  }

  @media (max-width: 1320px) {
    .slick-prev {
      width: 250px;
    }
    .slick-next {
      width: 250px;
    }
  }

  @media (max-width: 1024px) {
    .slick-prev {
      width: 150px;
    }
    .slick-next {
      width: 150px;
    }
  }
  @media (max-width: 768px) {
    .slick-prev {
      width: 100px;
    }
    .slick-next {
      width: 100px;
    }
  }
`;

export const Wrapper = styled.div`
  background-color: #ffffff;
  width: 571px;
  padding: 0px 70px;
  height: 407px;
  h6 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #212f52;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 20px 0px;
  }
  p {
    font-family: 'Leitura Display';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 45px;
    text-align: center;
    color: #212f52;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 20px 0px;
  }
  @media (max-width: 320px) {
    padding: 0px;
  }
`;

export const ReadMore = styled(Link)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #212f52;
  flex: none;
  text-decoration: underline;
  -webkit-text-decoration-color: #dca73a;
  text-decoration-color: #dca73a;
  order: 0;
  flex-grow: 0;
  margin: 6px 0px;
  &:hover {
    color: #212f52;
  }
`;
