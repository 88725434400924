import styled from 'styled-components';

export const BeforeCheckInBox = styled.div`
  background: #ffffff;
  border: 1px solid #f14336;
  box-sizing: border-box;
  border-radius: 0px;
  padding: 10px;

  img {
    margin-right: 10px;
  }
`;

export const RedText = styled.h6`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #f14336;
`;
