import React from 'react';
import Header from '../../Components/Header';
import CheckoutDetailContainer from '../../Components/CheckoutDetailContainer';

const CheckoutDetail: React.FC = () => (
  <div>
    <Header hasLogin={false} />
    <CheckoutDetailContainer />
  </div>
);

export default CheckoutDetail;
