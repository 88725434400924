import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import MyStaysLists from '../MyStaysLists';
import { BGWrapper } from './styled';

const MyStaysContainer: React.FC = () => (
  <BGWrapper>
    <Container>
      <Row>
        <div>
          <Col className="m-auto" lg={6} md={8} sm={12}>
            <MyStaysLists />
          </Col>
        </div>
      </Row>
    </Container>
  </BGWrapper>
);

export default MyStaysContainer;
