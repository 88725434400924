import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 0px 60px;
  @media (max-width: 425px) {
    padding: 0px;
  }
`;

export const CardDiv = styled(Card)`
  border: none;
  background-color: #f9f9f9;
  text-decoration: none;
  .card-title {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #518ef8;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0px;
  }
  .card-text {
    font-family: 'Leitura Display';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 39px;
    text-align: center;
    color: #212f52;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 10px 0px;
  }
`;

export const Wrapper = styled.div`
  background: #caccd1;
  border-radius: 15px;
  height: 367px;
`;

export const Details = styled.p`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #212f52;
  padding-top: 20px;
  @media (max-width: 425px) {
    font-size: 24px;
  }
`;

export const CheckoutMoreLink = styled(Link)`
  background: #dca73a;
  border-radius: 33px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #212f52;
  text-decoration: none;
  &:hover {
    color: #212f52;
  }
`;
