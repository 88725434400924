import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import LocationIcon from '../../../Assets/Images/Icons/Location.png';
import { WrapperMarker } from './styled';

const Marker = ({ tooltip, text, onClick }: any): JSX.Element => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header className="popover-header">
        {tooltip?.title}
      </Popover.Header>
      <Popover.Body className="popover-body">
        {tooltip?.address?.street}
      </Popover.Body>
    </Popover>
  );

  return (
    <WrapperMarker onClick={onClick}>
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        placement="top"
        overlay={popover}
      >
        <img src={LocationIcon} alt={text} width="30" />
      </OverlayTrigger>
    </WrapperMarker>
  );
};

Marker.defaultProps = {
  onClick: null,
};

export default Marker;
