import React from 'react';
import { Wrapper, BillItem, BillItemText, BillItemAmount } from './styled';

interface BillDetailProps {
  reservation: any;
}

const BillDetail: React.FC<BillDetailProps> = ({
  reservation,
}: BillDetailProps) => (
  <Wrapper>
    <BillItem key={reservation?.id}>
      <div
        className="d-flex w-100 justify-content-between"
        key={reservation?.item?.prices?.per_day_Price}
      >
        <BillItemText>
          US${reservation?.prices?.per_day_Price} x {reservation?.nights} nights
        </BillItemText>
        <BillItemAmount>US${reservation?.prices?.total_nights}</BillItemAmount>
      </div>
      <div
        className="d-flex w-100 justify-content-between"
        key={reservation?.discount}
      >
        <BillItemText style={{ color: '#DCA73A' }}>
          {reservation?.discount}% off - member rate
        </BillItemText>
        <BillItemAmount style={{ color: '#DCA73A' }}>
          US${reservation?.prices?.total_discount}
        </BillItemAmount>
      </div>
      <div
        className="d-flex w-100 justify-content-between"
        key={reservation?.nights}
      >
        <BillItemText>
          Your rate US$
          {reservation?.prices?.total_yourRates / reservation?.nights} x{' '}
          {reservation?.nights}
          nights
        </BillItemText>
        <BillItemAmount>
          US${reservation?.prices?.total_yourRates}
        </BillItemAmount>
      </div>
      <div
        className="d-flex w-100 justify-content-between"
        key={reservation?.prices?.total_cleaning_fee}
      >
        <BillItemText>Cleaning Fee</BillItemText>
        <BillItemAmount>
          US${reservation?.prices?.total_cleaning_fee}
        </BillItemAmount>
      </div>
      <div
        className="d-flex w-100 justify-content-between"
        key={reservation?.prices?.total_tax}
      >
        <BillItemText>Tax</BillItemText>
        <BillItemAmount>US${reservation?.prices?.total_tax}</BillItemAmount>
      </div>
    </BillItem>
    <div
      className="d-flex w-100 justify-content-between"
      key={reservation?.prices?.totalPrice}
    >
      <BillItemText className="bold">Total</BillItemText>
      <BillItemAmount className="bold">
        ${reservation?.prices?.totalPrice}
      </BillItemAmount>
    </div>
  </Wrapper>
);
export default BillDetail;
