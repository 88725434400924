import React, { useContext, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import LoginModal from '../LoginModal';
import SignupModal from '../SignupModal';
import {
  HeaderBG,
  HeaderItemWrapper,
  MenuIcon,
  HeaderLogo,
  RightSideItemWrapper,
  RightIconItem,
  RightTextItem,
  LogoSection,
  HeaderContainer,
  FindRoomButton,
  Language,
  NavLanguageDiv,
  UserMenu,
  LoginMenu,
  Sizediv,
} from './styled';
import HamburgerIcon from '../../Assets/Images/Icons/HamburgerIcon.svg';
import LanguageIcon from '../../Assets/Images/Icons/LanguageIcon.svg';
import Us from '../../Assets/Images/Flag/us.jpg';
import Ca from '../../Assets/Images/Flag/ca.jpg';
import Gb from '../../Assets/Images/Flag/gb.jpg';
import Fr from '../../Assets/Images/Flag/fr.jpg';
import It from '../../Assets/Images/Flag/it.jpg';
import Logo from '../../Assets/Images/HeaderLogo.svg';
import AuthContext from '../../Contexts/AuthContext';
import ConfirmationModal from '../Modal/ConfirmationModal';

interface HeaderProps {
  hasLogin: boolean;
}

const Header: React.FC<HeaderProps> = () => {
  const history = useHistory();
  const authProvider = useContext(AuthContext);
  const [loginModal, setLoginModal] = useState<boolean>(false);
  const [signupModal, setSignupModal] = useState<boolean>(false);
  const [checkInModal, setCheckInModal] = useState<boolean>(false);

  const currentUser = authProvider.basicUserDetail;

  const handleLogout = async () => {
    try {
      await authProvider.logout();
    } catch (error) {
      toast.error('Error');
    }
  };

  const onClickFindRoom = () => {
    const button = document.getElementById('searchbar-btn');
    if (button === null) {
      history.push('/');
    } else {
      button.click();
    }
  };

  return (
    <HeaderBG>
      <HeaderContainer fluid>
        <HeaderItemWrapper>
          {/* <Col> */}
          <MenuIcon>
            <NavDropdown
              title={
                <img
                  src={HamburgerIcon}
                  className="img-dropdown"
                  alt="dropdown"
                />
              }
              className="header-menu img-icon"
            >
              <NavDropdown.Item as={Link} to="/about-us">
                About us
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/business-and-group">
                Business and groups
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/support">
                Support
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/responsibility">
                Responsibility
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/blog">
                Blog
              </NavDropdown.Item>
              {currentUser && (
                <Sizediv>
                  <NavDropdown.Item onClick={() => setCheckInModal(true)}>
                    Check In
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/my-stays">
                    My Stays
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/my-favorites">
                    Favorites
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/edit-profile">
                    Edit Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/" onClick={handleLogout}>
                    Log Out
                  </NavDropdown.Item>
                </Sizediv>
              )}
              {!currentUser && (
                <>
                  <Sizediv>
                    <NavDropdown.Item>
                      <button
                        type="submit"
                        onClick={() => setLoginModal(true)}
                        style={{
                          borderRadius: '33px',
                          background: '#212f52',
                          color: 'white',
                          width: '50%',
                          border: 'none',
                        }}
                        className="mx-1 py-2"
                      >
                        Log in
                      </button>
                      <button
                        type="submit"
                        onClick={() => setSignupModal(true)}
                        style={{
                          borderRadius: '33px',
                          background: '#212f52',
                          color: 'white',
                          width: '50%',
                          border: 'none',
                        }}
                        className="mx-1 py-2"
                      >
                        Sign up
                      </button>
                    </NavDropdown.Item>
                  </Sizediv>
                </>
              )}
            </NavDropdown>
          </MenuIcon>
          {/* </Col> */}
          <LogoSection>
            <Link to="/">
              <HeaderLogo src={Logo} />
            </Link>
          </LogoSection>
          <RightSideItemWrapper>
            <UserMenu>
              {currentUser && (
                <>
                  <NavDropdown
                    title={
                      currentUser.first_name.charAt(0).toUpperCase() +
                      currentUser.first_name.slice(1)
                    }
                    className="user-menu"
                  >
                    <NavDropdown.Item as={Link} to="/my-stays">
                      My Stays
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/my-favorites">
                      Favorites
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/edit-profile">
                      Edit Profile
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/" onClick={handleLogout}>
                      Log Out
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              )}
            </UserMenu>

            {!currentUser && (
              <>
                <NavDropdown
                  className="glob-menu"
                  title={<RightIconItem src={LanguageIcon} />}
                >
                  <NavLanguageDiv>
                    <NavDropdown.Header className="navdropdown-header">
                      Select your language
                    </NavDropdown.Header>
                  </NavLanguageDiv>
                  <Language>
                    <img src={Us} alt="flag" />
                    <NavDropdown.Item className="navdropdown-item">
                      English
                    </NavDropdown.Item>
                  </Language>
                  <Language>
                    <img src={Ca} alt="flag" />
                    <NavDropdown.Item className="navdropdown-item">
                      English
                    </NavDropdown.Item>
                  </Language>
                  <Language>
                    <img src={Gb} alt="flag" />
                    <NavDropdown.Item className="navdropdown-item">
                      English
                    </NavDropdown.Item>
                  </Language>
                  <Language>
                    <img src={Fr} alt="flag" />
                    <NavDropdown.Item className="navdropdown-item">
                      Fracne
                    </NavDropdown.Item>
                  </Language>
                  <Language>
                    <img src={It} alt="flag" />
                    <NavDropdown.Item className="navdropdown-item">
                      Italiano
                    </NavDropdown.Item>
                  </Language>
                </NavDropdown>
                <LoginMenu>
                  <RightTextItem onClick={() => setLoginModal(true)}>
                    Log in
                  </RightTextItem>
                  <RightTextItem onClick={() => setSignupModal(true)}>
                    Sign up
                  </RightTextItem>{' '}
                </LoginMenu>
              </>
            )}
            {currentUser && (
              <>
                <RightTextItem>
                  <FindRoomButton onClick={onClickFindRoom}>
                    Find a Room
                  </FindRoomButton>
                </RightTextItem>
              </>
            )}
          </RightSideItemWrapper>
        </HeaderItemWrapper>
      </HeaderContainer>
      <LoginModal
        show={loginModal}
        onCloseModal={setLoginModal}
        setSignupModal={setSignupModal}
      />
      <SignupModal show={signupModal} onCloseModal={setSignupModal} />
      <ConfirmationModal show={checkInModal} handleClose={setCheckInModal} />
    </HeaderBG>
  );
};
export default Header;
