import { collection, getDocs, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import BlogNavbar from '../../Common/BlogNavbar';
import { db } from '../../Config/firebase';
import BlogPost from '../BlogPost';
import BlogSlider from '../BlogSlider';
import CheckoutMore from '../CheckoutMore';
import { Blog, CheckoutMoreDiv } from './styled';

const BlogContainer: React.FC = () => {
  const [cityList, setCityList] = useState<any | null>([]);

  useEffect(() => {
    const getCity = async () => {
      const cityRef = query(collection(db, 'cities'));
      const querySnapshot = await getDocs(cityRef);
      const tempdata: any = [];
      querySnapshot.forEach(docs => {
        tempdata.push({
          ...docs?.data(),
          id: docs?.id,
          // color: ['#518EF8', '#28B446', '#FF9C00'],
        });
      });
      setCityList(tempdata);
    };
    getCity();
  }, []);

  return (
    <>
      <Container fluid>
        <Blog className="justify-content-center">
          <h6>Journal</h6>
        </Blog>
      </Container>
      <BlogNavbar />
      <BlogSlider />
      <BlogPost />
      <CheckoutMoreDiv>
        <CheckoutMore cityList={cityList} />
      </CheckoutMoreDiv>
    </>
  );
};

export default BlogContainer;
