import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface CloseIconProps {
  src: string;
}

export const SignupModalWrapper = styled(Modal)`
  .modal-content {
    border-radius: 15px;
    padding: 0px 80px;
  }
`;

export const CloseIcon = styled.img<CloseIconProps>`
  cursor: pointer;
  position: absolute;
  width: 36px;
  height: 36px;
  right: 28px;
  top: 20px;
`;

export const ModalTitle = styled.h2`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #212f52;
  max-width: 283px;
`;

export const SignupTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

export const InputField = styled.input`
  border: 0px;
  width: 100%;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #9f9fa3;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 15px 0px;
  border-bottom: #212f52 1px solid;
  padding-bottom: 15px;
  outline: none;
`;

export const OrText = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #000000;
  margin: 30px 0px;
`;

export const ModalBottomText = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #212f52;
`;

export const ResetPasswordLink = styled(Link)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #6a737b;
  &:hover {
    color: #6a737b;
  }
`;

export const LoginButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 22px 50px 20px;
  width: 100%;
  border: none;
  background: #dca73a;
  border-radius: 33px;

  color: #212f52;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  :hover {
    background: #e0b252;
  }
`;

export const SocialLoginButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px 55px;
  cursor: pointer;
  background: #f9f9f9;
  border-radius: 33px;
  margin: 10px 0px;
  :hover {
    background: #eff0f3;
  }
`;

export const SocialIcon = styled.img<CloseIconProps>`
  width: 25px;
  height: 25px;
`;

export const SocialButtonText = styled.h6`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #212f52;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 20px;
`;

export const EmailSubscribeCheckBox = styled.div`
  display: block;
  margin-bottom: 15px;
  .form-check-input[type='checkbox'] {
    padding: 8px;
    border-radius: 50%;
    margin-right: 18px;
    :hover {
      cursor: pointer;
    }
  }
  .form-check-input:checked {
    background-color: #212f52;
    border-color: #212f52;
  }

  .label {
    position: relative;
    cursor: pointer;
  }
`;

export const EmailSubscribeText = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #212f52;
`;
