import React from 'react';
import BasicDetail from './BasicDetail';
import BillDetail from './BillDetail';
import BookingDateSelector from './BookingDateSelector';
import HotelImage from './HotelImage';
import { RowWrapper, BGWrapper, Line } from './styled';

interface CheckoutCost1DetailsProps {
  reservation: any;
}
const CheckoutCost1Details: React.FC<CheckoutCost1DetailsProps> = ({
  reservation,
}: CheckoutCost1DetailsProps) => (
  <RowWrapper>
    <HotelImage reservation={reservation} />
    <BGWrapper className="bg pt-5">
      <BasicDetail reservation={reservation} />
      <BookingDateSelector reservation={reservation} />
      <Line>
        <hr />
      </Line>
      <BillDetail reservation={reservation} />
    </BGWrapper>
  </RowWrapper>
);
export default CheckoutCost1Details;
