import styled from 'styled-components';
import Modalcontainer from '../../../Common/Modal';

export const Modal = styled(Modalcontainer)`
  & .modal-content {
    border-radius: 15px;
    height: 100%;
    min-height: 500px;
  }
`;

export const Title = styled.h6`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #212f52;
`;

export const FormControl = styled.div`
  .form-control {
    border: none;
    border-radius: inherit;
    border-bottom: 1px solid #212f52;
    color: #9f9fa3;
    :hover {
      outline: none;
    }
  }
`;

export const BookedDetails = styled.div`
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212f52;
  }
  .links {
    color: #212f52;
  }
`;

export const NextButton = styled.button`
  background: #dca73a;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 33px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #212f52;
`;

export const CreateAccount = styled.div`
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #212f52;
  }
  .links {
    color: #212f52;
  }
`;
