import styled from 'styled-components';
import Container from '../../styled';
import LeituraFont from '../../Assets/Fonts/LeituraDisplayRoman.otf';

interface TestimonialBlockProps {
  bg: string;
}

export const BGWrapper = styled.div`
  padding: 120px 0px;
  @media (max-width: 425px) {
    padding: 50px 0px;
  }
`;

export const ComponentsContainer = styled(Container)`
  position: relative;
`;

export const SliderWrapper = styled.div`
  padding: 45px 0px;
  @media (max-width: 425px) {
    padding: 45px 0px;
  }
  .slick-slide {
    padding: 0px 35px;
    @media (max-width: 425px) {
      padding: 0px 0px;
    }
  }
  .slick-prev {
    z-index: 10;
    &:before {
      content: none;
      color: '#000' !important;
    }
    width: 350px;
    height: 100%;
    background: linear-gradient(
      90deg,
      #ffffff 0%,
      #ffffff 48.44%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .slick-next {
    top: 0;
    z-index: 10;
    &:before {
      content: none;
      color: '#000' !important;
    }
    width: 350px;
    height: 100%;
    background: linear-gradient(
      90deg,
      #ffffff 0%,
      #ffffff 48.44%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: matrix(-1, 0, 0, 1, 0, 0);
    right: 0px;
  }

  @media (max-width: 1320px) {
    .slick-prev {
      width: 250px;
    }
    .slick-next {
      width: 250px;
    }
  }

  @media (max-width: 1024px) {
    .slick-prev {
      width: 150px;
    }
    .slick-next {
      width: 150px;
    }
  }
  @media (max-width: 768px) {
    .slick-prev {
      width: 100px;
    }
    .slick-next {
      width: 100px;
    }
  }
  @media (max-width: 425px) {
    .slick-prev {
      width: 50px;
    }
    .slick-next {
      width: 50px;
    }
  }
`;

export const TestimonialBlock = styled.div<TestimonialBlockProps>`
  padding: 70px;
  @media (max-width: 768px) {
    width: 550px !important;
    padding: 50px;
  }
  @media (max-width: 425px) {
    width: 440px !important;
    padding: 30px;
  }
  background: ${p => p.bg};
  border-radius: 15px;
`;

export const TestimonialText = styled.h3`
  @font-face {
    font-family: 'Leitura Display';
    src: local('Leitura Display'), local('LeituraDisplay'),
      url(${LeituraFont}) format('truetype');
  }
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #212f52;
  padding-bottom: 80px;
`;

export const TestimonialOwner = styled.h4`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #212f52;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
`;

export const TestimonialOwnerLocation = styled.h4`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #212f52;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
`;
