import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail } from './styled';

const BackgroundChecks: React.FC = () => (
  <Row>
    <Detail>
      <p>
        <b>5. Background Checks</b> You acknowledge and agree that for
        transparency, safety, fraud prevention, and anti-money laundering
        purposes, and to the extent permitted by applicable law, we may, but
        have no obligation to, before and/or while you reserve and/or occupy a
        Property, including without limitation, before and/or during any renewal
        or extension: (a) require that you provide us with a form of government
        identification or other information sufficient to assist us with
        undertaking the background checks described in Sections 4(b)-(c); (b)
        screen you against third party databases or other sources and request
        reports (e.g., credit checks) regarding you from service providers (if
        available); and (c) for users outside of the UK and EU, obtain reports
        from public records of criminal convictions or sex offender
        registrations or an equivalent version of background or registered sex
        offender checks (if available). You hereby consent to our undertaking
        the foregoing background checks and to our processing of the resulting
        information in accordance with our Privacy Policy (if applicable),
        including sharing with landlords, property owners and/or their property
        managers (any of the foregoing, a <b>“Landlord”</b>) the results of
        background checks for users based in the United States.
      </p>
    </Detail>
  </Row>
);

export default BackgroundChecks;
