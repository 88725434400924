import React from 'react';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import MyFavoritesContainer from '../../Components/MyFavoritesContainer';

const MyFavorite: React.FC = () => (
  <div>
    <Header hasLogin />
    <MyFavoritesContainer />
    <Footer />
  </div>
);

export default MyFavorite;
