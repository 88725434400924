import styled from 'styled-components';

export const Wrapper = styled.div``;

export const DateInput = styled.input`
  border: none;
  width: 100%;
  color: #212f52;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: #212f52;
  }
`;
