import React from 'react';
import Header from '../../Components/Header';
import LandingContainer from '../../Components/LandingContainer';
import Footer from '../../Components/Footer';

const Landing: React.FC = () => (
  <div>
    <Header hasLogin />
    <LandingContainer />
    <Footer />
  </div>
);

export default Landing;
