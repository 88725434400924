import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import Container from '../../styled';

import LeituraFont from '../../Assets/Fonts/LeituraDisplayRoman.otf';

interface BGWrapperProps {
  src: string;
}

export const BGWrapper = styled.div<BGWrapperProps>`
  background-image: url(${p => p.src});
  width: 100%;
  background-size: cover;
  @media (max-width: 1024px) {
    height: 675px;
  }

  @media (max-width: 768px) {
    height: 600px;
  }
`;

export const BlockContainer = styled(Container)``;

export const BGOverlay = styled.div`
  height: inherit;
  background: linear-gradient(90deg, #1e2331 0%, rgba(30, 35, 49, 0) 100%);
`;

export const Contant = styled(Row)`
  position: relative;
  padding: 196px 0px;
  display: block;

  @media (max-width: 1024px) {
    padding-top: 150px;
  }

  @media (max-width: 768px) {
    padding-top: 100px;
  }

  @media (max-width: 420px) {
    padding-top: 50px;
  }
`;

export const Title = styled.h1`
  @font-face {
    font-family: 'Leitura Display';
    src: local('Leitura Display'), local('LeituraDisplay'),
      url(${LeituraFont}) format('truetype');
  }
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 54px;
  line-height: 58px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 20px 0px;
  @media (max-width: 425px) {
    font-size: 50px;
    line-height: 60px;
  }

  @media (max-width: 375px) {
    font-size: 40px;
    line-height: 50px;
  }

  @media (max-width: 320px) {
    font-size: 36px;
    line-height: 44px;
  }
`;

export const Text = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  width: 317px;
  color: #ffffff;
  flex: none;
  order: 1;
  margin: 20px 0px;
  @media (max-width: 320px) {
    font-size: 18px;
    width: 280px;
  }
`;

export const Videobutton = styled.button`
  border: none;
  width: 196px;
  height: 65px;
  left: 0px;
  top: 276px;
  background: #dca73a;
  border-radius: 33px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 50px 0px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #212f52;
  @media (max-width: 1024px) {
    margin: 0px;
  }
`;
