import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  img {
    width: 100%;
  }

  .white-layer {
    height: 100%;
    width: 100%;
    position: absolute;
    background: linear-gradient(90deg, #f9f9f9 0%, rgba(249, 249, 249, 0) 40%);
    @media (max-width: 992px) {
      background: linear-gradient(
        180deg,
        #f9f9f9 0%,
        rgba(249, 249, 249, 0) 60%
      );
    }
  }
`;

export const Image = styled.div`
  img {
    width: 100%;
  }

  .white-layer {
    height: 100%;
    width: 100%;
    position: absolute;
    background: linear-gradient(
      90deg,
      #f9f9f9 0%,
      rgba(255, 255, 255, 0) 46.77%
    );
  }
`;
