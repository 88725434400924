import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail, DotTag, Questions } from './styled';

const YourPersonalInfo: React.FC = () => (
  <>
    <Row>
      <Questions>2. How We Use Your Personal Information</Questions>
      <Detail>
        <p>We use your Personal Information for the following purposes:</p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          To operate, provide, and maintain our Services, to communicate with
          you about our Services, to respond to your inquiries, to fulfill our
          contract with you, to complete your transactions, and for other
          customer service purposes.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          If you are based in the United States, we may run a criminal
          background check on behalf of landlords or property managers who
          require it for Accommodations in the United States, to the extent
          permitted under applicable law.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          To link your Gilbert Hotels Account with Third Party Accounts to add
          information from Third Party Accounts to your Gilbert Hotels Account
          and Gilbert Hotels Account profile page. Depending on the Third Party
          Accounts you choose and subject to the privacy settings that you have
          set in such Third Party Accounts, you understand that by granting us
          access to the Third Party Accounts, we will access, make available and
          store (if applicable and as permitted by the SNS and authorized by
          you) the information in your Third Party Accounts so that it is
          available on and through your Gilbert Hotels Account on the Site and
          Service.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          To tailor the content and information that we may send or display to
          you, and to otherwise personalize your experiences while using our
          Services, including personalizing your Gilbert Hotels Account profile
          and identifying you as a return guest to provide products or services
          to you according to your preferences or restrictions (e.g., by turning
          on the air conditioner or filling the refrigerator according to your
          preferences). We may also tailor our Services and communications on
          the basis of your Site browsing history. Where required under
          applicable law, we will only use your Personal Information for such
          purposes with your consent.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          To contact you with newsletters, marketing or promotional materials
          and other information that may be of interest to you relating to
          Gilbert Hotels or its partners, via email, SMS and phone calls. Where
          required under applicable law, we will only send you marketing
          communications with your consent. We also may use the information that
          we learn about you to assist us in advertising our Services on third
          party websites.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          For quality assurance purposes.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          To solicit a review from you.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          To better understand how users access and use our Services, both on an
          aggregated and individualized basis, in order to improve the quality
          and value of our Services, provide you with a better experience, and
          develop new Services, and for other research and analytical purposes.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          To find and prevent fraud and respond to trust and safety issues that
          may arise.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          For compliance purposes, including enforcing our Terms of Service or
          other legal rights, or as may be required by applicable laws and
          regulations or requested by any judicial process or governmental
          agency.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          For other purposes for which we provide specific notice at the time
          the information is collected.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          During the ongoing COVID-19 pandemic, we may use the Personal
          Information described in the section labeled “COVID-19”, above, to
          help maintain a safe environment for our guests, team members and
          partners who may be required to access our Accommodations to provide
          services. Personal Information you share with us related to COVID-19
          may be used by Gilbert Hotels to determine whether to permit our team
          members or partners to service the Accommodation during your stay as a
          guest and will not be used or shared for any purposes unrelated to
          COVID-19.
        </p>
      </Detail>
    </Row>
  </>
);

export default YourPersonalInfo;
