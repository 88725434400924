import React from 'react';
import SecondPTag from './Components/SecondPTag';
import ServiceOverview from './Components/ServiceOverview';
import Eligibility from './Components/Eligibility';
import Title from './Components/Title';
import AccountRegistration from './Components/AccountRegistration';
import BackgroundChecks from './Components/BackgroundChecks';
import FeesandPayment from './Components/FeesandPayment';
import ServiceandBookedProperties from './Components/ServiceandBookedProperties';
import RestrictionsandResponsibilities from './Components/RestrictionsandResponsibilities';
import Ownership from './Components/Ownership';
import ThirdPartyTerms from './Components/ThirdPartyTerms';
import UserContent from './Components/UserContent';
import DigitalMillenniumCopyrightAct from './Components/DigitalMillenniumCopyrightAct';
import ModificationofthisAgreement from './Components/ModificationofthisAgreement';
import TermTerminationandModificationoftheService from './Components/TermTerminationandModificationoftheService';
import Indemnity from './Components/Indemnity';
import Disclaimers from './Components/Disclaimers';
import LimitationofLiability from './Components/LimitationofLiability';
import DisputeResolutionandArbitration from './Components/DisputeResolutionandArbitration';
import Miscellaneous from './Components/Miscellaneous';
import NoticeRegardingApple from './Components/NoticeRegardingApple';
import { ContainerWrapper } from './styled';

const TermsofService: React.FC = () => (
  <>
    <ContainerWrapper>
      <div>
        <Title />
        <ServiceOverview />
        <SecondPTag />
        <Eligibility />
        <AccountRegistration />
        <BackgroundChecks />
        <FeesandPayment />
        <ServiceandBookedProperties />
        <RestrictionsandResponsibilities />
        <Ownership />
        <ThirdPartyTerms />
        <UserContent />
        <DigitalMillenniumCopyrightAct />
        <ModificationofthisAgreement />
        <TermTerminationandModificationoftheService />
        <Indemnity />
        <Disclaimers />
        <LimitationofLiability />
        <DisputeResolutionandArbitration />
        <Miscellaneous />
        <NoticeRegardingApple />
      </div>
    </ContainerWrapper>
  </>
);

export default TermsofService;
