import React from 'react';
import { Row, Col } from 'react-bootstrap';
import GoogleMapReact from 'google-map-react';
import {
  Wrapper,
  Heading,
  BuildingName,
  ItemBox,
  Address,
  BuildingFeatureItemBox,
  MapContainer,
} from './styled';
import City1 from '../../../Assets/Images/CitysImages/City1.jpg';
import City2 from '../../../Assets/Images/CitysImages/City2.jpg';
import City3 from '../../../Assets/Images/CitysImages/City3.jpg';

import MapIcon from '../../../Assets/Images/Icons/MapIcon.svg';
import BuildingDownTownIcon from '../../../Assets/Images/Icons/BuildingDownTownIcon.svg';
import Marker from './marker';

const feature = [
  { id: 1, image: City1, text: 'Roof top / terrace / courtyard' },
  { id: 2, image: City2, text: 'Building Entrance' },
  { id: 3, image: City3, text: 'Lounge' },
];

const BuildingFeature = [
  {
    id: 1,
    image: MapIcon,
    title: 'Public Transit',
    text: 'Nearest Public Transportation: Leidseplein. Public Transportation to Downtown: 24 min.',
  },
  {
    id: 2,
    image: BuildingDownTownIcon,
    title: 'Driving to Downtown',
    text: 'Driving time to downtown is about 13 minutes.',
  },
];

interface TheBuildingProps {
  explore: any;
}

const TheBuilding: React.FC<TheBuildingProps> = ({
  explore,
}: TheBuildingProps) => (
  <>
    <Wrapper key={explore?.unit_id}>
      <Heading>The building</Heading>
      <BuildingName>{explore?.address?.display}</BuildingName>
      <Row className="pb-5 m-0">
        {feature.map(item => (
          <Col xxl={4} xl={4} md={4} sm={12} key={item.id}>
            <ItemBox>
              <img src={item.image} alt={`${item.id}`} />
              <p className="m-0">{item.text}</p>
            </ItemBox>
          </Col>
        ))}
      </Row>
      <MapContainer>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_APIKEY }}
          defaultCenter={{
            lat: explore?.address?.coordinates?.latitude,
            lng: explore?.address?.coordinates?.longitude,
          }}
          defaultZoom={17}
          yesIWantToUseGoogleMapApiInternals
        >
          <Marker
            key={explore?.id}
            tooltip={explore}
            lat={explore?.address?.coordinates?.latitude}
            lng={explore?.address?.coordinates?.longitude}
          />
        </GoogleMapReact>
      </MapContainer>
      <Address>Oud-Zuid, 53 Vossiusstraat, Amsterdam</Address>
      <div className="pt-5">
        {BuildingFeature.map(item => (
          <BuildingFeatureItemBox key={item.id}>
            <img src={item.image} alt={`${item.id}`} />
            <div>
              <h4 className="m-0">{item.title}</h4>
              <p className="m-0">{item.text}</p>
            </div>
          </BuildingFeatureItemBox>
        ))}
      </div>
    </Wrapper>
  </>
);
export default TheBuilding;
