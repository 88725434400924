import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import LeituraFont from '../../Assets/Fonts/LeituraDisplayRoman.otf';

export const BGWrapper = styled.div`
  padding: 60px 0px;
  padding-bottom: 60px;
  @media (max-width: 425px) {
    padding-top: 20px;
  }
`;

export const BGWrapperOverlay = styled.div`
  background: #dca73a;
  width: 100%;
  opacity: 0.1%;
  height: 100%;
`;

export const ItemWrapper = styled(Col)`
  padding: 0px 20px;
  &:first-child {
    padding-left: 12px;
  }
  &:last-child {
    padding-right: 12px;
  }
`;

export const RowWrapper = styled(Row)`
  padding-top: 48px;
  padding-bottom: 48px;
  @media (max-width: 425px) {
    padding-bottom: 30px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ItemBox = styled.div`
  border-radius: 15px;

  .image-contrainer {
    position: relative;
    .price-tag {
      flex-direction: row;
      align-items: flex-start;
      padding: 8px 15px;
      position: absolute;
      right: 0%;
      top: 0%;
      background: #212f52;
      border-radius: 0px 15px;
      padding: 10px 18px;
      h5 {
        display: flex;
        font-family: 'Arial';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        text-align: right;
        color: #ffffff;
        margin: 0px 0px;
        padding-right: 0px;
        padding-bottom: 0px;
      }
      p {
        display: flex;
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-align: right;
        color: #ffffff;
        margin: 0px 0px;
        justify-content: right;
      }
    }
    .image {
      height: 250px;
      background: #caccd1;
      border-radius: 15px;
    }
  }
`;

export const FeatureItemTitle = styled.h2`
  @font-face {
    font-family: 'Leitura Display';
    src: local('Leitura Display'), local('LeituraDisplay'),
      url(${LeituraFont}) format('truetype');
  }
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  margin: 20px 0px;
  color: #212f52;
`;

export const FeatureItemItem = styled.div`
  display: flex;
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #212f52;
    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 0px;
  }
  .dot {
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 10px;
    height: 4px;
    width: 4px;
    background-color: #6a737b;
    border-radius: 5px;
    align-self: center;
  }
`;

export const SliderWrapper = styled.div`
  .slick-arrow {
    display: none;
  }
`;

export const SliderButtons = styled.div`
  display: flex;
  justify-content: end;

  button {
    margin: 5px;
    width: 54px;
    height: 54px;
    background-color: transparent;
    border: 2px solid #212f52;
    box-sizing: border-box;
    border-radius: 48px;
    @media (max-width: 425px) {
      width: 44px;
      height: 44px;
    }

    img {
      width: 30px;
    }
  }

  .next-btn {
    transform: rotate(180deg);
  }
`;
