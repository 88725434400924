import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 30px 0px;
`;

export const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ShareDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 5px;
  width: 63px;
  height: 20px;
  flex: none;
  cursor: pointer;
  order: 0;
  flex-grow: 0;
`;

export const Share = styled.h6`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #212f52;
  flex: none;
  order: 1;
  flex-grow: 0;
  padding: 2px 0px;
`;
