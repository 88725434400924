import React from 'react';
import { Container } from 'react-bootstrap';
import Hero from '../Hero';
import ServiceBlock from '../../Common/ServiceBlock';
import BusinessAndGroup from '../../Assets/Images/BusinessAndGroup.jpg';

const BusinessAndGroupContainer: React.FC = () => (
  <>
    <Hero
      bgImage={BusinessAndGroup}
      title={
        <>
          Business and group <br />
          travel the better way
        </>
      }
      text="Whether it's a group trip or a work trip, we're here for you"
    />
    <Container className="mb-5">
      <ServiceBlock
        isImageLeft
        title="Business travel made better"
        texts={[
          'Super fast and free wifi. Space to collaborate (or just concentrate). Rooms that look great on Zoom. Your Gilbert Hotel is ready to work when you are.',
        ]}
        button="Get a corporate rate"
        buttonLink="/business-and-group/business-inquiry"
      />
      <ServiceBlock
        isImageLeft={false}
        title="Group travel without compromises"
        texts={[
          `A class reunion, a wedding, or just some overdue friend time. You'll be the genius who found a room perfect for everyone.`,
        ]}
        button="Ask about our group rates"
        buttonLink="/business-and-group/group-inquiry"
      />
    </Container>
  </>
);

export default BusinessAndGroupContainer;
