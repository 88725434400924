import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const ContainerWrapper = styled(Container)`
  @media (max-width: 768px) {
    padding: 0px;
  }
`;

export const Temp = styled(Container)`
  @media (max-width: 768px) {
    padding: 0px;
  }
`;
