import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 0px 300px;
`;

export const Start = styled.div`
  padding-top: 30px;
  @media (max-width: 425px) {
    padding-top: 20px;
  }
`;

export const Heading = styled.h6`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #212f52;
  padding-bottom: 12px;
`;

export const Details = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #212f52;
`;

export const Bounce = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 300px;
  font-size: 16px;
  line-height: 24px;
  color: #212f52;
  padding-bottom: 16px;
  b {
    text-decoration-line: underline;
    font-weight: 700px;
  }
`;

export const Ruler = styled.div`
  hr {
    background: #caccd1;
    border: 1px #caccd1 solid;
  }
`;
