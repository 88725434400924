import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import Modalcontainer from '../../../Common/Modal';

interface ImageProps {
  src: string;
}

interface CloseIconProps {
  src: string;
}

export const ContainerWrapper = styled(Container)`
  @media (max-width: 375px) {
    padding: 0px;
  }
`;

export const Heading = styled.h6`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #212f52;
`;

export const UpdateAccountDiv = styled.div`
  background: #ffffff;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 15px;
`;

export const Info = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #212f52;
`;

export const InputIcon = styled.img<ImageProps>`
  padding-right: 25px;
`;

export const InputField = styled.input`
  border: 0px;
  width: 100%;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #9f9fa3;
  padding-bottom: 10px;
  margin: 10px 0px;
  border-bottom: 1px solid #212f52;
  outline: none;
`;

export const FieldDiv = styled.div`
  padding: 30px;
`;

export const ChangePassword = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #6a737b;
`;

export const SaveUpdateButton = styled.button`
  background: #212f52;
  border-radius: 5px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  width: 100%;
  padding: 23px 0px;
`;

export const SuspenseLoaderWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  div {
    display: flex;
  }
`;

export const Modal = styled(Modalcontainer)`
  max-width: 530px;
  & .modal-content {
    border-radius: 15px;
  }
`;

export const Button = styled.button`
  &.button-submit {
    width: 100%;
    height: 64px;
    background: #212f52;
    border-radius: 5px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  &.button-cancel {
    background: #ffffff;
    border: 2px solid #212f52;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    height: 64px;
  }
`;

export const CloseIcon = styled.img<CloseIconProps>`
  position: absolute;
  width: 36px;
  height: 36px;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;
