import React from 'react';
import { Row } from 'react-bootstrap';
import BasicDetail from './BasicDetail';
import TheSpace from './TheSpace';
import TheAmenities from './TheAmenities';
import TheBuilding from './TheBuilding';
import TheNeighborhood from './TheNeighborhood';
import ThingsToNote from './ThingsToNote';
import ForMoreDetail from './ForMoreDetail';
import ComponentsContainer, { Rowwrapper } from './styled';

interface RoomDetailsProps {
  explore: any;
}

const RoomDetails: React.FC<RoomDetailsProps> = ({
  explore,
}: RoomDetailsProps) => (
  <Rowwrapper>
    <ComponentsContainer>
      <BasicDetail explore={explore} />
      <TheSpace />
      <TheAmenities explore={explore} />
      <TheBuilding explore={explore} />
      <TheNeighborhood explore={explore} />
      <ThingsToNote explore={explore} />
      <ForMoreDetail />
    </ComponentsContainer>
  </Rowwrapper>
);
export default RoomDetails;
