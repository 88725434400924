import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Container from '../../styled';
import LeituraFont from '../../Assets/Fonts/LeituraDisplayRoman.otf';

interface ItemImageProps {
  src: string;
}

export const BGWrapper = styled.div`
  padding: 120px 0px;
  padding-bottom: 60px;
  @media (max-width: 425px) {
    padding: 60px 0px;
  }
`;

export const Rowcontainer = styled(Row)`
  padding-top: 48px;
  padding-bottom: 48px;
  @media (max-width: 425px) {
    padding-bottom: 10px;
  }
`;

export const BGWrapperOverlay = styled.div`
  background: #dca73a;
  width: 100%;
  opacity: 0.1%;
  height: 100%;
`;

export const ComponentsContainer = styled(Container)`
  position: relative;
`;

export const FeatureItemImage = styled.img<ItemImageProps>`
  border-radius: 15px;
  width: 90%;
  @media (max-width: 1320px) {
    width: 100%;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FeatureItemWrapper = styled.div`
  width: 90%;
  margin: auto;
  margin-bottom: 30px;
  @media (max-width: 1320px) {
    width: 100%;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 425px) {
    padding-top: 20px;
  }
`;

export const FeatureItemLocation = styled.div<ItemImageProps>`
  display: flex;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #212f52;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
  align-items: center;
  margin: 0px;
  :before {
    display: block;
    content: ' ';
    margin-right: 14px;
    background-image: url(${p => p.src});
    background-size: 25px 25px;
    height: 25px;
    width: 25px;
  }
`;

export const FeatureItemName = styled.h2`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 39px;
  color: #212f52;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 20px 0px;
`;

export const FeatureItemText = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #6a737b;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 20px 0px;
`;

export const ContinueReadingLink = styled(Link)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-decoration-line: underline;
  color: #212f52;
  flex: none;
  order: 3;
  flex-grow: 0;
  margin: 20px 0px;
  &:hover {
    color: #212f52;
  }
`;

export const SeeMoreText = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #212f52;
`;

export const ItemWrapper = styled(Col)`
  padding: 12px;
  &:first-child {
    padding-left: 12px;
  }
  &:last-child {
    padding-right: 12px;
  }
`;

export const ItemBox = styled.div`
  border-radius: 15px;

  .image-contrainer {
    position: relative;
    .price-tag {
      flex-direction: row;
      align-items: flex-start;
      padding: 8px 15px;
      position: absolute;
      right: 0%;
      top: 0%;
      background: #212f52;
      border-radius: 0px 15px;
      padding: 10px 18px;
      h5 {
        display: flex;
        font-family: 'Arial';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        text-align: right;
        color: #ffffff;
        margin: 0px 0px;
        padding-right: 0px;
        padding-bottom: 0px;
      }
      p {
        display: flex;
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-align: right;
        color: #ffffff;
        margin: 0px 0px;
        justify-content: right;
      }
    }
    .image {
      height: 300px;
      background-repeat: round;
      background-size: cover;
      border-radius: 15px;
    }
  }
`;

export const FeatureItemTitle = styled.h2`
  @font-face {
    font-family: 'Leitura Display';
    src: local('Leitura Display'), local('LeituraDisplay'),
      url(${LeituraFont}) format('truetype');
  }
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  margin: 20px 0px 5px;
  color: #212f52;
`;

export const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid #caccd1;
  padding-top: 70px;
  @media (max-width: 992px) {
    display: block;
  }
  @media (max-width: 425px) {
    padding-top: 50px;
  }
`;

export const Tab = styled.div`
  padding-bottom: 19px;
  margin-right: 100px;
  font-size: 20px;
  cursor: pointer;
  @media (max-width: 425px) {
    margin-right: 80px;
  }
  &.active {
    border-bottom: 2px solid #dca73a;
    color: #dca73a;
    font-weight: 700;
    @media (max-width: 992px) {
      width: fit-content;
    }
  }
`;

export const FeatureItemItem = styled.div`
  display: flex;
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #6a737b;
    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 0px;
  }
  .dot {
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 10px;
    height: 4px;
    width: 4px;
    background-color: #6a737b;
    border-radius: 5px;
    align-self: center;
  }
`;

export const ViewMoreButton = styled.button`
  background: #212f52;
  border-radius: 33px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  margin: 0px 10px;
`;

export const NoDataFound = styled.p`
  font-family: 'Leitura Display';
  font-size: 24px;
  text-align: center;
  line-height: 23px;
  font-weight: 400;
`;
