import React from 'react';
import { DateRange } from 'react-day-picker';
import BasicDetail from './BasicDetail';
import BookingDateSelector from './BookingDateSelector';
import BillDetail from './BillDetail';
import { DatePickerWrapper, ErrorDiv, RowWrapper, WarningDiv } from './styled';

interface RoomCostDetailsProps {
  explore: any;
  isShow: boolean;
  setIsShow: (value: boolean) => void;
  getHoteldetails: () => void;
  setFromValue: (value: string) => void;
  setToValue: (value: string) => void;
  fromValue: string;
  toValue: string;
  bookLoading: boolean;
  handleRangeSelect: (range1: DateRange) => any | void;
  selectedRange: any;
  setSelectedRange: (value: any) => void | DateRange;
  disableDatesData: any;
  error: string;
  setErrorCalendar: (value: string) => void;
  priceLoading: boolean;
  price: any;
  warningCalendar: string;
}

const RoomCostDetails: React.FC<RoomCostDetailsProps> = ({
  explore,
  isShow,
  setIsShow,
  getHoteldetails,
  setFromValue,
  setToValue,
  fromValue,
  toValue,
  bookLoading,
  handleRangeSelect,
  selectedRange,
  setSelectedRange,
  disableDatesData,
  error,
  setErrorCalendar,
  priceLoading,
  price,
  warningCalendar,
}: RoomCostDetailsProps) => (
  <RowWrapper className="py-5">
    <BasicDetail explore={explore} />
    {warningCalendar && <WarningDiv>{warningCalendar}</WarningDiv>}
    <DatePickerWrapper
      style={{
        border:
          (error && '1px solid rgb(193, 53, 21)') ||
          (warningCalendar && '1px solid #dca73a'),
      }}
    >
      <BookingDateSelector
        setIsShow={setIsShow}
        isShow={isShow}
        fromValue={fromValue}
        setFromValue={setFromValue}
        toValue={toValue}
        setToValue={setToValue}
        handleRangeSelect={handleRangeSelect}
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
        disableDatesData={disableDatesData}
        setErrorCalendar={setErrorCalendar}
      />
    </DatePickerWrapper>
    {error && <ErrorDiv>{error}</ErrorDiv>}
    <BillDetail
      getHoteldetails={getHoteldetails}
      bookLoading={bookLoading}
      error={error}
      priceLoading={priceLoading}
      price={price}
    />
  </RowWrapper>
);
export default RoomCostDetails;
