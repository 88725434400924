import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import SearchingFilteredList from '../SearchingFilteredList';
import ShortByfilter from '../SearchingFilteredList/ShortByfilter';
import SearchingFilteredMap from '../SearchingFilteredMap';
import SearchingFilter from '../SearchingFilter';
import { functions } from '../../Config/firebase';
import {
  BGWrapper,
  Itemrow,
  ColWrapper,
  ComponentsContainer as Container,
  ContainerWrapper,
} from './styled';

const LandingContainer: React.FC = () => {
  const location = useLocation();
  const { city }: any = useParams();
  const [searchList, setSearchList] = useState<null | any>(null);
  const [searchErrorMessage, setSearchErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const callableCheckFilters = functions.httpsCallable('ghotels-checkFilters');
  const [locationStateDetail, setLocationStateDetail] = useState<any>();
  const getHotels = async (queryParams: any) => {
    setLoading(true);
    const response = await callableCheckFilters(queryParams);
    if (response.data) {
      setSearchList(response.data);
      setSearchErrorMessage('');
      setLoading(false);
    } else {
      setSearchErrorMessage(
        'Something went wrong! Please try after some time.',
      );
      setSearchList(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let data: any = {};
    if (location?.state) {
      if (!locationStateDetail) {
        data = location?.state;
      } else {
        data = locationStateDetail;
      }

      console.log('data', data);
      getHotels({ ...data, city });
    } else {
      data = { city };
      getHotels(data);
    }
  }, [location.state, city]);

  return (
    <BGWrapper>
      <ContainerWrapper fluid>
        <SearchingFilter
          locationStateDetail={locationStateDetail}
          setLocationStateDetail={setLocationStateDetail}
        />
        <Itemrow>
          <ColWrapper lg={8} md={12} sm={12}>
            <ShortByfilter searchList={searchList} city={city} />
            <SearchingFilteredList
              searchList={searchList}
              loading={loading}
              city={city}
            />
          </ColWrapper>
          <ColWrapper lg={4} md={12} sm={12}>
            <SearchingFilteredMap searchList={searchList} />
          </ColWrapper>
        </Itemrow>
      </ContainerWrapper>
    </BGWrapper>
  );
};

export default LandingContainer;
