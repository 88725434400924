import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 0px 300px;
`;

export const Start = styled.div`
  padding-top: 48px;
  @media (max-width: 425px) {
    padding-top: 0px;
  }
`;

export const Heading = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #212f52;
  @media (max-width: 425px) {
    padding-top: 20px !important;
  }
`;

export const Ruler = styled.div`
  hr {
    border: 1px #caccd1 solid;
  }
`;

export const MailAndPakagesDetails = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212f52;
`;
