import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail } from './styled';

const SafetyFeatures: React.FC = () => (
  <Row>
    <Detail>
      <div style={{ paddingLeft: '30px' }}>
        <p>
          <b>8.6 Safety Features.</b>
          <div style={{ paddingLeft: '30px', marginTop: '20px' }}>
            <p>
              <b>8.6.1 Safety Features for STA/Hotel Reservations.</b> You
              acknowledge and agree that the Properties may contain certain
              safety features such as smoke detectors, fire alarms, or sound
              level monitoring units as may be required by law, you consent to
              our use of these safety features, and you agree not to interfere
              or tamper with these features (as prohibited under Section
              7.5(12)). You agree to immediately report any problems or need for
              maintenance or repairs to us. We have a right to enter the
              STA/Hotel Property to check and maintain these safety features as
              provided by law. It is expressly understood that you must not, at
              any time, disable or remove any installed safety feature, and to
              do so shall be considered a material breach of this Agreement and
              will be just cause for termination of this Agreement and your
              removal from the Property.
            </p>
            <p>
              <b>8.6.2 Safety Features for Extended Stay Reservations.</b> You
              acknowledge and agree that the Properties may contain certain
              safety features such as smoke detectors, fire alarms, or sound
              level monitoring units as may be required by law, you consent to
              our use of these safety features, you agree not to interfere or
              tamper with these features (as prohibited under Section 7.5(12)),
              and you shall be responsible for testing any such safety features
              (except any sound level monitoring units) weekly and immediately
              reporting any problems or need for maintenance or repairs to us.
              If battery operated, you are responsible for changing the (e.g.,
              smoke detector’s or fire alarm’s) battery as necessary, or
              immediately reporting to us the need for assistance in changing
              such battery. We have a right to enter the Extended Stay Property
              to check and maintain these safety features as provided by law. It
              is expressly understood that you must not, at any time, disable or
              remove any installed safety feature, and to do so shall be
              considered a material breach of this Agreement and will be just
              cause for termination of this Agreement and your removal from the
              Property.
            </p>
            <p>
              <b>8.6.3 Safety Features for Subscription Reservations.</b> You
              acknowledge and agree that the Properties may contain certain
              safety features such as smoke detectors, fire alarms, or sound
              level monitoring units as may be required by law, you consent to
              our use of these safety features, you agree not to interfere or
              tamper with these features (as prohibited under Section 7.5(12)),
              and you shall be responsible for testing any such safety features
              (except any sound level monitoring units) weekly and immediately
              reporting any problems or need for maintenance or repairs to us.
              If battery operated, you are responsible for changing the (e.g.,
              smoke detector’s or fire alarm’s) battery as necessary, or
              immediately reporting to us the need for assistance in changing
              such battery. We have a right to enter the Subscription Property
              to check and maintain these safety features as provided by law. It
              is expressly understood that you must not, at any time, disable or
              remove any installed safety feature, and to do so shall be
              considered a material breach of this Agreement and will be just
              cause for termination of this Agreement and your removal from the
              Property.
            </p>
            <p>
              <b>8.6.4 Security.</b> You acknowledge and agree that certain
              security measures may be provided by us in connection with the
              Properties. We reserve the right to commence, expand, reduce or
              discontinue any such security measures at any time without notice
              to you.
            </p>
          </div>
        </p>
      </div>
    </Detail>
  </Row>
);

export default SafetyFeatures;
