import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ComponentsContainer = styled.div`
  padding-right: 40px;
  @media (max-width: 576px) {
    padding-right: 10px;
  }
`;

export const Rowwrapper = styled(Row)`
  padding-top: 48px;
  padding-bottom: 48px;
`;

export const BookingDetails = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212f52;
`;

export const ResendLink = styled(Link)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212f52;
`;

export const BookingConfirmation = styled.h6`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #212f52;
`;

export const Checkout1Title = styled.h6`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 60px;
  color: #212f52;
`;
