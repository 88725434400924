import React from 'react';
import Header from '../../Components/Header';
import EditCheckInOutTimeContainer from '../../Components/EditCheckInOutTimeContainer';
import Footer from '../../Components/Footer';

const EditCheckInOut: React.FC = () => (
  <div>
    <Header hasLogin />
    <EditCheckInOutTimeContainer />
    <Footer />
  </div>
);

export default EditCheckInOut;
