import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 0px 0px;
`;

export const Temp = styled.div`
  padding: 60px 0px;
`;

export const ContainerWrapper = styled(Container)`
  @media (max-width: 320px) {
    padding: 0px 10px;
  }
`;
