import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Illustration from '../Illustration';
import OurGuestFavorites from '../OurGuestFavorites';
import { BGWrapper, ColWrapper } from './styled';

const MyFavoritesContainer: React.FC = () => (
  <BGWrapper>
    <Container>
      <Row>
        <Col className="m-auto" lg={6} md={8} sm={12}>
          <Illustration />
        </Col>
        <ColWrapper className="m-auto" lg={8} md={10} sm={12}>
          <OurGuestFavorites />
        </ColWrapper>
      </Row>
    </Container>
  </BGWrapper>
);

export default MyFavoritesContainer;
