import React from 'react';
import { Container, Col } from 'react-bootstrap';
import ContactInformation from './ContactInformation';
import {
  BackLink,
  BGWrapper,
  BusinessHotelDetail,
  BusinessLink,
} from './styled';

const AskAboutGroupRateContainer: React.FC = () => (
  <BGWrapper>
    <Container>
      {/* <Row> */}
      <Col lg={9} md={12} sm={12}>
        <Container>
          <BackLink className="pb-5">
            <BusinessLink to="/business-and-group">
              Business and Groups
            </BusinessLink>{' '}
            / Group Inquiry
          </BackLink>
          <BusinessHotelDetail>
            <h6>Group travel with Gilbert Hotels</h6>
            <p>Tell us about your event details</p>
          </BusinessHotelDetail>
          <ContactInformation />
        </Container>
      </Col>
      {/* </Row> */}
    </Container>
  </BGWrapper>
);

export default AskAboutGroupRateContainer;
