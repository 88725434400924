import React from 'react';
import { Spinner } from 'react-bootstrap';

import {
  Wrapper,
  BillItem,
  BillItemText,
  BillItemAmount,
  AddPromo,
  Bookbutton,
  GrayText,
} from './styled';

interface BillDetailProps {
  getHoteldetails: () => void;
  bookLoading: boolean;
  error: string;
  priceLoading: boolean;
  price: any;
}

const BillDetail: React.FC<BillDetailProps> = ({
  getHoteldetails,
  bookLoading,
  error,
  priceLoading,
  price,
}: BillDetailProps) => (
  <Wrapper>
    {priceLoading ? (
      <div className="text-center mb-3">
        <Spinner animation="border" size="sm" variant="warning" />
      </div>
    ) : (
      <>
        {price && (
          <>
            <BillItem>
              {price?.nightlyRate && (
                <div className="d-flex w-100 justify-content-between">
                  <BillItemText>
                    US${price?.nightlyRate} × {price?.nights} nights
                  </BillItemText>
                  <BillItemAmount>US${price?.baseTotal}</BillItemAmount>
                </div>
              )}
              {price?.memberDiscount && (
                <div className="d-flex w-100 justify-content-between">
                  <BillItemText style={{ color: '#DCA73A' }}>
                    {price?.discount}% off - member rate
                  </BillItemText>
                  <BillItemAmount style={{ color: '#DCA73A' }}>
                    -US${price?.memberDiscount}
                  </BillItemAmount>
                </div>
              )}
              {price?.memberTotal && (
                <div className="d-flex w-100 justify-content-between">
                  <BillItemText>
                    Your rate US${price?.memberNightlyRate} x {price?.nights}
                    nights
                  </BillItemText>
                  <BillItemAmount>US${price?.memberTotal}</BillItemAmount>
                </div>
              )}
              {price?.taxes && (
                <div className="d-flex w-100 justify-content-between">
                  <BillItemText>Tax</BillItemText>
                  <BillItemAmount>US${price?.taxes}</BillItemAmount>
                </div>
              )}
            </BillItem>
            {price?.total && (
              <div className="d-flex w-100 justify-content-between">
                <BillItemText className="bold">Total</BillItemText>
                <BillItemAmount className="bold">
                  ${price?.total}
                </BillItemAmount>
              </div>
            )}
          </>
        )}
      </>
    )}
    <AddPromo>Add promo code &gt;</AddPromo>
    <Bookbutton onClick={getHoteldetails} disabled={error && true}>
      {bookLoading && (
        <Spinner animation="border" variant="warning" size="sm" />
      )}
      &nbsp; Book
    </Bookbutton>
    <GrayText>You won&apos;t be charged yet</GrayText>
  </Wrapper>
);

export default BillDetail;
