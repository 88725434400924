import React, { useState } from 'react';
import { ErrorMessage, Formik } from 'formik';
import { serverTimestamp } from 'firebase/firestore';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import {
  ComingSoonImage,
  ComingSoonWrapper,
  FooterText,
  FooterWrapper,
  InputEmail,
  SocialMediaImage,
  SocialMediaWrapper,
  StayTuned,
  Subscribe,
  SubscribeButton,
  SubscribeWrapper,
  SubscriveInputMainWrapper,
  TitleComingSoon,
} from './styled';
import ComingSoonIcon from '../../Assets/Images/Icons/ComingSoonIcon.svg';
import FacebookBlueIcon from '../../Assets/Images/Icons/FacebookBlueIcon.svg';
import TwitterIcon from '../../Assets/Images/Icons/TwitterIcon.svg';
import SnapchatIcon from '../../Assets/Images/Icons/SnapchatIcon.svg';
import InstagramIcon from '../../Assets/Images/Icons/InstagramIcon.svg';
import LinkedinBlueIcon from '../../Assets/Images/Icons/LinkedinBlueIcon.svg';
import GithubIcon from '../../Assets/Images/Icons/GithubIcon.svg';
import { db } from '../../Config/firebase';

const socialMediaList = [
  { id: 1, icon: FacebookBlueIcon },
  { id: 2, icon: TwitterIcon },
  { id: 3, icon: SnapchatIcon },
  { id: 4, icon: InstagramIcon },
  { id: 5, icon: LinkedinBlueIcon },
  { id: 6, icon: GithubIcon },
];

const ComingSoonContainer: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .email('Email is not valid'),
  });

  return (
    <ComingSoonWrapper>
      <TitleComingSoon>WE ARE COMING SOON!!</TitleComingSoon>
      <StayTuned>Stay tuned for something amazing</StayTuned>
      <ComingSoonImage src={ComingSoonIcon} />
      <Subscribe>Subscribe to our mailing list to get latest updates</Subscribe>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={async (value: any, { resetForm }) => {
          setLoading(true);
          const emailSubscriptionRef = await db
            .collection('email-subscription')
            .where('email', '==', value?.email)
            .get();
          if (emailSubscriptionRef?.size) {
            await db
              .collection('email-subscription')
              .doc(emailSubscriptionRef?.docs[0]?.id)
              .set({ updated_at: serverTimestamp() }, { merge: true });
            toast.success('Email Successfully Subscribe');
            setLoading(false);
            resetForm({
              values: {
                email: '',
              },
            });
          } else {
            await db.collection('email-subscription').add({
              created_at: serverTimestamp(),
              email: value?.email,
              updated_at: '',
            });
            toast.success('Email Successfully Subscribe');
            setLoading(false);
            resetForm({
              values: {
                email: '',
              },
            });
          }
        }}
      >
        {({
          touched,
          errors,
          isSubmitting,
          values,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <div>
            <SubscriveInputMainWrapper>
              <SubscribeWrapper onSubmit={handleSubmit}>
                <InputEmail
                  type="email"
                  name="email"
                  placeholder="Enter your email..."
                  value={values?.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`${
                    touched.email && errors?.email ? 'is-invalid' : ''
                  }`}
                />
                <SubscribeButton
                  type="submit"
                  disabled={isSubmitting || loading}
                >
                  Subscribe{' '}
                  {loading ? (
                    <Spinner animation="border" variant="#212f52" size="sm" />
                  ) : (
                    ''
                  )}
                </SubscribeButton>
              </SubscribeWrapper>
            </SubscriveInputMainWrapper>
            <ErrorMessage
              component="div"
              name="email"
              className="invalid-feedback d-flex justify-content-center"
            />
          </div>
        )}
      </Formik>
      <FooterWrapper>
        <SocialMediaWrapper>
          {socialMediaList?.map(element => (
            <SocialMediaImage
              src={element?.icon}
              alt="social-media"
              key={element?.id}
            />
          ))}
        </SocialMediaWrapper>
        <FooterText>Ⓒ 2021 Gilbert Hotels. All rights reserved.</FooterText>
      </FooterWrapper>
    </ComingSoonWrapper>
  );
};
export default ComingSoonContainer;
