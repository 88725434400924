import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const RowWrapper = styled(Row)`
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 30px;
  @media (max-width: 320px) {
    padding: 30px 15px;
  }
`;

export const CardHeader = styled.h3`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #212f52;
  padding-bottom: 40px;
  @media (max-width: 320px) {
    padding-bottom: 10px;
  }
`;

export const DetailWrapper = styled.div`
  padding: 0px;
  img {
    height: 482px;
    width: 100%;
  }
`;

export const StayDuration = styled.p`
  padding-top: 20px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #212f52;
`;

export const StayName = styled.p`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: #212f52;
`;

export const StayAddress = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #212f52;
`;

export const ActionWrapper = styled.div`
  display: flex;
  @media (max-width: 425px) {
    display: grid;
  }
  .stay-menu {
    width: 100%;
    .dropdown-toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      background: #f9f9f9;
      border: 2px solid #212f52;
      box-sizing: border-box;
      border-radius: 5px;
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.1em;
      color: #212f52;
      outline: none;
      background: #f9f9f9;
      margin-left: 5px;
      @media (max-width: 425px) {
        margin: 10px 0px;
      }
    }
    .dropdown-menu {
      padding: 0px;
      .dropdown-item {
        :hover {
          background: #eff0f3;
        }
        background: #f9f9f9;
        height: 50px;
        display: flex;
        align-items: center;
      }
    }
  }
`;

export const ReservationDetailButton = styled.button`
  background: #212f52;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 23px 0px;
  width: 100%;
  text-align: center;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.1em;
  margin-right: 5px;
  text-decoration: none;
  :hover {
    color: #ffffff;
  }
`;

export const StayEditOptionsWrapper = styled.select`
  background: #f9f9f9;
  border: 2px solid #212f52;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #212f52;
  outline: none;
  background: #f9f9f9;
  margin-left: 5px;

  option {
    &:hover {
      background: #eff0f3;
    }
    background: #f9f9f9;
    padding: 25px 20px;
  }
`;

export const StayEditOption = styled.option`
  &:hover {
    background: #eff0f3 !important;
  }
  background: #f9f9f9 !important;
  padding: 25px 20px;
`;

export const NoBooking = styled.h1`
  color: #212f52;
  font-family: 'Leitura Display';
  font-size: 72px;
`;

export const LorderDiv = styled.div`
  margin: 25% 50%;
`;
