import React from 'react';
import Header from '../../Components/Header';
import AddGuestsContainer from '../../Components/AddGuestsContainer';
import Footer from '../../Components/Footer';

const AddGuests: React.FC = () => (
  <div>
    <Header hasLogin />
    <AddGuestsContainer />
    <Footer />
  </div>
);

export default AddGuests;
