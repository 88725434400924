import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';

export const SearchBarContainer = styled.div`
  background: #f9f9f9;
  padding: 25px 40px;
  border-radius: 67.5px;
  position: absolute;
  bottom: -180px;
  @media (max-width: 991px) {
    height: 200px;
    bottom: -200px;
  }
  @media (max-width: 425px) {
    height: 250px;
    bottom: -275px;
  }
`;

export const SearchBaxItemWrapper = styled(Row)`
  background: #ffffff;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  height: 85px;
  border-radius: 45px;
  padding: 25px;
  @media (max-width: 991px) {
    height: 200px;
    padding: 10px;
  }
`;

export const SearchBar = styled(Col)`
  display: flex;
  align-items: center;
  border-right: 1px solid #caccd1;
  height: 100%;
  @media (max-width: 991px) {
    border-right: 0px solid #caccd1;
    border-bottom: 1px solid #caccd1;
    height: auto;
  }
  @media (max-width: 1501px) {
    padding: 0 25px;
  }
`;

export const GuestSection = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  position: relative;
  @media (max-width: 991px) {
    height: auto;
  }
  padding: 0 50px;
  @media (max-width: 1501px) {
    padding: 0 25px;
  }

  @media (max-width: 425px) {
    height: auto;
    flex-direction: column;
  }

  & svg {
    width: 20px;
  }
`;

export const GuestCounter = styled.div`
  display: flex;
  align-items: center;
  h6 {
    color: #212f52;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 0px;
  }
  & > svg {
    margin-right: 20px;
  }

  @media (max-width: 1050px) {
    width: 200px;
    & > svg {
      margin-right: 10px;
    }
  }
  @media (max-width: 991px) {
    width: 200px;
  }
  @media (max-width: 425px) {
    padding-top: 5px;
  }
`;

export const SearchButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 50px 20px;
  background: #dca73a;
  margin: auto;
  border-radius: 33px;
  border: 0;
  position: absolute;
  top: -16px;
  right: -16px;
  color: #212f52;
  :hover {
    background: #e0b252;
  }

  @media (max-width: 991px) {
    position: unset;
    padding: 10px 50px;
    margin: auto 0;
  }
`;

export const Counter = styled.div`
  display: flex;
  margin-left: 30px;
  @media (max-width: 480px) {
    margin-left: 0px;
    display: flex;
  }
`;

export const RemoveButton = styled.button`
  border: 0;
  background-color: transparent;
  :hover {
    background: #eff0f3;
    border-radius: 50%;
  }
`;
export const AddButton = styled.button`
  border: 0;
  background-color: transparent;
  :hover {
    background: #eff0f3;
    border-radius: 50%;
  }
`;

export const DatePickerWrapper = styled(Col)`
  display: flex;
  align-items: center;
  border-right: 1px solid #caccd1;
  height: 100%;
  cursor: pointer;
  padding: 0 50px;
  position: relative;
  & input {
    font-size: 16px;
  }
  & img {
    width: 20px;
  }
  @media (max-width: 1501px) {
    padding: 0 25px;
  }

  @media (max-width: 991px) {
    border-right: 0px solid #caccd1;
    border-bottom: 1px solid #caccd1;
    height: auto;
  }
  @media (max-width: 320px) {
    padding: 0px;
  }
`;
