import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 0px 60px;
  /* display: flex; */
  @media (max-width: 425px) {
    padding: 0px;
  }
`;

export const ImageDiv = styled.div`
  background: #caccd1;
  border-radius: 15px;
  /* display: flex; */
  height: 480px;
  width: 100%;
`;

export const Heading = styled.h6`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 52px;
  color: #212f52;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 30px 0px;
  @media (max-width: 425px) {
    font-size: 36px;
    margin: 0px;
  }
`;

export const Details = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212f52;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 30px 0px;
  @media (max-width: 425px) {
    margin: 10px 0px;
  }
`;

export const Rowcontainer = styled(Row)`
  padding-top: 48px;
  @media (max-width: 425px) {
    padding-top: 20px;
  }
`;

export const CorporateLink = styled(Link)`
  display: flex;
  justify-content: center;
  font-family: 'Arial';
  background: #dca73a;
  border-radius: 33px;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #212f52;
  text-decoration: none;
  padding: 20px 50px;
  :hover {
    color: #ffffff;
  }
  @media (max-width: 425px) {
    font-size: 16px;
    padding: 10px 12px;
  }
`;

export const ListItem = styled.li`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #212f52;
`;
