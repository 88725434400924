import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import Container from '../../styled';

interface HeroBGProps {
  src: string;
}

export const HeroBG = styled.div<HeroBGProps>`
  background-image: url(${p => p.src});
  height: 600px;
  width: 100%;
  background-size: cover;
  @media (max-width: 1024px) {
    height: 600px;
  }

  @media (max-width: 768px) {
    height: 600px;
  }
`;

export const Herocontainer = styled(Container)``;

export const HeroBGOverlay = styled.div`
  height: inherit;
  background: #000000;
  opacity: 0.5;
  position: absolute;
  width: 100%;
`;

export const HeroContant = styled(Row)`
  padding-top: 150px;
  position: relative;

  @media (max-width: 1024px) {
    padding-top: 150px;
  }

  @media (max-width: 768px) {
    padding-top: 100px;
  }
`;

export const HeroTitle = styled.h1`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 82px;
  line-height: 89px;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 30px 0px;
  @media (max-width: 768px) {
    font-size: 60px;
    line-height: 60px;
  }

  @media (max-width: 425px) {
    font-size: 45px;
    line-height: 60px;
  }

  @media (max-width: 375px) {
    font-size: 40px;
    line-height: 50px;
  }
`;
