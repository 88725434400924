import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 60px 0px;
`;

export const Header = styled.h6`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #212f52;
`;

export const SliderWrapper = styled.div`
  .slick-slide {
    padding: 0px 0px;
  }
  .slick-prev {
    z-index: 10;

    &:before {
      content: none;
      color: '#000' !important;
    }
  }
  .slick-next {
    top: 0px;
    z-index: 10;
    &:before {
      content: none;
      color: '#000' !important;
    }
    height: 100%;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  @media (max-width: 1320px) {
    .slick-prev {
      width: 25px;
    }
    .slick-next {
      width: 25px;
    }
  }

  @media (max-width: 1024px) {
    .slick-prev {
      width: 20px;
    }
    .slick-next {
      width: 20px;
    }
  }
  @media (max-width: 768px) {
    .slick-prev {
      width: 10px;
    }
    .slick-next {
      width: 10px;
    }
  }
`;

export const Wrapper = styled.div`
  background: #caccd1;
  border-radius: 15px;
  height: 170px;
  flex: none;
  width: auto;
  order: 0;
  flex-grow: 0;
  padding-right: 40%;
  padding-top: 13px;
  div {
    flex-direction: row;
    align-items: flex-start;
    background: #212f52;
  }
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
  }
`;

export const TitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #212f52;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
  }
  .heart {
    display: flex;
  }
`;
