import React from 'react';
import { Modal, Title, Description, Footer, Button } from './styled';

const CheckOutModal = ({ show, handleClose }: any): JSX.Element => (
  <Modal show={show} handleClose={handleClose}>
    <Title>Wondering if you can check out later?</Title>
    <Description>
      {`Late check-out is a popular request, but our cleaning schedule doesn't
      always allow for it.`}
    </Description>
    <Footer>
      <Button className="button-submit">Ok</Button>
    </Footer>
  </Modal>
);

export default CheckOutModal;
