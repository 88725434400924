import React, { useEffect, useState } from 'react';
import { collection, getDocs, query } from 'firebase/firestore';
import { Row, Col, Spinner } from 'react-bootstrap';
import {
  BGWrapper,
  ComponentsContainer as Container,
  ItemBox,
  Tab,
  Tabs,
  ItemWrapper,
  FeatureItemWrapper,
  FeatureItemImage,
  FeatureItemLocation,
  FeatureItemName,
  FeatureItemText,
  ContinueReadingLink,
  SeeMoreText,
  FeatureItemTitle,
  FeatureItemItem,
  ViewMoreButton,
  NoDataFound,
  Rowcontainer,
} from './styled';
import LandingComponentHeader from '../../Common/LandingComponentHeader';
import CityFeturedHotel from '../../Assets/Images/CityFeturedHotel.jpg';
import LocationIcon from '../../Assets/Images/Icons/LocationIcon.svg';
import { db } from '../../Config/firebase';

const CitywiseHotels: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [cities, setCities] = useState<any>([]);
  const [citiesdata, setCitiesData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getCityLiist = async () => {
      const q = query(collection(db, 'cities'));
      const querySnapshot = await getDocs(q);
      const tempdata: any = [];
      querySnapshot.forEach(async docs => {
        tempdata.push({
          ...docs?.data(),
          id: docs?.id,
        });
      });
      setCities(tempdata);
      if (tempdata && tempdata.length) {
        if (tempdata[0]?.name) {
          setActiveTab(tempdata[0]?.name);
        }
      }
    };
    getCityLiist();
  }, []);

  useEffect(() => {
    setLoading(true);
    const getCityDataList = async () => {
      const tempdata: any = [];
      const exploreRef = db
        .collection('explore')
        .where('address.city', '==', activeTab);
      const querySnapshot = await exploreRef.get();
      querySnapshot?.forEach(doc => {
        tempdata.push({
          ...doc?.data(),
          id: doc?.id,
        });
      });
      setCitiesData(tempdata);
      setLoading(false);
    };
    if (activeTab) {
      getCityDataList();
    }
  }, [activeTab]);

  return (
    <BGWrapper>
      <Container fluid>
        <Col lg={6} md={10} sm={12}>
          <LandingComponentHeader
            title="A stay infused with
          creativity and culture"
            text="From award-wining interiors to curated neighbourhood
          guides, our stays celebrate what’s special about each city
          we call home."
          />
        </Col>
        <Tabs style={{ display: 'flex', overflowX: 'auto' }}>
          {cities?.map((res: any) => (
            <Tab
              key={`tab-${res?.name}`}
              className={`${res?.name === activeTab ? 'active' : ''} `}
              onClick={() => setActiveTab(res?.name)}
            >
              {res?.name}
            </Tab>
          ))}
        </Tabs>
        <Rowcontainer>
          <Col sm={12} md={6} lg={6}>
            <FeatureItemImage src={CityFeturedHotel} />
          </Col>
          <Col sm={12} md={6} lg={6}>
            <FeatureItemWrapper>
              <FeatureItemLocation src={LocationIcon}>
                Callowhill, Philadelphia
              </FeatureItemLocation>
              <FeatureItemName>The Heid building</FeatureItemName>
              <FeatureItemText>
                Class industrial meets modern comfort. With its impossibly high
                ceilings, murals, and soaring window lines, experience
                Philadelphia like never before at the help.
              </FeatureItemText>
              <ContinueReadingLink to="/">Continue reading</ContinueReadingLink>
            </FeatureItemWrapper>
          </Col>
        </Rowcontainer>
        {!loading ? (
          <>
            <SeeMoreText>
              {citiesdata && citiesdata.length ? (
                'Stay at The Heid building'
              ) : (
                <NoDataFound>No data found</NoDataFound>
              )}
            </SeeMoreText>
            <Row style={{ padding: '18px 0px 30px' }}>
              {citiesdata?.map((item: any) => (
                <ItemWrapper lg={3} md={4} sm={12} key={item.id}>
                  <ItemBox>
                    <div className="image-contrainer">
                      <div className="price-tag">
                        <h5>${item.price.amount}</h5>
                        <p>Avg Rate</p>
                      </div>
                      <div
                        className="image"
                        style={{ backgroundImage: `url(${item.pictures[0]})` }}
                      />
                    </div>
                    <FeatureItemTitle>{item.title}</FeatureItemTitle>
                    <div className="d-flex">
                      <div>
                        <FeatureItemItem>
                          <p>{item.capacity.bedrooms} Bedrooms</p>
                        </FeatureItemItem>
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <FeatureItemItem>
                          <p className="px-1">
                            <span>&bull;</span>&nbsp;&nbsp;&nbsp;
                            {item.capacity.bathrooms} Bathrooms
                          </p>
                        </FeatureItemItem>
                      </div>
                    </div>
                  </ItemBox>
                </ItemWrapper>
              ))}
            </Row>
          </>
        ) : (
          <div className="text-center py-5">
            <Spinner animation="border" variant="warning" />
          </div>
        )}
        <div className="d-flex justify-content-center">
          <ViewMoreButton>View all spaces</ViewMoreButton>
        </div>
      </Container>
    </BGWrapper>
  );
};
export default CitywiseHotels;
