import React from 'react';
import {
  BGWrapper,
  Descripation,
  FirstInnerImage,
  FirstWrapper,
  FullWrapper,
  HostName,
  MainDiv,
  Quote,
  SecondInnerWrapper,
  SecondMainInnerFirstDiv,
  SecondMainInnerSecondDiv,
  SecondMainInnerThirdDiv,
  SecondWrapper,
  ThirdInnerFirstDiv,
  ThirdInnerSecondDiv,
  Title,
  WriteingWrapper,
} from './styled';
import QuoteIcon from '../../../Assets/Images/Icons/QuoteIcon.svg';

interface FullKitchenProps {
  element: any;
}

const FullKitchen: React.FC<FullKitchenProps> = ({
  element,
}: FullKitchenProps) => (
  <BGWrapper>
    <MainDiv>
      <FirstWrapper>
        <Title>{element?.title}</Title>
        <Descripation>
          {element?.room_feature?.map((item: any, i: number) =>
            i === 0 ? (
              <span key={`item${item?.id}`}>{item?.text}</span>
            ) : (
              <li key={`item${item?.id}`}>{item?.text}</li>
            ),
          )}
        </Descripation>
      </FirstWrapper>
      <SecondWrapper>
        <SecondMainInnerFirstDiv>
          <FullWrapper
            style={{
              backgroundImage: `url(${element?.photos[0]?.original})`,
            }}
          />
        </SecondMainInnerFirstDiv>
        <SecondMainInnerSecondDiv>
          <div style={{ width: '50%', padding: '5px' }}>
            <FirstInnerImage
              style={{
                backgroundImage: `url(${element?.photos[1]?.original})`,
              }}
            />
          </div>
          <div style={{ width: '50%', padding: '5px' }}>
            <FirstInnerImage
              style={{
                backgroundImage: `url(${element?.photos[2]?.original})`,
              }}
            />
          </div>
        </SecondMainInnerSecondDiv>
        <SecondMainInnerFirstDiv>
          <FullWrapper
            style={{
              backgroundImage: `url(${element?.photos[3]?.original})`,
            }}
          />
        </SecondMainInnerFirstDiv>
        <SecondMainInnerThirdDiv>
          <ThirdInnerFirstDiv>
            <WriteingWrapper>
              <div style={{ padding: '70px 40px' }}>
                <img
                  src={QuoteIcon}
                  alt="quote"
                  style={{ paddingBottom: '25px' }}
                />
                <Quote>
                  Well equipt kitchen opens to sun room and back yard.
                </Quote>
                <HostName>Lisa, your host</HostName>
              </div>
            </WriteingWrapper>
          </ThirdInnerFirstDiv>
          <ThirdInnerSecondDiv>
            <SecondInnerWrapper
              style={{
                backgroundImage: `url(${element?.photos[4]?.original})`,
              }}
            />
          </ThirdInnerSecondDiv>
        </SecondMainInnerThirdDiv>
      </SecondWrapper>
    </MainDiv>
  </BGWrapper>
);

export default FullKitchen;
