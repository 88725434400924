import { Row } from 'react-bootstrap';
import styled from 'styled-components';

import LeituraFont from '../../Assets/Fonts/LeituraDisplayRoman.otf';

export const ContantHeader = styled(Row)`
  position: relative;
`;

export const Title = styled.h1`
  @font-face {
    font-family: 'Leitura Display';
    src: local('Leitura Display'), local('LeituraDisplay'),
      url(${LeituraFont}) format('truetype');
  }
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 54px;
  line-height: 58px;
  color: #212f52;
  margin-bottom: 25px;
  @media (max-width: 425px) {
    font-size: 36px;
  }
`;

export const SubTitle = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #6a737b;
`;
