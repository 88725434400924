import React from 'react';
import { Box, LoaderItem } from './styled';

const Loader = (): JSX.Element => (
  <Box>
    <LoaderItem />
  </Box>
);

export default Loader;
