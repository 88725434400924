import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail, LinkTag, Questions, DotTag } from './styled';

const YourRights: React.FC = () => (
  <>
    <Row>
      <Questions>11. Your Rights and Choices</Questions>
      <Detail>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          <b> Marketing opt-out.</b> If you decide at any time that you no
          longer wish to receive marketing communications from us, please follow
          the unsubscribe instructions provided in any of the communications.
          Please note that even if you opt out of receiving promotional messages
          from us, you will continue to receive administrative messages from us.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          <b> Changing or deleting information.</b> All Members may review,
          update, correct or delete the Personal Information in their
          registration profile by contacting us at{' '}
          <LinkTag to="/">privacy@gilberthotels.com</LinkTag> or editing the
          relevant part of their profile. If you would like us to cancel your
          Gilbert Hotels Account, please contact us and we will attempt to
          accommodate your request if we do not have any legal obligation or a
          legitimate business reason to retain the information contained in your
          Gilbert Hotels Account. Please note that, if you cancel your Gilbert
          Hotels Account, any reviews you have posted via the Site will remain
          publicly viewable via the Site. Please see below for privacy contact
          information.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          <b> Opt out from cookies.</b> Most Internet browsers automatically
          accept cookies, but you may be able to change the settings of your
          browser to stop accepting cookies or to prompt you before accepting a
          cookie from the websites you visit. If you set your browser to reject
          cookies, parts of our website may not work for you.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          <b> European privacy rights.</b> If you are located in the EEA or
          Switzerland, you are entitled to reach out to us via the contact
          details in this Policy and ask us for an overview of your Personal
          Information or ask for a copy. In addition, you may request us to
          update and correct inaccuracies, delete your Personal Information,
          restrict processing of your Personal Information, or exercise your
          right to data portability and to easily transfer your Personal
          Information to another company. In some cases, you may object to the
          processing of your Personal Information and where we have asked you
          for your consent to process your Personal Information, you can
          withdraw it at any time. These rights may be limited in some
          circumstances by local law requirements. We always enjoy hearing from
          you and appreciate your business. Should you nonetheless have
          unresolved concerns, you have the right to lodge a complaint with the
          supervisory authority of your residence, place of work or where the
          incident took place.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          <b> California privacy rights.</b> If you are a California resident,
          please review our California Resident Privacy Notice for more
          information regarding your California privacy rights.
        </p>
      </Detail>
    </Row>
  </>
);

export default YourRights;
