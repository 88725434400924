import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 50px 0px;
`;

export const BackLink = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #212f52;
`;

export const BusinessLink = styled(Link)`
  text-decoration-line: underline;
  color: #212f52;
`;

export const BusinessHotelDetail = styled.div`
  h6 {
    font-family: 'Leitura Display';
    font-style: normal;
    font-weight: 400;
    font-size: 54px;
    line-height: 58px;
    color: #212f52;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 50px 0px;
    @media (max-width: 425px) {
      margin: 0px;
    }
  }
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #212f52;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 30px 0px;
  }
`;
