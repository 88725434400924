import React from 'react';
import { Wrapper, Heading, NeighborName, NeighborDetail } from './styled';

interface TheNeighborhoodProps {
  explore: any;
}

const TheNeighborhood: React.FC<TheNeighborhoodProps> = ({
  explore,
}: TheNeighborhoodProps) => (
  <Wrapper>
    <Heading>The neighborhood</Heading>
    <NeighborName>Oud-Zuid</NeighborName>
    <NeighborDetail>{explore?.neighborhood}</NeighborDetail>
  </Wrapper>
);
export default TheNeighborhood;
