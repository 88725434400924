import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 0px 0px;
`;

export const Temp = styled.div`
  padding: 60px 0px;
`;

export const LorderDiv = styled.div`
  margin: 25% 50%;
`;
