import { serverTimestamp } from 'firebase/firestore';
import React from 'react';
import { toast } from 'react-toastify';
import { db } from '../../../Config/firebase';
import { Title, Description, Modal, Footer, Button } from './styled';

const ReservationConfirmation = ({
  show,
  handleClose,
  getReservationData,
  detail,
}: any): JSX.Element => {
  const cancelReservation = async (cancel_id: string) => {
    try {
      await db
        .collection('reservations')
        .doc(cancel_id)
        .set(
          { status: 'cancelled', canceled_at: serverTimestamp() },
          { merge: true },
        );
      toast.success('Booking Cancelled');
      handleClose(false);
      getReservationData();
    } catch (error) {
      toast.error(error);
      handleClose(false);
    }
  };
  return (
    <Modal show={show} handleClose={handleClose} className="Width">
      <Title>Are you sure you want to cancel this reservation?</Title>
      <Description>
        Since you are within the cencellation period, you will receive a 100%
        refund to your original form of payment (US $
        {detail?.invoice?.total_price})
      </Description>
      <Description>
        Once you cancel, your reservation cannot be reinstated.
      </Description>
      <Footer>
        <Button
          className="button-submit"
          onClick={() => cancelReservation(detail?.uuid)}
        >
          Yes, Cancel This Reservation
        </Button>
        <Button className="button-cancel" onClick={() => handleClose(false)}>
          No, Go Back
        </Button>
      </Footer>
    </Modal>
  );
};

export default ReservationConfirmation;
