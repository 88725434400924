import React from 'react';
import { Col, NavDropdown, Row } from 'react-bootstrap';

import FooterMain, {
  FooterContainer,
  Logo,
  Label,
  ListingSection,
  List,
  LanguageDropDown,
  CompanyInfo,
  ContactInfo,
  Copyrights,
  CompanyInfoLabel,
  SearchDiv,
  SearchDetails,
} from './styled';
import FooterLogo from '../../Assets/Images/FooterLogo.svg';
import ArrowIcon from '../../Assets/Images/Icons/ArrowIcon.svg';
import Facebook, { Instagram, Twitter } from '../Icons';

const companyList = [
  'About Us ',
  'News',
  'Careers',
  'Press',
  'Business and Groups',
  'Real Estate',
  'Responsibility',
  'Investor Realations',
];

const supportList = ['Help Center', 'Security'];

const languageList = ['English', 'Gujarati', 'Franch'];

const Footer: React.FC = () => (
  <FooterMain>
    <FooterContainer fluid>
      <Row>
        <Col>
          <Logo src={FooterLogo} alt="footer" />
        </Col>
      </Row>
      <Row>
        <Col xxl={2} xl={3} lg={3} md={6} className="mt-5">
          <Label>Company</Label>
          <ListingSection>
            {companyList?.map((res: string) => (
              <List key={`company-${res}`}>{res}</List>
            ))}
          </ListingSection>
        </Col>
        <Col xxl={2} xl={3} lg={3} md={6} className="mt-5">
          <Label>Support</Label>
          <ListingSection>
            {supportList?.map((res: string) => (
              <List key={`support-${res}`}>{res}</List>
            ))}
          </ListingSection>
        </Col>
        <Col xxl={2} xl={3} lg={3} md={6} className="mt-5">
          <Label>Social</Label>
          <ListingSection>
            <Facebook fill="#fff" />
            <Instagram fill="#fff" />
            <Twitter fill="#fff" />
          </ListingSection>
        </Col>
        <Col xxl={2} xl={3} lg={3} md={6} className="mt-5">
          <Label>language</Label>
          <LanguageDropDown title="English" className="language-title ">
            {languageList?.map((res: string) => (
              <NavDropdown.Item
                className="navdropdown-item"
                key={`language-${res}`}
              >
                {res}
              </NavDropdown.Item>
            ))}
          </LanguageDropDown>
        </Col>
        <Col xxl={3} xl={3} lg={3} md={6} className="mt-5">
          <Label>STAY UPDATED</Label>
          <SearchDiv>
            <input type="email" placeholder="Email Address" />
            <img src={ArrowIcon} alt="arrow" />
          </SearchDiv>
          <SearchDetails className="pt-1">
            Sign up to get the latest Gilbert Hotels news, city launches, and
            exclusive deals.
          </SearchDetails>
        </Col>
      </Row>
      <CompanyInfo>
        <Col xxl={8} xl={6} lg={6}>
          <Copyrights>
            <CompanyInfoLabel>
              Ⓒ 2021 Gilbert Hotels. All rights reserved.
            </CompanyInfoLabel>
          </Copyrights>
        </Col>
        <Col xxl={4} xl={6} lg={6}>
          <ContactInfo>
            <CompanyInfoLabel>+123-456-9789</CompanyInfoLabel>
            <CompanyInfoLabel>Terms of Service</CompanyInfoLabel>
            <CompanyInfoLabel>Privacy Policy</CompanyInfoLabel>
          </ContactInfo>
        </Col>
      </CompanyInfo>
    </FooterContainer>
  </FooterMain>
);

export default Footer;
