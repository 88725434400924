import React from 'react';
import { Link } from 'react-router-dom';

import { Wrapper, Heading } from './styled';

const ForMoreDetail: React.FC = () => (
  <Wrapper>
    <Heading>Anything else you’d like to know?</Heading>
    <div className="pt-1">
      Message our team at <Link to="/">inquires@gilberthotels.com</Link>
    </div>
  </Wrapper>
);
export default ForMoreDetail;
