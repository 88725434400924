import React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  BGWrapper,
  Herocontainer as Container,
  CityBox,
  CityBoxText,
  CityBoxLink,
  ViewMoreButton,
} from './styled';
import LandingComponentHeader from '../../Common/LandingComponentHeader';

import City1 from '../../Assets/Images/CitysImages/City1.jpg';
import City2 from '../../Assets/Images/CitysImages/City2.jpg';
import City3 from '../../Assets/Images/CitysImages/City3.jpg';
import City4 from '../../Assets/Images/CitysImages/City4.jpg';

const supportList = [
  { id: 1, text: 'The next step in travel’s future', image: City1 },
  { id: 2, text: 'The next step in travel’s future', image: City2 },
  { id: 3, text: 'The next step in travel’s future', image: City3 },
  { id: 4, text: 'The next step in travel’s future', image: City4 },
];

const LandingHotelPlus: React.FC = () => (
  <BGWrapper>
    <Container fluid>
      <Col lg={6} md={9} sm={12}>
        <LandingComponentHeader
          title="Gilbert hotels plus"
          text="From latest news to team interviews, learn more about the
          passion that powers."
        />
      </Col>
      <Row className="py-5">
        {supportList.map(item => (
          <CityBox
            className="side-section"
            lg={3}
            md={3}
            sm={3}
            key={`${item.id}`}
          >
            <div
              style={{
                backgroundImage: `url(${item.image})`,
                borderRadius: '15px',
              }}
            >
              {' '}
            </div>
            <CityBoxText>{item.text}</CityBoxText>
            <CityBoxLink to="/">Continue reading</CityBoxLink>
          </CityBox>
        ))}
      </Row>
      <div className="d-flex justify-content-center">
        <ViewMoreButton>Go to blog</ViewMoreButton>
      </div>
    </Container>
  </BGWrapper>
);

export default LandingHotelPlus;
