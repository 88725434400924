import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail } from './styled';

const ThirdPartyTerms: React.FC = () => (
  <Row>
    <Detail>
      <p>
        <b>10. Third-Party Terms.</b> We may provide tools or features through
        the Service that enable you to interact with, including export
        information to, third-party websites or services, including through
        features that allow you to link your Gilbert Hotels account with an
        account on a third-party website or service, such as Twitter, Facebook,
        or a third-party payment processor, or through our implementation of
        third-party buttons (such as “like” or “share” buttons). By using one of
        these tools or features, you agree that we may transfer such exported
        information to the applicable third-party website or service.
        Third-party websites and services are not under our control, and, to the
        fullest extent permitted by law, we are not responsible for any
        third-party service’s use of your exported information. You acknowledge
        and agree that such interactions with third-party websites or services
        may be governed by one or more third parties’ terms, conditions, and/or
        policies.
      </p>
    </Detail>
  </Row>
);

export default ThirdPartyTerms;
