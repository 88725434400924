import React from 'react';
import BusinessAndGroupContainer from '../../Components/BusinessAndGroupContainer';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';

const BusinessAndGroup: React.FC = () => (
  <div>
    <Header hasLogin />
    <BusinessAndGroupContainer />
    <Footer />
  </div>
);

export default BusinessAndGroup;
