import React from 'react';
import { Col } from 'react-bootstrap';

import {
  HeroBG,
  HeroBGOverlay,
  Herocontainer,
  HeroContant,
  HeroTitle,
} from './styled';

const ResponsibilityHero = ({ bgImage, title }: any): JSX.Element => (
  <HeroBG src={bgImage}>
    <HeroBGOverlay />
    <Herocontainer fluid>
      <HeroContant>
        <Col xl={12} lg={12} md={12} sm={12}>
          <HeroTitle>{title}</HeroTitle>
        </Col>
      </HeroContant>
    </Herocontainer>
  </HeroBG>
);

export default ResponsibilityHero;
