import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const Heading = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #212f52;
`;

export const IllustrationDiv = styled.div`
  background: #ffffff;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 15px;
`;

export const IllustrationImage = styled.img`
  width: 100%;
`;

export const ContainerWrapper = styled(Container)`
  @media (max-width: 1024px) {
    padding: 0px;
  }
`;
