import React from 'react';
import { Col } from 'react-bootstrap';
import Slider from 'react-slick';
import {
  BGWrapper,
  ComponentsContainer as Container,
  SliderWrapper,
  TestimonialBlock,
  TestimonialText,
  TestimonialOwner,
  TestimonialOwnerLocation,
} from './Style';
import LandingComponentHeader from '../../Common/LandingComponentHeader';
import SliderPrevIcon from '../../Assets/Images/Icons/SliderPrevIcon.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const testimonialsList = [
  {
    id: 1,
    text: 'This unit was perfect! I needed a furnished apartment for a few months and Gilbert Hotels thought of everything!',
    owner: 'Leah',
    location: 'San Antonio',
    bg: '#FFEEBF',
  },
  {
    id: 2,
    text: 'This unit was perfect! I needed a furnished apartment for a few months and Gilbert Hotels thought of everything!',
    owner: 'Leah',
    location: 'San Antonio',
    bg: '#FFEEBF',
  },
  {
    id: 3,
    text: 'This unit was perfect! I needed a furnished apartment for a few months and Gilbert Hotels thought of everything!',
    owner: 'Leah',
    location: 'San Antonio',
    bg: '#FFEEBF',
  },
  {
    id: 4,
    text: 'This unit was perfect! I needed a furnished apartment for a few months and Gilbert Hotels thought of everything!',
    owner: 'Leah',
    location: 'San Antonio',
    bg: '#FFEEBF',
  },
  {
    id: 5,
    text: 'This unit was perfect! I needed a furnished apartment for a few months and Gilbert Hotels thought of everything!',
    owner: 'Leah',
    location: 'San Antonio',
    bg: '#FFEEBF',
  },
  {
    id: 6,
    text: 'This unit was perfect! I needed a furnished apartment for a few months and Gilbert Hotels thought of everything!',
    owner: 'Leah',
    location: 'San Antonio',
    bg: '#FFEEBF',
  },
];

const NextArrow = ({ className, onClick }: any) => (
  <div
    role="button"
    className={className}
    style={{ display: 'flex', alignItems: 'center' }}
    onClick={onClick}
    onKeyDown={() => {}}
    tabIndex={0}
  >
    <img src={SliderPrevIcon} alt="prev-icon" />
  </div>
);

const PrevArrow = ({ className, onClick }: any) => (
  <div
    role="button"
    className={className}
    style={{ display: 'flex', alignItems: 'center' }}
    onClick={onClick}
    onKeyDown={() => {}}
    tabIndex={-1}
  >
    <img src={SliderPrevIcon} alt="prev-icon" style={{ paddingLeft: '7%' }} />
  </div>
);

const settings = {
  centerMode: true,
  centerPadding: '500px',
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  infinite: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        centerMode: true,
        centerPadding: '250px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        centerMode: true,
        centerPadding: '250px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        centerMode: true,
        centerPadding: '200px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        centerMode: true,
        centerPadding: '70px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 720,
      settings: {
        centerMode: true,
        centerPadding: '25px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        centerMode: true,
        centerPadding: '100px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 425,
      settings: {
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 1,
      },
    },
  ],
};

const LandingTestimonials: React.FC = () => (
  <BGWrapper>
    <Container fluid>
      <Col lg={6} md={10} sm={12}>
        <LandingComponentHeader
          title="Every stay has a story"
          text="It’s hard to explain what makes Gilbert Hotels so special. Unless,
        of course, you’re one of our guests."
        />
      </Col>
    </Container>
    <SliderWrapper>
      <Slider {...settings}>
        {testimonialsList.map(item => (
          <TestimonialBlock bg={item.bg} key={item.id}>
            <TestimonialText>“{item.text}”</TestimonialText>
            <TestimonialOwner>{item.owner}</TestimonialOwner>
            <TestimonialOwnerLocation>{item.location}</TestimonialOwnerLocation>
          </TestimonialBlock>
        ))}
      </Slider>
    </SliderWrapper>
  </BGWrapper>
);

export default LandingTestimonials;
