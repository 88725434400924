import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-bottom: 50px;
  div {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #212f52;
    a {
      color: #212f52;
    }
  }
  @media (max-width: 768px) {
    padding-bottom: 20px;
  }

  @media (max-width: 425px) {
    padding-bottom: 20px;
  }
`;

export const Heading = styled.h1`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #212f52;
`;
