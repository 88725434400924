import styled from 'styled-components';

export const FilterWrapper = styled.div`
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 33px;
  display: flex;
  justify-content: center;
  padding: 15px 14px;
  &:hover {
    background: #212f5214;
  }
  #dropdown-item-button {
    background: none;
    outline: none;
    box-shadow: none;
    color: #212f52;
    border: none;
    :focus-visible {
      outline: none;
      box-shadow: none;
    }
    .btn:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .dropdown-menu {
    min-width: 700px;
    border-radius: 15px;
  }
  .dropdown-item-text {
    color: #212f52;
    padding: 0;
  }
  .form-check-input[type='checkbox'] {
    border-radius: 50px;
  }
`;

export const Title = styled.p`
  font-family: Arial;
  color: #212f52;
`;

export const RemoveButton = styled.button`
  border: 0;
  background-color: transparent;
`;
export const AddButton = styled.button`
  border: 0;
  background-color: transparent;
`;
