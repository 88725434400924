import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { NavDropdown } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  RowWrapper,
  CardHeader,
  DetailWrapper,
  StayDuration,
  StayName,
  StayAddress,
  ActionWrapper,
  ReservationDetailButton,
  NoBooking,
  LorderDiv,
} from './styled';
import { auth, db } from '../../Config/firebase';
import ReservationConfirmation from '../Modal/ReservationConfirmation';
import AnimationLoader from '../../Common/Loader/AnimationLoader';

const MyStaysLists: React.FC = () => {
  const [reservations, setReservations] = useState<any>();
  const [loading, setloading] = useState<boolean>(false);
  const [isShowReservation, setIsShowReservation] = useState(false);
  const [cancelStayDetail, setCancelStayDetail] = useState();

  const today = moment(new Date()).format('YYYY-MM-DD');
  const uid = auth?.currentUser?.uid;

  const getReservationData = async () => {
    setloading(true);
    // const q = query(collection(db, 'my-stays'), where('guest.uuid', '==', uid));
    const q = query(
      collection(db, 'reservations'),
      where('guest.uuid', '==', uid),
    );
    const querySnapshot = await getDocs(q);
    const promises: any = [];
    if (querySnapshot?.size === 0) {
      setReservations(null);
      setloading(false);
    } else {
      querySnapshot?.forEach(async docs => {
        promises.push({
          ...docs?.data(),
          id: docs.id,
        });
      });
      Promise.all(promises).then(async results => {
        const sortData = await results?.sort(
          (a: any, b: any) =>
            new Date(a?.check_in_time).getTime() -
            new Date(b?.check_in_time).getTime(),
        );
        const sortWithStatus = await sortData.sort((a: any, b: any) =>
          a.status < b.status ? 1 : -1,
        );
        setReservations(sortWithStatus);
        setloading(false);
      });
    }
  };

  useEffect(() => {
    getReservationData();
  }, []);

  return (
    <>
      {reservations === null || reservations?.length === 0 ? (
        <NoBooking>No Bookings</NoBooking>
      ) : (
        <div>
          {!loading ? (
            <div>
              {reservations?.map((ele: any) => (
                <div>
                  {(ele.status === 'succeeded' ||
                    ele.status === 'date_change') &&
                    moment(ele?.start_date).format('YYYY-MM-DD') >= today && (
                      <div key={`12${ele?.id}`}>
                        <>
                          <div>
                            <RowWrapper className="mt-4">
                              <CardHeader>Upcoming stays</CardHeader>
                              <DetailWrapper>
                                <img src={ele?.unit_picture} alt="hotel-pic" />
                                <StayDuration>
                                  {moment(ele?.start_date).format('MMMM DD')} -{' '}
                                  {moment(ele?.end_date).format('MMMM DD')}
                                </StayDuration>
                                <StayName>{ele?.unit_name}</StayName>
                                <StayAddress>{ele?.address}</StayAddress>
                                <ActionWrapper>
                                  <ReservationDetailButton
                                    as={Link}
                                    to={`/reservation-detail/${ele?.uuid}`}
                                  >
                                    Reservation Details
                                  </ReservationDetailButton>
                                  <NavDropdown
                                    title="Manage Reservation..."
                                    className="stay-menu"
                                  >
                                    <NavDropdown.Item
                                      as={Link}
                                      to={`/add-guests/${ele?.uuid}`}
                                    >
                                      Add guests
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                      as={Link}
                                      to={`/edit-check-in-out-time/${ele?.uuid}`}
                                    >
                                      Edit check-in or checkout time
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                      as={Link}
                                      to={`/edit-check-in-out-date/${ele?.uuid}`}
                                    >
                                      Change dates
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                      onClick={() => {
                                        setCancelStayDetail(ele);
                                        setIsShowReservation(true);
                                      }}
                                    >
                                      Cancel reservation
                                    </NavDropdown.Item>
                                  </NavDropdown>
                                </ActionWrapper>
                              </DetailWrapper>
                            </RowWrapper>
                          </div>
                          <ReservationConfirmation
                            show={isShowReservation}
                            handleClose={() => setIsShowReservation(false)}
                            detail={cancelStayDetail}
                            getReservationData={getReservationData}
                          />
                        </>
                      </div>
                    )}
                  {ele?.status === 'cancelled' &&
                    moment(ele?.check_in_date, 'YYYY-MM-DD').format() >
                      today && (
                      <>
                        <div key={ele?.id}>
                          <RowWrapper className="mt-4">
                            <CardHeader>Cancelled stays</CardHeader>
                            <DetailWrapper>
                              <img src={ele?.unit_picture} alt="hotel-pic" />
                              <StayDuration>
                                {moment(ele?.start_date).format('MMMM DD')} -{' '}
                                {moment(ele?.end_date).format('MMMM DD')}
                              </StayDuration>
                              <StayName>{ele?.unit_name}</StayName>
                              <StayAddress>{ele?.address}</StayAddress>
                            </DetailWrapper>
                          </RowWrapper>
                        </div>
                      </>
                    )}
                  {ele?.status !== 'cancelled' &&
                    moment(ele?.start_date, 'YYYY-MM-DD').format() < today && (
                      <div key={ele.id}>
                        <RowWrapper className="mt-4">
                          <CardHeader>Previous stays</CardHeader>
                          <DetailWrapper>
                            <img src={ele?.unit_picture} alt="hotel-pic" />
                            <StayDuration>
                              {moment(ele?.start_date).format('MMMM DD')} -{' '}
                              {moment(ele?.end_date).format('MMMM DD')}
                            </StayDuration>
                            <StayName>{ele?.unit_name}</StayName>
                            <StayAddress>{ele?.address}</StayAddress>
                            <ActionWrapper>
                              <ReservationDetailButton
                                as={Link}
                                to={`/reservation-detail/${ele?.uuid}`}
                              >
                                Reservation Details
                              </ReservationDetailButton>
                            </ActionWrapper>
                          </DetailWrapper>
                        </RowWrapper>
                      </div>
                    )}
                </div>
              ))}
            </div>
          ) : (
            <LorderDiv>
              <AnimationLoader />
            </LorderDiv>
          )}
        </div>
      )}
    </>
  );
};
export default MyStaysLists;
