import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-bottom: 50px;
`;

export const Heading = styled.h1`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 34px;
  color: #212f52;
  @media (max-width: 425px) {
    font-size: 24px;
  }
`;

export const ItemBox = styled.div`
  display: flex;
  padding-bottom: 35px;
  img {
    align-self: start;
    width: 36px;
    margin-right: 36px;
  }

  h4 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #212f52;
    padding-bottom: 15px;
  }

  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #212f52;
  }

  .note {
    padding-top: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #6a737b;
  }

  li {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #212f52;
  }
`;
