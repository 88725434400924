import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import {
  BGWrapper,
  SliderWrapper,
  RoomImageBlock,
  ViewAllPhotosWrapper,
  ViewAllPhotoLink,
} from './styled';
import SliderYallow from '../../Assets/Images/Icons/SliderYallow.svg';
import Room1 from '../../Assets/Images/HotelDetail/Room1.png';
import Room2 from '../../Assets/Images/HotelDetail/Room2.png';
import Room3 from '../../Assets/Images/HotelDetail/Room3.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const RoomImages = [
  {
    id: 1,
    pictures: Room1,
  },
  {
    id: 2,
    pictures: Room2,
  },
  {
    id: 3,
    pictures: Room3,
  },
];

const NextArrow = ({ className, onClick }: any) => (
  <div
    role="button"
    className={className}
    style={{ display: 'flex', alignItems: 'center' }}
    onClick={onClick}
    onKeyDown={() => {}}
    tabIndex={0}
  >
    <img src={SliderYallow} alt="prev-icon" style={{ marginLeft: '30%' }} />
  </div>
);

const PrevArrow = ({ className, onClick }: any) => (
  <div
    role="button"
    className={className}
    style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
    onClick={onClick}
    onKeyDown={() => {}}
    tabIndex={-1}
  >
    <img src={SliderYallow} alt="prev-icon" style={{ marginLeft: '30%' }} />
  </div>
);

const settings = {
  centerMode: true,
  centerPadding: '950px',
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  infinite: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 2508,
      settings: {
        centerMode: true,
        centerPadding: '700px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1900,
      settings: {
        centerMode: true,
        centerPadding: '590px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1850,
      settings: {
        centerMode: true,
        centerPadding: '550px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1750,
      settings: {
        centerMode: true,
        centerPadding: '500px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1700,
      settings: {
        centerMode: true,
        centerPadding: '470px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        centerMode: true,
        centerPadding: '450px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1550,
      settings: {
        centerMode: true,
        centerPadding: '400px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1450,
      settings: {
        centerMode: true,
        centerPadding: '370px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1400,
      settings: {
        centerMode: true,
        centerPadding: '350px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1350,
      settings: {
        centerMode: true,
        centerPadding: '300px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1270,
      settings: {
        centerMode: true,
        centerPadding: '280px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1250,
      settings: {
        centerMode: true,
        centerPadding: '270px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1210,
      settings: {
        centerMode: true,
        centerPadding: '250px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        centerMode: true,
        centerPadding: '230px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1125,
      settings: {
        centerMode: true,
        centerPadding: '200px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1070,
      settings: {
        centerMode: true,
        centerPadding: '195px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1055,
      settings: {
        centerMode: true,
        centerPadding: '190px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1050,
      settings: {
        centerMode: true,
        centerPadding: '180px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1030,
      settings: {
        centerMode: true,
        centerPadding: '150px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 970,
      settings: {
        centerMode: true,
        centerPadding: '140px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 952,
      settings: {
        centerMode: true,
        centerPadding: '130px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 930,
      settings: {
        centerMode: true,
        centerPadding: '120px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 915,
      settings: {
        centerMode: true,
        centerPadding: '110px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 890,
      settings: {
        centerMode: true,
        centerPadding: '100px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 870,
      settings: {
        centerMode: true,
        centerPadding: '90px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 850,
      settings: {
        centerMode: true,
        centerPadding: '80px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 830,
      settings: {
        centerMode: true,
        centerPadding: '70px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 810,
      settings: {
        centerMode: true,
        centerPadding: '50px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        centerMode: true,
        centerPadding: '30px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 715,
      settings: {
        centerMode: true,
        centerPadding: '10px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 680,
      settings: {
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 655,
      settings: {
        centerMode: true,
        centerPadding: '25px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 625,
      settings: {
        centerMode: true,
        centerPadding: '15px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 590,
      settings: {
        centerMode: true,
        centerPadding: '20px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 560,
      settings: {
        centerMode: true,
        centerPadding: '30px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 530,
      settings: {
        centerMode: true,
        centerPadding: '20px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 510,
      settings: {
        centerMode: true,
        centerPadding: '10px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        centerMode: true,
        centerPadding: '30px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        centerMode: true,
        centerPadding: '20px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 460,
      settings: {
        centerMode: true,
        centerPadding: '10px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 450,
      settings: {
        centerMode: true,
        centerPadding: '25px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 425,
      settings: {
        centerMode: true,
        centerPadding: '15px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 400,
      settings: {
        centerMode: true,
        centerPadding: '25px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 370,
      settings: {
        centerMode: true,
        centerPadding: '15px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 350,
      settings: {
        centerMode: true,
        centerPadding: '25px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 320,
      settings: {
        centerMode: true,
        centerPadding: '15px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 300,
      settings: {
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 1,
      },
    },
  ],
};

interface RoomDetailsProps {
  id: string;
}

const RoomDetailImageSlider: React.FC<RoomDetailsProps> = ({
  id,
}: RoomDetailsProps) => (
  <BGWrapper>
    <SliderWrapper>
      <Slider {...settings}>
        {RoomImages?.map((item: any) => (
          <RoomImageBlock key={item?.id}>
            <img src={item?.pictures} alt={`${item?.id}`} />
          </RoomImageBlock>
        ))}
      </Slider>
      <ViewAllPhotosWrapper>
        <ViewAllPhotoLink to={`/view-all-photos/${id}`}>
          View all photos
        </ViewAllPhotoLink>
      </ViewAllPhotosWrapper>
    </SliderWrapper>
  </BGWrapper>
);

export default RoomDetailImageSlider;
