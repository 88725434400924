import React from 'react';
import { Row } from 'react-bootstrap';
import AboutYou from './Components/AboutYou';
import Cookies from './Components/Cookies';
import PersonalInfoCollect from './Components/PersonalInfoCollect';
import Share from './Components/Share';
import Title from './Components/Title';
import YourPersonalInfo from './Components/YourPersonalInfo';
import YourRights from './Components/YourRights';
import { ContainerWrapper, Detail, LinkTag, Questions } from './styled';

const PrivacyPolicy: React.FC = () => (
  <>
    <ContainerWrapper>
      <div>
        <Title />
        <PersonalInfoCollect />
        <YourPersonalInfo />
        <AboutYou />
        <Share />
        <Cookies />
        <Row>
          <Questions>6. Children’s Privacy</Questions>
          <Detail>
            <p>
              The Site is not directed to individuals under 16. We do not
              knowingly collect Personal Information from children under 16. If
              a parent or guardian becomes aware that his or her child has
              provided us with Personal Information without their consent, he or
              she should contact us via the information provided in the “How to
              Contact Us” section, below. If we become aware that a child under
              16 has provided us with Personal Information, we will delete such
              information from our files.
            </p>
          </Detail>
        </Row>
        <Row>
          <Questions>7. Links to Other Websites</Questions>
          <Detail>
            <p>
              Our Site contains links to other websites. If you choose to visit
              an advertiser by clicking on a banner ad or other type of
              advertisement, or click on another third party link, you will be
              directed to that third party&apos;s website. The fact that we link
              to a website or present a banner ad or other type of advertisement
              is not an endorsement, authorization or representation of our
              affiliation with that third party, nor is it an endorsement of
              their privacy or information security policies or practices. We do
              not exercise control over third party websites. These other
              websites may place their own cookies or other files on your
              computer, collect data or solicit personal information from you.
              Other websites follow different rules regarding the use or
              disclosure of the personal information you submit to them. We
              encourage you to read the privacy policies or statements of the
              other websites you visit. Some portions of the Gilbert Hotels
              Platform implement Google Maps/Earth mapping services, including
              Google Maps API(s). Your use of Google Maps/Earth is subject to
              Google’s <LinkTag to="/">terms of use,</LinkTag> which
              incorporates Google’s <LinkTag to="/">Privacy Policy.</LinkTag>
            </p>
          </Detail>
        </Row>
        <Row>
          <Questions>8. Data Security</Questions>
          <Detail>
            <p>
              Gilbert Hotels is very concerned with safeguarding your Personal
              Information. When you enter particularly sensitive information
              (such as a credit card number) on our registration or reservation
              forms, we encrypt that information using secure socket layer
              technology (SSL). We follow generally accepted industry standards
              to protect the Personal Information submitted to us, both during
              transmission and once we receive it. No method of transmission
              over the Internet, or method of electronic storage, is 100%
              secure, however. Therefore, we cannot guarantee its absolute
              security. If you have any questions about security on our Site and
              Services, you can contact us. We will make any legally required
              disclosures of any breach of the security, confidentiality, or
              integrity of your unencrypted electronically stored “personal
              data” (as defined in applicable state statutes on security breach
              notification) to you via email or conspicuous posting on the Site
              in the most expedient time possible and without unreasonable
              delay, insofar as consistent with (i) the legitimate needs of law
              enforcement or (ii) any measures necessary to determine the scope
              of the breach and restore the reasonable integrity of the data
              system.
            </p>
          </Detail>
        </Row>
        <Row>
          <Questions>9. Phishing</Questions>
          <Detail>
            <p>
              Identity theft and the practice currently known as “phishing” are
              of great concern to Gilbert Hotels. Safeguarding Personal
              Information to help protect you from identity theft is a top
              priority. We do not and will not, at any time, request your credit
              card information, your Gilbert Hotels Account ID, login password,
              or national identification numbers in a non-secure or unsolicited
              email or telephone communication.
            </p>
          </Detail>
        </Row>
        <Row>
          <Questions>10. Data Retention</Questions>
          <Detail>
            <p>
              We take measures to delete your Personal Information or keep it in
              a form that does not permit identifying you when this information
              is no longer necessary for the purposes for which we process it,
              unless we are required by law to keep this information for a
              longer period. When determining the retention period, we take into
              account various criteria, such as the type of Services requested
              by or provided to you, the nature and length of our relationship
              with you, possible re-enrolment with our Services, the impact on
              the Services we provide to you if we delete some information from
              or about you, mandatory retention periods provided by law and the
              statute of limitations.
            </p>
          </Detail>
        </Row>
        <YourRights />
        <Row>
          <Questions>12. Cross-Border Data Transfers</Questions>
          <Detail>
            <p>
              Our Services are operated in multiple countries. The Personal
              Information we collect is transferred to our affiliates,
              subsidiaries, service providers and agents, who may be located in
              the United States or in other jurisdictions worldwide. The United
              States and the other jurisdictions to which we transfer
              information may not have equivalent data protection laws as your
              home jurisdiction. Your consent to this Privacy Policy followed by
              your submission of such information represents your agreement to
              that transfer.
            </p>
            <p>
              If you are located in the EEA, UK or Switzerland, we comply with
              applicable legal requirements for the transfer of Personal
              Information to countries outside of the EEA, UK or Switzerland. We
              may transfer your Personal Information to countries for which
              adequacy decisions have been issued (e.g., Canada), use
              contractual protections for the transfer of Personal Information,
              or rely on third parties’ Privacy Shield certifications, where
              applicable. You may contact us as specified below to obtain a copy
              of the safeguards we use to transfer Personal Information outside
              of the EEA, UK or Switzerland.
            </p>
          </Detail>
        </Row>
        <Row>
          <Questions>13. Changes to this Policy</Questions>
          <Detail>
            <p>
              Because we’re always developing and improving standards of
              services we provide to you, this Policy may change over time. We
              will post the new Policy online and we will change the “Last
              Updated” date. We encourage you to periodically review this page
              for the latest information on our privacy practices. If any
              modifications materially change your rights, we will provide
              notice on the Site or we will notify you by email (sent to the
              email address specified in your Gilbert Hotels Account).
            </p>
          </Detail>
        </Row>
        <Row>
          <Questions>14. How to Contact Us</Questions>
          <Detail>
            <p>
              For privacy queries, please contact us at{' '}
              <LinkTag to="/">privacy@gilberthotels.com</LinkTag> or at: Gilbert
              Hotels Holdings Inc., 101 15th Street, San Francisco, CA 94103,
              United States.
            </p>
          </Detail>
        </Row>
        <Row>
          <Questions>15. Steward of Your Personal Information</Questions>
          <Detail>
            <p>
              The Gilbert Hotels entity responsible for your Personal
              Information under this Privacy Policy is defined as the “Steward.”
              The Steward of your Personal Information is based on the location
              of travel. Generally, if your stay is located in the United States
              or Mexico, the Steward of your Personal Information is Gilbert
              Hotels Technology Inc. If your stay is located in Canada, the
              Steward of your Personal Information is Hospitalité Gilbert Hotels
              Canada Inc. If your stay is located outside of the United States,
              Mexico or Canada, the Steward of your Personal Information is
              Gilbert Hotels Technology B.V. Note that we reserve the right to
              replace the Steward of your Personal Information at any time and
              without notice.
            </p>
          </Detail>
        </Row>
      </div>
    </ContainerWrapper>
  </>
);

export default PrivacyPolicy;
