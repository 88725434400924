import React from 'react';
import { ContantHeader, Title, SubTitle } from './style';

interface LandingComponentHeaderProps {
  title: string;
  text: string;
}

const LandingComponentHeader: React.FC<LandingComponentHeaderProps> = ({
  title,
  text,
}) => (
  <>
    <ContantHeader>
      {/* <Col xxl={6} lg={6} md={9} sm={12}> */}
      <Title>{title}</Title>
      {/* </Col> */}
      {/* </ContantHeader> */}
      {/* <ContantHeader> */}
      {/* <Col xxl={5} lg={7} md={9} sm={12}> */}
      <SubTitle>{text}</SubTitle>
      {/* </Col> */}
    </ContantHeader>
  </>
);

export default LandingComponentHeader;
