import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const RowWrapper = styled(Row)`
  background: #f9f9f9;
  border-radius: 15px;
`;

export const Header = styled.h3`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 52px;
  color: #212f52;
  @media (max-width: 1024px) {
    font-size: 40px;
  }
  @media (max-width: 768px) {
    font-size: 48px;
  }
  @media (max-width: 425px) {
    font-size: 36px;
  }
`;

export const SubHeader = styled.h3`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212f52;
`;

export const LinkButton = styled(Link)`
  display: flex;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #212f52;
  text-decoration: none;
  padding: 20px 50px;
  background: #dca73a;
  border-radius: 33px;
  justify-content: center;
  margin-top: 50px;
  &:hover {
    color: #212f52;
  }
  @media (max-width: 1024px) {
    margin-top: 0px;
    padding: 20px 10px;
  }
  @media (max-width: 768px) {
    margin-top: 50px;
    padding: 20px 10px;
  }
  @media (max-width: 425px) {
    margin-top: 20px;
    padding: 20px 10px;
  }
`;
