import styled from 'styled-components';

export const ComingSoonWrapper = styled.div`
  text-align: center;
  padding-top: 70px;
  @media (max-width: 425px) {
    padding-top: 50px;
    padding-left: 16px;
  }
`;

export const TitleComingSoon = styled.h4`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  color: #212f52;
  @media (max-width: 425px) {
    font-family: 'Leitura Display';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 39px;
    color: #212f52;
    text-align: left;
  }
`;

export const StayTuned = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #212f52;
  padding-top: 10px;
  @media (max-width: 425px) {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    padding-top: 5px;
    color: #6a737b;
  }
`;

export const ComingSoonImage = styled.img`
  max-width: 670px;
  padding-top: 70px;
  @media (max-width: 425px) {
    max-width: 300px;
    padding-right: 16px;
    padding-top: 50px;
  }
`;

export const Subscribe = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #212f52;
  padding-top: 70px;
  @media (max-width: 425px) {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    padding-top: 50px;
    line-height: 26px;
    color: #212f52;
    text-align: left;
  }
`;

export const InputEmail = styled.input`
  background: #ffffff;
  border: 1px solid #caccd1;
  border-radius: 45px;
  padding-left: 30px;
  width: 100%;
  @media (max-width: 425px) {
    padding-top: 18px;
    padding-bottom: 18px;
  }
`;

export const SubscribeWrapper = styled.form`
  display: flex;
  position: relative;
  width: 570px;
  height: 85px;
  @media (max-width: 425px) {
    display: block;
    height: auto;
  }
`;

export const SubscribeButton = styled.button`
  background: #dca73a;
  border-radius: 33px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  border: 0px;
  color: #212f52;
  padding: 22px 50px 20px 50px;
  position: absolute;
  right: 0;
  margin: 10px;
  @media (max-width: 425px) {
    position: relative;
    padding-top: 18px;
    padding-bottom: 18px;
    width: 100%;
    margin: 10px 0px;
    font-size: 16px;
    line-height: 18px;
  }
`;

export const SubscriveInputMainWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  @media (max-width: 425px) {
    margin-left: 0px;
    margin-right: 16px;
  }
`;

export const SocialMediaWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const SocialMediaImage = styled.img`
  margin-right: 30px;
`;

export const FooterText = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin-top: 30px;
  color: #9f9fa3;
`;

export const FooterWrapper = styled.div`
  margin-top: 144px;
  margin-bottom: 70px;
  @media (max-width: 425px) {
    margin-top: 167px;
    margin-bottom: 30px;
  }
`;
