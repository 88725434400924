import React from 'react';
import SVG from './styled';

interface FaceBookProps {
  fill: string;
}

const FaceBook = ({ fill }: FaceBookProps): JSX.Element => (
  <SVG
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_69_972)">
      <path d="M13.8312 3.82083H15.6571V0.640833C15.3421 0.5975 14.2587 0.5 12.9971 0.5C7.22042 0.5 8.79208 7.04167 8.56208 8H5.65625V11.555H8.56125V20.5H12.1229V11.5558H14.9104L15.3529 8.00083H12.1221C12.2787 5.6475 11.4879 3.82083 13.8312 3.82083Z" />
    </g>
    <defs>
      <clipPath id="clip0_69_972">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </SVG>
);

export default FaceBook;
