import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Formik, Form, ErrorMessage } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { FormControl, Spinner } from 'react-bootstrap';
import firebase from 'firebase/compat/app';
import {
  LoginModalWrapper,
  CloseIcon,
  ModalTitle,
  ForgotpAsswordText,
  ResetPasswordLink,
  LoginButton,
  SocialLoginButton,
  SocialIcon,
  SocialButtonText,
  SignupButton,
} from './Style';
import CloseIconSvg from '../../Assets/Images/Icons/CloseIcon.svg';
import GoogleIcon from '../../Assets/Images/Icons/GoogleIcon.svg';
import FacebookIcon from '../../Assets/Images/Icons/FacebookIcon.svg';
import LinkedinIcon from '../../Assets/Images/Icons/LinkedinIcon.svg';
import AppleIcon from '../../Assets/Images/Icons/AppleIcon.svg';
import AuthContext from '../../Contexts/AuthContext';
import ForgetPasswordModal from '../Modal/ForgetPasswordModal';
import { auth } from '../../Config/firebase';

interface ModalProps {
  show: boolean;
  onCloseModal: (value: boolean) => void;
  setSignupModal: (value: boolean) => void;
}

const LoginModal: React.FC<ModalProps> = ({
  show,
  onCloseModal,
  setSignupModal,
}) => {
  const authProvider = useContext(AuthContext);
  const histroy = useHistory();
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [emailValue, setEmailValue] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .email('Email is not valid'),
    password: Yup.string().required('Password is required'),
  });

  const handleLogin = async (
    formValue: {
      email: string;
      password: string;
    },
    { setErrors }: any,
  ) => {
    try {
      setLoading(true);
      const { email, password } = formValue;
      const loginAuthProvider = await authProvider.login(email, password);
      const data = await auth.currentUser?.getIdTokenResult();
      if (data?.claims?.isAdmin === false) {
        if (loginAuthProvider.user.email) {
          setLoading(false);
          onCloseModal(false);
          toast.success('login successfully');
          setErrorMessage(null);
          histroy.push('/');
        }
      } else {
        setLoading(false);
        onCloseModal(false);
        await authProvider.logout();
        toast.error('Only guest allowed');
      }
    } catch (error: any) {
      setLoading(false);
      switch (error.code) {
        case 'auth/user-not-found':
          toast.error(
            'There is no user record corresponding to this identifier.',
          );
          setErrors({
            email: 'There is no user record corresponding to this identifier.',
          });
          break;
        case 'auth/wrong-password':
          toast.error(
            'The password is invalid or the user does not have a password.',
          );
          setErrors({
            password:
              'The password is invalid or the user does not have a password.',
          });
          break;
        default:
          toast.error(
            'There is no user record corresponding to this identifier.',
          );
          setErrors(
            'There is no user record corresponding to this identifier.',
          );
          break;
      }
    }
  };

  // login with google
  const signInWithGoogle = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      await authProvider.signinWithGoogle(provider);
      toast.dark('Login Successfully');
      onCloseModal(false);
    } catch (error: any) {
      const errorcode = error.code;
      toast.error(errorcode);
    }
  };

  // login with facebook
  const signInWithFacebook = async () => {
    try {
      const provider = new firebase.auth.FacebookAuthProvider();
      await authProvider.signInWithFacebook(provider);
      toast.dark('Login Successfully');
      onCloseModal(false);
    } catch (error: any) {
      const errorcode = error.code;
      toast.error(errorcode);
    }
  };

  const getSignInMethodsForEmail = async () => {
    try {
      await authProvider.fetchSignInMethodsForEmail(emailValue);
    } catch (error: any) {
      const errorcode = error.code;
      toast.error(errorcode);
    }
  };

  return (
    <>
      <LoginModalWrapper
        show={show}
        onHide={() => {
          onCloseModal(false);
        }}
        centered
        dialogClassName="signup-dialog"
      >
        <CloseIcon src={CloseIconSvg} onClick={() => onCloseModal(false)} />
        <ModalTitle>Login</ModalTitle>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
        >
          {({
            touched,
            errors,
            isSubmitting,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div style={{ margin: '50px 0px' }}>
                <FormControl
                  name="email"
                  type="email"
                  placeholder="Email Address"
                  className={`input m-0 p-0 pb-3 mb-4 ${
                    touched.email && errors?.email ? 'is-invalid' : ''
                  }`}
                  onChange={event => {
                    handleChange(event);
                    setEmailValue(event.target.value);
                    setErrorMessage(null);
                  }}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  component="div"
                  name="email"
                  className="invalid-feedback"
                />
                <FormControl
                  name="password"
                  type="password"
                  placeholder="Password"
                  id="txtPassword"
                  className={`input m-0 p-0 pb-3 mb-4 ${
                    touched.password && errors?.password ? 'is-invalid' : ''
                  }`}
                  onChange={event => {
                    handleChange(event);
                    setErrorMessage(null);
                  }}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  component="div"
                  name="password"
                  className="invalid-feedback"
                />
                <div style={{ color: 'red' }}>{errorMessage}</div>
                <div className="d-flex justify-content-center">
                  <ForgotpAsswordText className="mx-1">
                    Forgot your password?
                  </ForgotpAsswordText>
                  <ResetPasswordLink
                    className="mx-1"
                    onClick={() => {
                      onCloseModal(false);
                      setOpenModal(true);
                    }}
                  >
                    Reset password
                  </ResetPasswordLink>
                </div>
              </div>
              {!loading ? (
                <LoginButton
                  disabled={isSubmitting}
                  onClick={() => getSignInMethodsForEmail()}
                >
                  Log in
                </LoginButton>
              ) : (
                <div className="text-center">
                  <Spinner animation="border" variant="warning" />
                </div>
              )}
            </Form>
          )}
        </Formik>
        <p className="text-center" style={{ margin: '30px 0px' }}>
          or
        </p>
        <SocialLoginButton onClick={signInWithGoogle}>
          <SocialIcon src={GoogleIcon} />
          <SocialButtonText>Log in with google</SocialButtonText>
        </SocialLoginButton>
        <SocialLoginButton onClick={signInWithFacebook}>
          <SocialIcon src={FacebookIcon} />
          <SocialButtonText>Log in with facebook</SocialButtonText>
        </SocialLoginButton>
        <SocialLoginButton>
          <SocialIcon src={LinkedinIcon} />
          <SocialButtonText>Log in with linkedIn</SocialButtonText>
        </SocialLoginButton>
        <SocialLoginButton>
          <SocialIcon src={AppleIcon} />
          <SocialButtonText>Log in with apple</SocialButtonText>
        </SocialLoginButton>
        <div className="d-flex justify-content-center my-5">
          <ForgotpAsswordText className="mx-1">
            Don’t have an account?
          </ForgotpAsswordText>
          <SignupButton
            onClick={() => {
              onCloseModal(false);
              setSignupModal(true);
            }}
            className="mx-1 mb-0"
          >
            Sign up
          </SignupButton>
        </div>
      </LoginModalWrapper>
      <ForgetPasswordModal show={openModal} handleClose={setOpenModal} />
    </>
  );
};
export default LoginModal;
