import React from 'react';
import { Row } from 'react-bootstrap';
import { Details, Heading } from './styled';

const CheckInDetails: React.FC = () => (
  <Row>
    <Heading className="pt-4">Check-in details:</Heading>
    <Details className="pt-3">
      Check-in is Monday, October 11, 2021 anytime <b> after 4pm.</b>
      <br />
      <br />
      To request an early check-in time, and track the status of your request,
      you may use our app.
      <br />
      <br />
      Access codes and check-in instructions will be available here and emailed
      to you 3 days prior to check-in, <b>on Friday, October 8, 2021.</b>
    </Details>
  </Row>
);

export default CheckInDetails;
