import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { BGWrapper, CheckBoxDiv, LeaveReplyDiv, PostComment } from './styled';

const LeaveReply: React.FC = () => (
  <BGWrapper>
    <Container>
      <LeaveReplyDiv className="pt-5">
        <h6>Leave a reply</h6>
        <Col lg={12} md={12} sm={12}>
          <Form.Control
            as="textarea"
            rows={5}
            placeholder="Enter your comment here"
          />
        </Col>
        <Row>
          <Col lg={6} md={6} sm={6}>
            <Form.Control
              type="text"
              placeholder="Name"
              className="py-3 px-3"
            />
          </Col>
          <Col lg={6} md={6} sm={6}>
            <Form.Control
              type="email"
              placeholder="Email"
              className="py-3 px-3"
            />
          </Col>
        </Row>
        <Row className="pb-5">
          <Col lg={9} md={12} sm={12}>
            <CheckBoxDiv className="d-flex">
              <Form.Check type="checkbox" />
              <p className="pt-1">Notify me of new comments via email.</p>
            </CheckBoxDiv>
            <CheckBoxDiv className="d-flex">
              <Form.Check type="checkbox" />
              <p className="pt-1">Notify me of new posts via email.</p>
            </CheckBoxDiv>
          </Col>
          <Col lg={3} md={12} sm={12}>
            <PostComment to="/" className="px-5 py-2">
              Post comment
            </PostComment>
          </Col>
        </Row>
      </LeaveReplyDiv>
    </Container>
  </BGWrapper>
);

export default LeaveReply;
