import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const Wrapper = styled.div`
  padding-bottom: 50px;
  @media (max-width: 425px) {
    padding-right: 20px;
    padding-bottom: 20px;
  }
`;

export const Heading = styled.h1`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 34px;
  color: #212f52;
  padding-bottom: 50px;
  @media (max-width: 425px) {
    font-size: 24px;
    padding-bottom: 20px;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 425px) {
    flex-wrap: nowrap;
    overflow: scroll;
    ::-webkit-scrollbar {
      display: none !important;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
`;

export const ItemBox = styled.div`
  padding-right: 20px;
  width: min-content;
  img {
    background: #f9f9f9;
    border-radius: 15px;
    padding: 45px 50px;
  }

  p {
    padding-top: 15px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #212f52;
    padding-bottom: 50px;
  }
`;

export const ItemBoxList = styled.div`
  padding-right: 20px;
  li {
    padding-top: 15px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #212f52;
  }
`;

export const FeatureLable = styled.h4`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #212f52;
`;

export const FeatureSmallText = styled.h4`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #6a737b;
`;

export const FeatureItemWrapper = styled(Row)`
  padding-top: 50px;
  margin-right: 0;
  @media (max-width: 425px) {
    padding-top: 20px;
  }
`;

export const FeatureItemBox = styled.div`
  display: flex;
  padding: 12px 0px;
  img {
    padding-right: 15px;
  }

  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #212f52;
    margin: auto 0px;
  }
`;
