import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding-top: 50px;
`;

export const Title = styled.h2`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #212f52;
`;

export const MainDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const FirstWrapper = styled.div`
  width: 40%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SecondWrapper = styled.div`
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SecondMainDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SecondMainInnerLeftDiv = styled.div`
  width: 50%;
  padding: 5px 5px;
`;

export const SecondMainInnerRightDiv = styled.div`
  width: 50%;
  padding: 5px 5px;
`;

export const Descripation = styled.ul`
  display: flex;
  gap: 35px;
  padding: 0px;
  li {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #212f52;
  }
  span {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #212f52;
  }
`;

export const RightWrapper = styled.div`
  background: #d9d9d9;
  border-radius: 15px;
  height: 600px;
  background-size: cover;
  @media (max-width: 425px) {
    height: 280px;
  }
`;

export const LeftFirstWrapper = styled.div`
  background: #d9d9d9;
  border-radius: 15px;
  height: 295px;
  background-size: cover;
  @media (max-width: 425px) {
    height: 135px;
  }
`;

export const LeftSecondWrapper = styled.div`
  background: #d9d9d9;
  border-radius: 15px;
  background-size: cover;
  margin-top: 10px;
  height: 295px;
  @media (max-width: 425px) {
    height: 135px;
  }
`;
