import styled from 'styled-components';
import { Row } from 'react-bootstrap';

interface ImageProps {
  src: string;
}

export const RowWrapper = styled(Row)``;

export const CardHeader = styled.h3`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 52px;
  color: #212f52;
`;

export const CheckInOutTitle = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #212f52;
`;

export const CheckInOutDate = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #212f52;
`;

export const CheckInOutStandardTime = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #6a737b;
`;

export const CheckInOutTimeBlockWraper = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #6a737b;
  display: flex;
  flex-wrap: wrap;

  .selected {
    background: #212f52;
    color: #ffffff;
  }

  .disabled {
    background: #e9e9e9;
    color: #9f9fa3;
    cursor: not-allowed;
  }
`;

export const CheckInOutTimeBlock = styled.div`
  background: #ffffff;
  color: #212f52;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 9px 15px;
  width: fit-content;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const CheckInOutTimeIcon = styled.img<ImageProps>`
  padding-right: 13px;
  height: 18px;
`;

export const SeeMoreTimeLink = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #6a737b;
`;

export const SaveButton = styled.button`
  padding: 23px 79px;
  background: #e9e9e9;
  border-radius: 5px;
  border: none;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #9f9fa3;
  @media (max-width: 425px) {
    width: 390px;
  }
  @media (max-width: 375px) {
    width: 340px;
  }
  @media (max-width: 375px) {
    width: 290px;
  }

  :hover {
    background: #212f52;
    color: #ffffff;
  }
`;

export const CancleButton = styled.button`
  padding: 23px 79px;
  background: transparent;
  border-radius: 5px;
  border: none;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #212f52;
  @media (max-width: 425px) {
    width: 390px;
  }
  @media (max-width: 375px) {
    width: 340px;
  }
  @media (max-width: 375px) {
    width: 290px;
  }
`;
