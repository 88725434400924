import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

interface CloseIconProps {
  src: string;
}

export const LoginModalWrapper = styled(Modal)`
  .modal-content {
    border-radius: 15px;
    padding: 0px 80px;
  }
`;

export const CloseIcon = styled.img<CloseIconProps>`
  position: absolute;
  width: 36px;
  height: 36px;
  right: 28px;
  top: 20px;
  cursor: pointer;
`;

export const ModalTitle = styled.h2`
  margin-top: 50px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #212f52;
`;

export const InputField = styled.input`
  border: 0px;
  width: 100%;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #9f9fa3;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 15px 0px;
  border-bottom: #212f52 1px solid;
  padding-bottom: 15px;
  outline: none;
`;

export const ForgotpAsswordText = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #6a737b;
  margin: 0px;
`;

export const ResetPasswordLink = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  margin: 0px;
  color: #6a737b;
  cursor: pointer;
  &:hover {
    color: #6a737b;
  }
`;

export const LoginButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 22px 50px 20px;
  width: 100%;
  border: none;
  background: #dca73a;
  border-radius: 33px;

  color: #212f52;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  :hover {
    background: #e0b252;
  }
`;

export const SocialLoginButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px 50px 20px;
  background: #f9f9f9;
  border-radius: 33px;
  margin: 10px 0px;
  cursor: pointer;
  :hover {
    background: #eff0f3;
  }
`;

export const SocialIcon = styled.img<CloseIconProps>`
  width: 25px;
  height: 25px;
`;

export const SocialButtonText = styled.h6`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;

  text-align: center;
  color: #212f52;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 20px;
`;

export const SignupButton = styled.p`
  cursor: pointer;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #212f52;
`;
