import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const BGWrapper = styled.div`
  display: flex;
  padding: 60px 0px;
  @media (max-width: 425px) {
    padding: 20px 0px;
  }
`;

export const ContainerWrapper = styled(Container)`
  @media (max-width: 768px) {
    padding: 0px;
  }
  @media (max-width: 425px) {
    padding: 0px 10px;
  }
`;
