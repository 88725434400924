import React from 'react';
import {
  BGWrapper,
  FirstWrapper,
  MainDiv,
  SecondWrapper,
  Title,
  Wrapper,
} from './styled';

interface ExteriorProps {
  element: any;
}

const Exterior: React.FC<ExteriorProps> = ({ element }: ExteriorProps) => (
  <BGWrapper>
    <MainDiv>
      <FirstWrapper>
        <Title>{element?.title}</Title>
      </FirstWrapper>
      <SecondWrapper>
        <div style={{ width: '100%' }}>
          <Wrapper
            style={{ backgroundImage: `url(${element?.photos[1]?.original})` }}
          />
        </div>
      </SecondWrapper>
    </MainDiv>
  </BGWrapper>
);

export default Exterior;
