import React, { useEffect, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map';
import {
  ItemBox,
  ItemWrapper,
  FeatureItemTitle,
  FeatureItemItem,
  FeatureItemLocation,
  LoaderDiv,
  NoResults,
  RowWrapper,
} from './styled';
import AnimationLoader from '../../Common/Loader/AnimationLoader';

interface SearchingFilteredListProps {
  searchList: any;
  loading: any;
  city: string;
}

const SearchingFilteredList: React.FC<SearchingFilteredListProps> = ({
  searchList,
  loading,
  city,
}: SearchingFilteredListProps) => {
  const location = useLocation();
  const history = useHistory();
  const resetPage = () => {
    const string = `/destinations/${city}/search`;
    // string += `?guests=1&bedrooms=0&beds=1&bathrooms=1`;
    history.push(string);
  };

  return (
    <>
      {!loading ? (
        <>
          {searchList?.length === 0 ? (
            <NoResults className="pt-5">
              <h3 className="pt-5">No Results</h3>
              <p>
                Try modifying your search by <br />
                changing your dates or removing filters.
              </p>
              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="m-2 px-5 py-3"
                  onClick={resetPage}
                >
                  Reset all filters
                </button>
              </div>
            </NoResults>
          ) : (
            <RowWrapper id="list-container">
              {searchList?.map((item: any) => (
                <ItemWrapper
                  id={item?.unit_id}
                  lg={6}
                  md={6}
                  sm={12}
                  key={item?.unit_id}
                >
                  <ItemBox
                    as={Link}
                    to={`/destinations/${city}/${item?.unit_id}/search/${location.search}`}
                  >
                    <div className="image-contrainer">
                      <div className="price-tag">
                        <h5>
                          US {getSymbolFromCurrency(item.price?.currency)}
                          {item.price?.amount}
                        </h5>
                        <p>{item?.smallText || 'Avg Rate'}</p>
                      </div>
                      {item?.pictures?.[0] ? (
                        <div
                          className="image"
                          style={{
                            backgroundImage: `url(${item?.pictures?.[0]})`,
                          }}
                        />
                      ) : (
                        <div className="not-image" />
                      )}
                    </div>
                    <FeatureItemTitle>{item?.title}</FeatureItemTitle>
                    <FeatureItemLocation>{item.location}</FeatureItemLocation>
                    <div className="d-flex">
                      <div>
                        <FeatureItemItem>
                          <p>
                            {item?.capacity?.bathrooms} Bathroom
                            {item?.capacity?.bathrooms > 1 ? 's' : ''}
                          </p>
                        </FeatureItemItem>
                      </div>
                      <div>
                        <FeatureItemItem>
                          <p className="px-1">
                            <span>&bull;</span>{' '}
                            {item?.capacity?.bedrooms === 0
                              ? 'Studio'
                              : item?.capacity?.bedrooms}
                            {item?.capacity?.bedrooms === 1 ? ' Bedroom' : ''}
                            {item?.capacity?.bedrooms > 1 ? 's' : ''}
                          </p>
                        </FeatureItemItem>
                      </div>
                      <div>
                        <FeatureItemItem>
                          <p>
                            <span>&bull;</span> {item?.capacity?.max} Guests
                          </p>
                        </FeatureItemItem>
                      </div>
                    </div>
                  </ItemBox>
                </ItemWrapper>
              ))}
            </RowWrapper>
          )}
        </>
      ) : (
        <LoaderDiv>
          <AnimationLoader />
        </LoaderDiv>
      )}
    </>
  );
};
export default SearchingFilteredList;
