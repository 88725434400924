import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail, LinkTag } from './styled';

const Termination: React.FC = () => (
  <Row>
    <Detail>
      <div style={{ paddingLeft: '30px' }}>
        <p>
          <b>14.2 Termination.</b> To the maximum extent permitted by applicable
          law, if you violate any provision of this Agreement or if Gilbert
          Hotels’s lease of, or right to use, any Property terminates or expires
          for any reason, all of our licenses to you, any permission to use any
          Property, (including any Booked Property), the Period of Occupancy,
          and this Agreement (which includes any still active Order Forms)
          automatically terminate without notice to you and, in addition to the
          remedies in Section 13.3, we reserve all rights and remedies available
          to us at law and equity including, without limitation, the right to
          remove you and Your Guests from any Property. In addition, we may, in
          our sole discretion, subject to compliance with all applicable law,
          terminate this Agreement or your account on the Service, or suspend or
          terminate your access to the Service, at any time for any reason or no
          reason, with or without notice.
          <div style={{ paddingLeft: '30px', marginTop: '20px' }}>
            <p>
              <b>14.2.1 Termination by You for STA/Hotel Reservations.</b> You
              may terminate your account and this Agreement as applicable to
              STA/Hotel Reservations at any time by contacting customer service
              at <LinkTag to="/">reservations@gilberthotels.com</LinkTag> and
              requesting that your account be deleted.
            </p>
            <p>
              <b>14.2.2 Termination by You for Extended Stay Reservations.</b>{' '}
              You may terminate your account and this Agreement as applicable to
              Extended Stay Reservations only if and as expressly permitted in
              the applicable Extended Stay Agreement or Confirmation.
            </p>
            <p>
              <b>14.2.3 Termination by You for Subscription Reservations.</b>{' '}
              You may terminate your account and this Agreement as applicable to
              Subscription Reservations only if and as expressly permitted in
              the applicable Membership Agreement.
            </p>
          </div>
        </p>
      </div>
    </Detail>
  </Row>
);

export default Termination;
