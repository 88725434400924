import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const PTag = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #212f52;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const Detail = styled.div`
  p {
    font-family: 'Arial';
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: #212f52;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
`;

export const LinkTag = styled(Link)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212f52;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 20px 0px;
  :hover {
    color: #212f52;
  }
`;
