import React from 'react';
import { Row } from 'react-bootstrap';
import { Bounce, Details, Heading, Ruler, Start } from './styled';

const LuggageStorage: React.FC = () => (
  <Start>
    <Heading>Luggage Storage</Heading>
    <Details>
      If you need to store your luggage prior to arrival, please check out one
      of our partners:
    </Details>
    <Bounce>
      <b>Bounce</b> - Bounce has several storage locations around Austin and you
      can use the promo code GHBOUNCE to get 15% off. Be sure to keep an eye on
      pickup and drop off times at their various locations.
    </Bounce>
    <Ruler>
      <hr />
    </Ruler>
  </Start>
);

export default LuggageStorage;
