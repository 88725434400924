import React from 'react';
import { Row } from 'react-bootstrap';
import UseofBookedProperties from '../UseofBookedProperties';
import { Detail } from './styled';

const ServiceandBookedProperties: React.FC = () => (
  <Row>
    <Detail>
      <p>
        <b>7. Service and Booked Properties.</b>
      </p>
      <div style={{ paddingLeft: '30px' }}>
        <p>
          <b>7.1 Limited License to the Service.</b> Subject to your complete
          and ongoing compliance with this Agreement, we grant you, solely for
          your personal (except as expressly provided in this Section 6.1) and
          non-commercial use, a limited, non-exclusive, non-transferable,
          non-sublicensable, revocable license to: (a) install and use one
          object code copy of any mobile application associated with the Service
          obtained from a legitimate marketplace (whether installed by you or
          pre-installed on your mobile device by the device manufacturer) on a
          mobile device that you own or control; and (b) access and use the
          Service. Notwithstanding the foregoing, if permissible under the laws
          applicable to the Service or any Booked Property, you may use the
          Service for non-personal use only if you are an organization and only
          to reserve Properties, or enter into a subscription, for or on behalf
          of your organization’s employees, your organization’s students, and/or
          a third party (“Limited Non-Personal Use”), provided that: (i) you
          enter into a separate written agreement with us that provides
          additional terms and conditions governing this Limited Non-Personal
          Use (“Limited Use Agreement”) and such use shall be subject to the
          terms and conditions of this Agreement and the Limited Use Agreement;
          (ii) you require each employee, student, and/or third party to agree
          in writing to be bound by this Agreement; and (iii) you remain
          responsible and liable for all acts and omissions of each such
          employee, student, and/or third party to the same extent as if such
          acts and omissions had been undertaken by you.
        </p>
      </div>
      <UseofBookedProperties />
      <div style={{ paddingLeft: '30px' }}>
        <p>
          <b>7.3 Feedback License to Us.</b> If you choose to provide
          suggestions or any other input regarding problems with, proposed
          modifications or improvements to, or any other aspect of the Service
          or Properties (<b>“Feedback”</b>), then you hereby grant us an
          unrestricted, worldwide, perpetual, irrevocable, non-exclusive, freely
          transferable and sublicensable (including through multiple tiers),
          royalty-free, and fully-paid right and license to exploit the Feedback
          in any manner and for any purpose with no obligation or accounting to
          you, including to improve the Service and Properties and create other
          products and services.
        </p>
      </div>
    </Detail>
  </Row>
);

export default ServiceandBookedProperties;
