import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail } from './styled';

const DigitalMillenniumCopyrightAct: React.FC = () => (
  <Row>
    <Detail>
      <p>
        <b>12. Digital Millennium Copyright Act</b>
      </p>
      <div style={{ paddingLeft: '30px' }}>
        <p>
          <b>12.1 DMCA Notification.</b> We comply with the provisions of the
          Digital Millennium Copyright Act applicable to Internet service
          providers (17 U.S.C. §512, as amended). If you have an intellectual
          property rights-related complaint about material posted on the
          Service, you may contact our Designated Agent at the following
          address:
        </p>
        <p>
          Gilbert Hotels Technology Inc.
          <br /> ATTN: Office of the General Counsel (Copyright Notification).
          <br /> 101 15th Street, San Francisco, CA 94103.
          <br />
          Email: copyright@gilberthotels.com
        </p>
        <p>
          Any notice alleging that materials hosted by or distributed through
          the Service infringe intellectual property rights must include the
          following information:
        </p>
        <div style={{ paddingLeft: '30px', marginTop: '20px' }}>
          <p>
            12.1.1 an electronic or physical signature of the individual
            authorized to act on behalf of the owner of the copyright or other
            right being infringed;
          </p>
          <p>
            12.1.2 a description of the copyrighted work or other intellectual
            property that you claim has been infringed;
          </p>
          <p>
            12.1.3 a description of the material that you claim is infringing
            and where it is located on the Service;
          </p>
          <p>12.1.4 your address, telephone number, and email address;</p>
          <p>
            12.1.5 a statement by you that you have a good faith belief that the
            use of the materials on the Service of which you are complaining is
            not authorized by the copyright owner, its agent, or the law; and
          </p>
          <p>
            12.1.6 a statement by you that the above information in your notice
            is accurate and that, under penalty of perjury, you are the
            copyright or intellectual property owner or authorized to act on the
            copyright or intellectual property owner’s behalf.
          </p>
        </div>
      </div>
    </Detail>
  </Row>
);

export default DigitalMillenniumCopyrightAct;
