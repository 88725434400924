import React from 'react';
import { Wrapper } from './styled';
import WorkWithUs from '../../../Assets/Images/WorkWithUs.png';

const Inverster: React.FC = () => (
  <Wrapper>
    <div className="white-layer" />
    <img src={WorkWithUs} alt="work-with-us" />
  </Wrapper>
);

export default Inverster;
