import React from 'react';
import Wrapper from './styled';

interface HotelImageProps {
  billingData: any;
}

const HotelImage: React.FC<HotelImageProps> = ({
  billingData,
}: HotelImageProps) => (
  <>
    <Wrapper src={billingData?.picture} alt="hotel-room" />
  </>
);
export default HotelImage;
