import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 60px 0px;
  @media (max-width: 425px) {
    padding: 0px !important;
  }
  @media (max-width: 425px) {
    padding: 0px !important;
  }
`;

export const Temp = styled.div`
  padding: 60px 0px;
`;

export const SuspenseLoaderWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  div {
    display: flex;
  }
`;
