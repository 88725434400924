import React from 'react';
import { Container } from 'react-bootstrap';
import ServiceBlock from '../../Common/ServiceBlock';
import Hero from '../Hero';
import BusinessAndGroup from '../../Assets/Images/BusinessAndGroup.jpg';
import { GrayImageBox } from './styled';
import Ourleadership from '../Ourleadership';
import InversterBlock from '../InversterBlock';
import WorkWithUs from '../WorkWithUs';
import FeaturedStays from '../FeaturedStays';

const AboutUsContainer: React.FC = () => (
  <>
    <Hero
      bgImage={BusinessAndGroup}
      title={
        <>
          Building the future <br />
          of hospitality
        </>
      }
      text={
        <>
          At Gilbert Hotels, we’re on a mission to redefine hospitality
          <br /> by bringing exceptional stays everywhere.
        </>
      }
    />
    <Container>
      <ServiceBlock
        isImageLeft
        title="Why we’re here"
        texts={[
          'Hotels aren’t always hip. Hosts aren’t always reliable. So we’re changing the game with spaces that inspire and delight, that fulfill needs without sacrificing style, all while delivering seamless, personalized experiences. Check in, request fresh towels, and get dinner recommendations all from your phone.',
          'By eliminating inefficiencies as we grow, we can deliver hospitality that’s both remarkable and accessible. Because everyone should be able to afford an extraordinary place to stay.',
        ]}
      />
      <div className="mb-5">
        <ServiceBlock
          isImageLeft={false}
          title="How we got started"
          texts={[
            `Then a university student in Montreal, our co-founder and CEO Francis Davidson began managing a handful of apartments. He greeted guests with wine, parked their cars, and began to envision a new set of ideals around hospitality. Over the next few summers, Francis experimented with various models such as matching travelers with vacant student apartments across cities and managing other people's apartments for short-term rentals. A few years later, he and co-founder Martin Picard solidified the foundation that would become Gilbert Hotels today.`,
          ]}
        />
      </div>
    </Container>
    <GrayImageBox />
    <Container>
      <ServiceBlock
        isImageLeft
        title="Gilbert Hotels today"
        texts={[
          'Gilbert Hotel has multiple offices across the globe, with Hub cities in Denver, San Francisco, Montreal and London/Amsterdam. We’re a very locally driven organization, with teams on the ground in cities across the world.',
        ]}
        lists={[
          'Over 30 cities in 8 countries',
          '4,500+ listings worldwide',
          '1M+ guests',
          '$560+ million in investor funding',
        ]}
      />
      <ServiceBlock
        isImageLeft={false}
        title="Where we’re going"
        texts={[
          'What began as a journey to provide a better stay has grown into a celebration of modern design and exceptional comfort. We’re working with world-class architects and designers to create truly remarkable spaces—ones that transcend hospitality and enrich lives. And we’re employing technology to keep those spaces affordable as we bring Gilbert Hotels to more cities around the world.',
        ]}
      />
    </Container>
    <Ourleadership />
    <Container>
      <InversterBlock />
      <WorkWithUs />
      <FeaturedStays />
    </Container>
  </>
);

export default AboutUsContainer;
