import React from 'react';
import { Link } from 'react-router-dom';
import {
  AmenitiesDetails,
  ApartmentDetails,
  Heading,
  Ruler,
  Start,
} from './styled';

const ApartmentGuide: React.FC = () => (
  <Start>
    <Heading>Apartment Guide</Heading>
    <ApartmentDetails className="pt-3">
      Find more details about your Gilbert Hotels and the neighborhood in our
      Hotel app.
    </ApartmentDetails>
    <Ruler className="pt-3">
      <hr />
    </Ruler>
    <Heading className="pt-5">Amenities</Heading>
    <AmenitiesDetails className="pt-3">
      We’ve stocked your space with enough toiletries and kitchen items to get
      you started. If you need more supplies,
      <Link to="/" className="linkDownload">
        download the hotel app
      </Link>
      &nbsp; for a nearby store or delivery recommendations. If you’re unable to
      get to the store right away, we’ll restock your Gilbert Hotels once as a
      courtesy. Please reach out to our team to make a request.
    </AmenitiesDetails>
    <Ruler className="pt-3">
      <hr />
    </Ruler>
  </Start>
);

export default ApartmentGuide;
