import React from 'react';
import { Row } from 'react-bootstrap';
import ProhibitedConduct from '../ProhibitedConduct';
import SafetyFeatures from '../SafetyFeatures';
import { Detail, LinkTag } from './styled';

const RestrictionsandResponsibilities: React.FC = () => (
  <Row>
    <Detail>
      <p>
        <b>8. Restrictions and Responsibilities.</b>
      </p>
      <div style={{ paddingLeft: '30px' }}>
        <p>
          <b>8.1 Compliance with Law.</b> If you are prohibited under applicable
          law from using the Service or any of the Properties (including Booked
          Properties), you may not use them. You understand and agree that you
          are solely responsible for compliance with any and all laws, rules,
          and regulations that may apply to your use of the Service and/or
          Properties. We shall comply with the requirements of the Fair
          Employment and Housing Act and shall not discriminate based upon any
          of the protected categories covered in such Act where applicable.
        </p>
        <p>
          <b>8.2 Use of Amenities.</b> You and your occupants, guest(s),
          visitors, invitees and any other parties to whom you give access to or
          use of any Property, including the Booked Property (collectively and
          individually, <b>“Your Guests”</b>) may use only the Booked Property
          amenities (e.g., fitness room and gym equipment) expressly made
          available by us for the common and joint use of Gilbert Hotels’s
          members, guests and residents in the applicable Property
          (collectively, the <b>“Amenities”</b>). Access to such Amenities is
          not guaranteed and may be limited, revoked, or suspended from time to
          time. You agree to comply and ensure all of Your Guests’ compliance
          with all applicable rules with respect to your and Your Guests’ use of
          such Amenities. You understand and agree that use of such Amenities
          may be suspended if you or any of Your Guests violate any such rules.
          You shall be responsible for any damage to such Amenities that you or
          any of Your Guests cause. ANY USE OF SUCH AMENITIES IS AT YOUR AND
          YOUR GUESTS’ OWN RISK AND YOU AND YOUR GUESTS RELEASE, DISCHARGE, AND
          COVENANT NOT TO SUE ANY GILBERT HOTELS ENTITIES (DEFINED BELOW) IN
          CONNECTION WITH ANY USE OF SUCH AMENITIES. YOU AGREE THAT, AT OUR
          REQUEST AT ANY TIME, YOU AND/OR YOUR GUESTS WILL EXECUTE AND DELIVER A
          COMMERCIALLY REASONABLE FORM OF WAIVER AND RELEASE TO CONFIRM THE
          WAIVER AND RELEASE SET FORTH HEREIN AND THAT YOUR AND/OR YOUR GUESTS’
          USE OF THE AMENITIES MAY BE CONDITIONED ON YOUR AND/OR YOUR GUESTS’
          EXECUTION AND DELIVERY OF SUCH WAIVER AND RELEASE.
        </p>
        <p>
          <b>8.3 Use of On-Site Services.</b> We may, but shall not be obligated
          to, offer On-Site Services for an additional fee from time to time,
          such as cleaning, babysitting, local tours, parking and special
          events. If so offered by us and so requested by you, we will mutually
          agree on the dates and times for such services and we and/or the
          applicable third party service provider shall have the right to enter
          the Booked Property to perform such services. To the extent that we
          offer and you request or use any such services, you hereby agree to
          comply and ensure all of Your Guests’ compliance with all of the
          applicable rules with respect to your and Your Guests’ use of such
          services. You understand and agree that your and Your Guests’ use of
          such services may be suspended if you or any of Your Guests violate
          any such rules. You shall be responsible for any damage that you or
          any of Your Guests cause. ANY USE OF ON-SITE SERVICES IS AT YOUR AND
          YOUR GUESTS’ OWN RISK AND YOU AND YOUR GUESTS RELEASE, DISCHARGE, AND
          COVENANT NOT TO SUE ANY GILBERT HOTELS ENTITIES (DEFINED BELOW) IN
          CONNECTION WITH ANY SUCH SERVICES. YOU UNDERSTAND THAT THIS AGREEMENT
          INCLUDES A RELEASE OF ALL KNOWN AND UNKNOWN CLAIMS. YOU AGREE THAT, AT
          OUR REQUEST AT ANY TIME, YOU AND/OR YOUR GUESTS WILL EXECUTE AND
          DELIVER A COMMERCIALLY REASONABLE FORM OF WAIVER AND RELEASE TO
          CONFIRM THE WAIVER AND RELEASE SET FORTH HEREIN AND THAT YOUR AND/OR
          YOUR GUESTS’ USE OF THE ON-SITE SERVICES MAY BE CONDITIONED ON YOUR
          AND/OR YOUR GUESTS’ EXECUTION AND DELIVERY OF SUCH WAIVER AND RELEASE.
        </p>
        <p>
          <b>8.4 Obligation to Maintain.</b> You shall keep all interior
          portions of the Booked Property, and all furniture and decorations
          within the Booked Property, in good, clean, and sanitary condition,
          including free from all pests (such as, without limitation, bedbugs,
          cockroaches, mice and rats), and in proper working order throughout
          the applicable Period of Occupancy. Should any component fail to
          operate (including any appliance, plumbing, electrical, etc.), it is
          your responsibility to promptly contact us at the contact information
          located at{' '}
          <LinkTag to="/">https://www.gilberthotels.com/help,</LinkTag> as soon
          as a problem is detected.
        </p>
      </div>
      <ProhibitedConduct />
      <SafetyFeatures />
      <div style={{ paddingLeft: '30px' }}>
        <p>
          <b>8.7 Damaged Property.</b> You are responsible for leaving any
          Property including the Booked Property in the condition same was in
          when you arrived and for following all related Additional Terms
          applicable to your stay at a Booked Property. You acknowledge and
          agree that you are responsible for your, your Guests, and your and
          their pets’ own acts, omissions, negligence and willful misconduct. If
          we reasonably believe that you, Your Guests, or your or their pets
          have damaged a Booked Property or any Property or damaged or removed
          or stolen any physical item therein (<b>“Damaged Property”</b>), we
          will provide you with reasonable notice and evidence of such damage or
          suspected removal or theft and will give you two business days to
          respond to our claim. After this time period, we will be automatically
          entitled to charge the Applicable Payment Method for the full amount
          of any lost revenue that we incur as a result of the Damaged Property
          and the full amount of the greater of the costs and fees actually
          incurred by us or that we reasonably expect to incur for fixing or
          replacing the Damaged Property (which may include replacing damaged
          items with equivalent items) (<b>“Damaged Property Fee”</b>).
          Additionally, if you, Your Guests, or your or their pets have damaged
          a Booked Property or any Property or damaged or removed or stolen any
          physical item therein we may terminate this Agreement and remove you
          and any and all of Your Guests from the Booked Property and any
          Property. We reserve at all times the right to fix the Damaged
          Property in the manner of our choosing and to pursue in our sole
          discretion any and all avenues available to us to collect from you the
          Damaged Property Fee. For example, we may exercise the foregoing right
          by fixing the Damaged Property over an extended period of time,
          charging the Applicable Payment Method the sum total of the Damaged
          Property Fee up front or in installments, or by making one or more
          claims under your homeowner’s, renter’s, and/or other insurance
          policy, and you agree to cooperate with and assist us in good faith,
          and to provide us with such information as we may reasonably request
          in order to successfully make such claim.
        </p>
        <p>
          <b>8.8 Your Personal Items.</b> We are not responsible for any of your
          property or personal items (collectively, <b>“Personal Items”</b>)
          either during the term of this Agreement or that you may leave in any
          of the Properties after the expiration or earlier termination of this
          Agreement. Prior to the expiration of the applicable Period of
          Occupancy, you must remove all of your Personal Items from all
          applicable Booked Properties and any Property. If you have not done
          so, and after using commercially reasonable efforts to provide you
          with reasonable notice, we will be entitled to remove, store, and/or
          dispose of any Personal Items remaining in any of our Properties in
          accordance with applicable law, and you waive any claims or demands
          regarding such Personal Items or our handling of them. You will be
          responsible for paying any fees reasonably incurred by us regarding
          such removal, storage, and/or disposal. We recommend that you consider
          obtaining renter’s insurance or personal property insurance to protect
          against any damage to or loss of your Personal Items.
        </p>
        <p>
          <b>8.9 Investigation and Prosecution.</b> We reserve at all times the
          right to investigate and prosecute any breach of any provision of this
          Agreement to the fullest extent of the law. We may involve and
          cooperate with law enforcement authorities in prosecuting users who
          breach any provision of this Agreement. You acknowledge that we have
          no obligation to monitor your access to or use of the Service or
          Properties but have the right to do so for the purpose of operating
          the Service, to ensure your compliance with this Agreement, or to
          comply with applicable law or the order or requirement of a court,
          administrative agency, or other governmental body.
        </p>
      </div>
    </Detail>
  </Row>
);

export default RestrictionsandResponsibilities;
