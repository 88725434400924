import React, { useState } from 'react';
import { FormControl } from 'react-bootstrap';

import {
  Wrapper,
  BillItem,
  BillItemText,
  BillItemAmount,
  AddPromo,
  AddPromoCode,
} from './styled';
import ArrowIcon from '../../../Assets/Images/Icons/ArrowIcon.svg';

interface BillDetailProps {
  billingData: any;
}

const BillDetail: React.FC<BillDetailProps> = ({
  billingData,
}: BillDetailProps) => {
  const [show, setShow] = useState<boolean>(false);
  return (
    <Wrapper>
      <BillItem key={billingData?.id}>
        <div
          className="d-flex w-100 justify-content-between"
          key={billingData?.item?.prices?.per_day_Price}
        >
          <BillItemText>
            US${billingData?.prices?.per_day_Price} x {billingData?.nights}{' '}
            nights
          </BillItemText>
          <BillItemAmount>US${billingData?.prices?.base_total}</BillItemAmount>
        </div>
        <div
          className="d-flex w-100 justify-content-between"
          key={billingData?.prices?.discount}
        >
          <BillItemText style={{ color: '#DCA73A' }}>
            {billingData?.prices?.discount}% off - member rate
          </BillItemText>
          <BillItemAmount style={{ color: '#DCA73A' }}>
            US${billingData?.prices?.total_discount}
          </BillItemAmount>
        </div>
        <div
          className="d-flex w-100 justify-content-between"
          key={billingData?.nights}
        >
          <BillItemText>
            Your rate US$
            {billingData?.prices?.total_yourRates} x {billingData?.nights}{' '}
            nights
          </BillItemText>
          <BillItemAmount>
            US${billingData?.prices?.total_nights}
          </BillItemAmount>
        </div>
        <div
          className="d-flex w-100 justify-content-between"
          key={billingData?.prices?.total_tax}
        >
          <BillItemText>Tax</BillItemText>
          <BillItemAmount>US${billingData?.prices?.total_tax}</BillItemAmount>
        </div>
      </BillItem>
      <div
        className="d-flex w-100 justify-content-between"
        key={billingData?.prices?.totalPrice}
      >
        <BillItemText className="bold">Total</BillItemText>
        <BillItemAmount className="bold">
          ${billingData?.prices?.totalPrice}
        </BillItemAmount>
      </div>
      <AddPromo onClick={() => setShow(true)}>Add promo code &gt;</AddPromo>
      {show && (
        <AddPromoCode className="mt-3">
          <FormControl
            className="add-promo-code py-3"
            placeholder="Promo code"
          />
          <img src={ArrowIcon} alt="arrow" />
        </AddPromoCode>
      )}
    </Wrapper>
  );
};
export default BillDetail;
