import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 0px 0px;
`;

export const Heading = styled.h6`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  color: #212f52;
  padding-top: 70px;
  @media (max-width: 425px) {
    padding-top: 30px;
  }
`;

export const RowWrapper = styled(Row)`
  height: 100%;
  padding-top: 48px;
  @media (max-width: 425px) {
    padding-top: 15px;
  }
`;
