import React from 'react';
import { BeforeCheckInBox, RedText } from './styled';
import RedAlertIcon from '../../../Assets/Images/Icons/RedAlertIcon.svg';

const ErrorAlertBox: React.FC = () => (
  <div className="pb-5">
    <BeforeCheckInBox className="d-flex align-items-center">
      <img src={RedAlertIcon} alt="key" />
      <RedText className="m-0">
        Please provide an ID to verify your account and enable check-in.
      </RedText>
    </BeforeCheckInBox>
  </div>
);
export default ErrorAlertBox;
