import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail } from './styled';

const ModificationoftheService: React.FC = () => (
  <Row>
    <Detail>
      <div style={{ paddingLeft: '30px' }}>
        <p>
          <b>14.4 Modification of the Service.</b>
          <div style={{ paddingLeft: '30px', marginTop: '20px' }}>
            <p>
              <b>
                14.4.1 Modification of the Service for STA/Hotel Reservations.
              </b>{' '}
              We reserve the right to modify or discontinue the Service at any
              time (including by limiting or discontinuing certain features of
              the Service such as any of our product or Property offerings),
              temporarily or permanently, without notice to you. We will have no
              liability for any such change to or discontinuation of the
              Service.
            </p>
            <p>
              <b>
                14.4.2 Modification of the Service for Extended Stay
                Reservations.
              </b>{' '}
              We reserve the right to modify or discontinue the Service at any
              time (including by limiting or discontinuing certain features of
              the Service such as any of our product or Property offerings, but
              not the Extended Stay Property during the Extended Stay Period of
              Occupancy except as expressly permitted in the Extended Stay
              Agreement or Confirmation), temporarily or permanently, without
              notice to you. We will have no liability for any such change to or
              discontinuation of the Service.
            </p>
            <p>
              <b>
                14.4.3 Modification of the Service for Subscription
                Reservations.
              </b>{' '}
              We reserve the right to modify or discontinue the Service at any
              time (including by limiting or discontinuing certain features of
              the Service such as any of our product or Property offerings, but
              not the Subscription Property during the Subscription Period of
              Occupancy except as expressly permitted in the Membership
              Agreement), temporarily or permanently, without notice to you. We
              will have no liability for any such change to or discontinuation
              of the Service.
            </p>
          </div>
        </p>
      </div>
    </Detail>
  </Row>
);

export default ModificationoftheService;
