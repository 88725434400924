import React from 'react';
import { Row } from 'react-bootstrap';
import { Detail, DotTag, LinkTag, Questions } from './styled';

const Cookies: React.FC = () => (
  <>
    <Row>
      <Questions>5. Cookies and Other Tracking Mechanisms</Questions>
      <Detail>
        <p>
          We use cookies, web beacons, and log files to collect information
          about your use of our Website and Apps, as described above.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          <b> Cookies.</b> We use “cookies” to collect information. A cookie is
          a small data file that we transfer to your computer’s hard disk for
          record-keeping purposes. We use cookies for two purposes. First, we
          utilize persistent cookies to save your login information for future
          logins to the Services. Second, we utilize session ID cookies to
          enable certain features of the Services, to better understand how you
          interact with the Services and to monitor aggregate usage by Gilbert
          Hotels Users and web traffic routing on the Services. Unlike
          persistent cookies, session cookies are deleted from your computer
          when you log off from the Site and Service and then close your
          browser. We use the following cookies:
        </p>
        <div className="px-3">
          <p>
            <DotTag className="px-1">&#8728;</DotTag>
            <b> Google.</b> We use Google Analytics cookies and Google Tag
            Manager to analyze our Services. You can learn about Google’s
            practices by going to{' '}
            <LinkTag to="/">
              https://policies.google.com/privacy/google-partners/,
            </LinkTag>{' '}
            and opt out of them by downloading the Google Analytics opt-out
            browser add-on available at{' '}
            <LinkTag to="/">https://tools.google.com/dlpage/gaoptout.</LinkTag>{' '}
            We also use Google cookies to serve ads based on a Gilbert Hotels
            User’s prior visits to Gilbert Hotels. You can learn more about
            these cookies <LinkTag to="/">here</LinkTag> and disable these
            cookies via <LinkTag to="/">Google’s Ads Settings</LinkTag> or Ad
            Settings for mobile apps.
          </p>
          <p>
            <DotTag className="px-1">&#8728;</DotTag>
            <b> Hotjar.</b> We use Hotjar in order to better understand our
            users’ needs and to optimize this service and experience. Hotjar is
            a technology service that helps us better understand our users’
            experience (e.g., how much time they spend on which pages, which
            links they choose to click, what users do and don’t like, etc.) and
            this enables us to build and maintain our service with user
            feedback. Hotjar uses cookies and other technologies to collect data
            on our users’ behavior and their devices. This includes a device’s
            IP address (processed during your session and stored in a
            de-identified form), device screen size, device type (unique device
            identifiers), browser information, geographic location (country
            only), and the preferred language used to display our website.
            Hotjar stores this information on our behalf in a pseudonymized user
            profile. Hotjar is contractually forbidden to sell any of the data
            collected on our behalf. For further details, please see the ‘about
            Hotjar’ section of Hotjar’s support site.
          </p>
          <p>
            <DotTag className="px-1">&#8728;</DotTag>
            <b> Segment.io cookies.</b> We use Segment.io cookies to analyze our
            Services, support your use of the Services and track your activity
            on our Website and Apps. More on Segment.io can be found at{' '}
            <LinkTag to="/">https://segment.com/docs/legal/privacy/.</LinkTag>
          </p>
        </div>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          <b> Web beacons.</b> Our Site may contain electronic images known as
          Web beacons (sometimes called single-pixel gifs) and are used along
          with cookies to compile aggregated statistics to analyze how our Site
          is used and may be used in some of our emails to let us know which
          emails and links have been opened by recipients. This allows us to
          gauge the effectiveness of our customer communications and marketing
          campaigns.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          <b> Log data.</b> When you visit the Site, whether as a Member or a
          non-registered user just browsing (any of these, a “Gilbert Hotels
          User”), our servers automatically record information that your browser
          sends whenever you visit a website (“Log Data”). This Log Data may
          include information such as your computer’s Internet Protocol (“IP”)
          address, browser type or the webpage you were visiting before you came
          to our Site, pages of our Site that you visit, the time spent on those
          pages, information you search for on our Site, access times and dates,
          and other statistics. We use this information to monitor and analyze
          use of the Site and the Service and for the Site’s technical
          administration, to increase our Site functionality and
          user-friendliness, and to better tailor our Site to our visitors’
          needs.
        </p>
        <p>
          <DotTag className="px-1">&bull; </DotTag>
          <b> Error reporting.</b> We may use Bugsnag’s bug tracking JavaScript
          notifier or other technology to analyze the functioning of our
          Services. More on Bugsnag can be found at{' '}
          <LinkTag to="/">
            https://docs.bugsnag.com/legal/privacy-policy/.
          </LinkTag>
        </p>
      </Detail>
    </Row>
  </>
);

export default Cookies;
