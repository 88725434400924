import { Formik, Form, Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { auth, db } from '../../../Config/firebase';
import {
  ContainerWrapper,
  FieldDIv,
  GilberthotelsDiv,
  Heading,
  NotificationDiv,
  RemindersDiv,
  Reservationdiv,
  SavePreferencesButton,
} from './styled';

const NotificationPreferences: React.FC = () => {
  const [checkboxValue, setCheckboxValue] = useState<any>({
    rese_email: '',
    rese_sms: '',
    reminder_email: '',
    reminder_sms: '',
    updates: '',
  });
  const [listLoading, setListLoading] = useState<boolean>(false);

  const userid = auth?.currentUser?.uid;
  useEffect(() => {
    const getCurrentUserData = async () => {
      const docRef = db.collection('guests').doc(userid);
      const docSnap = await docRef.get();
      if (docSnap.exists) {
        setCheckboxValue({
          rese_email: docSnap?.data()?.reservation_updates?.email,
          rese_sms: docSnap?.data()?.reservation_updates?.sms,
          reminder_email: docSnap?.data()?.reminders?.email,
          reminder_sms: docSnap?.data()?.reminders?.sms,
          updates: docSnap?.data()?.updates,
        });
      } else {
        toast.error('Error');
      }
    };
    if (userid) {
      getCurrentUserData();
    } else {
      toast.error('Error');
    }
  }, [userid]);

  return (
    <ContainerWrapper className="pt-4">
      <NotificationDiv>
        <FieldDIv>
          <Heading>Notification Preferences</Heading>
          <Formik
            initialValues={{ ...checkboxValue }}
            enableReinitialize
            onSubmit={async values => {
              setListLoading(true);
              try {
                const docRef = db.collection('guests').doc(userid);
                const guestRef: any = await docRef.update({
                  reservation_updates: {
                    email: values?.rese_email,
                    sms: values?.rese_sms,
                  },
                  reminders: {
                    email: values?.reminder_email,
                    sms: values?.reminder_sms,
                  },
                  updates: values?.updates,
                });
                if (guestRef) {
                  toast.success('Record Updated!');
                  setListLoading(false);
                }
              } catch (error) {
                toast.error('Error');
                setListLoading(false);
              }
            }}
          >
            {({ isSubmitting, handleSubmit, handleChange, handleBlur }) => (
              <Form onSubmit={handleSubmit}>
                <Reservationdiv className="pt-4">
                  <h6>Reservation Updates</h6>
                  <p>
                    Receive updates regarding your booking and early
                    check-in/late check-out requests.
                  </p>
                  <div className="d-flex">
                    <Field
                      type="checkbox"
                      className="form-check-input"
                      name="rese_email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p className="input-label px-3">Email</p>
                  </div>
                  <div className="d-flex">
                    <Field
                      type="checkbox"
                      className="form-check-input"
                      name="rese_sms"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p className="input-label px-3">SMS</p>
                  </div>
                </Reservationdiv>
                <RemindersDiv className="pt-4">
                  <h6>Reminders & Recommendations</h6>
                  <p>
                    Receive booking reminders, travel tips, and recommendations
                    relevant to your stay.
                  </p>
                  <div className="d-flex">
                    <Field
                      type="checkbox"
                      className="form-check-input"
                      name="reminder_email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p className="input-label px-3">Email</p>
                  </div>
                  <div className="d-flex">
                    <Field
                      type="checkbox"
                      className="form-check-input"
                      name="reminder_sms"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p className="input-label px-3">SMS</p>
                  </div>
                </RemindersDiv>
                <GilberthotelsDiv className="pt-4">
                  <h6>Gilberthotels Updates</h6>
                  <p>
                    Receive updates about the latest Gilbert Hotels news, city
                    launches, and exclusive deals.
                  </p>
                  <div className="d-flex">
                    <Field
                      type="checkbox"
                      className="form-check-input"
                      name="updates"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p className="input-label px-3">Email</p>
                  </div>
                </GilberthotelsDiv>
                {!listLoading ? (
                  <SavePreferencesButton disabled={isSubmitting}>
                    Save Preference
                  </SavePreferencesButton>
                ) : (
                  <div className="text-center">
                    <Spinner animation="border" />
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </FieldDIv>
      </NotificationDiv>
    </ContainerWrapper>
  );
};
export default NotificationPreferences;
