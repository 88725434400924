import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import moment from 'moment';
import BookingDateSelector from './BookingDateSelector';
import SearchInput from './SearchInput';
import StreetInput from './StreetInput';
import GuestInput from './GuestInput';
import { Wrapper, DatePickerWrapper, ColWrapper } from './styled';
import Price from './Price';
import Filter from './Filter';

interface SearchingFilterProps {
  locationStateDetail: any;
  setLocationStateDetail: (data: any) => void;
}

const SearchingFilter: React.FC<SearchingFilterProps> = ({
  locationStateDetail,
  setLocationStateDetail,
}: SearchingFilterProps) => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [count, setCount] = useState<number>(Number(query.get('guests')));
  const [fromValue, setFromValue] = useState<string | null>(
    query.get('startDate') || 'Check In',
  );
  const [toValue, setToValue] = useState<string | null>(
    query.get('endDate') || 'Check Out',
  );
  const [bedRoomsCount, setBedRoomsCount] = useState<number>(
    Number(query.get('bedrooms')),
  );
  const [bedsCount, setBedsCount] = useState<number>(Number(query.get('beds')));
  const [bathRoomsCount, setBathRoomsCount] = useState<number>(
    Number(query.get('bathrooms')),
  );
  const [min, setMin] = useState<any | null>();
  const [max, setMax] = useState<any | null>();
  const handleChangeCity = (citydata: {
    key: string;
    label: string;
    value: string;
  }) => {
    setSelectedCity(citydata.key);
  };

  const handleAddCount = () => {
    setCount(count + 1);
    query.set('guests', `${count + 1}`);
    setLocationStateDetail({ ...locationStateDetail, guests: count + 1 });
    history.push({
      pathname: location.pathname,
      search: query.toString(),
      state: { ...locationStateDetail, guests: count + 1 },
    });
  };
  const handleRemoveCount = () => {
    if (count === 1) {
      query.delete('guests');
      setCount(count - 1);
      setLocationStateDetail({ ...locationStateDetail, guests: count - 1 });
      history.push({
        pathname: location.pathname,
        search: query.toString(),
        state: { ...locationStateDetail, guests: count - 1 },
      });
    }
    if (count > 1) {
      setCount(count - 1);
      query.set('guests', `${count - 1}`);
      setLocationStateDetail({ ...locationStateDetail, guests: count - 1 });
      history.push({
        pathname: location.pathname,
        search: query.toString(),
        state: { ...locationStateDetail, guests: count - 1 },
      });
    }
  };

  const handleEvent = (value: any) => {
    const startdate = moment(value.from).format('MM-DD-YYYY');
    const momentStartDate = moment(startdate).format('MM-DD-YYYY');
    setFromValue(momentStartDate);
    query.set('startDate', `${momentStartDate}`);
    const enddate = moment(value.to).format('MM-DD-YYYY');
    const momentendDate = moment(enddate).format('MM-DD-YYYY');
    setToValue(momentendDate);
    console.log('location', location.state);
    query.set('endDate', `${momentendDate}`);
    setLocationStateDetail({
      ...locationStateDetail,
      startDate: moment(momentStartDate).format('YYYY-MM-DD'),
      endDate: moment(momentendDate).format('YYYY-MM-DD'),
    });
    history.push({
      pathname: location.pathname,
      search: query.toString(),
      state: {
        ...locationStateDetail,
        startDate: moment(momentStartDate).format('YYYY-MM-DD'),
        endDate: moment(momentendDate).format('YYYY-MM-DD'),
      },
    });
  };

  const handleRemoveBedRoomsCount = () => {
    if (bedRoomsCount === 1) {
      query.delete('bedrooms');
      setBedRoomsCount(bedRoomsCount - 1);
      setLocationStateDetail({
        ...locationStateDetail,
        bedrooms: bedRoomsCount - 1,
      });
      history.push({
        pathname: location.pathname,
        search: query.toString(),
        state: { ...locationStateDetail, bedrooms: bedRoomsCount - 1 },
      });
    }
    if (bedRoomsCount > 1) {
      setBedRoomsCount(bedRoomsCount - 1);
      query.set('bedrooms', `${bedRoomsCount - 1}`);
      setLocationStateDetail({
        ...locationStateDetail,
        bedrooms: bedRoomsCount - 1,
      });
      history.push({
        pathname: location.pathname,
        search: query.toString(),
        state: { ...locationStateDetail, bedrooms: bedRoomsCount - 1 },
      });
    }
  };
  const handleRemoveBedsCount = () => {
    if (bedsCount === 1) {
      query.delete('beds');
      setBedsCount(bedsCount - 1);
      setLocationStateDetail({
        ...locationStateDetail,
        beds: bedsCount - 1,
      });
      history.push({
        pathname: location.pathname,
        search: query.toString(),
        state: { ...locationStateDetail, beds: bedsCount - 1 },
      });
    }
    if (bedsCount > 1) {
      setBedsCount(bedsCount - 1);
      query.set('beds', `${bedsCount - 1}`);
      setLocationStateDetail({
        ...locationStateDetail,
        beds: bedsCount - 1,
      });
      history.push({
        pathname: location.pathname,
        search: query.toString(),
        state: {
          ...locationStateDetail,
          beds: bedsCount - 1,
        },
      });
    }
  };
  const handleRemoveBathRoomsCount = () => {
    if (bathRoomsCount === 1) {
      query.delete('bathrooms');
      setBathRoomsCount(bathRoomsCount - 1);
      setLocationStateDetail({
        ...locationStateDetail,
        bathrooms: bathRoomsCount - 1,
      });
      history.push({
        pathname: location.pathname,
        search: query.toString(),
        state: { ...locationStateDetail, bathrooms: bathRoomsCount - 1 },
      });
    }
    if (bathRoomsCount > 1) {
      setBathRoomsCount(bathRoomsCount - 1);
      query.set('bathrooms', `${bathRoomsCount - 1}`);
      setLocationStateDetail({
        ...locationStateDetail,
        bathrooms: bathRoomsCount - 1,
      });
      history.push({
        pathname: location.pathname,
        search: query.toString(),
        state: { ...locationStateDetail, bathrooms: bathRoomsCount - 1 },
      });
    }
  };

  const handleAddBedRoomsCount = () => {
    setBedRoomsCount(bedRoomsCount + 1);
    query.set('bedrooms', `${bedRoomsCount + 1}`);
    setLocationStateDetail({
      ...locationStateDetail,
      bedrooms: bedRoomsCount + 1,
    });
    history.push({
      pathname: location.pathname,
      search: query.toString(),
      state: { ...locationStateDetail, bedrooms: bedRoomsCount + 1 },
    });
  };
  const handleAddBedCount = () => {
    setBedsCount(bedsCount + 1);
    query.set('beds', `${bedsCount + 1}`);
    setLocationStateDetail({
      ...locationStateDetail,
      beds: bedsCount + 1,
    });
    history.push({
      pathname: location.pathname,
      search: query.toString(),
      state: { ...locationStateDetail, beds: bedsCount + 1 },
    });
  };
  const handleAddBathRoomsCount = () => {
    setBathRoomsCount(bathRoomsCount + 1);
    query.set('bathrooms', `${bathRoomsCount + 1}`);
    setLocationStateDetail({
      ...locationStateDetail,
      bathrooms: bathRoomsCount + 1,
    });
    history.push({
      pathname: location.pathname,
      search: query.toString(),
      state: { ...locationStateDetail, bathrooms: bathRoomsCount + 1 },
    });
  };

  const handleAirConditioning = (e: any) => {
    if (e.target.checked) {
      query.set(e.target.name, e.target.checked);
      setLocationStateDetail({
        ...locationStateDetail,
        [e.target.name]: e.target.checked,
      });
    } else {
      query.delete(e.target.name);
      setLocationStateDetail({
        ...locationStateDetail,
        [e.target.name]: e.target.checked,
      });
    }

    history.push({
      pathname: location.pathname,
      search: query.toString(),
      state: { ...locationStateDetail, [e.target.name]: e.target.checked },
    });
  };

  const handleMinPrice = (e: any) => {
    setMin(e[0]);
    query.set('priceMin', `${e[0]}`);
    setMax(e[1]);
    query.set('priceMax', `${e[1]}`);
    setLocationStateDetail({
      ...locationStateDetail,
      priceMin: e[0],
      priceMax: e[1],
    });
    history.push({
      pathname: location.pathname,
      search: query.toString(),
      state: { ...locationStateDetail, priceMin: e[0], priceMax: e[1] },
    });
  };

  useEffect(() => {
    if (selectedCity) {
      const string = `/destinations/${selectedCity}/search`;
      history.push({
        pathname: string,
        search: query.toString(),
      });
    }
  }, [selectedCity]);

  return (
    <Wrapper>
      <Row>
        <ColWrapper lg={2} md={6} sm={12}>
          <SearchInput setSelectedCity={handleChangeCity} />
        </ColWrapper>
        <ColWrapper lg={2} md={6} sm={12}>
          <StreetInput />
        </ColWrapper>
        <ColWrapper lg={3} md={6} sm={12}>
          <DatePickerWrapper>
            <BookingDateSelector
              setHandleEvent={handleEvent}
              isShowIcon={false}
              fromValue={fromValue}
              setFromValue={setFromValue}
              toValue={toValue}
              setToValue={setToValue}
            />
          </DatePickerWrapper>
        </ColWrapper>
        <ColWrapper lg={2} md={6} sm={12}>
          <GuestInput
            setAddGuestCount={handleAddCount}
            setRemoveGuestCount={handleRemoveCount}
            count={count}
          />
        </ColWrapper>
        <ColWrapper lg={1} md={6} sm={12}>
          <Price
            setMin={handleMinPrice}
            setMax={handleMinPrice}
            min={min}
            max={max}
          />
        </ColWrapper>
        <ColWrapper lg={1} md={6} sm={12}>
          <Filter
            setAddBedRoomsCount={handleAddBedRoomsCount}
            setAddBedsCount={handleAddBedCount}
            setAddBathRoomsCount={handleAddBathRoomsCount}
            setRemoveBedRoomsCount={handleRemoveBedRoomsCount}
            setRemoveBedsCount={handleRemoveBedsCount}
            setRemoveBathRoomsCount={handleRemoveBathRoomsCount}
            bathRoomsCount={bathRoomsCount}
            bedRoomsCount={bedRoomsCount}
            bedsCount={bedsCount}
            setAirConditioning={handleAirConditioning}
          />
        </ColWrapper>
      </Row>
    </Wrapper>
  );
};
export default SearchingFilter;
