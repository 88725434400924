import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { DateInput } from './styled';
import DateArrowIcon from '../../../Assets/Images/Icons/DateArrowIcon.svg';
import CalanderIcon from '../../../Assets/Images/Icons/CalanderIcon.svg';

interface BookingDateSelectorProps {
  billingData: any;
}

const BookingDateSelector: React.FC<BookingDateSelectorProps> = ({
  billingData,
}: BookingDateSelectorProps) => (
  <>
    <div className="d-flex">
      <img src={CalanderIcon} alt="calander" className="date-picker-icon" />
      <Row className="date-item-container m-auto">
        <div className="d-flex">
          <Col className="p-0" lg={5} md={5} sm={5} key={billingData?.id}>
            <DateInput
              placeholder="Check In"
              value={billingData?.check_in_date}
              disabled
            />
          </Col>
          <Col className="p-0" lg={2} md={2} sm={2}>
            <img src={DateArrowIcon} alt="arrow" />
          </Col>
          <Col className="p-0" lg={5} md={5} sm={5}>
            <DateInput
              placeholder="Check Out"
              value={billingData?.check_out_date}
              disabled
            />
          </Col>
        </div>
      </Row>
    </div>
  </>
);

export default BookingDateSelector;
