import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { DateRange } from 'react-day-picker';
import {
  ArrowIconWrapper,
  CalendarIconWrapper,
  CalendarWrapper,
  DateInput,
  DateRangePicker,
  DatePicker,
  MainWrapper,
} from './styled';
import 'react-day-picker/dist/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import BlueArrow from '../../../Assets/Images/Icons/BlueArrow.svg';
import CalanderIcon from '../../../Assets/Images/Icons/CalanderIcon.svg';

interface BookingDateProps {
  setHandleEvent: (picker: any) => void;
  isShowIcon: boolean;
  setFromValue: (value: string) => void;
  setToValue: (value: string) => void;
  fromValue: string;
  toValue: string;
}

const BookingDateSelector: React.FC<BookingDateProps> = ({
  setHandleEvent,
  isShowIcon,
  setFromValue,
  setToValue,
  fromValue,
  toValue,
}: BookingDateProps) => {
  const [isShow, setIsShow] = useState(false);
  const [selectedRange, setSelectedRange] = useState<DateRange>();

  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    setSelectedRange({
      ...selectedRange,
      from: fromValue?.length === 10 ? new Date(fromValue) : undefined,
      to: toValue?.length === 10 ? new Date(toValue) : undefined,
    });
    setFromValue(
      fromValue?.length === 10
        ? moment(fromValue).format('y-MM-DD')
        : 'Check In',
    );
    setToValue(
      toValue?.length === 10 ? moment(toValue).format('y-MM-DD') : 'Check Out',
    );
  }, [fromValue, toValue]);

  const handleRangeSelect: any = (range1: DateRange | undefined) => {
    setSelectedRange({ ...range1 });
    if (range1?.from && range1?.to) {
      if (range1?.from) {
        setHandleEvent({ from: range1.from, to: range1.to });
      } else {
        setFromValue('');
      }
      if (range1?.to) {
        setHandleEvent({ from: range1.from, to: range1.to });
        setIsShow(false);
      } else {
        setToValue('');
      }
    }
  };

  const today = new Date();
  today.setDate(today.getDate() - 1);
  const disabledDates = [{ from: new Date(1999, 1, 1), to: new Date(today) }];
  return (
    <>
      <MainWrapper ref={wrapperRef}>
        {isShowIcon ? (
          <CalendarIconWrapper onClick={() => setIsShow(true)}>
            <img
              src={CalanderIcon}
              alt="calander"
              style={{
                height: isShowIcon ? '24px' : '20px',
                width: isShowIcon ? '24px' : '20px',
              }}
            />
          </CalendarIconWrapper>
        ) : null}
        <CalendarWrapper
          onClick={() => setIsShow(true)}
          className="date-picker-icon2"
        >
          <DateInput
            placeholder="Check In"
            value={fromValue === 'Invalid date' ? 'Check In' : fromValue}
            disabled
            style={{ fontSize: isShowIcon ? '20px' : '15px' }}
          />
          <ArrowIconWrapper>
            <img
              src={BlueArrow}
              alt="arrow"
              style={{
                height: isShowIcon ? '24px' : '20px',
                width: isShowIcon ? '24px' : '20px',
              }}
            />
          </ArrowIconWrapper>
          <DateInput
            placeholder="Check Out"
            value={toValue === 'Invalid date' ? 'Check Out' : toValue}
            disabled
            style={{ fontSize: isShowIcon ? '20px' : '15px' }}
          />
        </CalendarWrapper>
        {isShow ? (
          <>
            <DateRangePicker
              className="Selectable"
              mode="range"
              selected={selectedRange}
              onSelect={(range1: DateRange | undefined) => {
                if (fromValue !== 'Check In' && toValue !== 'Check Out') {
                  setSelectedRange(null);
                  setFromValue('');
                  setToValue('');
                  if (range1) {
                    if (moment(range1?.from).format('YYYY-MM-DD') < fromValue) {
                      setFromValue(moment(range1?.from).format('MM-DD-YYYY'));
                      setToValue('');
                    } else if (
                      moment(range1?.to).format('YYYY-MM-DD') >= fromValue
                    ) {
                      setFromValue(moment(range1?.to).format('MM-DD-YYYY'));
                      setToValue('');
                    } else {
                      setFromValue(moment(range1?.from).format('MM-DD-YYYY'));
                    }
                  } else {
                    setFromValue(moment(fromValue).format('MM-DD-YYYY'));
                    setToValue('');
                  }
                } else {
                  handleRangeSelect(range1);
                }
              }}
              numberOfMonths={2}
              disabled={disabledDates}
            />
            <DatePicker
              className="Selectable"
              mode="range"
              selected={selectedRange}
              onSelect={(range1: DateRange | undefined) => {
                if (fromValue !== 'Check In' && toValue !== 'Check Out') {
                  setSelectedRange(null);
                  setFromValue('');
                  setToValue('');
                  if (range1) {
                    if (moment(range1?.from).format('YYYY-MM-DD') < fromValue) {
                      setFromValue(moment(range1?.from).format('MM-DD-YYYY'));
                      setToValue('');
                    } else if (
                      moment(range1?.to).format('YYYY-MM-DD') >= fromValue
                    ) {
                      setFromValue(moment(range1?.to).format('MM-DD-YYYY'));
                      setToValue('');
                    } else {
                      setFromValue(moment(range1?.from).format('MM-DD-YYYY'));
                    }
                  } else {
                    setFromValue(moment(fromValue).format('MM-DD-YYYY'));
                    setToValue('');
                  }
                } else {
                  handleRangeSelect(range1);
                }
              }}
              numberOfMonths={1}
              disabled={disabledDates}
            />
          </>
        ) : null}
      </MainWrapper>
    </>
  );
};

export default BookingDateSelector;
