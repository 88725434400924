import { Nav, Navbar } from 'react-bootstrap';
import styled from 'styled-components';

export const NavDiv = styled(Nav)`
  display: flex;
  .nav-link {
    color: #212f52;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    flex: none;
    order: 0;
    flex-grow: 0;
    -webkit-text-fill-color: #212f52;
  }
  @media (max-width: 425px) {
    flex-direction: column !important;
    display: block !important;
  }
`;

export const NavbarWrapper = styled(Navbar)`
  @media (max-width: 425px) {
    display: block;
  }
`;

export const BlogNavbarDiv = styled.div`
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 20px;
  @media (max-width: 425px) {
    /* flex-direction: column; */
    /* display: none; */
  }
`;

export const SearchBar = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  input {
    background: #f9f9f9;
    border: 1px solid #caccd1;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    flex: none;
    order: 0;
    flex-grow: 1;
    margin: 0px 10px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #9f9fa3;
    outline: none;
  }
  img {
    position: absolute;
    flex: none;
    order: 1;
    flex-grow: 0;
    padding-right: 10px;
  }
`;
