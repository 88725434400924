import React from 'react';
import {
  ComponentsContainer,
  Checkout1Title,
  BookingConfirmation,
  BookingDetails,
  ResendLink,
  Rowwrapper,
} from './styled';
import PaymentSummary from './PaymentSummary';
import CancellationPolicy from './CancellationPolicy';
import BeforeCheckIn from './BeforeCheckIn';
import WhatToExpect from './WhatToExpect';
import ErrorAlertBox from './ErrorAlertBox';

interface Checkout1DetailsProps {
  reservation: any;
}

const Checkout1Details: React.FC<Checkout1DetailsProps> = ({
  reservation,
}: Checkout1DetailsProps) => (
  <Rowwrapper>
    <ComponentsContainer>
      <ErrorAlertBox />
      <Checkout1Title>
        Thanks for booking, just a
        <br />
        few more things...
      </Checkout1Title>
      <BookingConfirmation className="pt-5">
        Booking Confirmation #AB-CDEFG
      </BookingConfirmation>
      <BookingDetails className="pt-2">
        Sent to {reservation?.guest?.email}{' '}
        <ResendLink to="/">Re-send email</ResendLink>
      </BookingDetails>
      <BeforeCheckIn reservation={reservation} />
      <WhatToExpect />
      <PaymentSummary reservation={reservation} />
      <CancellationPolicy />
    </ComponentsContainer>
  </Rowwrapper>
);
export default Checkout1Details;
