import styled from 'styled-components';

interface ImageProps {
  src: string;
}

export const RowWrapper = styled.div``;

export const CardHeader = styled.h3`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 52px;
  color: #212f52;
`;

export const CardDescription = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212f52;
`;

export const ActionWrapper = styled.div`
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 30px;
`;

export const InputField = styled.input`
  border: 0px;
  width: 100%;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #9f9fa3;
  order: 0;
  flex-grow: 0;
  margin: 15px 0px;
  border-bottom: #212f52 1px solid;
  padding-bottom: 15px;
  outline: none;
`;

export const SendInviteButton = styled.button`
  background: #212f52;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 23px 0px;
  width: 100%;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.1em;
  margin-right: 5px;
`;

export const InputIcon = styled.img<ImageProps>`
  padding-right: 25px;
`;
