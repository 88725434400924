import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export default styled.div`
  padding-right: 80px;
  @media (max-width: 768px) {
    padding-right: 0px;
  }
  @media (max-width: 425px) {
    padding-right: 10px;
  }
`;

export const Rowwrapper = styled(Row)`
  padding-top: 48px;
`;

export const LoaderDiv = styled.div`
  text-align: center;
`;
