import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 60px 0px;
`;

export const UpdateAccountDiv = styled.div`
  background: #ffffff;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 15px;
`;
