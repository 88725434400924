import React from 'react';
import { Row } from 'react-bootstrap';
import { Clock, Gym, Heading, Pool, Ruler, TimesDetails } from './style';
import ClockIcon from '../../../Assets/Images/Icons/ClockIcon.svg';

const Times = [
  {
    id: 1,
    title: 'Sunday',
    time: '10:00am-6:00pm',
  },
  {
    id: 2,
    title: 'Monday',
    time: '10:00am-6:00pm',
  },
  {
    id: 3,
    title: 'Tuesday',
    time: '10:00am-6:00pm',
  },
  {
    id: 4,
    title: 'Wednesday',
    time: '10:00am-6:00pm',
  },
  {
    id: 5,
    title: 'Thursday',
    time: '10:00am-6:00pm',
  },
  {
    id: 6,
    title: 'Friday',
    time: '10:00am-6:00pm',
  },
  {
    id: 7,
    title: 'Saturday',
    time: '10:00am-6:00pm',
  },
];

const BuildingGuide: React.FC = () => (
  <Row className="pt-4">
    <Heading className="pb-3">Building Guide</Heading>
    <div>
      <Gym>
        <div className="pt-4 px-3 pb-2">
          <p>
            <b>Gym</b>
            <br />
            <br />
            The entrance to the gym is to the left of the glass elevator at the
            northeast end of the retail parking garage. You’ll need your key fob
            to get in. The gym is open 24/7, but currently with limited
            capacity. Reservations are required and limited to a single one hour
            reservation per guest, per day. Please contact us to reserve a spot
            at the gym – at least 24 hours before your desired time slot. The
            following rules are in place: No more than 12 people at a time. Be
            respectful and give others space (6 ft for social distancing). Don’t
            plug-in any equipment that has been purposefully moved to make more
            room. We appreciate your cooperation with these rules, as well as
            any posted signage in the gym. If the rules are broken then the
            building may close the gym. The wifi password is “resident”.
          </p>
          <Ruler className="pb-1">
            <hr />
          </Ruler>
          <Row>
            <Clock>
              <div>
                <img src={ClockIcon} alt="clock" />
              </div>
              <div>
                <p>Open 24h</p>
              </div>
            </Clock>
          </Row>
        </div>
      </Gym>
    </div>
    <div className="pt-3">
      <Pool className="pt-3">
        <div className="pt-4 px-3 pb-2">
          <p>
            <b>Pool</b>
            <br />
            <br />
            The pool is open from 10 a.m. to 5:45 p.m. The entrance to the pool
            is located beside the gym, on the ground floor of the parking
            garage. The pool code is 2 + 4 (at the same time) then 3. When in
            the pool area, masks are required when not swimming. All members of
            your party must wear an orange pool access bracelet which you’ll
            find in your Gilbert Hotels. Lost bracelets result in a $100 charge,
            so keep it close!
          </p>
          <Ruler className="pb-1">
            <hr />
          </Ruler>
          <Row>
            <Clock>
              <div>
                <img src={ClockIcon} alt="clock" />
              </div>
              <div>
                {Times.map(item => (
                  <TimesDetails>
                    {item.title}:{item.time}
                  </TimesDetails>
                ))}
              </div>
            </Clock>
          </Row>
        </div>
      </Pool>
    </div>
    <Ruler className="pt-5">
      <hr />
    </Ruler>
  </Row>
);

export default BuildingGuide;
