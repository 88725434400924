import React from 'react';
import SVG from './styled';

interface TwitterProps {
  fill: string;
}

const Twitter = ({ fill }: TwitterProps): JSX.Element => (
  <SVG
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_69_976)">
      <path d="M24.5 4.5585C23.6075 4.95 22.6565 5.2095 21.665 5.3355C22.685 4.7265 23.4635 3.7695 23.8295 2.616C22.8785 3.183 21.8285 3.5835 20.7095 3.807C19.8065 2.8455 18.5195 2.25 17.1155 2.25C14.3915 2.25 12.1985 4.461 12.1985 7.1715C12.1985 7.5615 12.2315 7.9365 12.3125 8.2935C8.222 8.094 4.6025 6.1335 2.171 3.147C1.7465 3.8835 1.4975 4.7265 1.4975 5.634C1.4975 7.338 2.375 8.8485 3.683 9.723C2.8925 9.708 2.117 9.4785 1.46 9.117C1.46 9.132 1.46 9.1515 1.46 9.171C1.46 11.562 3.1655 13.548 5.402 14.0055C5.0015 14.115 4.565 14.1675 4.112 14.1675C3.797 14.1675 3.479 14.1495 3.1805 14.0835C3.818 16.032 5.627 17.4645 7.778 17.511C6.104 18.8205 3.9785 19.6095 1.6775 19.6095C1.274 19.6095 0.887 19.5915 0.5 19.542C2.6795 20.9475 5.2625 21.75 8.048 21.75C17.102 21.75 22.052 14.25 22.052 7.749C22.052 7.5315 22.0445 7.3215 22.034 7.113C23.0105 6.42 23.831 5.5545 24.5 4.5585Z" />
    </g>
    <defs>
      <clipPath id="clip0_69_976">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </SVG>
);

export default Twitter;
