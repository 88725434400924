import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const ContainerWrapper = styled(Container)`
  @media (max-width: 375px) {
    padding: 0px;
  }
`;

export const NotificationDiv = styled.div`
  background: #ffffff;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 15px;
`;

export const FieldDIv = styled.div`
  padding: 30px;
`;

export const Heading = styled.h6`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #212f52;
`;

export const Reservationdiv = styled.div`
  h6 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #212f52;
  }
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #212f52;
  }
  .form-check-input[type='checkbox'] {
    padding: 8px;
    border-radius: 4px;
    :hover {
      cursor: pointer;
    }
  }
  .form-check-input:checked {
    background-color: #212f52;
    border-color: #212f52;
  }
  .input-label {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
  }
`;

export const RemindersDiv = styled.div`
  h6 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #212f52;
  }
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #212f52;
  }
  .form-check-input[type='checkbox'] {
    padding: 8px;
    border-radius: 4px;
    :hover {
      cursor: pointer;
    }
  }
  .form-check-input:checked {
    background-color: #212f52;
    border-color: #212f52;
  }
  .input-label {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
  }
`;

export const GilberthotelsDiv = styled.div`
  h6 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #212f52;
  }
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #212f52;
  }
  .form-check-input[type='checkbox'] {
    padding: 8px;
    border-radius: 4px;
    :hover {
      cursor: pointer;
    }
  }
  .form-check-input:checked {
    background-color: #212f52;
    border-color: #212f52;
  }
  .input-label {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
  }
`;

export const SavePreferencesButton = styled.button`
  background: #212f52;
  border-radius: 5px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #ffffff;
  width: 100%;
  padding: 23px 0px;
`;
