import styled from 'styled-components';

export const BGWrapper = styled.div`
  padding: 60px 0px;
`;

export const Temp = styled.div`
  padding: 60px 0px;
`;

export const GuidebookTitle = styled.h3`
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 60px;
  color: #212f52;
  padding-bottom: 48px;
  @media (max-width: 425px) {
    font-size: 36px;
    padding-bottom: 20px;
  }
`;

export const GuidebookAddress = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #212f52;
`;

export const GuidebookAddressTitle = styled.div`
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #212f52;
  }
  .address {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #212f52;
  }
`;

export const Address = styled.h6`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  color: #212f52;
  padding-top: 20px;
  padding-bottom: 30px;
  b {
    font-weight: 700;
  }
`;

export const Ruler = styled.div`
  hr {
    background: #caccd1;
    border: 1px #caccd1 solid;
  }
`;

export const MapContainer = styled.div`
  width: 100%;
  height: 400px;
  border-radius: 15px;
  overflow: hidden;
`;
