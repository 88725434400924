import React from 'react';
import { Wrapper, Image, LeaderName, LeaderPost } from './styled';

const Leader = ({ name, post }: any): JSX.Element => (
  <Wrapper>
    <Image />
    <div className="pt-4">
      <LeaderName>{name}</LeaderName>
      <LeaderPost>{post}</LeaderPost>
    </div>
  </Wrapper>
);
export default Leader;
