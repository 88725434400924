import React from 'react';
import Wrapper from './styled';

interface HotelImageProps {
  reservation: any;
}

const HotelImage: React.FC<HotelImageProps> = ({
  reservation,
}: HotelImageProps) => (
  <>
    <Wrapper
      src={reservation?.explore?.pictures?.[0]?.url}
      alt={reservation?.id}
    />
  </>
);

export default HotelImage;
