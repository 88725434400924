import React from 'react';
import { Col, Container } from 'react-bootstrap';
import CheckingIn from '../CheckingIn';
import FindAndBooking from '../FindAndBooking';
import StayingWithUs from '../StayingWithUs';
import { BGWrapper, Heading, RowWrapper } from './styled';

const AllTopics: React.FC = () => (
  <Container>
    <BGWrapper>
      <Heading>All Topics</Heading>
      <RowWrapper>
        <Col lg={4} md={12} sm={12} className="py-2">
          <FindAndBooking />
        </Col>
        <Col lg={4} md={12} sm={12} className="py-2">
          <CheckingIn />
        </Col>
        <Col lg={4} md={12} sm={12} className="py-2">
          <StayingWithUs />
        </Col>
      </RowWrapper>
    </BGWrapper>
  </Container>
);

export default AllTopics;
