import { DayPicker } from 'react-day-picker';
import styled from 'styled-components';

export const Box = styled.div`
  background: #ffffff;
  border: 1px solid #caccd1;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 30px;
  @media (max-width: 320px) {
    padding: 30px 15px;
  }
`;

export const Title = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  text-transform: capitalize;
  color: #212f52;
  margin: 0;
`;

export const Content = styled.div`
  margin-top: 30px;
`;

export const Label = styled.p`
  margin: 0;
`;

export const Notes = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #212f52;
`;

export const Button = styled.button`
  width: 100%;
  height: 64px;
  background: #212f52;
  border-radius: 5px;
  color: #ffffff;
  border: 0;
  margin-top: 14px;
  &:disabled {
    background-color: #e9e9e9;
    color: #9f9fa3;
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-around !important;
  @media (max-width: 425px) {
    display: grid;
  }
`;

export const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  background: #f9f9f9;
  border: 2px solid #212f52;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  margin: 10px 0 30px;
  & input {
    border: 0;
    outline: none;
    width: 100%;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #212f52;
  }
  & img {
    // margin-right: 50px;
    @media (max-width: 320px) {
      margin-right: 10px;
    }
  }
`;

export const BillItem = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #212f52;
  border-bottom: 2px #caccd1 solid;
`;

export const BillItemText = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 50px;
  color: #212f52;
  margin: 0px;
`;

export const BillItemAmount = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  text-align: right;
  color: #212f52;
  margin: 0px;
`;

export const BillWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 30%;
`;

export const DateRangeWrapper = styled.div`
  // display: flex;
  // position: relative;
  & .react-datepicker-wrapper {
    width: 60px;
  }
`;

export const Dash = styled.div`
  width: 50px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #212f52;
  text-align: center;
`;

export const BackLink = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  color: #212f52;
  margin-top: 33px;
  cursor: pointer;
`;

export const DateInput = styled.input`
  border: none;
  width: 100%;
  color: #212f52;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: #212f52;
  }
`;

export const DateRangePicker = styled(DayPicker)`
  position: absolute;
  background: white;
  z-index: 1;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  padding: 15px;
  @media (max-width: 425px) {
    display: none;
  }

  & .rdp-caption_label {
    text-align: center;
    vertical-align: middle;
    min-width: 32px;
    line-height: 24px;
    font-size: 12px;
    border-radius: 4px;
    white-space: nowrap;
    cursor: pointer;
  }
  & .rdp-day {
    border-radius: 0;
  }

  & .rdp-day:hover:not([disabled]) {
    background-color: #eee;
    color: #000;
    border-radius: 5px;
  }

  & .rdp-day_range_start,
  .rdp-day_selected:focus:not([disabled]),
  .rdp-day_range_start:hover:not([disabled]) {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    background: #357ebd;
    border: none;
    color: #fff;
  }

  & .rdp-day_range_end,
  .rdp-day_selected:focus:not([disabled]),
  .rdp-day_range_end:hover:not([disabled]) {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    background: #357ebd;
    border: none;
    color: #fff;
  }
  & .rdp-day_range_middle {
    background: #ebf4f8;
    color: #000;
  }

  & .rdp-cell {
    text-align: center;
    vertical-align: middle;
    min-width: 32px;
    width: 32px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    border-radius: 4px;
    /* border: 1px solid transparent; */
    white-space: nowrap;
    cursor: pointer;
    & .rdp-day {
      width: 100%;
      height: 100%;
    }
  }

  & .rdp-head_cell {
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    min-width: 32px;
    width: 32px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    border-radius: 4px;
    white-space: nowrap;
    cursor: pointer;
  }

  & .rdp-nav {
    height: 27px;
    & button {
      height: 100%;
      width: 100%;
      & svg {
        height: 12px;
        width: 12px;
      }
    }
  }
`;

export const DatePicker = styled(DayPicker)`
  position: absolute;
  background: white;
  z-index: 1;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  padding: 15px;
  display: none;
  @media (max-width: 425px) {
    display: block;
  }

  & .rdp-caption_label {
    text-align: center;
    vertical-align: middle;
    min-width: 32px;
    line-height: 24px;
    font-size: 12px;
    border-radius: 4px;
    white-space: nowrap;
    cursor: pointer;
  }
  & .rdp-day {
    border-radius: 0;
  }

  & .rdp-day:hover:not([disabled]) {
    background-color: #eee;
    color: #000;
    border-radius: 5px;
  }

  & .rdp-day_range_start,
  .rdp-day_selected:focus:not([disabled]),
  .rdp-day_range_start:hover:not([disabled]) {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    background: #357ebd;
    border: none;
    color: #fff;
  }

  & .rdp-day_range_end,
  .rdp-day_selected:focus:not([disabled]),
  .rdp-day_range_end:hover:not([disabled]) {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    background: #357ebd;
    border: none;
    color: #fff;
  }
  & .rdp-day_range_middle {
    background: #ebf4f8;
    color: #000;
  }

  & .rdp-cell {
    text-align: center;
    vertical-align: middle;
    min-width: 32px;
    width: 32px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    border-radius: 4px;
    /* border: 1px solid transparent; */
    white-space: nowrap;
    cursor: pointer;
    & .rdp-day {
      width: 100%;
      height: 100%;
    }
  }

  & .rdp-head_cell {
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    min-width: 32px;
    width: 32px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    border-radius: 4px;
    white-space: nowrap;
    cursor: pointer;
  }

  & .rdp-nav {
    height: 27px;
    & button {
      height: 100%;
      width: 100%;
      & svg {
        height: 12px;
        width: 12px;
      }
    }
  }
`;
