import React, { useState, useEffect } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import { Card, FormControl } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { auth } from '../../Config/firebase';
import { MainDiv, SaveButton } from './styled';
import Loader from '../../Common/Loader';

const ResetPasswordContainer: React.FC = () => {
  const [emailValue, setEmailValue] = useState<any | null>();
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [errorvalue, setErrorValue] = useState<any | null>();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const oobCode = params.get('oobCode');

  useEffect(() => {
    const verifyPassworgFunction = async () => {
      try {
        if (oobCode) {
          const email = await auth.verifyPasswordResetCode(
            params.get('oobCode'),
          );
          setEmailValue(email);
          setIsloading(false);
        } else {
          history.push('/');
        }
      } catch (error: any) {
        setIsloading(false);
        switch (error.code) {
          case 'auth/invalid-action-code':
            setErrorValue(
              'Your request to reset your password has expired or the link has already been used',
            );
            break;
          default:
            setErrorValue(error.code);
            break;
        }
      }
    };
    verifyPassworgFunction();
  }, []);

  const validationScheda = Yup.object().shape({
    newPassword: Yup.string()
      .required('New Password is Required')
      .min(8, 'Min 8 character required'),
    confirmPassword: Yup.string()
      .required('Confirm Password is Required')
      .min(8, 'Min 8 character required')
      .oneOf([Yup.ref('newPassword')], 'passwords must match'),
  });

  return (
    <>
      {!isLoading ? (
        <MainDiv>
          {!errorvalue ? (
            <>
              <Card
                style={{ width: '30rem', height: '100%', minHeight: '16rem' }}
              >
                <Card.Body>
                  <Card.Title>Reset your Password</Card.Title>
                  <Card.Text>
                    for <b>{emailValue}</b>
                  </Card.Text>
                  <Formik
                    initialValues={{ newPassword: '', confirmPassword: '' }}
                    validationSchema={validationScheda}
                    onSubmit={async values => {
                      try {
                        const { newPassword } = values;
                        await auth.confirmPasswordReset(
                          params.get('oobCode'),
                          newPassword,
                        );
                        history.push('/');
                        toast.success('Password Successfully Change');
                      } catch (error: any) {
                        setErrorValue(error.code);
                        toast.error(error.code);
                      }
                    }}
                  >
                    {({
                      touched,
                      errors,
                      isSubmitting,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <FormControl
                          name="newPassword"
                          placeholder="Enter New password"
                          type="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`newpassword-input ${
                            touched.newPassword && errors?.newPassword
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="newPassword"
                          className="invalid-feedback mb-3"
                        />
                        <FormControl
                          name="confirmPassword"
                          placeholder="Enter Confirm password"
                          type="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`newpassword-input ${
                            touched.confirmPassword && errors?.confirmPassword
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="confirmPassword"
                          className="invalid-feedback mb-3"
                        />
                        <div className="d-flex justify-content-end">
                          <SaveButton type="submit" disabled={isSubmitting}>
                            Save
                          </SaveButton>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </>
          ) : (
            <>
              <Card
                style={{ width: '30rem', height: '100%', minHeight: '10rem' }}
              >
                <Card.Body>
                  <Card.Title style={{ textAlign: 'center' }}>
                    Try resetting your password again
                  </Card.Title>
                  <Card.Text style={{ textAlign: 'center' }}>
                    {errorvalue}
                  </Card.Text>
                </Card.Body>
              </Card>
            </>
          )}
        </MainDiv>
      ) : (
        <>
          <div style={{ marginTop: '23%' }}>
            <Loader />
          </div>
        </>
      )}
    </>
  );
};

export default ResetPasswordContainer;
