import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Container, FormControl, Modal, Spinner } from 'react-bootstrap';
import firebase from 'firebase/compat/app';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { reauthenticateWithCredential } from 'firebase/auth';
import PasswordIcon from '../../../Assets/Images/Icons/PasswordIcon.svg';
import AuthContext from '../../../Contexts/AuthContext';
import { auth } from '../../../Config/firebase';
import {
  Button,
  ChangePassword,
  ContainerWrapper,
  FieldDiv,
  Heading,
  Info,
  InputIcon,
  SaveUpdateButton,
  UpdateAccountDiv,
} from './styled';

const UpdatePassword: React.FC = () => {
  const authProvider = useContext(AuthContext);
  const [show, setShow] = useState<boolean>(false);
  const [passwordValue, setPasswordValue] = useState<string>('');

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required('Current Password is required')
      .min(8, 'Min 8 character required'),
    newPassword: Yup.string().when(
      'currentPassword',
      (currentPassword, field) =>
        currentPassword
          ? Yup.string()
              .required('New Password is required')
              .min(8, 'Min 8 character required')
          : field,
    ),
    confirm_Password: Yup.string().when(
      'currentPassword',
      (currentPassword, field) =>
        currentPassword
          ? Yup.string()
              .required('Confirm Password is required')
              .min(8, 'Min 8 character required')
              .oneOf([Yup.ref('newPassword')], 'passwords must match')
          : field,
    ),
  });

  const handleClose = () => setShow(false);
  const handleShow = async () => {
    try {
      const credential = firebase.auth.EmailAuthProvider.credential(
        auth.currentUser.email,
        passwordValue,
      );
      await reauthenticateWithCredential(auth.currentUser, credential);
      setShow(false);
    } catch (error: any) {
      switch (error.code) {
        case 'auth/wrong-password':
          toast.error(
            ' The password is invalid or the user does not have a password.',
          );
          break;

        default:
          break;
      }
      console.log('Error', error);
    }
  };

  const updatepassword = (
    newPassword: string,
    currentPassword: string,
    { setErrors }: any,
  ) => {
    const credential = firebase.auth.EmailAuthProvider.credential(
      auth.currentUser.email,
      currentPassword,
    );
    reauthenticateWithCredential(auth.currentUser, credential)
      .then(result => {
        authProvider
          .updatePassword(newPassword)
          .then(res => {
            toast.success('Successfully Password Updated!');
          })
          .catch(error => {
            switch (error.code) {
              case 'auth/requires-recent-login':
                setErrors({
                  newPassword:
                    'This operation is sensitive and requires recent authentication.',
                });
                setShow(true);
                break;
              default:
                setErrors({ newPassword: 'password invalid' });
                break;
            }
          });
      })
      .catch(error => {
        switch (error.code) {
          case 'auth/wrong-password':
            setErrors({
              currentPassword: `The password is invalid or the user does not have a password.`,
            });
            break;

          default:
            setErrors({ currentPassword: 'password invalid' });
            break;
        }
      });
  };
  return (
    <>
      <ContainerWrapper className="pt-4">
        <UpdateAccountDiv>
          <FieldDiv>
            <Heading className="pt-3">Update Password</Heading>
            <Info>You can update your contact password here.</Info>
            <Formik
              initialValues={{
                newPassword: '',
                confirm_Password: '',
                currentPassword: '',
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={async (values, { setErrors, setSubmitting }) => {
                setSubmitting(true);
                try {
                  const { newPassword, currentPassword } = values;
                  updatepassword(newPassword, currentPassword, { setErrors });
                } catch (error) {
                  setSubmitting(false);
                }
              }}
            >
              {({
                touched,
                errors,
                isSubmitting,
                handleSubmit,
                handleChange,
                handleBlur,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="d-flex">
                    <InputIcon src={PasswordIcon} />
                    <FormControl
                      name="currentPassword"
                      placeholder="Current Password"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`edit-profile-input ${
                        touched.currentPassword && errors?.currentPassword
                          ? 'is-invalid'
                          : ''
                      }`}
                    />
                  </div>
                  <ErrorMessage
                    component="div"
                    name="currentPassword"
                    className="invalid-feedback"
                  />
                  <div className="d-flex pt-4">
                    <InputIcon src={PasswordIcon} />
                    <FormControl
                      name="newPassword"
                      placeholder="New Password"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`edit-profile-input ${
                        touched.newPassword && errors?.newPassword
                          ? 'is-invalid'
                          : ''
                      }`}
                    />
                  </div>
                  <ErrorMessage
                    component="div"
                    name="newPassword"
                    className="invalid-feedback"
                  />
                  <div className="d-flex pt-4">
                    <InputIcon src={PasswordIcon} />
                    <FormControl
                      name="confirm_Password"
                      placeholder="Confirm New Password"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`edit-profile-input ${
                        touched.confirm_Password && errors?.confirm_Password
                          ? 'is-invalid'
                          : ''
                      }`}
                    />
                  </div>
                  <ErrorMessage
                    component="div"
                    name="confirm_Password"
                    className="invalid-feedback"
                  />
                  <ChangePassword>
                    We need your current password to confirm your changes
                  </ChangePassword>
                  {!isSubmitting ? (
                    <SaveUpdateButton disabled={isSubmitting} className="mt-3">
                      Save Updated Password
                    </SaveUpdateButton>
                  ) : (
                    <div className="text-center">
                      <Spinner animation="border" />
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </FieldDiv>
        </UpdateAccountDiv>
      </ContainerWrapper>

      <Modal show={show} onHide={handleClose}>
        {/* <CloseIcon src={CloseIconSvg} onClick={handleClose} /> */}
        <Container>
          <Modal.Body className="pt-5">
            <FormControl
              type="text"
              value={auth?.currentUser?.email}
              className="input"
              disabled
            />
            <FormControl
              type="password"
              className="input"
              onChange={e => setPasswordValue(e.target.value)}
              placeholder="Password"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button className="button-cancel" onClick={handleClose}>
              Close
            </Button>
            <Button className="button-submit" onClick={handleShow}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Container>
      </Modal>
    </>
  );
};
export default UpdatePassword;
