import styled from 'styled-components';

interface ItemImageProps {
  src: string;
}

export const BGWrapper = styled.div`
  padding: 0px 300px;
`;

export const Heading = styled.h6`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #212f52;
`;

export const Details = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #212f52;
`;

export const Ruler = styled.div`
  hr {
    background: #caccd1;
    border: 1px #caccd1 solid;
  }
`;

export const AppLinkImage = styled.img<ItemImageProps>`
  position: static;
  width: 176px;
  height: 60px;
  left: 0px;
  top: 0px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const HelpDetails = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #212f52;
  .helpLink {
    color: #212f52;
  }
`;
