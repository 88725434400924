import React, { useContext, useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutDetails from '../CheckoutDetails';
import CheckoutCostDetails from '../CheckoutCostDetails';
import { BGWrapper } from './styled';
import { db } from '../../Config/firebase';
import AuthContext from '../../Contexts/AuthContext';
import Loader from '../../Common/Loader';

const stripePromise = loadStripe(
  'pk_test_51KpPUxDLkhxgaBQSErQOPF7qLangEpR7zGwIBDSWEsqbtNXuBHIJ6hWo2c1xOTvGWXXeUWjkg5Jv27Ioe7jkpyQb00YVNihGbX',
);

const CheckoutDetailContainer: React.FC = () => {
  const authProvider = useContext(AuthContext);
  const currentUser = authProvider?.basicUserDetail;
  const { id }: any = useParams();
  const [billingData, setBillingData] = useState<any | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [reservation, setReservation] = useState<any>(null);
  const clientSecret = billingData?.payment?.client_secret;
  const location = useLocation();
  const paramsquery = new URLSearchParams(location?.search);
  const isPaymentSuccess = paramsquery.get('redirect_status') === 'succeeded';

  const getBillingDetail = async () => {
    setLoading(true);
    await db
      .collection('checkout')
      .doc(id)
      .onSnapshot(async response => {
        const cartData = response?.data();
        if (currentUser?.uuid === cartData.guest.uuid) {
          setBillingData({ ...cartData, id: response?.id });
          if (cartData?.payment?.status === 'succeeded') {
            await db
              .collection('reservations')
              .where('checkout_id', '==', id)
              .onSnapshot(querySnapshot => {
                querySnapshot?.forEach(item => {
                  setReservation({
                    ...item?.data(),
                    id: item?.id,
                  });
                });
              });
          }
        } else {
          <Redirect to="/" />;
        }
      });
    setLoading(false);
  };

  useEffect(() => {
    getBillingDetail();
  }, []);

  useEffect(() => {
    if (isPaymentSuccess) getBillingDetail();
  }, [isPaymentSuccess]);

  const options = {
    clientSecret,
  };

  return (
    <BGWrapper>
      <Container>
        {!loading ? (
          <Row>
            <Col lg={8} md={12} sm={12}>
              <>
                {clientSecret && (
                  <Elements stripe={stripePromise} options={options}>
                    <CheckoutDetails
                      currentUser={currentUser}
                      billingData={billingData}
                      loading={loading}
                      reservation={reservation}
                    />
                  </Elements>
                )}
              </>
            </Col>
            <Col lg={4} md={12} sm={12}>
              {billingData && <CheckoutCostDetails billingData={billingData} />}
            </Col>
          </Row>
        ) : (
          <div style={{ margin: '20% 35%' }}>
            <Loader />
          </div>
        )}
      </Container>
    </BGWrapper>
  );
};

export default CheckoutDetailContainer;
