import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Container from '../../styled';
import LeituraFont from '../../Assets/Fonts/LeituraDisplayRoman.otf';

export const ComponentsContainer = styled(Container)`
  position: relative;
`;

export const ItemWrapper = styled(Col)`
  padding-bottom: 50px;
`;

export const ItemBox = styled.div`
  border-radius: 15px;
  text-decoration: none;
  .image-contrainer {
    position: relative;
    .price-tag {
      flex-direction: row;
      align-items: flex-start;
      padding: 8px 15px;
      position: absolute;
      right: 0%;
      top: 0%;
      background: #212f52;
      border-radius: 0px 15px;
      padding: 10px 18px;
      h5 {
        display: flex;
        font-family: 'Arial';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        text-align: right;
        color: #ffffff;
        margin: 0px 0px;
        padding-right: 0px;
        padding-bottom: 0px;
      }
      p {
        display: flex;
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-align: right;
        color: #ffffff;
        margin: 0px 0px;
        justify-content: right;
      }
    }
    .image {
      height: 300px;
      background-repeat: round;
      background-size: cover;
      border-radius: 15px;
    }
    .not-image {
      height: 300px;
      border-radius: 15px;
      background: #adadad;
    }
  }
`;

export const FeatureItemTitle = styled.h2`
  @font-face {
    font-family: 'Leitura Display';
    src: local('Leitura Display'), local('LeituraDisplay'),
      url(${LeituraFont}) format('truetype');
  }
  font-family: 'Leitura Display';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 30px;
  margin: 20px 0px;
  margin-bottom: 10px;
  color: #212f52;
`;
export const FeatureItemLocation = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #212f52;
  padding-bottom: 20px;
  margin: 0px;
  text-decoration: none;
`;

export const FeatureItemItem = styled.div`
  display: flex;
  p {
    text-decoration: none;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 16px;
    color: #6a737b;
    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 0px;
    @media (max-width: 1024px) {
      font-size: 15px;
    }
  }
  .dot {
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 10px;
    height: 4px;
    width: 4px;
    background-color: #6a737b;
    border-radius: 5px;
  }
`;

export const LoaderDiv = styled.div`
  margin: 25% 50%;
`;

export const NoResults = styled.div`
  h3 {
    color: #212f52;
    font-family: 'Leitura Display';
    font-size: 64px;
    text-align: center;
  }
  p {
    color: #212f52;
    font-family: 'Arial';
    font-size: 16px;
    text-align: center;
    line-height: 16px;
    font-weight: 400;
  }
  button {
    background: #e6b022;
    border: 0px;
    font-size: 16px;
    font-family: 'Arial';
    border-radius: 30px;
    color: #212f52;
    text-align: center;
  }
`;

export const RowWrapper = styled(Row)`
  min-height: 550px;
  height: 550px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none !important;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  @media (max-width: 1024px) {
    height: 100%;
  }
`;
