import { collection, getDocs, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactSelect from 'react-select';

import { db } from '../../../Config/firebase';

import { SearchInputWrapper, SelectCityBox, Search } from './styled';

interface SearchInputProps {
  setSelectedCity: (value: {
    key: string;
    label: string;
    value: string;
  }) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
  setSelectedCity,
}: SearchInputProps) => {
  const [cities, setCities] = useState<any>([]);
  const params: any = useParams();

  useEffect(() => {
    const getCityLiist = async () => {
      const q = query(collection(db, 'cities'));
      const querySnapshot = await getDocs(q);
      const tempdata: any = [];
      querySnapshot.forEach(doc => {
        tempdata.push({
          label: doc.data().name,
          value: doc.data().name,
          key: doc.data().name,
        });
      });
      setCities(tempdata);
    };
    getCityLiist();
  }, []);

  return (
    <>
      <SearchInputWrapper>
        <Search fill="#fff" />
        <SelectCityBox>
          <ReactSelect
            isMulti={false}
            id="react-select-3-input"
            styles={{
              control: () => ({
                border: 0,
                display: 'flex',
                color: '#212f52',
              }),
              indicatorSeparator: () => ({ display: 'none' }),
              dropdownIndicator: () => ({ display: 'none' }),
              input: () => ({
                color: '#212f52',
                position: 'absolute',
                paddingLeft: '10px',
              }),
              valueContainer: () => ({
                alignItems: 'center',
                display: 'grid',
                flex: 1,
                flexWrap: 'wrap',
                padding: '2px 8px',
                position: 'relative',
                boxSizing: 'border-box',
              }),
            }}
            placeholder="Search city"
            onChange={setSelectedCity}
            options={cities}
            defaultValue={params.city}
            defaultInputValue={params.city}
          />
        </SelectCityBox>
      </SearchInputWrapper>
    </>
  );
};
export default SearchInput;
