import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  BGWrapper,
  CardDiv,
  CheckoutMoreLink,
  Details,
  Wrapper,
} from './styled';

interface CheckoutMoreProps {
  cityList: any;
}

const CheckoutMore: React.FC<CheckoutMoreProps> = ({
  cityList,
}: CheckoutMoreProps) => (
  <BGWrapper>
    <Container>
      <Row>
        {cityList?.map((item: any) => (
          <Col lg={4} md={6} sm={12} key={item?.id}>
            <CardDiv
              as={Link}
              to={`/exploration/${item?.name}/search`}
              key={item?.id}
            >
              <Card.Body>
                <Card.Title style={{ color: `${item?.color}` }}>
                  Gilbert hotels
                </Card.Title>
                <Card.Text>{item?.name}</Card.Text>
                <Wrapper />
              </Card.Body>
            </CardDiv>
          </Col>
        ))}
      </Row>
      <Container>
        <Row className="d-flex justify-content-center pb-5">
          <Col lg={6} md={8} sm={12}>
            <Details>
              We’re building an entirely new way to stay in your favorite
              neighborhoods around the world.
            </Details>
            <Row className="d-flex justify-content-center">
              <Col lg={5} md={7} sm={7} style={{ display: 'contents' }}>
                <CheckoutMoreLink to="/" className="px-5 py-3 d-flex">
                  Check out more
                </CheckoutMoreLink>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  </BGWrapper>
);
export default CheckoutMore;
